<template>
  <div class="mb-6 pa-4">
      <v-dialog persistent v-model="dialogoprogress" max-width="200">
          <v-card class="pa-12">
              <v-progress-circular :rotate="90" :size="100" :width="15" color="primary" indeterminate></v-progress-circular>
          </v-card>
      </v-dialog>
  
      <v-card>
          <v-card-title>
              <v-row class="mx-auto mt-n4 text-center">
                  <v-col cols="6">
                      <v-text-field type="date" v-model="date" label="Inicio"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                      <v-text-field type="date" v-model="date2" label="Fin"></v-text-field>
                  </v-col>
              </v-row>
          </v-card-title>
  
          <v-row class="mx-auto mt-n6 text-center">
              <v-col cols="6">
                  <v-btn @click="ejecutaConsolida()" color="success">consolida</v-btn>
              </v-col>
              <v-col cols="6">
                  <h4 class="mx-auto text-center">Total a Consolidar = S/. {{sumaConsolida()}}</h4>
              </v-col>
          </v-row>
          <v-simple-table fixed-header height="70vh" dense>
              <template v-slot:default>
                  <thead>
                      <tr>
                          <th class="text-left">
                              Correlativo
                          </th>
                          <th class="text-left">
                              Fecha
                          </th>
                          <th class="text-left">
                              Total
                          </th>
                          <th class="text-left">
                              Accion
                          </th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-for="item in listafiltrada" :key="item.id">
                          <td>{{ item.numeracion }}</td>
                          <td>{{ conviertefecha(item.fecha) }}</td>
                          <td>S/.{{ item.total }}</td>
                          <td>
                              <v-checkbox class="mt-n4 mb-n8" v-model="item.consolida"></v-checkbox>
                          </td>
                      </tr>
                  </tbody>
              </template>
          </v-simple-table>
  
      </v-card>
  
      <div class="text-center">
          <v-snackbar v-model="snackbar" :timeout="timeout" top>
              {{ text }}
  
              <template v-slot:action="{ attrs }">
                  <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
                      Close
                  </v-btn>
              </template>
          </v-snackbar>
      </div>
      <caja />
  </div>
  </template>
  
  <script>
  import {
      allCabecera,
      consultaDetalle,
      obtenContador,
      sumaContador,
      grabaCabecera,
      grabaDetalle,
      grabaEstadoTicketConsolidado,
  } from '../../db'
  import moment from 'moment'
  import store from '@/store/index'
  import axios from "axios"
  import caja from '@/components/dialogos/dialogo_caja'
  export default {
      components: {
          caja
      },
      data: () => ({
          snackbar: false,
          text: '',
          timeout: 2000,
          dialogoprogress: false,
          dialog: false,
          botonConsolida: true,
          dialogcobra: false,
          tipocomprobante: 'B',
          nombreCompleto: '',
          direccion: '',
          radios: 'Efectivo',
          numero: '',
          documento: 'DNI',
          ordenboleta: '',
          ordenfactura: '',
          desserts: [],
          arrayConsolidar: [],
          date: moment(String(new Date)).format('YYYY-MM-DD'),
          date2: moment(String(new Date)).format('YYYY-MM-DD'),
          date3: moment(String(new Date)).format('YYYY-MM-DD'),
          data_consolida: []
      }),
      mounted() {
          allCabecera().orderByChild('fecha').startAt(moment(String(this.date)) / 1000).endAt(moment(String(this.date2)).add(23, 'h').add(59, 'm').add(59, 's') / 1000).once("value", this.onDataChange);
      },
      destroyed() {
          allCabecera().off("value");
      },
      computed: {
          listafiltrada() {
              allCabecera().orderByChild('fecha').startAt(moment(String(this.date)) / 1000).endAt(moment(String(this.date2)).add(23, 'h').add(59, 'm').add(59, 's') / 1000).on("value", this.onDataChange);
              return this.desserts
          }
      },
      methods: {
          onDataChange(items) {
              let array = [];
              items.forEach((item) => {
                  let data = item.val();
                  if (data.estado == 'aprobado' && data.tipocomprobante == 'T') {
                      data.consolida = false
                      array.push(data);
                  }
              });
              this.desserts = array;
          },
  
          conviertefecha(date) {
              return moment.unix(date).format('DD/MM/YYYY hh:mm A')
          },
          sumaConsolida() {
              if (store.state.completa_cobro) {
                  console.log(store.state.ultimo_correlativo)
                  this.grabaConsolido(store.state.ultimo_correlativo, this.data_consolida)
                  store.commit("dialogo_caja")
                  store.commit("completa_cobro", false)
              }
              var total = 0
              for (var i = 0; i < this.desserts.length; i++) {
                  if (this.desserts[i].consolida == true) {
                      total = total + parseFloat(this.desserts[i].total) - parseFloat(this.desserts[i].descuentos)
                  }
              }
              return total
          },
          ejecutaConsolida() {
              this.consolida_detalles(this.desserts).then((r) => {
                  console.log(r)
                  store.commit("productos_consolida", r)
                  store.commit('dialogo_caja')
              })
          },
          consolida_detalles(arrays) {
              this.data_consolida = arrays
              var consolidado = []
              var a = new Promise((resolve, reject) => {
                  arrays.forEach((items, index, array) => {
                      var data = items
                      if (data.consolida == true) {
                          consultaDetalle(data.numeracion).once("value").then((snapshot) => {
                              snapshot.forEach((item) => {
                                  consolidado.push(item.val())
                              })
                              if (index === array.length - 1) {
                                  resolve(consolidado)
                              };
                          })
                      } else {
                          if (index === array.length - 1) {
                              resolve(consolidado)
                          };
                      }
                  })
              })
              return a
          },
          grabaConsolido(correlativo, array) {
              console.log(array)
              for (var i = 0; i < array.length; i++) {
                  if (array[i].consolida == true) {
                            grabaEstadoTicketConsolidado(array[i].numeracion, correlativo)
                  }
              }
          },
          graba() {
              this.dialog = false
              this.botonConsolida = false
              this.consolida()
          },
          consolida() {
              this.numero = '-'
              this.nombreCompleto = '---'
              obtenContador().once("value").then((snapshot) => {
                  this.ordenboleta = snapshot.val().ordenboleta
                  this.ordenfactura = snapshot.val().ordenfactura
                  this.ordenticket = snapshot.val().ordenticket
                  this.dialogcobra = true
              })
  
          },
  
          sumaTotal() {
              var suma = 0
              for (var i = 0; i < this.arrayConsolidar.length; i++) {
                  suma = suma + (this.arrayConsolidar[i].cantidad * this.arrayConsolidar[i].precioedita)
              }
              this.totalDocumento = suma
              return suma
          },
          sumaDescuentos() {
              var suma = 0
              for (var i = 0; i < this.arrayConsolidar.length; i++) {
                  suma = suma + parseInt(this.arrayConsolidar[i].preciodescuento)
              }
              this.totalDescuento = suma
              return suma
          },
          llenardatos(data) {
              if (this.documento == 'DNI') {
                  this.nombreCompleto = data.nombre_completo
              }
              if (this.documento == 'RUC') {
                  this.nombreCompleto = data.nombre_o_razon_social
                  this.direccion = data.direccion_completa
              }
          },
  
          verdate() {
              var fecha = moment(String(this.date3)) / 1000
              if (moment(String(new Date())).format('YYYY-MM-DD') == moment(String(this.date3)).format('YYYY-MM-DD')) {
                  fecha = moment().unix()
              }
              return fecha
  
          },
          snack(text) {
              this.snackbar = true
              this.text = text
          },
  
          grabaVenta(numeroOrden) {
              this.grabaConsolido(this.tipocomprobante + numeroOrden, this.desserts)
              this.numero = ''
              this.nombreCompleto = ''
              this.direccion = ''
              this.totalDocumento = ''
              this.dialogcobra = false
              this.dialogoprogress = false
          },
  
          sumarCorrelativo() {
              if (this.tipocomprobante == "B") {
                  sumaContador("ordenboleta", (parseInt(this.ordenboleta) + 1).toString().padStart(8, 0))
              }
              if (this.tipocomprobante == "F") {
                  sumaContador("ordenfactura", (parseInt(this.ordenfactura) + 1).toString().padStart(8, 0))
              }
          },
  
      }
  }
  </script>
  