export const departamento = () => {
    var nombre = []
    var i = 0
    var snapshot = depas()
    snapshot.forEach((item) => {
        nombre.push({
            id: i,
            nombre: item.nombre_ubigeo,
            ubigeo: item.id_ubigeo,
            ubigeo_sunat: item.codigo_ubigeo
        })
        i++
    })
    return nombre
}
export const provincia = (ubigeo) => {
    var nombre = []
    var i = 0
    var snapshot = provin(ubigeo)
    snapshot.forEach((item) => {
        nombre.push({
            id: i,
            nombre: item.nombre_ubigeo,
            ubigeo: item.id_ubigeo,
            ubigeo_sunat: item.codigo_ubigeo
        })
        i++
    })
    return nombre
}
export const distrito = (ubigeo) => {
    var nombre = []
    var i = 0
    var snapshot = distri(ubigeo)
    snapshot.forEach((item) => {
        nombre.push({
            id: i,
            nombre: item.nombre_ubigeo,
            ubigeo: item.id_ubigeo,
            ubigeo_sunat: item.codigo_ubigeo
        })
        i++
    })
    return nombre
}

function depas() {
    var depas = [
        {
            "buscador_ubigeo": "amazonas perú",
            "codigo_ubigeo": "01",
            "etiqueta_ubigeo": "Amazonas, Perú",
            "id_padre_ubigeo": "2533",
            "id_ubigeo": "2534",
            "nivel_ubigeo": "1",
            "nombre_ubigeo": "Amazonas",
            "numero_hijos_ubigeo": "7"
        },
        {
            "buscador_ubigeo": "ancash perú",
            "codigo_ubigeo": "02",
            "etiqueta_ubigeo": "Ancash, Perú",
            "id_padre_ubigeo": "2533",
            "id_ubigeo": "2625",
            "nivel_ubigeo": "1",
            "nombre_ubigeo": "Ancash",
            "numero_hijos_ubigeo": "20"
        },
        {
            "buscador_ubigeo": "apurimac perú",
            "codigo_ubigeo": "03",
            "etiqueta_ubigeo": "Apurimac, Perú",
            "id_padre_ubigeo": "2533",
            "id_ubigeo": "2812",
            "nivel_ubigeo": "1",
            "nombre_ubigeo": "Apurimac",
            "numero_hijos_ubigeo": "7"
        },
        {
            "buscador_ubigeo": "arequipa perú",
            "codigo_ubigeo": "04",
            "etiqueta_ubigeo": "Arequipa, Perú",
            "id_padre_ubigeo": "2533",
            "id_ubigeo": "2900",
            "nivel_ubigeo": "1",
            "nombre_ubigeo": "Arequipa",
            "numero_hijos_ubigeo": "8"
        },
        {
            "buscador_ubigeo": "ayacucho perú",
            "codigo_ubigeo": "05",
            "etiqueta_ubigeo": "Ayacucho, Perú",
            "id_padre_ubigeo": "2533",
            "id_ubigeo": "3020",
            "nivel_ubigeo": "1",
            "nombre_ubigeo": "Ayacucho",
            "numero_hijos_ubigeo": "11"
        },
        {
            "buscador_ubigeo": "cajamarca perú",
            "codigo_ubigeo": "06",
            "etiqueta_ubigeo": "Cajamarca, Perú",
            "id_padre_ubigeo": "2533",
            "id_ubigeo": "3143",
            "nivel_ubigeo": "1",
            "nombre_ubigeo": "Cajamarca",
            "numero_hijos_ubigeo": "13"
        },
        {
            "buscador_ubigeo": "cusco perú",
            "codigo_ubigeo": "08",
            "etiqueta_ubigeo": "Cusco, Perú",
            "id_padre_ubigeo": "2533",
            "id_ubigeo": "3292",
            "nivel_ubigeo": "1",
            "nombre_ubigeo": "Cusco",
            "numero_hijos_ubigeo": "13"
        },
        {
            "buscador_ubigeo": "huancavelica perú",
            "codigo_ubigeo": "09",
            "etiqueta_ubigeo": "Huancavelica, Perú",
            "id_padre_ubigeo": "2533",
            "id_ubigeo": "3414",
            "nivel_ubigeo": "1",
            "nombre_ubigeo": "Huancavelica",
            "numero_hijos_ubigeo": "7"
        },
        {
            "buscador_ubigeo": "huanuco perú",
            "codigo_ubigeo": "10",
            "etiqueta_ubigeo": "Huanuco, Perú",
            "id_padre_ubigeo": "2533",
            "id_ubigeo": "3518",
            "nivel_ubigeo": "1",
            "nombre_ubigeo": "Huanuco",
            "numero_hijos_ubigeo": "11"
        },
        {
            "buscador_ubigeo": "ica perú",
            "codigo_ubigeo": "11",
            "etiqueta_ubigeo": "Ica, Perú",
            "id_padre_ubigeo": "2533",
            "id_ubigeo": "3606",
            "nivel_ubigeo": "1",
            "nombre_ubigeo": "Ica",
            "numero_hijos_ubigeo": "5"
        },
        {
            "buscador_ubigeo": "junin perú",
            "codigo_ubigeo": "12",
            "etiqueta_ubigeo": "Junin, Perú",
            "id_padre_ubigeo": "2533",
            "id_ubigeo": "3655",
            "nivel_ubigeo": "1",
            "nombre_ubigeo": "Junin",
            "numero_hijos_ubigeo": "9"
        },
        {
            "buscador_ubigeo": "la libertad perú",
            "codigo_ubigeo": "13",
            "etiqueta_ubigeo": "La Libertad, Perú",
            "id_padre_ubigeo": "2533",
            "id_ubigeo": "3788",
            "nivel_ubigeo": "1",
            "nombre_ubigeo": "La Libertad",
            "numero_hijos_ubigeo": "12"
        },
        {
            "buscador_ubigeo": "lambayeque perú",
            "codigo_ubigeo": "14",
            "etiqueta_ubigeo": "Lambayeque, Perú",
            "id_padre_ubigeo": "2533",
            "id_ubigeo": "3884",
            "nivel_ubigeo": "1",
            "nombre_ubigeo": "Lambayeque",
            "numero_hijos_ubigeo": "3"
        },
        {
            "buscador_ubigeo": "lima perú",
            "codigo_ubigeo": "15",
            "etiqueta_ubigeo": "Lima, Perú",
            "id_padre_ubigeo": "2533",
            "id_ubigeo": "3926",
            "nivel_ubigeo": "1",
            "nombre_ubigeo": "Lima",
            "numero_hijos_ubigeo": "10"
        },
        {
            "buscador_ubigeo": "loreto perú",
            "codigo_ubigeo": "16",
            "etiqueta_ubigeo": "Loreto, Perú",
            "id_padre_ubigeo": "2533",
            "id_ubigeo": "4108",
            "nivel_ubigeo": "1",
            "nombre_ubigeo": "Loreto",
            "numero_hijos_ubigeo": "6"
        },
        {
            "buscador_ubigeo": "madre de dios perú",
            "codigo_ubigeo": "17",
            "etiqueta_ubigeo": "Madre de Dios, Perú",
            "id_padre_ubigeo": "2533",
            "id_ubigeo": "4165",
            "nivel_ubigeo": "1",
            "nombre_ubigeo": "Madre de Dios",
            "numero_hijos_ubigeo": "3"
        },
        {
            "buscador_ubigeo": "moquegua perú",
            "codigo_ubigeo": "18",
            "etiqueta_ubigeo": "Moquegua, Perú",
            "id_padre_ubigeo": "2533",
            "id_ubigeo": "4180",
            "nivel_ubigeo": "1",
            "nombre_ubigeo": "Moquegua",
            "numero_hijos_ubigeo": "3"
        },
        {
            "buscador_ubigeo": "pasco perú",
            "codigo_ubigeo": "19",
            "etiqueta_ubigeo": "Pasco, Perú",
            "id_padre_ubigeo": "2533",
            "id_ubigeo": "4204",
            "nivel_ubigeo": "1",
            "nombre_ubigeo": "Pasco",
            "numero_hijos_ubigeo": "3"
        },
        {
            "buscador_ubigeo": "piura perú",
            "codigo_ubigeo": "20",
            "etiqueta_ubigeo": "Piura, Perú",
            "id_padre_ubigeo": "2533",
            "id_ubigeo": "4236",
            "nivel_ubigeo": "1",
            "nombre_ubigeo": "Piura",
            "numero_hijos_ubigeo": "8"
        },
        {
            "buscador_ubigeo": "puno perú",
            "codigo_ubigeo": "21",
            "etiqueta_ubigeo": "Puno, Perú",
            "id_padre_ubigeo": "2533",
            "id_ubigeo": "4309",
            "nivel_ubigeo": "1",
            "nombre_ubigeo": "Puno",
            "numero_hijos_ubigeo": "13"
        },
        {
            "buscador_ubigeo": "san martin perú",
            "codigo_ubigeo": "22",
            "etiqueta_ubigeo": "San Martin, Perú",
            "id_padre_ubigeo": "2533",
            "id_ubigeo": "4431",
            "nivel_ubigeo": "1",
            "nombre_ubigeo": "San Martin",
            "numero_hijos_ubigeo": "10"
        },
        {
            "buscador_ubigeo": "tacna perú",
            "codigo_ubigeo": "23",
            "etiqueta_ubigeo": "Tacna, Perú",
            "id_padre_ubigeo": "2533",
            "id_ubigeo": "4519",
            "nivel_ubigeo": "1",
            "nombre_ubigeo": "Tacna",
            "numero_hijos_ubigeo": "4"
        },
        {
            "buscador_ubigeo": "tumbes perú",
            "codigo_ubigeo": "24",
            "etiqueta_ubigeo": "Tumbes, Perú",
            "id_padre_ubigeo": "2533",
            "id_ubigeo": "4551",
            "nivel_ubigeo": "1",
            "nombre_ubigeo": "Tumbes",
            "numero_hijos_ubigeo": "3"
        },
        {
            "buscador_ubigeo": "ucayali perú",
            "codigo_ubigeo": "25",
            "etiqueta_ubigeo": "Ucayali, Perú",
            "id_padre_ubigeo": "2533",
            "id_ubigeo": "4567",
            "nivel_ubigeo": "1",
            "nombre_ubigeo": "Ucayali",
            "numero_hijos_ubigeo": "4"
        }
    ]
    return depas
}
function provin(val) {
    var prov = {
        "2534": [
            {
                "buscador_ubigeo": "bagua amazonas",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Bagua, Amazonas",
                "id_padre_ubigeo": "2534",
                "id_ubigeo": "2557",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Bagua",
                "numero_hijos_ubigeo": "5"
            },
            {
                "buscador_ubigeo": "bongara amazonas",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Bongara, Amazonas",
                "id_padre_ubigeo": "2534",
                "id_ubigeo": "2563",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Bongara",
                "numero_hijos_ubigeo": "12"
            },
            {
                "buscador_ubigeo": "chachapoyas amazonas",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Chachapoyas, Amazonas",
                "id_padre_ubigeo": "2534",
                "id_ubigeo": "2535",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Chachapoyas",
                "numero_hijos_ubigeo": "21"
            },
            {
                "buscador_ubigeo": "condorcanqui amazonas",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Condorcanqui, Amazonas",
                "id_padre_ubigeo": "2534",
                "id_ubigeo": "2576",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Condorcanqui",
                "numero_hijos_ubigeo": "3"
            },
            {
                "buscador_ubigeo": "luya amazonas",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Luya, Amazonas",
                "id_padre_ubigeo": "2534",
                "id_ubigeo": "2580",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Luya",
                "numero_hijos_ubigeo": "23"
            },
            {
                "buscador_ubigeo": "rodriguez de mendoza amazonas",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Rodriguez de Mendoza, Amazonas",
                "id_padre_ubigeo": "2534",
                "id_ubigeo": "2604",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Rodriguez de Mendoza",
                "numero_hijos_ubigeo": "12"
            },
            {
                "buscador_ubigeo": "utcubamba amazonas",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Utcubamba, Amazonas",
                "id_padre_ubigeo": "2534",
                "id_ubigeo": "2617",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Utcubamba",
                "numero_hijos_ubigeo": "7"
            }
        ],
        "2625": [
            {
                "buscador_ubigeo": "aija ancash",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Aija, Ancash",
                "id_padre_ubigeo": "2625",
                "id_ubigeo": "2639",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Aija",
                "numero_hijos_ubigeo": "5"
            },
            {
                "buscador_ubigeo": "antonio raymondi ancash",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Antonio Raymondi, Ancash",
                "id_padre_ubigeo": "2625",
                "id_ubigeo": "2645",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Antonio Raymondi",
                "numero_hijos_ubigeo": "6"
            },
            {
                "buscador_ubigeo": "asuncion ancash",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Asuncion, Ancash",
                "id_padre_ubigeo": "2625",
                "id_ubigeo": "2652",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Asuncion",
                "numero_hijos_ubigeo": "2"
            },
            {
                "buscador_ubigeo": "bolognesi ancash",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Bolognesi, Ancash",
                "id_padre_ubigeo": "2625",
                "id_ubigeo": "2655",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Bolognesi",
                "numero_hijos_ubigeo": "15"
            },
            {
                "buscador_ubigeo": "carhuaz ancash",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Carhuaz, Ancash",
                "id_padre_ubigeo": "2625",
                "id_ubigeo": "2671",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Carhuaz",
                "numero_hijos_ubigeo": "11"
            },
            {
                "buscador_ubigeo": "carlos fermin fitzcarrald ancash",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Carlos Fermin Fitzcarrald, Ancash",
                "id_padre_ubigeo": "2625",
                "id_ubigeo": "2683",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Carlos Fermin Fitzcarrald",
                "numero_hijos_ubigeo": "3"
            },
            {
                "buscador_ubigeo": "casma ancash",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Casma, Ancash",
                "id_padre_ubigeo": "2625",
                "id_ubigeo": "2687",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Casma",
                "numero_hijos_ubigeo": "4"
            },
            {
                "buscador_ubigeo": "corongo ancash",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Corongo, Ancash",
                "id_padre_ubigeo": "2625",
                "id_ubigeo": "2692",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Corongo",
                "numero_hijos_ubigeo": "7"
            },
            {
                "buscador_ubigeo": "huaraz ancash",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Huaraz, Ancash",
                "id_padre_ubigeo": "2625",
                "id_ubigeo": "2626",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Huaraz",
                "numero_hijos_ubigeo": "12"
            },
            {
                "buscador_ubigeo": "huari ancash",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Huari, Ancash",
                "id_padre_ubigeo": "2625",
                "id_ubigeo": "2700",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Huari",
                "numero_hijos_ubigeo": "16"
            },
            {
                "buscador_ubigeo": "huarmey ancash",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Huarmey, Ancash",
                "id_padre_ubigeo": "2625",
                "id_ubigeo": "2717",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Huarmey",
                "numero_hijos_ubigeo": "5"
            },
            {
                "buscador_ubigeo": "huaylas ancash",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Huaylas, Ancash",
                "id_padre_ubigeo": "2625",
                "id_ubigeo": "2723",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Huaylas",
                "numero_hijos_ubigeo": "10"
            },
            {
                "buscador_ubigeo": "mariscal luzuriaga ancash",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Mariscal Luzuriaga, Ancash",
                "id_padre_ubigeo": "2625",
                "id_ubigeo": "2734",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Mariscal Luzuriaga",
                "numero_hijos_ubigeo": "8"
            },
            {
                "buscador_ubigeo": "ocros ancash",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "Ocros, Ancash",
                "id_padre_ubigeo": "2625",
                "id_ubigeo": "2743",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Ocros",
                "numero_hijos_ubigeo": "10"
            },
            {
                "buscador_ubigeo": "pallasca ancash",
                "codigo_ubigeo": "15",
                "etiqueta_ubigeo": "Pallasca, Ancash",
                "id_padre_ubigeo": "2625",
                "id_ubigeo": "2754",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Pallasca",
                "numero_hijos_ubigeo": "11"
            },
            {
                "buscador_ubigeo": "pomabamba ancash",
                "codigo_ubigeo": "16",
                "etiqueta_ubigeo": "Pomabamba, Ancash",
                "id_padre_ubigeo": "2625",
                "id_ubigeo": "2766",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Pomabamba",
                "numero_hijos_ubigeo": "4"
            },
            {
                "buscador_ubigeo": "recuay ancash",
                "codigo_ubigeo": "17",
                "etiqueta_ubigeo": "Recuay, Ancash",
                "id_padre_ubigeo": "2625",
                "id_ubigeo": "2771",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Recuay",
                "numero_hijos_ubigeo": "10"
            },
            {
                "buscador_ubigeo": "santa ancash",
                "codigo_ubigeo": "18",
                "etiqueta_ubigeo": "Santa, Ancash",
                "id_padre_ubigeo": "2625",
                "id_ubigeo": "2782",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Santa",
                "numero_hijos_ubigeo": "9"
            },
            {
                "buscador_ubigeo": "sihuas ancash",
                "codigo_ubigeo": "19",
                "etiqueta_ubigeo": "Sihuas, Ancash",
                "id_padre_ubigeo": "2625",
                "id_ubigeo": "2792",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Sihuas",
                "numero_hijos_ubigeo": "10"
            },
            {
                "buscador_ubigeo": "yungay ancash",
                "codigo_ubigeo": "20",
                "etiqueta_ubigeo": "Yungay, Ancash",
                "id_padre_ubigeo": "2625",
                "id_ubigeo": "2803",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Yungay",
                "numero_hijos_ubigeo": "8"
            }
        ],
        "2812": [
            {
                "buscador_ubigeo": "abancay apurimac",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Abancay, Apurimac",
                "id_padre_ubigeo": "2812",
                "id_ubigeo": "2813",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Abancay",
                "numero_hijos_ubigeo": "9"
            },
            {
                "buscador_ubigeo": "andahuaylas apurimac",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Andahuaylas, Apurimac",
                "id_padre_ubigeo": "2812",
                "id_ubigeo": "2823",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Andahuaylas",
                "numero_hijos_ubigeo": "19"
            },
            {
                "buscador_ubigeo": "antabamba apurimac",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Antabamba, Apurimac",
                "id_padre_ubigeo": "2812",
                "id_ubigeo": "2843",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Antabamba",
                "numero_hijos_ubigeo": "7"
            },
            {
                "buscador_ubigeo": "aymaraes apurimac",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Aymaraes, Apurimac",
                "id_padre_ubigeo": "2812",
                "id_ubigeo": "2851",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Aymaraes",
                "numero_hijos_ubigeo": "17"
            },
            {
                "buscador_ubigeo": "chincheros apurimac",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Chincheros, Apurimac",
                "id_padre_ubigeo": "2812",
                "id_ubigeo": "2876",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Chincheros",
                "numero_hijos_ubigeo": "8"
            },
            {
                "buscador_ubigeo": "cotabambas apurimac",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Cotabambas, Apurimac",
                "id_padre_ubigeo": "2812",
                "id_ubigeo": "2869",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Cotabambas",
                "numero_hijos_ubigeo": "6"
            },
            {
                "buscador_ubigeo": "grau apurimac",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Grau, Apurimac",
                "id_padre_ubigeo": "2812",
                "id_ubigeo": "2885",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Grau",
                "numero_hijos_ubigeo": "14"
            }
        ],
        "2900": [
            {
                "buscador_ubigeo": "arequipa arequipa arequipa",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Arequipa, Arequipa, Arequipa",
                "id_padre_ubigeo": "2900",
                "id_ubigeo": "2901",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Arequipa",
                "numero_hijos_ubigeo": "29"
            },
            {
                "buscador_ubigeo": "camana arequipa",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Camana, Arequipa",
                "id_padre_ubigeo": "2900",
                "id_ubigeo": "2931",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Camana",
                "numero_hijos_ubigeo": "8"
            },
            {
                "buscador_ubigeo": "caraveli arequipa",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Caraveli, Arequipa",
                "id_padre_ubigeo": "2900",
                "id_ubigeo": "2940",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Caraveli",
                "numero_hijos_ubigeo": "13"
            },
            {
                "buscador_ubigeo": "castilla arequipa",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Castilla, Arequipa",
                "id_padre_ubigeo": "2900",
                "id_ubigeo": "2954",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Castilla",
                "numero_hijos_ubigeo": "16"
            },
            {
                "buscador_ubigeo": "caylloma arequipa",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Caylloma, Arequipa",
                "id_padre_ubigeo": "2900",
                "id_ubigeo": "2971",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Caylloma",
                "numero_hijos_ubigeo": "20"
            },
            {
                "buscador_ubigeo": "condesuyos arequipa",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Condesuyos, Arequipa",
                "id_padre_ubigeo": "2900",
                "id_ubigeo": "2992",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Condesuyos",
                "numero_hijos_ubigeo": "8"
            },
            {
                "buscador_ubigeo": "islay arequipa",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Islay, Arequipa",
                "id_padre_ubigeo": "2900",
                "id_ubigeo": "3001",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Islay",
                "numero_hijos_ubigeo": "6"
            },
            {
                "buscador_ubigeo": "la union arequipa",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "La Union, Arequipa",
                "id_padre_ubigeo": "2900",
                "id_ubigeo": "3008",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "La Union",
                "numero_hijos_ubigeo": "11"
            }
        ],
        "3020": [
            {
                "buscador_ubigeo": "cangallo ayacucho",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Cangallo, Ayacucho",
                "id_padre_ubigeo": "3020",
                "id_ubigeo": "3037",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Cangallo",
                "numero_hijos_ubigeo": "6"
            },
            {
                "buscador_ubigeo": "huamanga ayacucho",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Huamanga, Ayacucho",
                "id_padre_ubigeo": "3020",
                "id_ubigeo": "3021",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Huamanga",
                "numero_hijos_ubigeo": "15"
            },
            {
                "buscador_ubigeo": "huanca sancos ayacucho",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Huanca Sancos, Ayacucho",
                "id_padre_ubigeo": "3020",
                "id_ubigeo": "3044",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Huanca Sancos",
                "numero_hijos_ubigeo": "4"
            },
            {
                "buscador_ubigeo": "huanta ayacucho",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Huanta, Ayacucho",
                "id_padre_ubigeo": "3020",
                "id_ubigeo": "3049",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Huanta",
                "numero_hijos_ubigeo": "8"
            },
            {
                "buscador_ubigeo": "la mar ayacucho",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "La Mar, Ayacucho",
                "id_padre_ubigeo": "3020",
                "id_ubigeo": "3058",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "La Mar",
                "numero_hijos_ubigeo": "8"
            },
            {
                "buscador_ubigeo": "lucanas ayacucho",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Lucanas, Ayacucho",
                "id_padre_ubigeo": "3020",
                "id_ubigeo": "3067",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Lucanas",
                "numero_hijos_ubigeo": "21"
            },
            {
                "buscador_ubigeo": "parinacochas ayacucho",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Parinacochas, Ayacucho",
                "id_padre_ubigeo": "3020",
                "id_ubigeo": "3089",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Parinacochas",
                "numero_hijos_ubigeo": "8"
            },
            {
                "buscador_ubigeo": "paucar del sara sara ayacucho",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Paucar del Sara Sara, Ayacucho",
                "id_padre_ubigeo": "3020",
                "id_ubigeo": "3098",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Paucar del Sara Sara",
                "numero_hijos_ubigeo": "10"
            },
            {
                "buscador_ubigeo": "sucre ayacucho",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Sucre, Ayacucho",
                "id_padre_ubigeo": "3020",
                "id_ubigeo": "3109",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Sucre",
                "numero_hijos_ubigeo": "11"
            },
            {
                "buscador_ubigeo": "victor fajardo ayacucho",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Victor Fajardo, Ayacucho",
                "id_padre_ubigeo": "3020",
                "id_ubigeo": "3121",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Victor Fajardo",
                "numero_hijos_ubigeo": "12"
            },
            {
                "buscador_ubigeo": "vilcas huaman ayacucho",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Vilcas Huaman, Ayacucho",
                "id_padre_ubigeo": "3020",
                "id_ubigeo": "3134",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Vilcas Huaman",
                "numero_hijos_ubigeo": "8"
            }
        ],
        "3143": [
            {
                "buscador_ubigeo": "cajabamba cajamarca",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Cajabamba, Cajamarca",
                "id_padre_ubigeo": "3143",
                "id_ubigeo": "3157",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Cajabamba",
                "numero_hijos_ubigeo": "4"
            },
            {
                "buscador_ubigeo": "cajamarca cajamarca cajamarca",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Cajamarca, Cajamarca, Cajamarca",
                "id_padre_ubigeo": "3143",
                "id_ubigeo": "3144",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Cajamarca",
                "numero_hijos_ubigeo": "12"
            },
            {
                "buscador_ubigeo": "celendin cajamarca",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Celendin, Cajamarca",
                "id_padre_ubigeo": "3143",
                "id_ubigeo": "3162",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Celendin",
                "numero_hijos_ubigeo": "12"
            },
            {
                "buscador_ubigeo": "chota cajamarca",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Chota, Cajamarca",
                "id_padre_ubigeo": "3143",
                "id_ubigeo": "3175",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Chota",
                "numero_hijos_ubigeo": "19"
            },
            {
                "buscador_ubigeo": "contumaza cajamarca",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Contumaza, Cajamarca",
                "id_padre_ubigeo": "3143",
                "id_ubigeo": "3195",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Contumaza",
                "numero_hijos_ubigeo": "8"
            },
            {
                "buscador_ubigeo": "cutervo cajamarca",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Cutervo, Cajamarca",
                "id_padre_ubigeo": "3143",
                "id_ubigeo": "3204",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Cutervo",
                "numero_hijos_ubigeo": "15"
            },
            {
                "buscador_ubigeo": "hualgayoc cajamarca",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Hualgayoc, Cajamarca",
                "id_padre_ubigeo": "3143",
                "id_ubigeo": "3220",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Hualgayoc",
                "numero_hijos_ubigeo": "3"
            },
            {
                "buscador_ubigeo": "jaen cajamarca",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Jaen, Cajamarca",
                "id_padre_ubigeo": "3143",
                "id_ubigeo": "3224",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Jaen",
                "numero_hijos_ubigeo": "12"
            },
            {
                "buscador_ubigeo": "san ignacio cajamarca",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "San Ignacio, Cajamarca",
                "id_padre_ubigeo": "3143",
                "id_ubigeo": "3237",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "San Ignacio",
                "numero_hijos_ubigeo": "7"
            },
            {
                "buscador_ubigeo": "san marcos cajamarca",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "San Marcos, Cajamarca",
                "id_padre_ubigeo": "3143",
                "id_ubigeo": "3245",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "San Marcos",
                "numero_hijos_ubigeo": "7"
            },
            {
                "buscador_ubigeo": "san miguel cajamarca",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "San Miguel, Cajamarca",
                "id_padre_ubigeo": "3143",
                "id_ubigeo": "3253",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "San Miguel",
                "numero_hijos_ubigeo": "13"
            },
            {
                "buscador_ubigeo": "san pablo cajamarca",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "San Pablo, Cajamarca",
                "id_padre_ubigeo": "3143",
                "id_ubigeo": "3267",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "San Pablo",
                "numero_hijos_ubigeo": "4"
            },
            {
                "buscador_ubigeo": "santa cruz cajamarca",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Santa Cruz, Cajamarca",
                "id_padre_ubigeo": "3143",
                "id_ubigeo": "3272",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Santa Cruz",
                "numero_hijos_ubigeo": "11"
            }
        ],
        "3292": [
            {
                "buscador_ubigeo": "acomayo cusco",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Acomayo, Cusco",
                "id_padre_ubigeo": "3292",
                "id_ubigeo": "3302",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Acomayo",
                "numero_hijos_ubigeo": "7"
            },
            {
                "buscador_ubigeo": "anta cusco",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Anta, Cusco",
                "id_padre_ubigeo": "3292",
                "id_ubigeo": "3310",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Anta",
                "numero_hijos_ubigeo": "9"
            },
            {
                "buscador_ubigeo": "calca cusco",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Calca, Cusco",
                "id_padre_ubigeo": "3292",
                "id_ubigeo": "3320",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Calca",
                "numero_hijos_ubigeo": "8"
            },
            {
                "buscador_ubigeo": "canas cusco",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Canas, Cusco",
                "id_padre_ubigeo": "3292",
                "id_ubigeo": "3329",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Canas",
                "numero_hijos_ubigeo": "8"
            },
            {
                "buscador_ubigeo": "canchis cusco",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Canchis, Cusco",
                "id_padre_ubigeo": "3292",
                "id_ubigeo": "3338",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Canchis",
                "numero_hijos_ubigeo": "8"
            },
            {
                "buscador_ubigeo": "chumbivilcas cusco",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Chumbivilcas, Cusco",
                "id_padre_ubigeo": "3292",
                "id_ubigeo": "3347",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Chumbivilcas",
                "numero_hijos_ubigeo": "8"
            },
            {
                "buscador_ubigeo": "cusco cusco cusco",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Cusco, Cusco, Cusco",
                "id_padre_ubigeo": "3292",
                "id_ubigeo": "3293",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Cusco",
                "numero_hijos_ubigeo": "8"
            },
            {
                "buscador_ubigeo": "espinar cusco",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Espinar, Cusco",
                "id_padre_ubigeo": "3292",
                "id_ubigeo": "3356",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Espinar",
                "numero_hijos_ubigeo": "8"
            },
            {
                "buscador_ubigeo": "la convencion cusco",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "La Convencion, Cusco",
                "id_padre_ubigeo": "3292",
                "id_ubigeo": "3365",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "La Convencion",
                "numero_hijos_ubigeo": "10"
            },
            {
                "buscador_ubigeo": "paruro cusco",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Paruro, Cusco",
                "id_padre_ubigeo": "3292",
                "id_ubigeo": "3376",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Paruro",
                "numero_hijos_ubigeo": "9"
            },
            {
                "buscador_ubigeo": "paucartambo cusco",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Paucartambo, Cusco",
                "id_padre_ubigeo": "3292",
                "id_ubigeo": "3386",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Paucartambo",
                "numero_hijos_ubigeo": "6"
            },
            {
                "buscador_ubigeo": "quispicanchi cusco",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Quispicanchi, Cusco",
                "id_padre_ubigeo": "3292",
                "id_ubigeo": "3393",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Quispicanchi",
                "numero_hijos_ubigeo": "12"
            },
            {
                "buscador_ubigeo": "urubamba cusco",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Urubamba, Cusco",
                "id_padre_ubigeo": "3292",
                "id_ubigeo": "3406",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Urubamba",
                "numero_hijos_ubigeo": "7"
            }
        ],
        "3414": [
            {
                "buscador_ubigeo": "acobamba huancavelica",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Acobamba, Huancavelica",
                "id_padre_ubigeo": "3414",
                "id_ubigeo": "3435",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Acobamba",
                "numero_hijos_ubigeo": "8"
            },
            {
                "buscador_ubigeo": "angaraes huancavelica",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Angaraes, Huancavelica",
                "id_padre_ubigeo": "3414",
                "id_ubigeo": "3444",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Angaraes",
                "numero_hijos_ubigeo": "12"
            },
            {
                "buscador_ubigeo": "castrovirreyna huancavelica",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Castrovirreyna, Huancavelica",
                "id_padre_ubigeo": "3414",
                "id_ubigeo": "3457",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Castrovirreyna",
                "numero_hijos_ubigeo": "13"
            },
            {
                "buscador_ubigeo": "churcampa huancavelica",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Churcampa, Huancavelica",
                "id_padre_ubigeo": "3414",
                "id_ubigeo": "3471",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Churcampa",
                "numero_hijos_ubigeo": "10"
            },
            {
                "buscador_ubigeo": "huancavelica huancavelica huancavelica",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Huancavelica, Huancavelica, Huancavelica",
                "id_padre_ubigeo": "3414",
                "id_ubigeo": "3415",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Huancavelica",
                "numero_hijos_ubigeo": "19"
            },
            {
                "buscador_ubigeo": "huaytara huancavelica",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Huaytara, Huancavelica",
                "id_padre_ubigeo": "3414",
                "id_ubigeo": "3482",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Huaytara",
                "numero_hijos_ubigeo": "16"
            },
            {
                "buscador_ubigeo": "tayacaja huancavelica",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Tayacaja, Huancavelica",
                "id_padre_ubigeo": "3414",
                "id_ubigeo": "3499",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Tayacaja",
                "numero_hijos_ubigeo": "18"
            }
        ],
        "3518": [
            {
                "buscador_ubigeo": "ambo huanuco",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Ambo, Huanuco",
                "id_padre_ubigeo": "3518",
                "id_ubigeo": "3531",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Ambo",
                "numero_hijos_ubigeo": "8"
            },
            {
                "buscador_ubigeo": "dos de mayo huanuco",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Dos de Mayo, Huanuco",
                "id_padre_ubigeo": "3518",
                "id_ubigeo": "3540",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Dos de Mayo",
                "numero_hijos_ubigeo": "9"
            },
            {
                "buscador_ubigeo": "huacaybamba huanuco",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Huacaybamba, Huanuco",
                "id_padre_ubigeo": "3518",
                "id_ubigeo": "3550",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Huacaybamba",
                "numero_hijos_ubigeo": "4"
            },
            {
                "buscador_ubigeo": "huamalies huanuco",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Huamalies, Huanuco",
                "id_padre_ubigeo": "3518",
                "id_ubigeo": "3555",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Huamalies",
                "numero_hijos_ubigeo": "11"
            },
            {
                "buscador_ubigeo": "huanuco huanuco huanuco",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Huanuco, Huanuco, Huanuco",
                "id_padre_ubigeo": "3518",
                "id_ubigeo": "3519",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Huanuco",
                "numero_hijos_ubigeo": "11"
            },
            {
                "buscador_ubigeo": "lauricocha huanuco",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Lauricocha, Huanuco",
                "id_padre_ubigeo": "3518",
                "id_ubigeo": "3589",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Lauricocha",
                "numero_hijos_ubigeo": "7"
            },
            {
                "buscador_ubigeo": "leoncio prado huanuco",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Leoncio Prado, Huanuco",
                "id_padre_ubigeo": "3518",
                "id_ubigeo": "3567",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Leoncio Prado",
                "numero_hijos_ubigeo": "6"
            },
            {
                "buscador_ubigeo": "maraqon huanuco",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Maraqon, Huanuco",
                "id_padre_ubigeo": "3518",
                "id_ubigeo": "3574",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Maraqon",
                "numero_hijos_ubigeo": "3"
            },
            {
                "buscador_ubigeo": "pachitea huanuco",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Pachitea, Huanuco",
                "id_padre_ubigeo": "3518",
                "id_ubigeo": "3578",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Pachitea",
                "numero_hijos_ubigeo": "4"
            },
            {
                "buscador_ubigeo": "puerto inca huanuco",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Puerto Inca, Huanuco",
                "id_padre_ubigeo": "3518",
                "id_ubigeo": "3583",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Puerto Inca",
                "numero_hijos_ubigeo": "5"
            },
            {
                "buscador_ubigeo": "yarowilca huanuco",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Yarowilca, Huanuco",
                "id_padre_ubigeo": "3518",
                "id_ubigeo": "3597",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Yarowilca",
                "numero_hijos_ubigeo": "8"
            }
        ],
        "3606": [
            {
                "buscador_ubigeo": "chincha ica",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Chincha, Ica",
                "id_padre_ubigeo": "3606",
                "id_ubigeo": "3622",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Chincha",
                "numero_hijos_ubigeo": "11"
            },
            {
                "buscador_ubigeo": "ica ica ica",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Ica, Ica, Ica",
                "id_padre_ubigeo": "3606",
                "id_ubigeo": "3607",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Ica",
                "numero_hijos_ubigeo": "14"
            },
            {
                "buscador_ubigeo": "nazca ica",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Nazca, Ica",
                "id_padre_ubigeo": "3606",
                "id_ubigeo": "3634",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Nazca",
                "numero_hijos_ubigeo": "5"
            },
            {
                "buscador_ubigeo": "palpa ica",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Palpa, Ica",
                "id_padre_ubigeo": "3606",
                "id_ubigeo": "3640",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Palpa",
                "numero_hijos_ubigeo": "5"
            },
            {
                "buscador_ubigeo": "pisco ica",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Pisco, Ica",
                "id_padre_ubigeo": "3606",
                "id_ubigeo": "3646",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Pisco",
                "numero_hijos_ubigeo": "8"
            }
        ],
        "3655": [
            {
                "buscador_ubigeo": "chanchamayo junin",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Chanchamayo, Junin",
                "id_padre_ubigeo": "3655",
                "id_ubigeo": "3701",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Chanchamayo",
                "numero_hijos_ubigeo": "6"
            },
            {
                "buscador_ubigeo": "chupaca junin",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Chupaca, Junin",
                "id_padre_ubigeo": "3655",
                "id_ubigeo": "3778",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Chupaca",
                "numero_hijos_ubigeo": "9"
            },
            {
                "buscador_ubigeo": "concepcion junin",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Concepcion, Junin",
                "id_padre_ubigeo": "3655",
                "id_ubigeo": "3685",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Concepcion",
                "numero_hijos_ubigeo": "15"
            },
            {
                "buscador_ubigeo": "huancayo junin",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Huancayo, Junin",
                "id_padre_ubigeo": "3655",
                "id_ubigeo": "3656",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Huancayo",
                "numero_hijos_ubigeo": "28"
            },
            {
                "buscador_ubigeo": "jauja junin",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Jauja, Junin",
                "id_padre_ubigeo": "3655",
                "id_ubigeo": "3708",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Jauja",
                "numero_hijos_ubigeo": "34"
            },
            {
                "buscador_ubigeo": "junin junin junin",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Junin, Junin, Junin",
                "id_padre_ubigeo": "3655",
                "id_ubigeo": "3743",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Junin",
                "numero_hijos_ubigeo": "4"
            },
            {
                "buscador_ubigeo": "satipo junin",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Satipo, Junin",
                "id_padre_ubigeo": "3655",
                "id_ubigeo": "3748",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Satipo",
                "numero_hijos_ubigeo": "8"
            },
            {
                "buscador_ubigeo": "tarma junin",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Tarma, Junin",
                "id_padre_ubigeo": "3655",
                "id_ubigeo": "3757",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Tarma",
                "numero_hijos_ubigeo": "9"
            },
            {
                "buscador_ubigeo": "yauli junin",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Yauli, Junin",
                "id_padre_ubigeo": "3655",
                "id_ubigeo": "3767",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Yauli",
                "numero_hijos_ubigeo": "10"
            }
        ],
        "3788": [
            {
                "buscador_ubigeo": "ascope la libertad",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Ascope, La Libertad",
                "id_padre_ubigeo": "3788",
                "id_ubigeo": "3801",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Ascope",
                "numero_hijos_ubigeo": "8"
            },
            {
                "buscador_ubigeo": "bolivar la libertad",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Bolivar, La Libertad",
                "id_padre_ubigeo": "3788",
                "id_ubigeo": "3810",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Bolivar",
                "numero_hijos_ubigeo": "6"
            },
            {
                "buscador_ubigeo": "chepen la libertad",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Chepen, La Libertad",
                "id_padre_ubigeo": "3788",
                "id_ubigeo": "3817",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Chepen",
                "numero_hijos_ubigeo": "3"
            },
            {
                "buscador_ubigeo": "gran chimu la libertad",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Gran Chimu, La Libertad",
                "id_padre_ubigeo": "3788",
                "id_ubigeo": "3875",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Gran Chimu",
                "numero_hijos_ubigeo": "4"
            },
            {
                "buscador_ubigeo": "julcan la libertad",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Julcan, La Libertad",
                "id_padre_ubigeo": "3788",
                "id_ubigeo": "3821",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Julcan",
                "numero_hijos_ubigeo": "4"
            },
            {
                "buscador_ubigeo": "otuzco la libertad",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Otuzco, La Libertad",
                "id_padre_ubigeo": "3788",
                "id_ubigeo": "3826",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Otuzco",
                "numero_hijos_ubigeo": "10"
            },
            {
                "buscador_ubigeo": "pacasmayo la libertad",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Pacasmayo, La Libertad",
                "id_padre_ubigeo": "3788",
                "id_ubigeo": "3837",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Pacasmayo",
                "numero_hijos_ubigeo": "5"
            },
            {
                "buscador_ubigeo": "pataz la libertad",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Pataz, La Libertad",
                "id_padre_ubigeo": "3788",
                "id_ubigeo": "3843",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Pataz",
                "numero_hijos_ubigeo": "13"
            },
            {
                "buscador_ubigeo": "sanchez carrion la libertad",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Sanchez Carrion, La Libertad",
                "id_padre_ubigeo": "3788",
                "id_ubigeo": "3857",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Sanchez Carrion",
                "numero_hijos_ubigeo": "8"
            },
            {
                "buscador_ubigeo": "santiago de chuco la libertad",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Santiago de Chuco, La Libertad",
                "id_padre_ubigeo": "3788",
                "id_ubigeo": "3866",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Santiago de Chuco",
                "numero_hijos_ubigeo": "8"
            },
            {
                "buscador_ubigeo": "trujillo la libertad",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Trujillo, La Libertad",
                "id_padre_ubigeo": "3788",
                "id_ubigeo": "3789",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Trujillo",
                "numero_hijos_ubigeo": "11"
            },
            {
                "buscador_ubigeo": "viru la libertad",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Viru, La Libertad",
                "id_padre_ubigeo": "3788",
                "id_ubigeo": "3880",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Viru",
                "numero_hijos_ubigeo": "3"
            }
        ],
        "3884": [
            {
                "buscador_ubigeo": "chiclayo lambayeque",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Chiclayo, Lambayeque",
                "id_padre_ubigeo": "3884",
                "id_ubigeo": "3885",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Chiclayo",
                "numero_hijos_ubigeo": "20"
            },
            {
                "buscador_ubigeo": "ferreqafe lambayeque",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Ferreqafe, Lambayeque",
                "id_padre_ubigeo": "3884",
                "id_ubigeo": "3906",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Ferreqafe",
                "numero_hijos_ubigeo": "6"
            },
            {
                "buscador_ubigeo": "lambayeque lambayeque lambayeque",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Lambayeque, Lambayeque, Lambayeque",
                "id_padre_ubigeo": "3884",
                "id_ubigeo": "3913",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Lambayeque",
                "numero_hijos_ubigeo": "12"
            }
        ],
        "3926": [
            {
                "buscador_ubigeo": "barranca lima",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Barranca, Lima",
                "id_padre_ubigeo": "3926",
                "id_ubigeo": "3971",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Barranca",
                "numero_hijos_ubigeo": "5"
            },
            {
                "buscador_ubigeo": "cajatambo lima",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Cajatambo, Lima",
                "id_padre_ubigeo": "3926",
                "id_ubigeo": "3977",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Cajatambo",
                "numero_hijos_ubigeo": "5"
            },
            {
                "buscador_ubigeo": "callao callao lima",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Callao, Callao, Lima",
                "id_padre_ubigeo": "3926",
                "id_ubigeo": "3285",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Callao",
                "numero_hijos_ubigeo": "6"
            },
            {
                "buscador_ubigeo": "cañete lima",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Cañete, Lima",
                "id_padre_ubigeo": "3926",
                "id_ubigeo": "3991",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Cañete",
                "numero_hijos_ubigeo": "16"
            },
            {
                "buscador_ubigeo": "canta lima",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Canta, Lima",
                "id_padre_ubigeo": "3926",
                "id_ubigeo": "3983",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Canta",
                "numero_hijos_ubigeo": "7"
            },
            {
                "buscador_ubigeo": "huaral lima",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Huaral, Lima",
                "id_padre_ubigeo": "3926",
                "id_ubigeo": "4008",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Huaral",
                "numero_hijos_ubigeo": "12"
            },
            {
                "buscador_ubigeo": "huarochiri lima",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Huarochiri, Lima",
                "id_padre_ubigeo": "3926",
                "id_ubigeo": "4021",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Huarochiri",
                "numero_hijos_ubigeo": "32"
            },
            {
                "buscador_ubigeo": "huaura lima",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Huaura, Lima",
                "id_padre_ubigeo": "3926",
                "id_ubigeo": "4054",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Huaura",
                "numero_hijos_ubigeo": "12"
            },
            {
                "buscador_ubigeo": "lima lima lima",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Lima, Lima, Lima",
                "id_padre_ubigeo": "3926",
                "id_ubigeo": "3927",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Lima",
                "numero_hijos_ubigeo": "43"
            },
            {
                "buscador_ubigeo": "oyon lima",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Oyon, Lima",
                "id_padre_ubigeo": "3926",
                "id_ubigeo": "4067",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Oyon",
                "numero_hijos_ubigeo": "6"
            },
            {
                "buscador_ubigeo": "yauyos lima",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Yauyos, Lima",
                "id_padre_ubigeo": "3926",
                "id_ubigeo": "4074",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Yauyos",
                "numero_hijos_ubigeo": "33"
            }
        ],
        "4108": [
            {
                "buscador_ubigeo": "alto amazonas loreto",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Alto Amazonas, Loreto",
                "id_padre_ubigeo": "4108",
                "id_ubigeo": "4123",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Alto Amazonas",
                "numero_hijos_ubigeo": "11"
            },
            {
                "buscador_ubigeo": "loreto loreto",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Loreto, Loreto",
                "id_padre_ubigeo": "4108",
                "id_ubigeo": "4135",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Loreto",
                "numero_hijos_ubigeo": "5"
            },
            {
                "buscador_ubigeo": "mariscal ramon castilla loreto",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Mariscal Ramon Castilla, Loreto",
                "id_padre_ubigeo": "4108",
                "id_ubigeo": "4141",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Mariscal Ramon Castilla",
                "numero_hijos_ubigeo": "4"
            },
            {
                "buscador_ubigeo": "maynas loreto",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Maynas, Loreto",
                "id_padre_ubigeo": "4108",
                "id_ubigeo": "4109",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Maynas",
                "numero_hijos_ubigeo": "13"
            },
            {
                "buscador_ubigeo": "requena loreto",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Requena, Loreto",
                "id_padre_ubigeo": "4108",
                "id_ubigeo": "4146",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Requena",
                "numero_hijos_ubigeo": "11"
            },
            {
                "buscador_ubigeo": "ucayali loreto",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Ucayali, Loreto",
                "id_padre_ubigeo": "4108",
                "id_ubigeo": "4158",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Ucayali",
                "numero_hijos_ubigeo": "6"
            }
        ],
        "4165": [
            {
                "buscador_ubigeo": "manu madre de dios",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Manu, Madre de Dios",
                "id_padre_ubigeo": "4165",
                "id_ubigeo": "4171",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Manu",
                "numero_hijos_ubigeo": "4"
            },
            {
                "buscador_ubigeo": "tahuamanu madre de dios",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Tahuamanu, Madre de Dios",
                "id_padre_ubigeo": "4165",
                "id_ubigeo": "4176",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Tahuamanu",
                "numero_hijos_ubigeo": "3"
            },
            {
                "buscador_ubigeo": "tambopata madre de dios",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Tambopata, Madre de Dios",
                "id_padre_ubigeo": "4165",
                "id_ubigeo": "4166",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Tambopata",
                "numero_hijos_ubigeo": "4"
            }
        ],
        "4180": [
            {
                "buscador_ubigeo": "general sanchez cerro moquegua",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "General Sanchez Cerro, Moquegua",
                "id_padre_ubigeo": "4180",
                "id_ubigeo": "4188",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "General Sanchez Cerro",
                "numero_hijos_ubigeo": "11"
            },
            {
                "buscador_ubigeo": "ilo moquegua",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Ilo, Moquegua",
                "id_padre_ubigeo": "4180",
                "id_ubigeo": "4200",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Ilo",
                "numero_hijos_ubigeo": "3"
            },
            {
                "buscador_ubigeo": "mariscal nieto moquegua",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Mariscal Nieto, Moquegua",
                "id_padre_ubigeo": "4180",
                "id_ubigeo": "4181",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Mariscal Nieto",
                "numero_hijos_ubigeo": "6"
            }
        ],
        "4204": [
            {
                "buscador_ubigeo": "daniel alcides carrion pasco",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Daniel Alcides Carrion, Pasco",
                "id_padre_ubigeo": "4204",
                "id_ubigeo": "4219",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Daniel Alcides Carrion",
                "numero_hijos_ubigeo": "8"
            },
            {
                "buscador_ubigeo": "oxapampa pasco",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Oxapampa, Pasco",
                "id_padre_ubigeo": "4204",
                "id_ubigeo": "4228",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Oxapampa",
                "numero_hijos_ubigeo": "7"
            },
            {
                "buscador_ubigeo": "pasco pasco",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Pasco, Pasco",
                "id_padre_ubigeo": "4204",
                "id_ubigeo": "4205",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Pasco",
                "numero_hijos_ubigeo": "13"
            }
        ],
        "4236": [
            {
                "buscador_ubigeo": "ayabaca piura",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Ayabaca, Piura",
                "id_padre_ubigeo": "4236",
                "id_ubigeo": "4247",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Ayabaca",
                "numero_hijos_ubigeo": "10"
            },
            {
                "buscador_ubigeo": "huancabamba piura",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Huancabamba, Piura",
                "id_padre_ubigeo": "4236",
                "id_ubigeo": "4258",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Huancabamba",
                "numero_hijos_ubigeo": "8"
            },
            {
                "buscador_ubigeo": "morropon piura",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Morropon, Piura",
                "id_padre_ubigeo": "4236",
                "id_ubigeo": "4267",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Morropon",
                "numero_hijos_ubigeo": "10"
            },
            {
                "buscador_ubigeo": "paita piura",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Paita, Piura",
                "id_padre_ubigeo": "4236",
                "id_ubigeo": "4278",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Paita",
                "numero_hijos_ubigeo": "7"
            },
            {
                "buscador_ubigeo": "piura piura piura",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Piura, Piura, Piura",
                "id_padre_ubigeo": "4236",
                "id_ubigeo": "4237",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Piura",
                "numero_hijos_ubigeo": "9"
            },
            {
                "buscador_ubigeo": "sechura piura",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Sechura, Piura",
                "id_padre_ubigeo": "4236",
                "id_ubigeo": "4302",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Sechura",
                "numero_hijos_ubigeo": "6"
            },
            {
                "buscador_ubigeo": "sullana piura",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Sullana, Piura",
                "id_padre_ubigeo": "4236",
                "id_ubigeo": "4286",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Sullana",
                "numero_hijos_ubigeo": "8"
            },
            {
                "buscador_ubigeo": "talara piura",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Talara, Piura",
                "id_padre_ubigeo": "4236",
                "id_ubigeo": "4295",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Talara",
                "numero_hijos_ubigeo": "6"
            }
        ],
        "4309": [
            {
                "buscador_ubigeo": "azangaro puno",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Azangaro, Puno",
                "id_padre_ubigeo": "4309",
                "id_ubigeo": "4326",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Azangaro",
                "numero_hijos_ubigeo": "15"
            },
            {
                "buscador_ubigeo": "carabaya puno",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Carabaya, Puno",
                "id_padre_ubigeo": "4309",
                "id_ubigeo": "4342",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Carabaya",
                "numero_hijos_ubigeo": "10"
            },
            {
                "buscador_ubigeo": "chucuito puno",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Chucuito, Puno",
                "id_padre_ubigeo": "4309",
                "id_ubigeo": "4353",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Chucuito",
                "numero_hijos_ubigeo": "7"
            },
            {
                "buscador_ubigeo": "el collao puno",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "El Collao, Puno",
                "id_padre_ubigeo": "4309",
                "id_ubigeo": "4361",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "El Collao",
                "numero_hijos_ubigeo": "5"
            },
            {
                "buscador_ubigeo": "huancane puno",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Huancane, Puno",
                "id_padre_ubigeo": "4309",
                "id_ubigeo": "4367",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Huancane",
                "numero_hijos_ubigeo": "8"
            },
            {
                "buscador_ubigeo": "lampa puno",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Lampa, Puno",
                "id_padre_ubigeo": "4309",
                "id_ubigeo": "4376",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Lampa",
                "numero_hijos_ubigeo": "10"
            },
            {
                "buscador_ubigeo": "melgar puno",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Melgar, Puno",
                "id_padre_ubigeo": "4309",
                "id_ubigeo": "4387",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Melgar",
                "numero_hijos_ubigeo": "9"
            },
            {
                "buscador_ubigeo": "moho puno",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Moho, Puno",
                "id_padre_ubigeo": "4309",
                "id_ubigeo": "4397",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Moho",
                "numero_hijos_ubigeo": "4"
            },
            {
                "buscador_ubigeo": "puno puno puno",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Puno, Puno, Puno",
                "id_padre_ubigeo": "4309",
                "id_ubigeo": "4310",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Puno",
                "numero_hijos_ubigeo": "15"
            },
            {
                "buscador_ubigeo": "san antonio de putina puno",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "San Antonio de Putina, Puno",
                "id_padre_ubigeo": "4309",
                "id_ubigeo": "4402",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "San Antonio de Putina",
                "numero_hijos_ubigeo": "5"
            },
            {
                "buscador_ubigeo": "san roman puno",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "San Roman, Puno",
                "id_padre_ubigeo": "4309",
                "id_ubigeo": "4408",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "San Roman",
                "numero_hijos_ubigeo": "4"
            },
            {
                "buscador_ubigeo": "sandia puno",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Sandia, Puno",
                "id_padre_ubigeo": "4309",
                "id_ubigeo": "4413",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Sandia",
                "numero_hijos_ubigeo": "9"
            },
            {
                "buscador_ubigeo": "yunguyo puno",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Yunguyo, Puno",
                "id_padre_ubigeo": "4309",
                "id_ubigeo": "4423",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Yunguyo",
                "numero_hijos_ubigeo": "7"
            }
        ],
        "4431": [
            {
                "buscador_ubigeo": "bellavista san martin",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Bellavista, San Martin",
                "id_padre_ubigeo": "4431",
                "id_ubigeo": "4439",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Bellavista",
                "numero_hijos_ubigeo": "6"
            },
            {
                "buscador_ubigeo": "el dorado san martin",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "El Dorado, San Martin",
                "id_padre_ubigeo": "4431",
                "id_ubigeo": "4446",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "El Dorado",
                "numero_hijos_ubigeo": "5"
            },
            {
                "buscador_ubigeo": "huallaga san martin",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Huallaga, San Martin",
                "id_padre_ubigeo": "4431",
                "id_ubigeo": "4452",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Huallaga",
                "numero_hijos_ubigeo": "6"
            },
            {
                "buscador_ubigeo": "lamas san martin",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Lamas, San Martin",
                "id_padre_ubigeo": "4431",
                "id_ubigeo": "4459",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Lamas",
                "numero_hijos_ubigeo": "11"
            },
            {
                "buscador_ubigeo": "mariscal caceres san martin",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Mariscal Caceres, San Martin",
                "id_padre_ubigeo": "4431",
                "id_ubigeo": "4471",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Mariscal Caceres",
                "numero_hijos_ubigeo": "5"
            },
            {
                "buscador_ubigeo": "moyobamba san martin",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Moyobamba, San Martin",
                "id_padre_ubigeo": "4431",
                "id_ubigeo": "4432",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Moyobamba",
                "numero_hijos_ubigeo": "6"
            },
            {
                "buscador_ubigeo": "picota san martin",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Picota, San Martin",
                "id_padre_ubigeo": "4431",
                "id_ubigeo": "4477",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Picota",
                "numero_hijos_ubigeo": "10"
            },
            {
                "buscador_ubigeo": "rioja san martin",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Rioja, San Martin",
                "id_padre_ubigeo": "4431",
                "id_ubigeo": "4488",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Rioja",
                "numero_hijos_ubigeo": "9"
            },
            {
                "buscador_ubigeo": "san martin san martin",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "San Martin, San Martin",
                "id_padre_ubigeo": "4431",
                "id_ubigeo": "4498",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "San Martin",
                "numero_hijos_ubigeo": "14"
            },
            {
                "buscador_ubigeo": "tocache san martin",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Tocache, San Martin",
                "id_padre_ubigeo": "4431",
                "id_ubigeo": "4513",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Tocache",
                "numero_hijos_ubigeo": "5"
            }
        ],
        "4519": [
            {
                "buscador_ubigeo": "candarave tacna",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Candarave, Tacna",
                "id_padre_ubigeo": "4519",
                "id_ubigeo": "4531",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Candarave",
                "numero_hijos_ubigeo": "6"
            },
            {
                "buscador_ubigeo": "jorge basadre tacna",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Jorge Basadre, Tacna",
                "id_padre_ubigeo": "4519",
                "id_ubigeo": "4538",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Jorge Basadre",
                "numero_hijos_ubigeo": "3"
            },
            {
                "buscador_ubigeo": "tacna tacna tacna",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Tacna, Tacna, Tacna",
                "id_padre_ubigeo": "4519",
                "id_ubigeo": "4520",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Tacna",
                "numero_hijos_ubigeo": "10"
            },
            {
                "buscador_ubigeo": "tarata tacna",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Tarata, Tacna",
                "id_padre_ubigeo": "4519",
                "id_ubigeo": "4542",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Tarata",
                "numero_hijos_ubigeo": "8"
            }
        ],
        "4551": [
            {
                "buscador_ubigeo": "contralmirante villar tumbes",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Contralmirante Villar, Tumbes",
                "id_padre_ubigeo": "4551",
                "id_ubigeo": "4559",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Contralmirante Villar",
                "numero_hijos_ubigeo": "2"
            },
            {
                "buscador_ubigeo": "tumbes tumbes tumbes",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Tumbes, Tumbes, Tumbes",
                "id_padre_ubigeo": "4551",
                "id_ubigeo": "4552",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Tumbes",
                "numero_hijos_ubigeo": "6"
            },
            {
                "buscador_ubigeo": "zarumilla tumbes",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Zarumilla, Tumbes",
                "id_padre_ubigeo": "4551",
                "id_ubigeo": "4562",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Zarumilla",
                "numero_hijos_ubigeo": "4"
            }
        ],
        "4567": [
            {
                "buscador_ubigeo": "atalaya ucayali",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Atalaya, Ucayali",
                "id_padre_ubigeo": "4567",
                "id_ubigeo": "4575",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Atalaya",
                "numero_hijos_ubigeo": "4"
            },
            {
                "buscador_ubigeo": "coronel portillo ucayali",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Coronel Portillo, Ucayali",
                "id_padre_ubigeo": "4567",
                "id_ubigeo": "4568",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Coronel Portillo",
                "numero_hijos_ubigeo": "6"
            },
            {
                "buscador_ubigeo": "padre abad ucayali",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Padre Abad, Ucayali",
                "id_padre_ubigeo": "4567",
                "id_ubigeo": "4580",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Padre Abad",
                "numero_hijos_ubigeo": "3"
            },
            {
                "buscador_ubigeo": "purus ucayali",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Purus, Ucayali",
                "id_padre_ubigeo": "4567",
                "id_ubigeo": "4584",
                "nivel_ubigeo": "2",
                "nombre_ubigeo": "Purus",
                "numero_hijos_ubigeo": "1"
            }
        ]
    }
    return prov[val]
}
function distri(val) {
    var dist = {
        "2535": [
            {
                "buscador_ubigeo": "asuncion chachapoyas",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Asuncion, Chachapoyas",
                "id_padre_ubigeo": "2535",
                "id_ubigeo": "2537",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Asuncion",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "balsas chachapoyas",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Balsas, Chachapoyas",
                "id_padre_ubigeo": "2535",
                "id_ubigeo": "2538",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Balsas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chachapoyas chachapoyas",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Chachapoyas, Chachapoyas",
                "id_padre_ubigeo": "2535",
                "id_ubigeo": "2536",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chachapoyas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cheto chachapoyas",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Cheto, Chachapoyas",
                "id_padre_ubigeo": "2535",
                "id_ubigeo": "2539",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cheto",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chiliquin chachapoyas",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Chiliquin, Chachapoyas",
                "id_padre_ubigeo": "2535",
                "id_ubigeo": "2540",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chiliquin",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chuquibamba chachapoyas",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Chuquibamba, Chachapoyas",
                "id_padre_ubigeo": "2535",
                "id_ubigeo": "2541",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chuquibamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "granada chachapoyas",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Granada, Chachapoyas",
                "id_padre_ubigeo": "2535",
                "id_ubigeo": "2542",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Granada",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huancas chachapoyas",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Huancas, Chachapoyas",
                "id_padre_ubigeo": "2535",
                "id_ubigeo": "2543",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huancas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la jalca chachapoyas",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "La Jalca, Chachapoyas",
                "id_padre_ubigeo": "2535",
                "id_ubigeo": "2544",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Jalca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "leimebamba chachapoyas",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Leimebamba, Chachapoyas",
                "id_padre_ubigeo": "2535",
                "id_ubigeo": "2545",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Leimebamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "levanto chachapoyas",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Levanto, Chachapoyas",
                "id_padre_ubigeo": "2535",
                "id_ubigeo": "2546",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Levanto",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "magdalena chachapoyas",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Magdalena, Chachapoyas",
                "id_padre_ubigeo": "2535",
                "id_ubigeo": "2547",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Magdalena",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mariscal castilla chachapoyas",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Mariscal Castilla, Chachapoyas",
                "id_padre_ubigeo": "2535",
                "id_ubigeo": "2548",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mariscal Castilla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "molinopampa chachapoyas",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "Molinopampa, Chachapoyas",
                "id_padre_ubigeo": "2535",
                "id_ubigeo": "2549",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Molinopampa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "montevideo chachapoyas",
                "codigo_ubigeo": "15",
                "etiqueta_ubigeo": "Montevideo, Chachapoyas",
                "id_padre_ubigeo": "2535",
                "id_ubigeo": "2550",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Montevideo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "olleros chachapoyas",
                "codigo_ubigeo": "16",
                "etiqueta_ubigeo": "Olleros, Chachapoyas",
                "id_padre_ubigeo": "2535",
                "id_ubigeo": "2551",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Olleros",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "quinjalca chachapoyas",
                "codigo_ubigeo": "17",
                "etiqueta_ubigeo": "Quinjalca, Chachapoyas",
                "id_padre_ubigeo": "2535",
                "id_ubigeo": "2552",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Quinjalca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san francisco de daguas chachapoyas",
                "codigo_ubigeo": "18",
                "etiqueta_ubigeo": "San Francisco de Daguas, Chachapoyas",
                "id_padre_ubigeo": "2535",
                "id_ubigeo": "2553",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Francisco de Daguas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san isidro de maino chachapoyas",
                "codigo_ubigeo": "19",
                "etiqueta_ubigeo": "San Isidro de Maino, Chachapoyas",
                "id_padre_ubigeo": "2535",
                "id_ubigeo": "2554",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Isidro de Maino",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "soloco chachapoyas",
                "codigo_ubigeo": "20",
                "etiqueta_ubigeo": "Soloco, Chachapoyas",
                "id_padre_ubigeo": "2535",
                "id_ubigeo": "2555",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Soloco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sonche chachapoyas",
                "codigo_ubigeo": "21",
                "etiqueta_ubigeo": "Sonche, Chachapoyas",
                "id_padre_ubigeo": "2535",
                "id_ubigeo": "2556",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sonche",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2557": [
            {
                "buscador_ubigeo": "aramango bagua",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Aramango, Bagua",
                "id_padre_ubigeo": "2557",
                "id_ubigeo": "2559",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Aramango",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "copallin bagua",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Copallin, Bagua",
                "id_padre_ubigeo": "2557",
                "id_ubigeo": "2560",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Copallin",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "el parco bagua",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "El Parco, Bagua",
                "id_padre_ubigeo": "2557",
                "id_ubigeo": "2561",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "El Parco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "imaza bagua",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Imaza, Bagua",
                "id_padre_ubigeo": "2557",
                "id_ubigeo": "2562",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Imaza",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la peca bagua",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "La Peca, Bagua",
                "id_padre_ubigeo": "2557",
                "id_ubigeo": "2558",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Peca",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2563": [
            {
                "buscador_ubigeo": "chisquilla bongara",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Chisquilla, Bongara",
                "id_padre_ubigeo": "2563",
                "id_ubigeo": "2567",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chisquilla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "churuja bongara",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Churuja, Bongara",
                "id_padre_ubigeo": "2563",
                "id_ubigeo": "2568",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Churuja",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "corosha bongara",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Corosha, Bongara",
                "id_padre_ubigeo": "2563",
                "id_ubigeo": "2565",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Corosha",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cuispes bongara",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Cuispes, Bongara",
                "id_padre_ubigeo": "2563",
                "id_ubigeo": "2566",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cuispes",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "florida bongara",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Florida, Bongara",
                "id_padre_ubigeo": "2563",
                "id_ubigeo": "2569",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Florida",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jazan bongara",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Jazan, Bongara",
                "id_padre_ubigeo": "2563",
                "id_ubigeo": "2575",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jazan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jumbilla bongara",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Jumbilla, Bongara",
                "id_padre_ubigeo": "2563",
                "id_ubigeo": "2564",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jumbilla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "recta bongara",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Recta, Bongara",
                "id_padre_ubigeo": "2563",
                "id_ubigeo": "2570",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Recta",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san carlos bongara",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "San Carlos, Bongara",
                "id_padre_ubigeo": "2563",
                "id_ubigeo": "2571",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Carlos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "shipasbamba bongara",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Shipasbamba, Bongara",
                "id_padre_ubigeo": "2563",
                "id_ubigeo": "2572",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Shipasbamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "valera bongara",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Valera, Bongara",
                "id_padre_ubigeo": "2563",
                "id_ubigeo": "2573",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Valera",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yambrasbamba bongara",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Yambrasbamba, Bongara",
                "id_padre_ubigeo": "2563",
                "id_ubigeo": "2574",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yambrasbamba",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2576": [
            {
                "buscador_ubigeo": "el cenepa condorcanqui",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "El Cenepa, Condorcanqui",
                "id_padre_ubigeo": "2576",
                "id_ubigeo": "2578",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "El Cenepa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "nieva condorcanqui",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Nieva, Condorcanqui",
                "id_padre_ubigeo": "2576",
                "id_ubigeo": "2577",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Nieva",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "rio santiago condorcanqui",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Rio Santiago, Condorcanqui",
                "id_padre_ubigeo": "2576",
                "id_ubigeo": "2579",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Rio Santiago",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2580": [
            {
                "buscador_ubigeo": "camporredondo luya",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Camporredondo, Luya",
                "id_padre_ubigeo": "2580",
                "id_ubigeo": "2582",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Camporredondo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cocabamba luya",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Cocabamba, Luya",
                "id_padre_ubigeo": "2580",
                "id_ubigeo": "2583",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cocabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "colcamar luya",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Colcamar, Luya",
                "id_padre_ubigeo": "2580",
                "id_ubigeo": "2584",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Colcamar",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "conila luya",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Conila, Luya",
                "id_padre_ubigeo": "2580",
                "id_ubigeo": "2585",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Conila",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "inguilpata luya",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Inguilpata, Luya",
                "id_padre_ubigeo": "2580",
                "id_ubigeo": "2586",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Inguilpata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lamud luya",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Lamud, Luya",
                "id_padre_ubigeo": "2580",
                "id_ubigeo": "2581",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lamud",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "longuita luya",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Longuita, Luya",
                "id_padre_ubigeo": "2580",
                "id_ubigeo": "2587",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Longuita",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lonya chico luya",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Lonya Chico, Luya",
                "id_padre_ubigeo": "2580",
                "id_ubigeo": "2588",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lonya Chico",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "luya luya",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Luya, Luya",
                "id_padre_ubigeo": "2580",
                "id_ubigeo": "2589",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Luya",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "luya viejo luya",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Luya Viejo, Luya",
                "id_padre_ubigeo": "2580",
                "id_ubigeo": "2590",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Luya Viejo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "maria luya",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Maria, Luya",
                "id_padre_ubigeo": "2580",
                "id_ubigeo": "2591",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Maria",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ocalli luya",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Ocalli, Luya",
                "id_padre_ubigeo": "2580",
                "id_ubigeo": "2592",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ocalli",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ocumal luya",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Ocumal, Luya",
                "id_padre_ubigeo": "2580",
                "id_ubigeo": "2593",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ocumal",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pisuquia luya",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "Pisuquia, Luya",
                "id_padre_ubigeo": "2580",
                "id_ubigeo": "2594",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pisuquia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "providencia luya",
                "codigo_ubigeo": "15",
                "etiqueta_ubigeo": "Providencia, Luya",
                "id_padre_ubigeo": "2580",
                "id_ubigeo": "2595",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Providencia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san cristobal luya",
                "codigo_ubigeo": "16",
                "etiqueta_ubigeo": "San Cristobal, Luya",
                "id_padre_ubigeo": "2580",
                "id_ubigeo": "2596",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Cristobal",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san francisco del yeso luya",
                "codigo_ubigeo": "17",
                "etiqueta_ubigeo": "San Francisco del Yeso, Luya",
                "id_padre_ubigeo": "2580",
                "id_ubigeo": "2597",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Francisco del Yeso",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san jeronimo luya",
                "codigo_ubigeo": "18",
                "etiqueta_ubigeo": "San Jeronimo, Luya",
                "id_padre_ubigeo": "2580",
                "id_ubigeo": "2598",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Jeronimo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san juan de lopecancha luya",
                "codigo_ubigeo": "19",
                "etiqueta_ubigeo": "San Juan de Lopecancha, Luya",
                "id_padre_ubigeo": "2580",
                "id_ubigeo": "2599",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Juan de Lopecancha",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa catalina luya",
                "codigo_ubigeo": "20",
                "etiqueta_ubigeo": "Santa Catalina, Luya",
                "id_padre_ubigeo": "2580",
                "id_ubigeo": "2600",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Catalina",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santo tomas luya",
                "codigo_ubigeo": "21",
                "etiqueta_ubigeo": "Santo Tomas, Luya",
                "id_padre_ubigeo": "2580",
                "id_ubigeo": "2601",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santo Tomas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tingo luya",
                "codigo_ubigeo": "22",
                "etiqueta_ubigeo": "Tingo, Luya",
                "id_padre_ubigeo": "2580",
                "id_ubigeo": "2602",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tingo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "trita luya",
                "codigo_ubigeo": "23",
                "etiqueta_ubigeo": "Trita, Luya",
                "id_padre_ubigeo": "2580",
                "id_ubigeo": "2603",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Trita",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2604": [
            {
                "buscador_ubigeo": "chirimoto rodriguez de mendoza",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Chirimoto, Rodriguez de Mendoza",
                "id_padre_ubigeo": "2604",
                "id_ubigeo": "2606",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chirimoto",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cochamal rodriguez de mendoza",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Cochamal, Rodriguez de Mendoza",
                "id_padre_ubigeo": "2604",
                "id_ubigeo": "2607",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cochamal",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huambo rodriguez de mendoza",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Huambo, Rodriguez de Mendoza",
                "id_padre_ubigeo": "2604",
                "id_ubigeo": "2608",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huambo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "limabamba rodriguez de mendoza",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Limabamba, Rodriguez de Mendoza",
                "id_padre_ubigeo": "2604",
                "id_ubigeo": "2609",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Limabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "longar rodriguez de mendoza",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Longar, Rodriguez de Mendoza",
                "id_padre_ubigeo": "2604",
                "id_ubigeo": "2610",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Longar",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mariscal benavides rodriguez de mendoza",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Mariscal Benavides, Rodriguez de Mendoza",
                "id_padre_ubigeo": "2604",
                "id_ubigeo": "2611",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mariscal Benavides",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "milpuc rodriguez de mendoza",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Milpuc, Rodriguez de Mendoza",
                "id_padre_ubigeo": "2604",
                "id_ubigeo": "2612",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Milpuc",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "omia rodriguez de mendoza",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Omia, Rodriguez de Mendoza",
                "id_padre_ubigeo": "2604",
                "id_ubigeo": "2613",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Omia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san nicolas rodriguez de mendoza",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "San Nicolas, Rodriguez de Mendoza",
                "id_padre_ubigeo": "2604",
                "id_ubigeo": "2605",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Nicolas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa rosa rodriguez de mendoza",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Santa Rosa, Rodriguez de Mendoza",
                "id_padre_ubigeo": "2604",
                "id_ubigeo": "2614",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Rosa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "totora rodriguez de mendoza",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Totora, Rodriguez de Mendoza",
                "id_padre_ubigeo": "2604",
                "id_ubigeo": "2615",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Totora",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "vista alegre rodriguez de mendoza",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Vista Alegre, Rodriguez de Mendoza",
                "id_padre_ubigeo": "2604",
                "id_ubigeo": "2616",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Vista Alegre",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2617": [
            {
                "buscador_ubigeo": "bagua grande utcubamba",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Bagua Grande, Utcubamba",
                "id_padre_ubigeo": "2617",
                "id_ubigeo": "2618",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Bagua Grande",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cajaruro utcubamba",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Cajaruro, Utcubamba",
                "id_padre_ubigeo": "2617",
                "id_ubigeo": "2619",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cajaruro",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cumba utcubamba",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Cumba, Utcubamba",
                "id_padre_ubigeo": "2617",
                "id_ubigeo": "2620",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cumba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "el milagro utcubamba",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "El Milagro, Utcubamba",
                "id_padre_ubigeo": "2617",
                "id_ubigeo": "2621",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "El Milagro",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jamalca utcubamba",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Jamalca, Utcubamba",
                "id_padre_ubigeo": "2617",
                "id_ubigeo": "2622",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jamalca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lonya grande utcubamba",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Lonya Grande, Utcubamba",
                "id_padre_ubigeo": "2617",
                "id_ubigeo": "2623",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lonya Grande",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yamon utcubamba",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Yamon, Utcubamba",
                "id_padre_ubigeo": "2617",
                "id_ubigeo": "2624",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yamon",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2626": [
            {
                "buscador_ubigeo": "cochabamba huaraz",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Cochabamba, Huaraz",
                "id_padre_ubigeo": "2626",
                "id_ubigeo": "2628",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cochabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "colcabamba huaraz",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Colcabamba, Huaraz",
                "id_padre_ubigeo": "2626",
                "id_ubigeo": "2629",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Colcabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huanchay huaraz",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Huanchay, Huaraz",
                "id_padre_ubigeo": "2626",
                "id_ubigeo": "2630",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huanchay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huaraz huaraz",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Huaraz, Huaraz",
                "id_padre_ubigeo": "2626",
                "id_ubigeo": "2627",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huaraz",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "independencia huaraz",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Independencia, Huaraz",
                "id_padre_ubigeo": "2626",
                "id_ubigeo": "2631",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Independencia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jangas huaraz",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Jangas, Huaraz",
                "id_padre_ubigeo": "2626",
                "id_ubigeo": "2632",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jangas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la libertad huaraz",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "La Libertad, Huaraz",
                "id_padre_ubigeo": "2626",
                "id_ubigeo": "2633",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Libertad",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "olleros huaraz",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Olleros, Huaraz",
                "id_padre_ubigeo": "2626",
                "id_ubigeo": "2634",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Olleros",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pampas huaraz",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Pampas, Huaraz",
                "id_padre_ubigeo": "2626",
                "id_ubigeo": "2635",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pampas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pariacoto huaraz",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Pariacoto, Huaraz",
                "id_padre_ubigeo": "2626",
                "id_ubigeo": "2636",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pariacoto",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pira huaraz",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Pira, Huaraz",
                "id_padre_ubigeo": "2626",
                "id_ubigeo": "2637",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pira",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tarica huaraz",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Tarica, Huaraz",
                "id_padre_ubigeo": "2626",
                "id_ubigeo": "2638",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tarica",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2639": [
            {
                "buscador_ubigeo": "aija aija",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Aija, Aija",
                "id_padre_ubigeo": "2639",
                "id_ubigeo": "2640",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Aija",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "coris aija",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Coris, Aija",
                "id_padre_ubigeo": "2639",
                "id_ubigeo": "2641",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Coris",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huacllan aija",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Huacllan, Aija",
                "id_padre_ubigeo": "2639",
                "id_ubigeo": "2642",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huacllan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la merced aija",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "La Merced, Aija",
                "id_padre_ubigeo": "2639",
                "id_ubigeo": "2643",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Merced",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "succha aija",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Succha, Aija",
                "id_padre_ubigeo": "2639",
                "id_ubigeo": "2644",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Succha",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2645": [
            {
                "buscador_ubigeo": "aczo antonio raymondi",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Aczo, Antonio Raymondi",
                "id_padre_ubigeo": "2645",
                "id_ubigeo": "2647",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Aczo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chaccho antonio raymondi",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Chaccho, Antonio Raymondi",
                "id_padre_ubigeo": "2645",
                "id_ubigeo": "2648",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chaccho",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chingas antonio raymondi",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Chingas, Antonio Raymondi",
                "id_padre_ubigeo": "2645",
                "id_ubigeo": "2649",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chingas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "llamellin antonio raymondi",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Llamellin, Antonio Raymondi",
                "id_padre_ubigeo": "2645",
                "id_ubigeo": "2646",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Llamellin",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mirgas antonio raymondi",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Mirgas, Antonio Raymondi",
                "id_padre_ubigeo": "2645",
                "id_ubigeo": "2650",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mirgas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san juan de rontoy antonio raymondi",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "San Juan de Rontoy, Antonio Raymondi",
                "id_padre_ubigeo": "2645",
                "id_ubigeo": "2651",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Juan de Rontoy",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2652": [
            {
                "buscador_ubigeo": "acochaca asuncion",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Acochaca, Asuncion",
                "id_padre_ubigeo": "2652",
                "id_ubigeo": "2654",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Acochaca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chacas asuncion",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Chacas, Asuncion",
                "id_padre_ubigeo": "2652",
                "id_ubigeo": "2653",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chacas",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2655": [
            {
                "buscador_ubigeo": "abelardo pardo lezameta bolognesi",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Abelardo Pardo Lezameta, Bolognesi",
                "id_padre_ubigeo": "2655",
                "id_ubigeo": "2657",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Abelardo Pardo Lezameta",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "antonio raymondi bolognesi",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Antonio Raymondi, Bolognesi",
                "id_padre_ubigeo": "2655",
                "id_ubigeo": "2658",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Antonio Raymondi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "aquia bolognesi",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Aquia, Bolognesi",
                "id_padre_ubigeo": "2655",
                "id_ubigeo": "2659",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Aquia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cajacay bolognesi",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Cajacay, Bolognesi",
                "id_padre_ubigeo": "2655",
                "id_ubigeo": "2660",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cajacay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "canis bolognesi",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Canis, Bolognesi",
                "id_padre_ubigeo": "2655",
                "id_ubigeo": "2661",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Canis",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chiquian bolognesi",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Chiquian, Bolognesi",
                "id_padre_ubigeo": "2655",
                "id_ubigeo": "2656",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chiquian",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "colquioc bolognesi",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Colquioc, Bolognesi",
                "id_padre_ubigeo": "2655",
                "id_ubigeo": "2662",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Colquioc",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huallanca bolognesi",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Huallanca, Bolognesi",
                "id_padre_ubigeo": "2655",
                "id_ubigeo": "2663",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huallanca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huasta bolognesi",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Huasta, Bolognesi",
                "id_padre_ubigeo": "2655",
                "id_ubigeo": "2664",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huasta",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huayllacayan bolognesi",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Huayllacayan, Bolognesi",
                "id_padre_ubigeo": "2655",
                "id_ubigeo": "2665",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huayllacayan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la primavera bolognesi",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "La Primavera, Bolognesi",
                "id_padre_ubigeo": "2655",
                "id_ubigeo": "2666",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Primavera",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mangas bolognesi",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Mangas, Bolognesi",
                "id_padre_ubigeo": "2655",
                "id_ubigeo": "2667",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mangas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pacllon bolognesi",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Pacllon, Bolognesi",
                "id_padre_ubigeo": "2655",
                "id_ubigeo": "2668",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pacllon",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san miguel de corpanqui bolognesi",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "San Miguel de Corpanqui, Bolognesi",
                "id_padre_ubigeo": "2655",
                "id_ubigeo": "2669",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Miguel de Corpanqui",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ticllos bolognesi",
                "codigo_ubigeo": "15",
                "etiqueta_ubigeo": "Ticllos, Bolognesi",
                "id_padre_ubigeo": "2655",
                "id_ubigeo": "2670",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ticllos",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2671": [
            {
                "buscador_ubigeo": "acopampa carhuaz",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Acopampa, Carhuaz",
                "id_padre_ubigeo": "2671",
                "id_ubigeo": "2673",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Acopampa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "amashca carhuaz",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Amashca, Carhuaz",
                "id_padre_ubigeo": "2671",
                "id_ubigeo": "2674",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Amashca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "anta carhuaz",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Anta, Carhuaz",
                "id_padre_ubigeo": "2671",
                "id_ubigeo": "2675",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Anta",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ataquero carhuaz",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Ataquero, Carhuaz",
                "id_padre_ubigeo": "2671",
                "id_ubigeo": "2676",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ataquero",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "carhuaz carhuaz",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Carhuaz, Carhuaz",
                "id_padre_ubigeo": "2671",
                "id_ubigeo": "2672",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Carhuaz",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "marcara carhuaz",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Marcara, Carhuaz",
                "id_padre_ubigeo": "2671",
                "id_ubigeo": "2677",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Marcara",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pariahuanca carhuaz",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Pariahuanca, Carhuaz",
                "id_padre_ubigeo": "2671",
                "id_ubigeo": "2678",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pariahuanca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san miguel de aco carhuaz",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "San Miguel de Aco, Carhuaz",
                "id_padre_ubigeo": "2671",
                "id_ubigeo": "2679",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Miguel de Aco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "shilla carhuaz",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Shilla, Carhuaz",
                "id_padre_ubigeo": "2671",
                "id_ubigeo": "2680",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Shilla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tinco carhuaz",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Tinco, Carhuaz",
                "id_padre_ubigeo": "2671",
                "id_ubigeo": "2681",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tinco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yungar carhuaz",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Yungar, Carhuaz",
                "id_padre_ubigeo": "2671",
                "id_ubigeo": "2682",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yungar",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2683": [
            {
                "buscador_ubigeo": "san luis carlos fermin fitzcarrald",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "San Luis, Carlos Fermin Fitzcarrald",
                "id_padre_ubigeo": "2683",
                "id_ubigeo": "2684",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Luis",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san nicolas carlos fermin fitzcarrald",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "San Nicolas, Carlos Fermin Fitzcarrald",
                "id_padre_ubigeo": "2683",
                "id_ubigeo": "2685",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Nicolas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yauya carlos fermin fitzcarrald",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Yauya, Carlos Fermin Fitzcarrald",
                "id_padre_ubigeo": "2683",
                "id_ubigeo": "2686",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yauya",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2687": [
            {
                "buscador_ubigeo": "buena vista alta casma",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Buena Vista Alta, Casma",
                "id_padre_ubigeo": "2687",
                "id_ubigeo": "2689",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Buena Vista Alta",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "casma casma",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Casma, Casma",
                "id_padre_ubigeo": "2687",
                "id_ubigeo": "2688",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Casma",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "comandante noel casma",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Comandante Noel, Casma",
                "id_padre_ubigeo": "2687",
                "id_ubigeo": "2690",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Comandante Noel",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yautan casma",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Yautan, Casma",
                "id_padre_ubigeo": "2687",
                "id_ubigeo": "2691",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yautan",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2692": [
            {
                "buscador_ubigeo": "aco corongo",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Aco, Corongo",
                "id_padre_ubigeo": "2692",
                "id_ubigeo": "2694",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Aco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "bambas corongo",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Bambas, Corongo",
                "id_padre_ubigeo": "2692",
                "id_ubigeo": "2695",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Bambas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "corongo corongo",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Corongo, Corongo",
                "id_padre_ubigeo": "2692",
                "id_ubigeo": "2693",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Corongo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cusca corongo",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Cusca, Corongo",
                "id_padre_ubigeo": "2692",
                "id_ubigeo": "2696",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cusca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la pampa corongo",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "La Pampa, Corongo",
                "id_padre_ubigeo": "2692",
                "id_ubigeo": "2697",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Pampa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yanac corongo",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Yanac, Corongo",
                "id_padre_ubigeo": "2692",
                "id_ubigeo": "2698",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yanac",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yupan corongo",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Yupan, Corongo",
                "id_padre_ubigeo": "2692",
                "id_ubigeo": "2699",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yupan",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2700": [
            {
                "buscador_ubigeo": "anra huari",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Anra, Huari",
                "id_padre_ubigeo": "2700",
                "id_ubigeo": "2702",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Anra",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cajay huari",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Cajay, Huari",
                "id_padre_ubigeo": "2700",
                "id_ubigeo": "2703",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cajay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chavin de huantar huari",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Chavin de Huantar, Huari",
                "id_padre_ubigeo": "2700",
                "id_ubigeo": "2704",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chavin de Huantar",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huacachi huari",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Huacachi, Huari",
                "id_padre_ubigeo": "2700",
                "id_ubigeo": "2705",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huacachi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huacchis huari",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Huacchis, Huari",
                "id_padre_ubigeo": "2700",
                "id_ubigeo": "2706",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huacchis",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huachis huari",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Huachis, Huari",
                "id_padre_ubigeo": "2700",
                "id_ubigeo": "2707",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huachis",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huantar huari",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Huantar, Huari",
                "id_padre_ubigeo": "2700",
                "id_ubigeo": "2708",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huantar",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huari huari",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Huari, Huari",
                "id_padre_ubigeo": "2700",
                "id_ubigeo": "2701",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huari",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "masin huari",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Masin, Huari",
                "id_padre_ubigeo": "2700",
                "id_ubigeo": "2709",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Masin",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "paucas huari",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Paucas, Huari",
                "id_padre_ubigeo": "2700",
                "id_ubigeo": "2710",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Paucas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ponto huari",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Ponto, Huari",
                "id_padre_ubigeo": "2700",
                "id_ubigeo": "2711",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ponto",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "rahuapampa huari",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Rahuapampa, Huari",
                "id_padre_ubigeo": "2700",
                "id_ubigeo": "2712",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Rahuapampa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "rapayan huari",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Rapayan, Huari",
                "id_padre_ubigeo": "2700",
                "id_ubigeo": "2713",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Rapayan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san marcos huari",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "San Marcos, Huari",
                "id_padre_ubigeo": "2700",
                "id_ubigeo": "2714",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Marcos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san pedro de chana huari",
                "codigo_ubigeo": "15",
                "etiqueta_ubigeo": "San Pedro de Chana, Huari",
                "id_padre_ubigeo": "2700",
                "id_ubigeo": "2715",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Pedro de Chana",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "uco huari",
                "codigo_ubigeo": "16",
                "etiqueta_ubigeo": "Uco, Huari",
                "id_padre_ubigeo": "2700",
                "id_ubigeo": "2716",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Uco",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2717": [
            {
                "buscador_ubigeo": "cochapeti huarmey",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Cochapeti, Huarmey",
                "id_padre_ubigeo": "2717",
                "id_ubigeo": "2719",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cochapeti",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "culebras huarmey",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Culebras, Huarmey",
                "id_padre_ubigeo": "2717",
                "id_ubigeo": "2720",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Culebras",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huarmey huarmey",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Huarmey, Huarmey",
                "id_padre_ubigeo": "2717",
                "id_ubigeo": "2718",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huarmey",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huayan huarmey",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Huayan, Huarmey",
                "id_padre_ubigeo": "2717",
                "id_ubigeo": "2721",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huayan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "malvas huarmey",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Malvas, Huarmey",
                "id_padre_ubigeo": "2717",
                "id_ubigeo": "2722",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Malvas",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2723": [
            {
                "buscador_ubigeo": "caraz huaylas",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Caraz, Huaylas",
                "id_padre_ubigeo": "2723",
                "id_ubigeo": "2724",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Caraz",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huallanca huaylas",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Huallanca, Huaylas",
                "id_padre_ubigeo": "2723",
                "id_ubigeo": "2725",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huallanca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huata huaylas",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Huata, Huaylas",
                "id_padre_ubigeo": "2723",
                "id_ubigeo": "2726",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huaylas huaylas",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Huaylas, Huaylas",
                "id_padre_ubigeo": "2723",
                "id_ubigeo": "2727",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huaylas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mato huaylas",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Mato, Huaylas",
                "id_padre_ubigeo": "2723",
                "id_ubigeo": "2728",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mato",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pamparomas huaylas",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Pamparomas, Huaylas",
                "id_padre_ubigeo": "2723",
                "id_ubigeo": "2729",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pamparomas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pueblo libre huaylas",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Pueblo Libre, Huaylas",
                "id_padre_ubigeo": "2723",
                "id_ubigeo": "2730",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pueblo Libre",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa cruz huaylas",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Santa Cruz, Huaylas",
                "id_padre_ubigeo": "2723",
                "id_ubigeo": "2731",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Cruz",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santo toribio huaylas",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Santo Toribio, Huaylas",
                "id_padre_ubigeo": "2723",
                "id_ubigeo": "2732",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santo Toribio",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yuracmarca huaylas",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Yuracmarca, Huaylas",
                "id_padre_ubigeo": "2723",
                "id_ubigeo": "2733",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yuracmarca",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2734": [
            {
                "buscador_ubigeo": "casca mariscal luzuriaga",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Casca, Mariscal Luzuriaga",
                "id_padre_ubigeo": "2734",
                "id_ubigeo": "2736",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Casca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "eleazar guzman barron mariscal luzuriaga",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Eleazar Guzman Barron, Mariscal Luzuriaga",
                "id_padre_ubigeo": "2734",
                "id_ubigeo": "2737",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Eleazar Guzman Barron",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "fidel olivas escudero mariscal luzuriaga",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Fidel Olivas Escudero, Mariscal Luzuriaga",
                "id_padre_ubigeo": "2734",
                "id_ubigeo": "2738",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Fidel Olivas Escudero",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "llama mariscal luzuriaga",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Llama, Mariscal Luzuriaga",
                "id_padre_ubigeo": "2734",
                "id_ubigeo": "2739",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Llama",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "llumpa mariscal luzuriaga",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Llumpa, Mariscal Luzuriaga",
                "id_padre_ubigeo": "2734",
                "id_ubigeo": "2740",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Llumpa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lucma mariscal luzuriaga",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Lucma, Mariscal Luzuriaga",
                "id_padre_ubigeo": "2734",
                "id_ubigeo": "2741",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lucma",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "musga mariscal luzuriaga",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Musga, Mariscal Luzuriaga",
                "id_padre_ubigeo": "2734",
                "id_ubigeo": "2742",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Musga",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "piscobamba mariscal luzuriaga",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Piscobamba, Mariscal Luzuriaga",
                "id_padre_ubigeo": "2734",
                "id_ubigeo": "2735",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Piscobamba",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2743": [
            {
                "buscador_ubigeo": "acas ocros",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Acas, Ocros",
                "id_padre_ubigeo": "2743",
                "id_ubigeo": "2745",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Acas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cajamarquilla ocros",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Cajamarquilla, Ocros",
                "id_padre_ubigeo": "2743",
                "id_ubigeo": "2746",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cajamarquilla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "carhuapampa ocros",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Carhuapampa, Ocros",
                "id_padre_ubigeo": "2743",
                "id_ubigeo": "2747",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Carhuapampa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cochas ocros",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Cochas, Ocros",
                "id_padre_ubigeo": "2743",
                "id_ubigeo": "2748",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cochas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "congas ocros",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Congas, Ocros",
                "id_padre_ubigeo": "2743",
                "id_ubigeo": "2749",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Congas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "llipa ocros",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Llipa, Ocros",
                "id_padre_ubigeo": "2743",
                "id_ubigeo": "2750",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Llipa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ocros ocros",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Ocros, Ocros",
                "id_padre_ubigeo": "2743",
                "id_ubigeo": "2744",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ocros",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san cristobal de rajan ocros",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "San Cristobal de Rajan, Ocros",
                "id_padre_ubigeo": "2743",
                "id_ubigeo": "2751",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Cristobal de Rajan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san pedro ocros",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "San Pedro, Ocros",
                "id_padre_ubigeo": "2743",
                "id_ubigeo": "2752",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Pedro",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santiago de chilcas ocros",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Santiago de Chilcas, Ocros",
                "id_padre_ubigeo": "2743",
                "id_ubigeo": "2753",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santiago de Chilcas",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2754": [
            {
                "buscador_ubigeo": "bolognesi pallasca",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Bolognesi, Pallasca",
                "id_padre_ubigeo": "2754",
                "id_ubigeo": "2756",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Bolognesi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cabana pallasca",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Cabana, Pallasca",
                "id_padre_ubigeo": "2754",
                "id_ubigeo": "2755",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cabana",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "conchucos pallasca",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Conchucos, Pallasca",
                "id_padre_ubigeo": "2754",
                "id_ubigeo": "2757",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Conchucos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huacaschuque pallasca",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Huacaschuque, Pallasca",
                "id_padre_ubigeo": "2754",
                "id_ubigeo": "2758",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huacaschuque",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huandoval pallasca",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Huandoval, Pallasca",
                "id_padre_ubigeo": "2754",
                "id_ubigeo": "2759",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huandoval",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lacabamba pallasca",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Lacabamba, Pallasca",
                "id_padre_ubigeo": "2754",
                "id_ubigeo": "2760",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lacabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "llapo pallasca",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Llapo, Pallasca",
                "id_padre_ubigeo": "2754",
                "id_ubigeo": "2761",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Llapo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pallasca pallasca",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Pallasca, Pallasca",
                "id_padre_ubigeo": "2754",
                "id_ubigeo": "2762",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pallasca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pampas pallasca",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Pampas, Pallasca",
                "id_padre_ubigeo": "2754",
                "id_ubigeo": "2763",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pampas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa rosa pallasca",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Santa Rosa, Pallasca",
                "id_padre_ubigeo": "2754",
                "id_ubigeo": "2764",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Rosa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tauca pallasca",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Tauca, Pallasca",
                "id_padre_ubigeo": "2754",
                "id_ubigeo": "2765",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tauca",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2766": [
            {
                "buscador_ubigeo": "huayllan pomabamba",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Huayllan, Pomabamba",
                "id_padre_ubigeo": "2766",
                "id_ubigeo": "2768",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huayllan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "parobamba pomabamba",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Parobamba, Pomabamba",
                "id_padre_ubigeo": "2766",
                "id_ubigeo": "2769",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Parobamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pomabamba pomabamba",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Pomabamba, Pomabamba",
                "id_padre_ubigeo": "2766",
                "id_ubigeo": "2767",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pomabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "quinuabamba pomabamba",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Quinuabamba, Pomabamba",
                "id_padre_ubigeo": "2766",
                "id_ubigeo": "2770",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Quinuabamba",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2771": [
            {
                "buscador_ubigeo": "catac recuay",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Catac, Recuay",
                "id_padre_ubigeo": "2771",
                "id_ubigeo": "2773",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Catac",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cotaparaco recuay",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Cotaparaco, Recuay",
                "id_padre_ubigeo": "2771",
                "id_ubigeo": "2774",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cotaparaco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huayllapampa recuay",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Huayllapampa, Recuay",
                "id_padre_ubigeo": "2771",
                "id_ubigeo": "2775",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huayllapampa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "llacllin recuay",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Llacllin, Recuay",
                "id_padre_ubigeo": "2771",
                "id_ubigeo": "2776",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Llacllin",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "marca recuay",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Marca, Recuay",
                "id_padre_ubigeo": "2771",
                "id_ubigeo": "2777",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Marca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pampas chico recuay",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Pampas Chico, Recuay",
                "id_padre_ubigeo": "2771",
                "id_ubigeo": "2778",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pampas Chico",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pararin recuay",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Pararin, Recuay",
                "id_padre_ubigeo": "2771",
                "id_ubigeo": "2779",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pararin",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "recuay recuay",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Recuay, Recuay",
                "id_padre_ubigeo": "2771",
                "id_ubigeo": "2772",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Recuay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tapacocha recuay",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Tapacocha, Recuay",
                "id_padre_ubigeo": "2771",
                "id_ubigeo": "2780",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tapacocha",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ticapampa recuay",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Ticapampa, Recuay",
                "id_padre_ubigeo": "2771",
                "id_ubigeo": "2781",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ticapampa",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2782": [
            {
                "buscador_ubigeo": "caceres del peru santa",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Caceres del Peru, Santa",
                "id_padre_ubigeo": "2782",
                "id_ubigeo": "2784",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Caceres del Peru",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chimbote santa",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Chimbote, Santa",
                "id_padre_ubigeo": "2782",
                "id_ubigeo": "2783",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chimbote",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "coishco santa",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Coishco, Santa",
                "id_padre_ubigeo": "2782",
                "id_ubigeo": "2785",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Coishco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "macate santa",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Macate, Santa",
                "id_padre_ubigeo": "2782",
                "id_ubigeo": "2786",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Macate",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "moro santa",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Moro, Santa",
                "id_padre_ubigeo": "2782",
                "id_ubigeo": "2787",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Moro",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "nepeqa santa",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Nepeqa, Santa",
                "id_padre_ubigeo": "2782",
                "id_ubigeo": "2788",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Nepeqa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "nuevo chimbote santa",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Nuevo Chimbote, Santa",
                "id_padre_ubigeo": "2782",
                "id_ubigeo": "2791",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Nuevo Chimbote",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "samanco santa",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Samanco, Santa",
                "id_padre_ubigeo": "2782",
                "id_ubigeo": "2789",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Samanco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa santa",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Santa, Santa",
                "id_padre_ubigeo": "2782",
                "id_ubigeo": "2790",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2792": [
            {
                "buscador_ubigeo": "acobamba sihuas",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Acobamba, Sihuas",
                "id_padre_ubigeo": "2792",
                "id_ubigeo": "2794",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Acobamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "alfonso ugarte sihuas",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Alfonso Ugarte, Sihuas",
                "id_padre_ubigeo": "2792",
                "id_ubigeo": "2795",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Alfonso Ugarte",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cashapampa sihuas",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Cashapampa, Sihuas",
                "id_padre_ubigeo": "2792",
                "id_ubigeo": "2796",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cashapampa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chingalpo sihuas",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Chingalpo, Sihuas",
                "id_padre_ubigeo": "2792",
                "id_ubigeo": "2797",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chingalpo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huayllabamba sihuas",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Huayllabamba, Sihuas",
                "id_padre_ubigeo": "2792",
                "id_ubigeo": "2798",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huayllabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "quiches sihuas",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Quiches, Sihuas",
                "id_padre_ubigeo": "2792",
                "id_ubigeo": "2799",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Quiches",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ragash sihuas",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Ragash, Sihuas",
                "id_padre_ubigeo": "2792",
                "id_ubigeo": "2800",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ragash",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san juan sihuas",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "San Juan, Sihuas",
                "id_padre_ubigeo": "2792",
                "id_ubigeo": "2801",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Juan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sicsibamba sihuas",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Sicsibamba, Sihuas",
                "id_padre_ubigeo": "2792",
                "id_ubigeo": "2802",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sicsibamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sihuas sihuas",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Sihuas, Sihuas",
                "id_padre_ubigeo": "2792",
                "id_ubigeo": "2793",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sihuas",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2803": [
            {
                "buscador_ubigeo": "cascapara yungay",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Cascapara, Yungay",
                "id_padre_ubigeo": "2803",
                "id_ubigeo": "2805",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cascapara",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mancos yungay",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Mancos, Yungay",
                "id_padre_ubigeo": "2803",
                "id_ubigeo": "2806",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mancos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "matacoto yungay",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Matacoto, Yungay",
                "id_padre_ubigeo": "2803",
                "id_ubigeo": "2807",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Matacoto",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "quillo yungay",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Quillo, Yungay",
                "id_padre_ubigeo": "2803",
                "id_ubigeo": "2808",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Quillo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ranrahirca yungay",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Ranrahirca, Yungay",
                "id_padre_ubigeo": "2803",
                "id_ubigeo": "2809",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ranrahirca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "shupluy yungay",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Shupluy, Yungay",
                "id_padre_ubigeo": "2803",
                "id_ubigeo": "2810",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Shupluy",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yanama yungay",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Yanama, Yungay",
                "id_padre_ubigeo": "2803",
                "id_ubigeo": "2811",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yanama",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yungay yungay",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Yungay, Yungay",
                "id_padre_ubigeo": "2803",
                "id_ubigeo": "2804",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yungay",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2813": [
            {
                "buscador_ubigeo": "abancay abancay",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Abancay, Abancay",
                "id_padre_ubigeo": "2813",
                "id_ubigeo": "2814",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Abancay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chacoche abancay",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Chacoche, Abancay",
                "id_padre_ubigeo": "2813",
                "id_ubigeo": "2815",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chacoche",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "circa abancay",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Circa, Abancay",
                "id_padre_ubigeo": "2813",
                "id_ubigeo": "2816",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Circa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "curahuasi abancay",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Curahuasi, Abancay",
                "id_padre_ubigeo": "2813",
                "id_ubigeo": "2817",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Curahuasi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huanipaca abancay",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Huanipaca, Abancay",
                "id_padre_ubigeo": "2813",
                "id_ubigeo": "2818",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huanipaca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lambrama abancay",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Lambrama, Abancay",
                "id_padre_ubigeo": "2813",
                "id_ubigeo": "2819",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lambrama",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pichirhua abancay",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Pichirhua, Abancay",
                "id_padre_ubigeo": "2813",
                "id_ubigeo": "2820",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pichirhua",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san pedro de cachora abancay",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "San Pedro de Cachora, Abancay",
                "id_padre_ubigeo": "2813",
                "id_ubigeo": "2821",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Pedro de Cachora",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tamburco abancay",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Tamburco, Abancay",
                "id_padre_ubigeo": "2813",
                "id_ubigeo": "2822",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tamburco",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2823": [
            {
                "buscador_ubigeo": "andahuaylas andahuaylas",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Andahuaylas, Andahuaylas",
                "id_padre_ubigeo": "2823",
                "id_ubigeo": "2824",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Andahuaylas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "andarapa andahuaylas",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Andarapa, Andahuaylas",
                "id_padre_ubigeo": "2823",
                "id_ubigeo": "2825",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Andarapa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chiara andahuaylas",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Chiara, Andahuaylas",
                "id_padre_ubigeo": "2823",
                "id_ubigeo": "2826",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chiara",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huancarama andahuaylas",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Huancarama, Andahuaylas",
                "id_padre_ubigeo": "2823",
                "id_ubigeo": "2827",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huancarama",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huancaray andahuaylas",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Huancaray, Andahuaylas",
                "id_padre_ubigeo": "2823",
                "id_ubigeo": "2828",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huancaray",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huayana andahuaylas",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Huayana, Andahuaylas",
                "id_padre_ubigeo": "2823",
                "id_ubigeo": "2829",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huayana",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "kaquiabamba andahuaylas",
                "codigo_ubigeo": "19",
                "etiqueta_ubigeo": "Kaquiabamba, Andahuaylas",
                "id_padre_ubigeo": "2823",
                "id_ubigeo": "2842",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Kaquiabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "kishuara andahuaylas",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Kishuara, Andahuaylas",
                "id_padre_ubigeo": "2823",
                "id_ubigeo": "2830",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Kishuara",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pacobamba andahuaylas",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Pacobamba, Andahuaylas",
                "id_padre_ubigeo": "2823",
                "id_ubigeo": "2831",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pacobamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pacucha andahuaylas",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Pacucha, Andahuaylas",
                "id_padre_ubigeo": "2823",
                "id_ubigeo": "2832",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pacucha",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pampachiri andahuaylas",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Pampachiri, Andahuaylas",
                "id_padre_ubigeo": "2823",
                "id_ubigeo": "2833",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pampachiri",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pomacocha andahuaylas",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Pomacocha, Andahuaylas",
                "id_padre_ubigeo": "2823",
                "id_ubigeo": "2834",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pomacocha",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san antonio de cachi andahuaylas",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "San Antonio de Cachi, Andahuaylas",
                "id_padre_ubigeo": "2823",
                "id_ubigeo": "2835",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Antonio de Cachi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san jeronimo andahuaylas",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "San Jeronimo, Andahuaylas",
                "id_padre_ubigeo": "2823",
                "id_ubigeo": "2836",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Jeronimo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san miguel de chaccrampa andahuaylas",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "San Miguel de Chaccrampa, Andahuaylas",
                "id_padre_ubigeo": "2823",
                "id_ubigeo": "2837",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Miguel de Chaccrampa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa maria de chicmo andahuaylas",
                "codigo_ubigeo": "15",
                "etiqueta_ubigeo": "Santa Maria de Chicmo, Andahuaylas",
                "id_padre_ubigeo": "2823",
                "id_ubigeo": "2838",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Maria de Chicmo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "talavera andahuaylas",
                "codigo_ubigeo": "16",
                "etiqueta_ubigeo": "Talavera, Andahuaylas",
                "id_padre_ubigeo": "2823",
                "id_ubigeo": "2839",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Talavera",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tumay huaraca andahuaylas",
                "codigo_ubigeo": "17",
                "etiqueta_ubigeo": "Tumay Huaraca, Andahuaylas",
                "id_padre_ubigeo": "2823",
                "id_ubigeo": "2840",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tumay Huaraca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "turpo andahuaylas",
                "codigo_ubigeo": "18",
                "etiqueta_ubigeo": "Turpo, Andahuaylas",
                "id_padre_ubigeo": "2823",
                "id_ubigeo": "2841",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Turpo",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2843": [
            {
                "buscador_ubigeo": "antabamba antabamba",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Antabamba, Antabamba",
                "id_padre_ubigeo": "2843",
                "id_ubigeo": "2844",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Antabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "el oro antabamba",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "El Oro, Antabamba",
                "id_padre_ubigeo": "2843",
                "id_ubigeo": "2845",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "El Oro",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huaquirca antabamba",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Huaquirca, Antabamba",
                "id_padre_ubigeo": "2843",
                "id_ubigeo": "2846",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huaquirca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "juan espinoza medrano antabamba",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Juan Espinoza Medrano, Antabamba",
                "id_padre_ubigeo": "2843",
                "id_ubigeo": "2847",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Juan Espinoza Medrano",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "oropesa antabamba",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Oropesa, Antabamba",
                "id_padre_ubigeo": "2843",
                "id_ubigeo": "2848",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Oropesa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pachaconas antabamba",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Pachaconas, Antabamba",
                "id_padre_ubigeo": "2843",
                "id_ubigeo": "2849",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pachaconas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sabaino antabamba",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Sabaino, Antabamba",
                "id_padre_ubigeo": "2843",
                "id_ubigeo": "2850",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sabaino",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2851": [
            {
                "buscador_ubigeo": "capaya aymaraes",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Capaya, Aymaraes",
                "id_padre_ubigeo": "2851",
                "id_ubigeo": "2853",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Capaya",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "caraybamba aymaraes",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Caraybamba, Aymaraes",
                "id_padre_ubigeo": "2851",
                "id_ubigeo": "2854",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Caraybamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chalhuanca aymaraes",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Chalhuanca, Aymaraes",
                "id_padre_ubigeo": "2851",
                "id_ubigeo": "2852",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chalhuanca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chapimarca aymaraes",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Chapimarca, Aymaraes",
                "id_padre_ubigeo": "2851",
                "id_ubigeo": "2855",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chapimarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "colcabamba aymaraes",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Colcabamba, Aymaraes",
                "id_padre_ubigeo": "2851",
                "id_ubigeo": "2856",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Colcabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cotaruse aymaraes",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Cotaruse, Aymaraes",
                "id_padre_ubigeo": "2851",
                "id_ubigeo": "2857",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cotaruse",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huayllo aymaraes",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Huayllo, Aymaraes",
                "id_padre_ubigeo": "2851",
                "id_ubigeo": "2858",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huayllo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "justo apu sahuaraura aymaraes",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Justo Apu Sahuaraura, Aymaraes",
                "id_padre_ubigeo": "2851",
                "id_ubigeo": "2859",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Justo Apu Sahuaraura",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lucre aymaraes",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Lucre, Aymaraes",
                "id_padre_ubigeo": "2851",
                "id_ubigeo": "2860",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lucre",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pocohuanca aymaraes",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Pocohuanca, Aymaraes",
                "id_padre_ubigeo": "2851",
                "id_ubigeo": "2861",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pocohuanca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san juan de chacqa aymaraes",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "San Juan de Chacqa, Aymaraes",
                "id_padre_ubigeo": "2851",
                "id_ubigeo": "2862",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Juan de Chacqa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "saqayca aymaraes",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Saqayca, Aymaraes",
                "id_padre_ubigeo": "2851",
                "id_ubigeo": "2863",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Saqayca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "soraya aymaraes",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Soraya, Aymaraes",
                "id_padre_ubigeo": "2851",
                "id_ubigeo": "2864",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Soraya",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tapairihua aymaraes",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "Tapairihua, Aymaraes",
                "id_padre_ubigeo": "2851",
                "id_ubigeo": "2865",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tapairihua",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tintay aymaraes",
                "codigo_ubigeo": "15",
                "etiqueta_ubigeo": "Tintay, Aymaraes",
                "id_padre_ubigeo": "2851",
                "id_ubigeo": "2866",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tintay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "toraya aymaraes",
                "codigo_ubigeo": "16",
                "etiqueta_ubigeo": "Toraya, Aymaraes",
                "id_padre_ubigeo": "2851",
                "id_ubigeo": "2867",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Toraya",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yanaca aymaraes",
                "codigo_ubigeo": "17",
                "etiqueta_ubigeo": "Yanaca, Aymaraes",
                "id_padre_ubigeo": "2851",
                "id_ubigeo": "2868",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yanaca",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2869": [
            {
                "buscador_ubigeo": "challhuahuacho cotabambas",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Challhuahuacho, Cotabambas",
                "id_padre_ubigeo": "2869",
                "id_ubigeo": "2875",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Challhuahuacho",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cotabambas cotabambas",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Cotabambas, Cotabambas",
                "id_padre_ubigeo": "2869",
                "id_ubigeo": "2871",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cotabambas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "coyllurqui cotabambas",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Coyllurqui, Cotabambas",
                "id_padre_ubigeo": "2869",
                "id_ubigeo": "2872",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Coyllurqui",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "haquira cotabambas",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Haquira, Cotabambas",
                "id_padre_ubigeo": "2869",
                "id_ubigeo": "2873",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Haquira",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mara cotabambas",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Mara, Cotabambas",
                "id_padre_ubigeo": "2869",
                "id_ubigeo": "2874",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mara",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tambobamba cotabambas",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Tambobamba, Cotabambas",
                "id_padre_ubigeo": "2869",
                "id_ubigeo": "2870",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tambobamba",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2876": [
            {
                "buscador_ubigeo": "anco-huallo chincheros",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Anco-Huallo, Chincheros",
                "id_padre_ubigeo": "2876",
                "id_ubigeo": "2878",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Anco-Huallo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chincheros chincheros",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Chincheros, Chincheros",
                "id_padre_ubigeo": "2876",
                "id_ubigeo": "2877",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chincheros",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cocharcas chincheros",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Cocharcas, Chincheros",
                "id_padre_ubigeo": "2876",
                "id_ubigeo": "2879",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cocharcas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huaccana chincheros",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Huaccana, Chincheros",
                "id_padre_ubigeo": "2876",
                "id_ubigeo": "2880",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huaccana",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ocobamba chincheros",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Ocobamba, Chincheros",
                "id_padre_ubigeo": "2876",
                "id_ubigeo": "2881",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ocobamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ongoy chincheros",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Ongoy, Chincheros",
                "id_padre_ubigeo": "2876",
                "id_ubigeo": "2882",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ongoy",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ranracancha chincheros",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Ranracancha, Chincheros",
                "id_padre_ubigeo": "2876",
                "id_ubigeo": "2884",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ranracancha",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "uranmarca chincheros",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Uranmarca, Chincheros",
                "id_padre_ubigeo": "2876",
                "id_ubigeo": "2883",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Uranmarca",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2885": [
            {
                "buscador_ubigeo": "chuquibambilla grau",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Chuquibambilla, Grau",
                "id_padre_ubigeo": "2885",
                "id_ubigeo": "2886",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chuquibambilla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "curasco grau",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "Curasco, Grau",
                "id_padre_ubigeo": "2885",
                "id_ubigeo": "2899",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Curasco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "curpahuasi grau",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Curpahuasi, Grau",
                "id_padre_ubigeo": "2885",
                "id_ubigeo": "2887",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Curpahuasi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "gamarra grau",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Gamarra, Grau",
                "id_padre_ubigeo": "2885",
                "id_ubigeo": "2888",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Gamarra",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huayllati grau",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Huayllati, Grau",
                "id_padre_ubigeo": "2885",
                "id_ubigeo": "2889",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huayllati",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mamara grau",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Mamara, Grau",
                "id_padre_ubigeo": "2885",
                "id_ubigeo": "2890",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mamara",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "micaela bastidas grau",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Micaela Bastidas, Grau",
                "id_padre_ubigeo": "2885",
                "id_ubigeo": "2891",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Micaela Bastidas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pataypampa grau",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Pataypampa, Grau",
                "id_padre_ubigeo": "2885",
                "id_ubigeo": "2892",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pataypampa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "progreso grau",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Progreso, Grau",
                "id_padre_ubigeo": "2885",
                "id_ubigeo": "2893",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Progreso",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san antonio grau",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "San Antonio, Grau",
                "id_padre_ubigeo": "2885",
                "id_ubigeo": "2894",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Antonio",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa rosa grau",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Santa Rosa, Grau",
                "id_padre_ubigeo": "2885",
                "id_ubigeo": "2895",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Rosa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "turpay grau",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Turpay, Grau",
                "id_padre_ubigeo": "2885",
                "id_ubigeo": "2896",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Turpay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "vilcabamba grau",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Vilcabamba, Grau",
                "id_padre_ubigeo": "2885",
                "id_ubigeo": "2897",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Vilcabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "virundo grau",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Virundo, Grau",
                "id_padre_ubigeo": "2885",
                "id_ubigeo": "2898",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Virundo",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2901": [
            {
                "buscador_ubigeo": "alto selva alegre arequipa",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Alto Selva Alegre, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2903",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Alto Selva Alegre",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "arequipa arequipa",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Arequipa, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2902",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Arequipa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cayma arequipa",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Cayma, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2904",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cayma",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cerro colorado arequipa",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Cerro Colorado, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2905",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cerro Colorado",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "characato arequipa",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Characato, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2906",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Characato",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chiguata arequipa",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Chiguata, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2907",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chiguata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jacobo hunter arequipa",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Jacobo Hunter, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2908",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jacobo Hunter",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jose luis bustamante y rivero arequipa",
                "codigo_ubigeo": "29",
                "etiqueta_ubigeo": "Jose Luis Bustamante y Rivero, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2930",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jose Luis Bustamante y Rivero",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la joya arequipa",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "La Joya, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2909",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Joya",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mariano melgar arequipa",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Mariano Melgar, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2910",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mariano Melgar",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "miraflores arequipa",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Miraflores, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2911",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Miraflores",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mollebaya arequipa",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Mollebaya, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2912",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mollebaya",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "paucarpata arequipa",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Paucarpata, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2913",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Paucarpata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pocsi arequipa",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Pocsi, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2914",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pocsi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "polobaya arequipa",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "Polobaya, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2915",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Polobaya",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "quequeqa arequipa",
                "codigo_ubigeo": "15",
                "etiqueta_ubigeo": "Quequeqa, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2916",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Quequeqa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sabandia arequipa",
                "codigo_ubigeo": "16",
                "etiqueta_ubigeo": "Sabandia, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2917",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sabandia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sachaca arequipa",
                "codigo_ubigeo": "17",
                "etiqueta_ubigeo": "Sachaca, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2918",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sachaca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san juan de siguas arequipa",
                "codigo_ubigeo": "18",
                "etiqueta_ubigeo": "San Juan de Siguas, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2919",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Juan de Siguas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san juan de tarucani arequipa",
                "codigo_ubigeo": "19",
                "etiqueta_ubigeo": "San Juan de Tarucani, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2920",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Juan de Tarucani",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa isabel de siguas arequipa",
                "codigo_ubigeo": "20",
                "etiqueta_ubigeo": "Santa Isabel de Siguas, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2921",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Isabel de Siguas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa rita de siguas arequipa",
                "codigo_ubigeo": "21",
                "etiqueta_ubigeo": "Santa Rita de Siguas, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2922",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Rita de Siguas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "socabaya arequipa",
                "codigo_ubigeo": "22",
                "etiqueta_ubigeo": "Socabaya, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2923",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Socabaya",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tiabaya arequipa",
                "codigo_ubigeo": "23",
                "etiqueta_ubigeo": "Tiabaya, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2924",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tiabaya",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "uchumayo arequipa",
                "codigo_ubigeo": "24",
                "etiqueta_ubigeo": "Uchumayo, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2925",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Uchumayo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "vitor arequipa",
                "codigo_ubigeo": "25",
                "etiqueta_ubigeo": "Vitor, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2926",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Vitor",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yanahuara arequipa",
                "codigo_ubigeo": "26",
                "etiqueta_ubigeo": "Yanahuara, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2927",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yanahuara",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yarabamba arequipa",
                "codigo_ubigeo": "27",
                "etiqueta_ubigeo": "Yarabamba, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2928",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yarabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yura arequipa",
                "codigo_ubigeo": "28",
                "etiqueta_ubigeo": "Yura, Arequipa",
                "id_padre_ubigeo": "2901",
                "id_ubigeo": "2929",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yura",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2931": [
            {
                "buscador_ubigeo": "camana camana",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Camana, Camana",
                "id_padre_ubigeo": "2931",
                "id_ubigeo": "2932",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Camana",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jose maria quimper camana",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Jose Maria Quimper, Camana",
                "id_padre_ubigeo": "2931",
                "id_ubigeo": "2933",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jose Maria Quimper",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mariano nicolas valcarcel camana",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Mariano Nicolas Valcarcel, Camana",
                "id_padre_ubigeo": "2931",
                "id_ubigeo": "2934",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mariano Nicolas Valcarcel",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mariscal caceres camana",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Mariscal Caceres, Camana",
                "id_padre_ubigeo": "2931",
                "id_ubigeo": "2935",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mariscal Caceres",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "nicolas de pierola camana",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Nicolas de Pierola, Camana",
                "id_padre_ubigeo": "2931",
                "id_ubigeo": "2936",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Nicolas de Pierola",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ocoqa camana",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Ocoqa, Camana",
                "id_padre_ubigeo": "2931",
                "id_ubigeo": "2937",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ocoqa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "quilca camana",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Quilca, Camana",
                "id_padre_ubigeo": "2931",
                "id_ubigeo": "2938",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Quilca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "samuel pastor camana",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Samuel Pastor, Camana",
                "id_padre_ubigeo": "2931",
                "id_ubigeo": "2939",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Samuel Pastor",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2940": [
            {
                "buscador_ubigeo": "acari caraveli",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Acari, Caraveli",
                "id_padre_ubigeo": "2940",
                "id_ubigeo": "2942",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Acari",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "atico caraveli",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Atico, Caraveli",
                "id_padre_ubigeo": "2940",
                "id_ubigeo": "2943",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Atico",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "atiquipa caraveli",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Atiquipa, Caraveli",
                "id_padre_ubigeo": "2940",
                "id_ubigeo": "2944",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Atiquipa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "bella union caraveli",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Bella Union, Caraveli",
                "id_padre_ubigeo": "2940",
                "id_ubigeo": "2945",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Bella Union",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cahuacho caraveli",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Cahuacho, Caraveli",
                "id_padre_ubigeo": "2940",
                "id_ubigeo": "2946",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cahuacho",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "caraveli caraveli",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Caraveli, Caraveli",
                "id_padre_ubigeo": "2940",
                "id_ubigeo": "2941",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Caraveli",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chala caraveli",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Chala, Caraveli",
                "id_padre_ubigeo": "2940",
                "id_ubigeo": "2947",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chala",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chaparra caraveli",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Chaparra, Caraveli",
                "id_padre_ubigeo": "2940",
                "id_ubigeo": "2948",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chaparra",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huanuhuanu caraveli",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Huanuhuanu, Caraveli",
                "id_padre_ubigeo": "2940",
                "id_ubigeo": "2949",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huanuhuanu",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jaqui caraveli",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Jaqui, Caraveli",
                "id_padre_ubigeo": "2940",
                "id_ubigeo": "2950",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jaqui",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lomas caraveli",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Lomas, Caraveli",
                "id_padre_ubigeo": "2940",
                "id_ubigeo": "2951",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lomas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "quicacha caraveli",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Quicacha, Caraveli",
                "id_padre_ubigeo": "2940",
                "id_ubigeo": "2952",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Quicacha",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yauca caraveli",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Yauca, Caraveli",
                "id_padre_ubigeo": "2940",
                "id_ubigeo": "2953",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yauca",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2954": [
            {
                "buscador_ubigeo": "andagua castilla",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Andagua, Castilla",
                "id_padre_ubigeo": "2954",
                "id_ubigeo": "2956",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Andagua",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "aplao castilla",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Aplao, Castilla",
                "id_padre_ubigeo": "2954",
                "id_ubigeo": "2955",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Aplao",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ayo castilla",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Ayo, Castilla",
                "id_padre_ubigeo": "2954",
                "id_ubigeo": "2957",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ayo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chachas castilla",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Chachas, Castilla",
                "id_padre_ubigeo": "2954",
                "id_ubigeo": "2958",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chachas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chilcaymarca castilla",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Chilcaymarca, Castilla",
                "id_padre_ubigeo": "2954",
                "id_ubigeo": "2959",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chilcaymarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "choco castilla",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Choco, Castilla",
                "id_padre_ubigeo": "2954",
                "id_ubigeo": "2960",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Choco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huancarqui castilla",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Huancarqui, Castilla",
                "id_padre_ubigeo": "2954",
                "id_ubigeo": "2961",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huancarqui",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "machaguay castilla",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Machaguay, Castilla",
                "id_padre_ubigeo": "2954",
                "id_ubigeo": "2962",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Machaguay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "majes castilla",
                "codigo_ubigeo": "20",
                "etiqueta_ubigeo": "Majes, Castilla",
                "id_padre_ubigeo": "2954",
                "id_ubigeo": "2970",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Majes",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "orcopampa castilla",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Orcopampa, Castilla",
                "id_padre_ubigeo": "2954",
                "id_ubigeo": "2963",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Orcopampa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pampacolca castilla",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Pampacolca, Castilla",
                "id_padre_ubigeo": "2954",
                "id_ubigeo": "2964",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pampacolca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tipan castilla",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Tipan, Castilla",
                "id_padre_ubigeo": "2954",
                "id_ubigeo": "2965",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tipan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "uqon castilla",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Uqon, Castilla",
                "id_padre_ubigeo": "2954",
                "id_ubigeo": "2966",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Uqon",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "uraca castilla",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Uraca, Castilla",
                "id_padre_ubigeo": "2954",
                "id_ubigeo": "2967",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Uraca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "viraco castilla",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "Viraco, Castilla",
                "id_padre_ubigeo": "2954",
                "id_ubigeo": "2968",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Viraco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yanque castilla",
                "codigo_ubigeo": "19",
                "etiqueta_ubigeo": "Yanque, Castilla",
                "id_padre_ubigeo": "2954",
                "id_ubigeo": "2969",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yanque",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2971": [
            {
                "buscador_ubigeo": "achoma caylloma",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Achoma, Caylloma",
                "id_padre_ubigeo": "2971",
                "id_ubigeo": "2973",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Achoma",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cabanaconde caylloma",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Cabanaconde, Caylloma",
                "id_padre_ubigeo": "2971",
                "id_ubigeo": "2974",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cabanaconde",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "callalli caylloma",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Callalli, Caylloma",
                "id_padre_ubigeo": "2971",
                "id_ubigeo": "2975",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Callalli",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "caylloma caylloma",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Caylloma, Caylloma",
                "id_padre_ubigeo": "2971",
                "id_ubigeo": "2976",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Caylloma",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chivay caylloma",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Chivay, Caylloma",
                "id_padre_ubigeo": "2971",
                "id_ubigeo": "2972",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chivay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "coporaque caylloma",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Coporaque, Caylloma",
                "id_padre_ubigeo": "2971",
                "id_ubigeo": "2977",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Coporaque",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huambo caylloma",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Huambo, Caylloma",
                "id_padre_ubigeo": "2971",
                "id_ubigeo": "2978",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huambo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huanca caylloma",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Huanca, Caylloma",
                "id_padre_ubigeo": "2971",
                "id_ubigeo": "2979",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huanca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ichupampa caylloma",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Ichupampa, Caylloma",
                "id_padre_ubigeo": "2971",
                "id_ubigeo": "2980",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ichupampa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lari caylloma",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Lari, Caylloma",
                "id_padre_ubigeo": "2971",
                "id_ubigeo": "2981",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lari",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lluta caylloma",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Lluta, Caylloma",
                "id_padre_ubigeo": "2971",
                "id_ubigeo": "2982",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lluta",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "maca caylloma",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Maca, Caylloma",
                "id_padre_ubigeo": "2971",
                "id_ubigeo": "2983",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Maca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "madrigal caylloma",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Madrigal, Caylloma",
                "id_padre_ubigeo": "2971",
                "id_ubigeo": "2984",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Madrigal",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "majes caylloma",
                "codigo_ubigeo": "20",
                "etiqueta_ubigeo": "Majes, Caylloma",
                "id_padre_ubigeo": "2971",
                "id_ubigeo": "2991",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Majes",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san antonio de chuca caylloma",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "San Antonio de Chuca, Caylloma",
                "id_padre_ubigeo": "2971",
                "id_ubigeo": "2985",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Antonio de Chuca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sibayo caylloma",
                "codigo_ubigeo": "15",
                "etiqueta_ubigeo": "Sibayo, Caylloma",
                "id_padre_ubigeo": "2971",
                "id_ubigeo": "2986",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sibayo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tapay caylloma",
                "codigo_ubigeo": "16",
                "etiqueta_ubigeo": "Tapay, Caylloma",
                "id_padre_ubigeo": "2971",
                "id_ubigeo": "2987",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tapay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tisco caylloma",
                "codigo_ubigeo": "17",
                "etiqueta_ubigeo": "Tisco, Caylloma",
                "id_padre_ubigeo": "2971",
                "id_ubigeo": "2988",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tisco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tuti caylloma",
                "codigo_ubigeo": "18",
                "etiqueta_ubigeo": "Tuti, Caylloma",
                "id_padre_ubigeo": "2971",
                "id_ubigeo": "2989",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tuti",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yanque caylloma",
                "codigo_ubigeo": "19",
                "etiqueta_ubigeo": "Yanque, Caylloma",
                "id_padre_ubigeo": "2971",
                "id_ubigeo": "2990",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yanque",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "2992": [
            {
                "buscador_ubigeo": "andaray condesuyos",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Andaray, Condesuyos",
                "id_padre_ubigeo": "2992",
                "id_ubigeo": "2994",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Andaray",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cayarani condesuyos",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Cayarani, Condesuyos",
                "id_padre_ubigeo": "2992",
                "id_ubigeo": "2995",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cayarani",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chichas condesuyos",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Chichas, Condesuyos",
                "id_padre_ubigeo": "2992",
                "id_ubigeo": "2996",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chichas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chuquibamba condesuyos",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Chuquibamba, Condesuyos",
                "id_padre_ubigeo": "2992",
                "id_ubigeo": "2993",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chuquibamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "iray condesuyos",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Iray, Condesuyos",
                "id_padre_ubigeo": "2992",
                "id_ubigeo": "2997",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Iray",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "rio grande condesuyos",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Rio Grande, Condesuyos",
                "id_padre_ubigeo": "2992",
                "id_ubigeo": "2998",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Rio Grande",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "salamanca condesuyos",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Salamanca, Condesuyos",
                "id_padre_ubigeo": "2992",
                "id_ubigeo": "2999",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Salamanca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yanaquihua condesuyos",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Yanaquihua, Condesuyos",
                "id_padre_ubigeo": "2992",
                "id_ubigeo": "3000",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yanaquihua",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3001": [
            {
                "buscador_ubigeo": "cocachacra islay",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Cocachacra, Islay",
                "id_padre_ubigeo": "3001",
                "id_ubigeo": "3003",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cocachacra",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "dean valdivia islay",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Dean Valdivia, Islay",
                "id_padre_ubigeo": "3001",
                "id_ubigeo": "3004",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Dean Valdivia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "islay islay",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Islay, Islay",
                "id_padre_ubigeo": "3001",
                "id_ubigeo": "3005",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Islay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mejia islay",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Mejia, Islay",
                "id_padre_ubigeo": "3001",
                "id_ubigeo": "3006",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mejia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mollendo islay",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Mollendo, Islay",
                "id_padre_ubigeo": "3001",
                "id_ubigeo": "3002",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mollendo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "punta de bombon islay",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Punta de Bombon, Islay",
                "id_padre_ubigeo": "3001",
                "id_ubigeo": "3007",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Punta de Bombon",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3008": [
            {
                "buscador_ubigeo": "alca la union",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Alca, La Union",
                "id_padre_ubigeo": "3008",
                "id_ubigeo": "3010",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Alca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "charcana la union",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Charcana, La Union",
                "id_padre_ubigeo": "3008",
                "id_ubigeo": "3011",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Charcana",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cotahuasi la union",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Cotahuasi, La Union",
                "id_padre_ubigeo": "3008",
                "id_ubigeo": "3009",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cotahuasi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huaynacotas la union",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Huaynacotas, La Union",
                "id_padre_ubigeo": "3008",
                "id_ubigeo": "3012",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huaynacotas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pampamarca la union",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Pampamarca, La Union",
                "id_padre_ubigeo": "3008",
                "id_ubigeo": "3013",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pampamarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "puyca la union",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Puyca, La Union",
                "id_padre_ubigeo": "3008",
                "id_ubigeo": "3014",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Puyca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "quechualla la union",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Quechualla, La Union",
                "id_padre_ubigeo": "3008",
                "id_ubigeo": "3015",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Quechualla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sayla la union",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Sayla, La Union",
                "id_padre_ubigeo": "3008",
                "id_ubigeo": "3016",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sayla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tauria la union",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Tauria, La Union",
                "id_padre_ubigeo": "3008",
                "id_ubigeo": "3017",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tauria",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tomepampa la union",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Tomepampa, La Union",
                "id_padre_ubigeo": "3008",
                "id_ubigeo": "3018",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tomepampa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "toro la union",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Toro, La Union",
                "id_padre_ubigeo": "3008",
                "id_ubigeo": "3019",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Toro",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3021": [
            {
                "buscador_ubigeo": "acocro huamanga",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Acocro, Huamanga",
                "id_padre_ubigeo": "3021",
                "id_ubigeo": "3023",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Acocro",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "acos vinchos huamanga",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Acos Vinchos, Huamanga",
                "id_padre_ubigeo": "3021",
                "id_ubigeo": "3024",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Acos Vinchos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ayacucho huamanga",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Ayacucho, Huamanga",
                "id_padre_ubigeo": "3021",
                "id_ubigeo": "3022",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ayacucho",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "carmen alto huamanga",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Carmen Alto, Huamanga",
                "id_padre_ubigeo": "3021",
                "id_ubigeo": "3025",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Carmen Alto",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chiara huamanga",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Chiara, Huamanga",
                "id_padre_ubigeo": "3021",
                "id_ubigeo": "3026",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chiara",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jesús nazareno huamanga",
                "codigo_ubigeo": "15",
                "etiqueta_ubigeo": "Jesús Nazareno, Huamanga",
                "id_padre_ubigeo": "3021",
                "id_ubigeo": "3036",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jesús Nazareno",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ocros huamanga",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Ocros, Huamanga",
                "id_padre_ubigeo": "3021",
                "id_ubigeo": "3027",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ocros",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pacaycasa huamanga",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Pacaycasa, Huamanga",
                "id_padre_ubigeo": "3021",
                "id_ubigeo": "3028",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pacaycasa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "quinua huamanga",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Quinua, Huamanga",
                "id_padre_ubigeo": "3021",
                "id_ubigeo": "3029",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Quinua",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san jose de ticllas huamanga",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "San Jose de Ticllas, Huamanga",
                "id_padre_ubigeo": "3021",
                "id_ubigeo": "3030",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Jose de Ticllas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san juan bautista huamanga",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "San Juan Bautista, Huamanga",
                "id_padre_ubigeo": "3021",
                "id_ubigeo": "3031",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Juan Bautista",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santiago de pischa huamanga",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Santiago de Pischa, Huamanga",
                "id_padre_ubigeo": "3021",
                "id_ubigeo": "3032",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santiago de Pischa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "socos huamanga",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Socos, Huamanga",
                "id_padre_ubigeo": "3021",
                "id_ubigeo": "3033",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Socos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tambillo huamanga",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Tambillo, Huamanga",
                "id_padre_ubigeo": "3021",
                "id_ubigeo": "3034",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tambillo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "vinchos huamanga",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "Vinchos, Huamanga",
                "id_padre_ubigeo": "3021",
                "id_ubigeo": "3035",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Vinchos",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3037": [
            {
                "buscador_ubigeo": "cangallo cangallo",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Cangallo, Cangallo",
                "id_padre_ubigeo": "3037",
                "id_ubigeo": "3038",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cangallo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chuschi cangallo",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Chuschi, Cangallo",
                "id_padre_ubigeo": "3037",
                "id_ubigeo": "3039",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chuschi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "los morochucos cangallo",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Los Morochucos, Cangallo",
                "id_padre_ubigeo": "3037",
                "id_ubigeo": "3040",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Los Morochucos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "maria parado de bellido cangallo",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Maria Parado de Bellido, Cangallo",
                "id_padre_ubigeo": "3037",
                "id_ubigeo": "3041",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Maria Parado de Bellido",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "paras cangallo",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Paras, Cangallo",
                "id_padre_ubigeo": "3037",
                "id_ubigeo": "3042",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Paras",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "totos cangallo",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Totos, Cangallo",
                "id_padre_ubigeo": "3037",
                "id_ubigeo": "3043",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Totos",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3044": [
            {
                "buscador_ubigeo": "carapo huanca sancos",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Carapo, Huanca Sancos",
                "id_padre_ubigeo": "3044",
                "id_ubigeo": "3046",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Carapo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sacsamarca huanca sancos",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Sacsamarca, Huanca Sancos",
                "id_padre_ubigeo": "3044",
                "id_ubigeo": "3047",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sacsamarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sancos huanca sancos",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Sancos, Huanca Sancos",
                "id_padre_ubigeo": "3044",
                "id_ubigeo": "3045",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sancos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santiago de lucanamarca huanca sancos",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Santiago de Lucanamarca, Huanca Sancos",
                "id_padre_ubigeo": "3044",
                "id_ubigeo": "3048",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santiago de Lucanamarca",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3049": [
            {
                "buscador_ubigeo": "ayahuanco huanta",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Ayahuanco, Huanta",
                "id_padre_ubigeo": "3049",
                "id_ubigeo": "3051",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ayahuanco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huamanguilla huanta",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Huamanguilla, Huanta",
                "id_padre_ubigeo": "3049",
                "id_ubigeo": "3052",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huamanguilla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huanta huanta",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Huanta, Huanta",
                "id_padre_ubigeo": "3049",
                "id_ubigeo": "3050",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huanta",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "iguain huanta",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Iguain, Huanta",
                "id_padre_ubigeo": "3049",
                "id_ubigeo": "3053",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Iguain",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "llochegua huanta",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Llochegua, Huanta",
                "id_padre_ubigeo": "3049",
                "id_ubigeo": "3057",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Llochegua",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "luricocha huanta",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Luricocha, Huanta",
                "id_padre_ubigeo": "3049",
                "id_ubigeo": "3054",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Luricocha",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santillana huanta",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Santillana, Huanta",
                "id_padre_ubigeo": "3049",
                "id_ubigeo": "3055",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santillana",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sivia huanta",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Sivia, Huanta",
                "id_padre_ubigeo": "3049",
                "id_ubigeo": "3056",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sivia",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3058": [
            {
                "buscador_ubigeo": "anco la mar",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Anco, La Mar",
                "id_padre_ubigeo": "3058",
                "id_ubigeo": "3060",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Anco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ayna la mar",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Ayna, La Mar",
                "id_padre_ubigeo": "3058",
                "id_ubigeo": "3061",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ayna",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chilcas la mar",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Chilcas, La Mar",
                "id_padre_ubigeo": "3058",
                "id_ubigeo": "3062",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chilcas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chungui la mar",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Chungui, La Mar",
                "id_padre_ubigeo": "3058",
                "id_ubigeo": "3063",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chungui",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "luis carranza la mar",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Luis Carranza, La Mar",
                "id_padre_ubigeo": "3058",
                "id_ubigeo": "3064",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Luis Carranza",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san miguel la mar",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "San Miguel, La Mar",
                "id_padre_ubigeo": "3058",
                "id_ubigeo": "3059",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Miguel",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa rosa la mar",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Santa Rosa, La Mar",
                "id_padre_ubigeo": "3058",
                "id_ubigeo": "3065",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Rosa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tambo la mar",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Tambo, La Mar",
                "id_padre_ubigeo": "3058",
                "id_ubigeo": "3066",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tambo",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3067": [
            {
                "buscador_ubigeo": "aucara lucanas",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Aucara, Lucanas",
                "id_padre_ubigeo": "3067",
                "id_ubigeo": "3069",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Aucara",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cabana lucanas",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Cabana, Lucanas",
                "id_padre_ubigeo": "3067",
                "id_ubigeo": "3070",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cabana",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "carmen salcedo lucanas",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Carmen Salcedo, Lucanas",
                "id_padre_ubigeo": "3067",
                "id_ubigeo": "3071",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Carmen Salcedo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chaviqa lucanas",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Chaviqa, Lucanas",
                "id_padre_ubigeo": "3067",
                "id_ubigeo": "3072",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chaviqa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chipao lucanas",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Chipao, Lucanas",
                "id_padre_ubigeo": "3067",
                "id_ubigeo": "3073",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chipao",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huac-huas lucanas",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Huac-Huas, Lucanas",
                "id_padre_ubigeo": "3067",
                "id_ubigeo": "3074",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huac-Huas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "laramate lucanas",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Laramate, Lucanas",
                "id_padre_ubigeo": "3067",
                "id_ubigeo": "3075",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Laramate",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "leoncio prado lucanas",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Leoncio Prado, Lucanas",
                "id_padre_ubigeo": "3067",
                "id_ubigeo": "3076",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Leoncio Prado",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "llauta lucanas",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Llauta, Lucanas",
                "id_padre_ubigeo": "3067",
                "id_ubigeo": "3077",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Llauta",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lucanas lucanas",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Lucanas, Lucanas",
                "id_padre_ubigeo": "3067",
                "id_ubigeo": "3078",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lucanas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ocaqa lucanas",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Ocaqa, Lucanas",
                "id_padre_ubigeo": "3067",
                "id_ubigeo": "3079",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ocaqa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "otoca lucanas",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Otoca, Lucanas",
                "id_padre_ubigeo": "3067",
                "id_ubigeo": "3080",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Otoca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "puquio lucanas",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Puquio, Lucanas",
                "id_padre_ubigeo": "3067",
                "id_ubigeo": "3068",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Puquio",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "saisa lucanas",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "Saisa, Lucanas",
                "id_padre_ubigeo": "3067",
                "id_ubigeo": "3081",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Saisa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san cristobal lucanas",
                "codigo_ubigeo": "15",
                "etiqueta_ubigeo": "San Cristobal, Lucanas",
                "id_padre_ubigeo": "3067",
                "id_ubigeo": "3082",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Cristobal",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san juan lucanas",
                "codigo_ubigeo": "16",
                "etiqueta_ubigeo": "San Juan, Lucanas",
                "id_padre_ubigeo": "3067",
                "id_ubigeo": "3083",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Juan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san pedro lucanas",
                "codigo_ubigeo": "17",
                "etiqueta_ubigeo": "San Pedro, Lucanas",
                "id_padre_ubigeo": "3067",
                "id_ubigeo": "3084",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Pedro",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san pedro de palco lucanas",
                "codigo_ubigeo": "18",
                "etiqueta_ubigeo": "San Pedro de Palco, Lucanas",
                "id_padre_ubigeo": "3067",
                "id_ubigeo": "3085",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Pedro de Palco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sancos lucanas",
                "codigo_ubigeo": "19",
                "etiqueta_ubigeo": "Sancos, Lucanas",
                "id_padre_ubigeo": "3067",
                "id_ubigeo": "3086",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sancos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa ana de huaycahuacho lucanas",
                "codigo_ubigeo": "20",
                "etiqueta_ubigeo": "Santa Ana de Huaycahuacho, Lucanas",
                "id_padre_ubigeo": "3067",
                "id_ubigeo": "3087",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Ana de Huaycahuacho",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa lucia lucanas",
                "codigo_ubigeo": "21",
                "etiqueta_ubigeo": "Santa Lucia, Lucanas",
                "id_padre_ubigeo": "3067",
                "id_ubigeo": "3088",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Lucia",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3089": [
            {
                "buscador_ubigeo": "chumpi parinacochas",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Chumpi, Parinacochas",
                "id_padre_ubigeo": "3089",
                "id_ubigeo": "3091",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chumpi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "coracora parinacochas",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Coracora, Parinacochas",
                "id_padre_ubigeo": "3089",
                "id_ubigeo": "3090",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Coracora",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "coronel castaqeda parinacochas",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Coronel Castaqeda, Parinacochas",
                "id_padre_ubigeo": "3089",
                "id_ubigeo": "3092",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Coronel Castaqeda",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pacapausa parinacochas",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Pacapausa, Parinacochas",
                "id_padre_ubigeo": "3089",
                "id_ubigeo": "3093",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pacapausa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pullo parinacochas",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Pullo, Parinacochas",
                "id_padre_ubigeo": "3089",
                "id_ubigeo": "3094",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pullo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "puyusca parinacochas",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Puyusca, Parinacochas",
                "id_padre_ubigeo": "3089",
                "id_ubigeo": "3095",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Puyusca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san francisco de ravacayco parinacochas",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "San Francisco de Ravacayco, Parinacochas",
                "id_padre_ubigeo": "3089",
                "id_ubigeo": "3096",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Francisco de Ravacayco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "upahuacho parinacochas",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Upahuacho, Parinacochas",
                "id_padre_ubigeo": "3089",
                "id_ubigeo": "3097",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Upahuacho",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3098": [
            {
                "buscador_ubigeo": "colta paucar del sara sara",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Colta, Paucar del Sara Sara",
                "id_padre_ubigeo": "3098",
                "id_ubigeo": "3100",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Colta",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "corculla paucar del sara sara",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Corculla, Paucar del Sara Sara",
                "id_padre_ubigeo": "3098",
                "id_ubigeo": "3101",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Corculla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lampa paucar del sara sara",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Lampa, Paucar del Sara Sara",
                "id_padre_ubigeo": "3098",
                "id_ubigeo": "3102",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lampa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "marcabamba paucar del sara sara",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Marcabamba, Paucar del Sara Sara",
                "id_padre_ubigeo": "3098",
                "id_ubigeo": "3103",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Marcabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "oyolo paucar del sara sara",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Oyolo, Paucar del Sara Sara",
                "id_padre_ubigeo": "3098",
                "id_ubigeo": "3104",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Oyolo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pararca paucar del sara sara",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Pararca, Paucar del Sara Sara",
                "id_padre_ubigeo": "3098",
                "id_ubigeo": "3105",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pararca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pausa paucar del sara sara",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Pausa, Paucar del Sara Sara",
                "id_padre_ubigeo": "3098",
                "id_ubigeo": "3099",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pausa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san javier de alpabamba paucar del sara sara",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "San Javier de Alpabamba, Paucar del Sara Sara",
                "id_padre_ubigeo": "3098",
                "id_ubigeo": "3106",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Javier de Alpabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san jose de ushua paucar del sara sara",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "San Jose de Ushua, Paucar del Sara Sara",
                "id_padre_ubigeo": "3098",
                "id_ubigeo": "3107",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Jose de Ushua",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sara sara paucar del sara sara",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Sara Sara, Paucar del Sara Sara",
                "id_padre_ubigeo": "3098",
                "id_ubigeo": "3108",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sara Sara",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3109": [
            {
                "buscador_ubigeo": "belen sucre",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Belen, Sucre",
                "id_padre_ubigeo": "3109",
                "id_ubigeo": "3111",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Belen",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chalcos sucre",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Chalcos, Sucre",
                "id_padre_ubigeo": "3109",
                "id_ubigeo": "3112",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chalcos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chilcayoc sucre",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Chilcayoc, Sucre",
                "id_padre_ubigeo": "3109",
                "id_ubigeo": "3113",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chilcayoc",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huacaqa sucre",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Huacaqa, Sucre",
                "id_padre_ubigeo": "3109",
                "id_ubigeo": "3114",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huacaqa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "morcolla sucre",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Morcolla, Sucre",
                "id_padre_ubigeo": "3109",
                "id_ubigeo": "3115",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Morcolla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "paico sucre",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Paico, Sucre",
                "id_padre_ubigeo": "3109",
                "id_ubigeo": "3116",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Paico",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "querobamba sucre",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Querobamba, Sucre",
                "id_padre_ubigeo": "3109",
                "id_ubigeo": "3110",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Querobamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san pedro de larcay sucre",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "San Pedro de Larcay, Sucre",
                "id_padre_ubigeo": "3109",
                "id_ubigeo": "3117",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Pedro de Larcay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san salvador de quije sucre",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "San Salvador de Quije, Sucre",
                "id_padre_ubigeo": "3109",
                "id_ubigeo": "3118",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Salvador de Quije",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santiago de paucaray sucre",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Santiago de Paucaray, Sucre",
                "id_padre_ubigeo": "3109",
                "id_ubigeo": "3119",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santiago de Paucaray",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "soras sucre",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Soras, Sucre",
                "id_padre_ubigeo": "3109",
                "id_ubigeo": "3120",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Soras",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3121": [
            {
                "buscador_ubigeo": "alcamenca victor fajardo",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Alcamenca, Victor Fajardo",
                "id_padre_ubigeo": "3121",
                "id_ubigeo": "3123",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Alcamenca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "apongo victor fajardo",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Apongo, Victor Fajardo",
                "id_padre_ubigeo": "3121",
                "id_ubigeo": "3124",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Apongo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "asquipata victor fajardo",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Asquipata, Victor Fajardo",
                "id_padre_ubigeo": "3121",
                "id_ubigeo": "3125",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Asquipata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "canaria victor fajardo",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Canaria, Victor Fajardo",
                "id_padre_ubigeo": "3121",
                "id_ubigeo": "3126",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Canaria",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cayara victor fajardo",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Cayara, Victor Fajardo",
                "id_padre_ubigeo": "3121",
                "id_ubigeo": "3127",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cayara",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "colca victor fajardo",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Colca, Victor Fajardo",
                "id_padre_ubigeo": "3121",
                "id_ubigeo": "3128",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Colca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huamanquiquia victor fajardo",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Huamanquiquia, Victor Fajardo",
                "id_padre_ubigeo": "3121",
                "id_ubigeo": "3129",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huamanquiquia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huancapi victor fajardo",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Huancapi, Victor Fajardo",
                "id_padre_ubigeo": "3121",
                "id_ubigeo": "3122",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huancapi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huancaraylla victor fajardo",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Huancaraylla, Victor Fajardo",
                "id_padre_ubigeo": "3121",
                "id_ubigeo": "3130",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huancaraylla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huaya victor fajardo",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Huaya, Victor Fajardo",
                "id_padre_ubigeo": "3121",
                "id_ubigeo": "3131",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huaya",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sarhua victor fajardo",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Sarhua, Victor Fajardo",
                "id_padre_ubigeo": "3121",
                "id_ubigeo": "3132",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sarhua",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "vilcanchos victor fajardo",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Vilcanchos, Victor Fajardo",
                "id_padre_ubigeo": "3121",
                "id_ubigeo": "3133",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Vilcanchos",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3134": [
            {
                "buscador_ubigeo": "accomarca vilcas huaman",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Accomarca, Vilcas Huaman",
                "id_padre_ubigeo": "3134",
                "id_ubigeo": "3136",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Accomarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "carhuanca vilcas huaman",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Carhuanca, Vilcas Huaman",
                "id_padre_ubigeo": "3134",
                "id_ubigeo": "3137",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Carhuanca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "concepcion vilcas huaman",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Concepcion, Vilcas Huaman",
                "id_padre_ubigeo": "3134",
                "id_ubigeo": "3138",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Concepcion",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huambalpa vilcas huaman",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Huambalpa, Vilcas Huaman",
                "id_padre_ubigeo": "3134",
                "id_ubigeo": "3139",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huambalpa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "independencia vilcas huaman",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Independencia, Vilcas Huaman",
                "id_padre_ubigeo": "3134",
                "id_ubigeo": "3140",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Independencia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "saurama vilcas huaman",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Saurama, Vilcas Huaman",
                "id_padre_ubigeo": "3134",
                "id_ubigeo": "3141",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Saurama",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "vilcas huaman vilcas huaman",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Vilcas Huaman, Vilcas Huaman",
                "id_padre_ubigeo": "3134",
                "id_ubigeo": "3135",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Vilcas Huaman",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "vischongo vilcas huaman",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Vischongo, Vilcas Huaman",
                "id_padre_ubigeo": "3134",
                "id_ubigeo": "3142",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Vischongo",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3144": [
            {
                "buscador_ubigeo": "asuncion cajamarca",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Asuncion, Cajamarca",
                "id_padre_ubigeo": "3144",
                "id_ubigeo": "3146",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Asuncion",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cajamarca cajamarca",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Cajamarca, Cajamarca",
                "id_padre_ubigeo": "3144",
                "id_ubigeo": "3145",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cajamarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chetilla cajamarca",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Chetilla, Cajamarca",
                "id_padre_ubigeo": "3144",
                "id_ubigeo": "3147",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chetilla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cospan cajamarca",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Cospan, Cajamarca",
                "id_padre_ubigeo": "3144",
                "id_ubigeo": "3148",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cospan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "encaqada cajamarca",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Encaqada, Cajamarca",
                "id_padre_ubigeo": "3144",
                "id_ubigeo": "3149",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Encaqada",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jesus cajamarca",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Jesus, Cajamarca",
                "id_padre_ubigeo": "3144",
                "id_ubigeo": "3150",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jesus",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "llacanora cajamarca",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Llacanora, Cajamarca",
                "id_padre_ubigeo": "3144",
                "id_ubigeo": "3151",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Llacanora",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "los baqos del inca cajamarca",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Los Baqos del Inca, Cajamarca",
                "id_padre_ubigeo": "3144",
                "id_ubigeo": "3152",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Los Baqos del Inca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "magdalena cajamarca",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Magdalena, Cajamarca",
                "id_padre_ubigeo": "3144",
                "id_ubigeo": "3153",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Magdalena",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "matara cajamarca",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Matara, Cajamarca",
                "id_padre_ubigeo": "3144",
                "id_ubigeo": "3154",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Matara",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "namora cajamarca",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Namora, Cajamarca",
                "id_padre_ubigeo": "3144",
                "id_ubigeo": "3155",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Namora",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san juan cajamarca",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "San Juan, Cajamarca",
                "id_padre_ubigeo": "3144",
                "id_ubigeo": "3156",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Juan",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3157": [
            {
                "buscador_ubigeo": "cachachi cajabamba",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Cachachi, Cajabamba",
                "id_padre_ubigeo": "3157",
                "id_ubigeo": "3159",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cachachi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cajabamba cajabamba",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Cajabamba, Cajabamba",
                "id_padre_ubigeo": "3157",
                "id_ubigeo": "3158",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cajabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "condebamba cajabamba",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Condebamba, Cajabamba",
                "id_padre_ubigeo": "3157",
                "id_ubigeo": "3160",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Condebamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sitacocha cajabamba",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Sitacocha, Cajabamba",
                "id_padre_ubigeo": "3157",
                "id_ubigeo": "3161",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sitacocha",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3162": [
            {
                "buscador_ubigeo": "celendin celendin",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Celendin, Celendin",
                "id_padre_ubigeo": "3162",
                "id_ubigeo": "3163",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Celendin",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chumuch celendin",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Chumuch, Celendin",
                "id_padre_ubigeo": "3162",
                "id_ubigeo": "3164",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chumuch",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cortegana celendin",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Cortegana, Celendin",
                "id_padre_ubigeo": "3162",
                "id_ubigeo": "3165",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cortegana",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huasmin celendin",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Huasmin, Celendin",
                "id_padre_ubigeo": "3162",
                "id_ubigeo": "3166",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huasmin",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jorge chavez celendin",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Jorge Chavez, Celendin",
                "id_padre_ubigeo": "3162",
                "id_ubigeo": "3167",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jorge Chavez",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jose galvez celendin",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Jose Galvez, Celendin",
                "id_padre_ubigeo": "3162",
                "id_ubigeo": "3168",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jose Galvez",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la libertad de pallan celendin",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "La Libertad de Pallan, Celendin",
                "id_padre_ubigeo": "3162",
                "id_ubigeo": "3174",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Libertad de Pallan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "miguel iglesias celendin",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Miguel Iglesias, Celendin",
                "id_padre_ubigeo": "3162",
                "id_ubigeo": "3169",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Miguel Iglesias",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "oxamarca celendin",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Oxamarca, Celendin",
                "id_padre_ubigeo": "3162",
                "id_ubigeo": "3170",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Oxamarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sorochuco celendin",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Sorochuco, Celendin",
                "id_padre_ubigeo": "3162",
                "id_ubigeo": "3171",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sorochuco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sucre celendin",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Sucre, Celendin",
                "id_padre_ubigeo": "3162",
                "id_ubigeo": "3172",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sucre",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "utco celendin",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Utco, Celendin",
                "id_padre_ubigeo": "3162",
                "id_ubigeo": "3173",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Utco",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3175": [
            {
                "buscador_ubigeo": "anguia chota",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Anguia, Chota",
                "id_padre_ubigeo": "3175",
                "id_ubigeo": "3177",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Anguia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chadin chota",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Chadin, Chota",
                "id_padre_ubigeo": "3175",
                "id_ubigeo": "3178",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chadin",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chalamarca chota",
                "codigo_ubigeo": "19",
                "etiqueta_ubigeo": "Chalamarca, Chota",
                "id_padre_ubigeo": "3175",
                "id_ubigeo": "3194",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chalamarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chiguirip chota",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Chiguirip, Chota",
                "id_padre_ubigeo": "3175",
                "id_ubigeo": "3179",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chiguirip",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chimban chota",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Chimban, Chota",
                "id_padre_ubigeo": "3175",
                "id_ubigeo": "3180",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chimban",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "choropampa chota",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Choropampa, Chota",
                "id_padre_ubigeo": "3175",
                "id_ubigeo": "3181",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Choropampa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chota chota",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Chota, Chota",
                "id_padre_ubigeo": "3175",
                "id_ubigeo": "3176",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chota",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cochabamba chota",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Cochabamba, Chota",
                "id_padre_ubigeo": "3175",
                "id_ubigeo": "3182",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cochabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "conchan chota",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Conchan, Chota",
                "id_padre_ubigeo": "3175",
                "id_ubigeo": "3183",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Conchan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huambos chota",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Huambos, Chota",
                "id_padre_ubigeo": "3175",
                "id_ubigeo": "3184",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huambos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lajas chota",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Lajas, Chota",
                "id_padre_ubigeo": "3175",
                "id_ubigeo": "3185",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lajas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "llama chota",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Llama, Chota",
                "id_padre_ubigeo": "3175",
                "id_ubigeo": "3186",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Llama",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "miracosta chota",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Miracosta, Chota",
                "id_padre_ubigeo": "3175",
                "id_ubigeo": "3187",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Miracosta",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "paccha chota",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Paccha, Chota",
                "id_padre_ubigeo": "3175",
                "id_ubigeo": "3188",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Paccha",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pion chota",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "Pion, Chota",
                "id_padre_ubigeo": "3175",
                "id_ubigeo": "3189",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pion",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "querocoto chota",
                "codigo_ubigeo": "15",
                "etiqueta_ubigeo": "Querocoto, Chota",
                "id_padre_ubigeo": "3175",
                "id_ubigeo": "3190",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Querocoto",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san juan de licupis chota",
                "codigo_ubigeo": "16",
                "etiqueta_ubigeo": "San Juan de Licupis, Chota",
                "id_padre_ubigeo": "3175",
                "id_ubigeo": "3191",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Juan de Licupis",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tacabamba chota",
                "codigo_ubigeo": "17",
                "etiqueta_ubigeo": "Tacabamba, Chota",
                "id_padre_ubigeo": "3175",
                "id_ubigeo": "3192",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tacabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tocmoche chota",
                "codigo_ubigeo": "18",
                "etiqueta_ubigeo": "Tocmoche, Chota",
                "id_padre_ubigeo": "3175",
                "id_ubigeo": "3193",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tocmoche",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3195": [
            {
                "buscador_ubigeo": "chilete contumaza",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Chilete, Contumaza",
                "id_padre_ubigeo": "3195",
                "id_ubigeo": "3197",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chilete",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "contumaza contumaza",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Contumaza, Contumaza",
                "id_padre_ubigeo": "3195",
                "id_ubigeo": "3196",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Contumaza",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cupisnique contumaza",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Cupisnique, Contumaza",
                "id_padre_ubigeo": "3195",
                "id_ubigeo": "3198",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cupisnique",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "guzmango contumaza",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Guzmango, Contumaza",
                "id_padre_ubigeo": "3195",
                "id_ubigeo": "3199",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Guzmango",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san benito contumaza",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "San Benito, Contumaza",
                "id_padre_ubigeo": "3195",
                "id_ubigeo": "3200",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Benito",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa cruz de toled contumaza",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Santa Cruz de Toled, Contumaza",
                "id_padre_ubigeo": "3195",
                "id_ubigeo": "3201",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Cruz de Toled",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tantarica contumaza",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Tantarica, Contumaza",
                "id_padre_ubigeo": "3195",
                "id_ubigeo": "3202",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tantarica",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yonan contumaza",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Yonan, Contumaza",
                "id_padre_ubigeo": "3195",
                "id_ubigeo": "3203",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yonan",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3204": [
            {
                "buscador_ubigeo": "callayuc cutervo",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Callayuc, Cutervo",
                "id_padre_ubigeo": "3204",
                "id_ubigeo": "3206",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Callayuc",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "choros cutervo",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Choros, Cutervo",
                "id_padre_ubigeo": "3204",
                "id_ubigeo": "3207",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Choros",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cujillo cutervo",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Cujillo, Cutervo",
                "id_padre_ubigeo": "3204",
                "id_ubigeo": "3208",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cujillo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cutervo cutervo",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Cutervo, Cutervo",
                "id_padre_ubigeo": "3204",
                "id_ubigeo": "3205",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cutervo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la ramada cutervo",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "La Ramada, Cutervo",
                "id_padre_ubigeo": "3204",
                "id_ubigeo": "3209",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Ramada",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pimpingos cutervo",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Pimpingos, Cutervo",
                "id_padre_ubigeo": "3204",
                "id_ubigeo": "3210",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pimpingos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "querocotillo cutervo",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Querocotillo, Cutervo",
                "id_padre_ubigeo": "3204",
                "id_ubigeo": "3211",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Querocotillo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san andres de cutervo cutervo",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "San Andres de Cutervo, Cutervo",
                "id_padre_ubigeo": "3204",
                "id_ubigeo": "3212",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Andres de Cutervo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san juan de cutervo cutervo",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "San Juan de Cutervo, Cutervo",
                "id_padre_ubigeo": "3204",
                "id_ubigeo": "3213",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Juan de Cutervo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san luis de lucma cutervo",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "San Luis de Lucma, Cutervo",
                "id_padre_ubigeo": "3204",
                "id_ubigeo": "3214",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Luis de Lucma",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa cruz cutervo",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Santa Cruz, Cutervo",
                "id_padre_ubigeo": "3204",
                "id_ubigeo": "3215",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Cruz",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santo domingo de la capilla cutervo",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Santo Domingo de la Capilla, Cutervo",
                "id_padre_ubigeo": "3204",
                "id_ubigeo": "3216",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santo Domingo de la Capilla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santo tomas cutervo",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Santo Tomas, Cutervo",
                "id_padre_ubigeo": "3204",
                "id_ubigeo": "3217",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santo Tomas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "socota cutervo",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "Socota, Cutervo",
                "id_padre_ubigeo": "3204",
                "id_ubigeo": "3218",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Socota",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "toribio casanova cutervo",
                "codigo_ubigeo": "15",
                "etiqueta_ubigeo": "Toribio Casanova, Cutervo",
                "id_padre_ubigeo": "3204",
                "id_ubigeo": "3219",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Toribio Casanova",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3220": [
            {
                "buscador_ubigeo": "bambamarca hualgayoc",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Bambamarca, Hualgayoc",
                "id_padre_ubigeo": "3220",
                "id_ubigeo": "3221",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Bambamarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chugur hualgayoc",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Chugur, Hualgayoc",
                "id_padre_ubigeo": "3220",
                "id_ubigeo": "3222",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chugur",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "hualgayoc hualgayoc",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Hualgayoc, Hualgayoc",
                "id_padre_ubigeo": "3220",
                "id_ubigeo": "3223",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Hualgayoc",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3224": [
            {
                "buscador_ubigeo": "bellavista jaen",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Bellavista, Jaen",
                "id_padre_ubigeo": "3224",
                "id_ubigeo": "3226",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Bellavista",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chontali jaen",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Chontali, Jaen",
                "id_padre_ubigeo": "3224",
                "id_ubigeo": "3227",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chontali",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "colasay jaen",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Colasay, Jaen",
                "id_padre_ubigeo": "3224",
                "id_ubigeo": "3228",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Colasay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huabal jaen",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Huabal, Jaen",
                "id_padre_ubigeo": "3224",
                "id_ubigeo": "3229",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huabal",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jaen jaen",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Jaen, Jaen",
                "id_padre_ubigeo": "3224",
                "id_ubigeo": "3225",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jaen",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "las pirias jaen",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Las Pirias, Jaen",
                "id_padre_ubigeo": "3224",
                "id_ubigeo": "3230",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Las Pirias",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pomahuaca jaen",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Pomahuaca, Jaen",
                "id_padre_ubigeo": "3224",
                "id_ubigeo": "3231",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pomahuaca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pucara jaen",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Pucara, Jaen",
                "id_padre_ubigeo": "3224",
                "id_ubigeo": "3232",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pucara",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sallique jaen",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Sallique, Jaen",
                "id_padre_ubigeo": "3224",
                "id_ubigeo": "3233",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sallique",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san felipe jaen",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "San Felipe, Jaen",
                "id_padre_ubigeo": "3224",
                "id_ubigeo": "3234",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Felipe",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san jose del alto jaen",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "San Jose del Alto, Jaen",
                "id_padre_ubigeo": "3224",
                "id_ubigeo": "3235",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Jose del Alto",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa rosa jaen",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Santa Rosa, Jaen",
                "id_padre_ubigeo": "3224",
                "id_ubigeo": "3236",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Rosa",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3237": [
            {
                "buscador_ubigeo": "chirinos san ignacio",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Chirinos, San Ignacio",
                "id_padre_ubigeo": "3237",
                "id_ubigeo": "3239",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chirinos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huarango san ignacio",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Huarango, San Ignacio",
                "id_padre_ubigeo": "3237",
                "id_ubigeo": "3240",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huarango",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la coipa san ignacio",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "La Coipa, San Ignacio",
                "id_padre_ubigeo": "3237",
                "id_ubigeo": "3241",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Coipa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "namballe san ignacio",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Namballe, San Ignacio",
                "id_padre_ubigeo": "3237",
                "id_ubigeo": "3242",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Namballe",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san ignacio san ignacio",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "San Ignacio, San Ignacio",
                "id_padre_ubigeo": "3237",
                "id_ubigeo": "3238",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Ignacio",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san jose de lourdes san ignacio",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "San Jose de Lourdes, San Ignacio",
                "id_padre_ubigeo": "3237",
                "id_ubigeo": "3243",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Jose de Lourdes",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tabaconas san ignacio",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Tabaconas, San Ignacio",
                "id_padre_ubigeo": "3237",
                "id_ubigeo": "3244",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tabaconas",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3245": [
            {
                "buscador_ubigeo": "chancay san marcos",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Chancay, San Marcos",
                "id_padre_ubigeo": "3245",
                "id_ubigeo": "3247",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chancay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "eduardo villanueva san marcos",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Eduardo Villanueva, San Marcos",
                "id_padre_ubigeo": "3245",
                "id_ubigeo": "3248",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Eduardo Villanueva",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "gregorio pita san marcos",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Gregorio Pita, San Marcos",
                "id_padre_ubigeo": "3245",
                "id_ubigeo": "3249",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Gregorio Pita",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ichocan san marcos",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Ichocan, San Marcos",
                "id_padre_ubigeo": "3245",
                "id_ubigeo": "3250",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ichocan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jose manuel quiroz san marcos",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Jose Manuel Quiroz, San Marcos",
                "id_padre_ubigeo": "3245",
                "id_ubigeo": "3251",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jose Manuel Quiroz",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jose sabogal san marcos",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Jose Sabogal, San Marcos",
                "id_padre_ubigeo": "3245",
                "id_ubigeo": "3252",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jose Sabogal",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pedro galvez san marcos",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Pedro Galvez, San Marcos",
                "id_padre_ubigeo": "3245",
                "id_ubigeo": "3246",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pedro Galvez",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3253": [
            {
                "buscador_ubigeo": "bolivar san miguel",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Bolivar, San Miguel",
                "id_padre_ubigeo": "3253",
                "id_ubigeo": "3255",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Bolivar",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "calquis san miguel",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Calquis, San Miguel",
                "id_padre_ubigeo": "3253",
                "id_ubigeo": "3256",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Calquis",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "catilluc san miguel",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Catilluc, San Miguel",
                "id_padre_ubigeo": "3253",
                "id_ubigeo": "3257",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Catilluc",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "el prado san miguel",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "El Prado, San Miguel",
                "id_padre_ubigeo": "3253",
                "id_ubigeo": "3258",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "El Prado",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la florida san miguel",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "La Florida, San Miguel",
                "id_padre_ubigeo": "3253",
                "id_ubigeo": "3259",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Florida",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "llapa san miguel",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Llapa, San Miguel",
                "id_padre_ubigeo": "3253",
                "id_ubigeo": "3260",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Llapa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "nanchoc san miguel",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Nanchoc, San Miguel",
                "id_padre_ubigeo": "3253",
                "id_ubigeo": "3261",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Nanchoc",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "niepos san miguel",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Niepos, San Miguel",
                "id_padre_ubigeo": "3253",
                "id_ubigeo": "3262",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Niepos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san gregorio san miguel",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "San Gregorio, San Miguel",
                "id_padre_ubigeo": "3253",
                "id_ubigeo": "3263",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Gregorio",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san miguel san miguel",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "San Miguel, San Miguel",
                "id_padre_ubigeo": "3253",
                "id_ubigeo": "3254",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Miguel",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san silvestre de cochan san miguel",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "San Silvestre de Cochan, San Miguel",
                "id_padre_ubigeo": "3253",
                "id_ubigeo": "3264",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Silvestre de Cochan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tongod san miguel",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Tongod, San Miguel",
                "id_padre_ubigeo": "3253",
                "id_ubigeo": "3265",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tongod",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "union agua blanca san miguel",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Union Agua Blanca, San Miguel",
                "id_padre_ubigeo": "3253",
                "id_ubigeo": "3266",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Union Agua Blanca",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3267": [
            {
                "buscador_ubigeo": "san bernardino san pablo",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "San Bernardino, San Pablo",
                "id_padre_ubigeo": "3267",
                "id_ubigeo": "3269",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Bernardino",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san luis san pablo",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "San Luis, San Pablo",
                "id_padre_ubigeo": "3267",
                "id_ubigeo": "3270",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Luis",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san pablo san pablo",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "San Pablo, San Pablo",
                "id_padre_ubigeo": "3267",
                "id_ubigeo": "3268",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Pablo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tumbaden san pablo",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Tumbaden, San Pablo",
                "id_padre_ubigeo": "3267",
                "id_ubigeo": "3271",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tumbaden",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3272": [
            {
                "buscador_ubigeo": "andabamba santa cruz",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Andabamba, Santa Cruz",
                "id_padre_ubigeo": "3272",
                "id_ubigeo": "3274",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Andabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "catache santa cruz",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Catache, Santa Cruz",
                "id_padre_ubigeo": "3272",
                "id_ubigeo": "3275",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Catache",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chancaybaqos santa cruz",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Chancaybaqos, Santa Cruz",
                "id_padre_ubigeo": "3272",
                "id_ubigeo": "3276",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chancaybaqos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la esperanza santa cruz",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "La Esperanza, Santa Cruz",
                "id_padre_ubigeo": "3272",
                "id_ubigeo": "3277",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Esperanza",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ninabamba santa cruz",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Ninabamba, Santa Cruz",
                "id_padre_ubigeo": "3272",
                "id_ubigeo": "3278",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ninabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pulan santa cruz",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Pulan, Santa Cruz",
                "id_padre_ubigeo": "3272",
                "id_ubigeo": "3279",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pulan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa cruz santa cruz",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Santa Cruz, Santa Cruz",
                "id_padre_ubigeo": "3272",
                "id_ubigeo": "3273",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Cruz",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "saucepampa santa cruz",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Saucepampa, Santa Cruz",
                "id_padre_ubigeo": "3272",
                "id_ubigeo": "3280",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Saucepampa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sexi santa cruz",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Sexi, Santa Cruz",
                "id_padre_ubigeo": "3272",
                "id_ubigeo": "3281",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sexi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "uticyacu santa cruz",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Uticyacu, Santa Cruz",
                "id_padre_ubigeo": "3272",
                "id_ubigeo": "3282",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Uticyacu",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yauyucan santa cruz",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Yauyucan, Santa Cruz",
                "id_padre_ubigeo": "3272",
                "id_ubigeo": "3283",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yauyucan",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3285": [
            {
                "buscador_ubigeo": "bellavista callao",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Bellavista, Callao",
                "id_padre_ubigeo": "3285",
                "id_ubigeo": "3287",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Bellavista",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "callao callao",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Callao, Callao",
                "id_padre_ubigeo": "3285",
                "id_ubigeo": "3286",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Callao",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "carmen de la legua reynoso callao",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Carmen de la Legua Reynoso, Callao",
                "id_padre_ubigeo": "3285",
                "id_ubigeo": "3288",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Carmen de la Legua Reynoso",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la perla callao",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "La Perla, Callao",
                "id_padre_ubigeo": "3285",
                "id_ubigeo": "3289",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Perla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la punta callao",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "La Punta, Callao",
                "id_padre_ubigeo": "3285",
                "id_ubigeo": "3290",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Punta",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ventanilla callao",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Ventanilla, Callao",
                "id_padre_ubigeo": "3285",
                "id_ubigeo": "3291",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ventanilla",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3293": [
            {
                "buscador_ubigeo": "ccorca cusco",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Ccorca, Cusco",
                "id_padre_ubigeo": "3293",
                "id_ubigeo": "3295",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ccorca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cusco cusco",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Cusco, Cusco",
                "id_padre_ubigeo": "3293",
                "id_ubigeo": "3294",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cusco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "poroy cusco",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Poroy, Cusco",
                "id_padre_ubigeo": "3293",
                "id_ubigeo": "3296",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Poroy",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san jeronimo cusco",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "San Jeronimo, Cusco",
                "id_padre_ubigeo": "3293",
                "id_ubigeo": "3297",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Jeronimo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san sebastian cusco",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "San Sebastian, Cusco",
                "id_padre_ubigeo": "3293",
                "id_ubigeo": "3298",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Sebastian",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santiago cusco",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Santiago, Cusco",
                "id_padre_ubigeo": "3293",
                "id_ubigeo": "3299",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santiago",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "saylla cusco",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Saylla, Cusco",
                "id_padre_ubigeo": "3293",
                "id_ubigeo": "3300",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Saylla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "wanchaq cusco",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Wanchaq, Cusco",
                "id_padre_ubigeo": "3293",
                "id_ubigeo": "3301",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Wanchaq",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3302": [
            {
                "buscador_ubigeo": "acomayo acomayo",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Acomayo, Acomayo",
                "id_padre_ubigeo": "3302",
                "id_ubigeo": "3303",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Acomayo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "acopia acomayo",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Acopia, Acomayo",
                "id_padre_ubigeo": "3302",
                "id_ubigeo": "3304",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Acopia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "acos acomayo",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Acos, Acomayo",
                "id_padre_ubigeo": "3302",
                "id_ubigeo": "3305",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Acos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mosoc llacta acomayo",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Mosoc Llacta, Acomayo",
                "id_padre_ubigeo": "3302",
                "id_ubigeo": "3306",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mosoc Llacta",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pomacanchi acomayo",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Pomacanchi, Acomayo",
                "id_padre_ubigeo": "3302",
                "id_ubigeo": "3307",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pomacanchi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "rondocan acomayo",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Rondocan, Acomayo",
                "id_padre_ubigeo": "3302",
                "id_ubigeo": "3308",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Rondocan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sangarara acomayo",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Sangarara, Acomayo",
                "id_padre_ubigeo": "3302",
                "id_ubigeo": "3309",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sangarara",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3310": [
            {
                "buscador_ubigeo": "ancahuasi anta",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Ancahuasi, Anta",
                "id_padre_ubigeo": "3310",
                "id_ubigeo": "3312",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ancahuasi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "anta anta",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Anta, Anta",
                "id_padre_ubigeo": "3310",
                "id_ubigeo": "3311",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Anta",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cachimayo anta",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Cachimayo, Anta",
                "id_padre_ubigeo": "3310",
                "id_ubigeo": "3313",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cachimayo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chinchaypujio anta",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Chinchaypujio, Anta",
                "id_padre_ubigeo": "3310",
                "id_ubigeo": "3314",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chinchaypujio",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huarocondo anta",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Huarocondo, Anta",
                "id_padre_ubigeo": "3310",
                "id_ubigeo": "3315",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huarocondo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "limatambo anta",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Limatambo, Anta",
                "id_padre_ubigeo": "3310",
                "id_ubigeo": "3316",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Limatambo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mollepata anta",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Mollepata, Anta",
                "id_padre_ubigeo": "3310",
                "id_ubigeo": "3317",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mollepata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pucyura anta",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Pucyura, Anta",
                "id_padre_ubigeo": "3310",
                "id_ubigeo": "3318",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pucyura",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "zurite anta",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Zurite, Anta",
                "id_padre_ubigeo": "3310",
                "id_ubigeo": "3319",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Zurite",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3320": [
            {
                "buscador_ubigeo": "calca calca",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Calca, Calca",
                "id_padre_ubigeo": "3320",
                "id_ubigeo": "3321",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Calca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "coya calca",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Coya, Calca",
                "id_padre_ubigeo": "3320",
                "id_ubigeo": "3322",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Coya",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lamay calca",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Lamay, Calca",
                "id_padre_ubigeo": "3320",
                "id_ubigeo": "3323",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lamay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lares calca",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Lares, Calca",
                "id_padre_ubigeo": "3320",
                "id_ubigeo": "3324",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lares",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pisac calca",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Pisac, Calca",
                "id_padre_ubigeo": "3320",
                "id_ubigeo": "3325",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pisac",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san salvador calca",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "San Salvador, Calca",
                "id_padre_ubigeo": "3320",
                "id_ubigeo": "3326",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Salvador",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "taray calca",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Taray, Calca",
                "id_padre_ubigeo": "3320",
                "id_ubigeo": "3327",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Taray",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yanatile calca",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Yanatile, Calca",
                "id_padre_ubigeo": "3320",
                "id_ubigeo": "3328",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yanatile",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3329": [
            {
                "buscador_ubigeo": "checca canas",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Checca, Canas",
                "id_padre_ubigeo": "3329",
                "id_ubigeo": "3331",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Checca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "kunturkanki canas",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Kunturkanki, Canas",
                "id_padre_ubigeo": "3329",
                "id_ubigeo": "3332",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Kunturkanki",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "langui canas",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Langui, Canas",
                "id_padre_ubigeo": "3329",
                "id_ubigeo": "3333",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Langui",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "layo canas",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Layo, Canas",
                "id_padre_ubigeo": "3329",
                "id_ubigeo": "3334",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Layo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pampamarca canas",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Pampamarca, Canas",
                "id_padre_ubigeo": "3329",
                "id_ubigeo": "3335",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pampamarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "quehue canas",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Quehue, Canas",
                "id_padre_ubigeo": "3329",
                "id_ubigeo": "3336",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Quehue",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tupac amaru canas",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Tupac Amaru, Canas",
                "id_padre_ubigeo": "3329",
                "id_ubigeo": "3337",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tupac Amaru",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yanaoca canas",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Yanaoca, Canas",
                "id_padre_ubigeo": "3329",
                "id_ubigeo": "3330",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yanaoca",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3338": [
            {
                "buscador_ubigeo": "checacupe canchis",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Checacupe, Canchis",
                "id_padre_ubigeo": "3338",
                "id_ubigeo": "3340",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Checacupe",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "combapata canchis",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Combapata, Canchis",
                "id_padre_ubigeo": "3338",
                "id_ubigeo": "3341",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Combapata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "marangani canchis",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Marangani, Canchis",
                "id_padre_ubigeo": "3338",
                "id_ubigeo": "3342",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Marangani",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pitumarca canchis",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Pitumarca, Canchis",
                "id_padre_ubigeo": "3338",
                "id_ubigeo": "3343",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pitumarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san pablo canchis",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "San Pablo, Canchis",
                "id_padre_ubigeo": "3338",
                "id_ubigeo": "3344",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Pablo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san pedro canchis",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "San Pedro, Canchis",
                "id_padre_ubigeo": "3338",
                "id_ubigeo": "3345",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Pedro",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sicuani canchis",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Sicuani, Canchis",
                "id_padre_ubigeo": "3338",
                "id_ubigeo": "3339",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sicuani",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tinta canchis",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Tinta, Canchis",
                "id_padre_ubigeo": "3338",
                "id_ubigeo": "3346",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tinta",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3347": [
            {
                "buscador_ubigeo": "capacmarca chumbivilcas",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Capacmarca, Chumbivilcas",
                "id_padre_ubigeo": "3347",
                "id_ubigeo": "3349",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Capacmarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chamaca chumbivilcas",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Chamaca, Chumbivilcas",
                "id_padre_ubigeo": "3347",
                "id_ubigeo": "3350",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chamaca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "colquemarca chumbivilcas",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Colquemarca, Chumbivilcas",
                "id_padre_ubigeo": "3347",
                "id_ubigeo": "3351",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Colquemarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "livitaca chumbivilcas",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Livitaca, Chumbivilcas",
                "id_padre_ubigeo": "3347",
                "id_ubigeo": "3352",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Livitaca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "llusco chumbivilcas",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Llusco, Chumbivilcas",
                "id_padre_ubigeo": "3347",
                "id_ubigeo": "3353",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Llusco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "quiqota chumbivilcas",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Quiqota, Chumbivilcas",
                "id_padre_ubigeo": "3347",
                "id_ubigeo": "3354",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Quiqota",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santo tomas chumbivilcas",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Santo Tomas, Chumbivilcas",
                "id_padre_ubigeo": "3347",
                "id_ubigeo": "3348",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santo Tomas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "velille chumbivilcas",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Velille, Chumbivilcas",
                "id_padre_ubigeo": "3347",
                "id_ubigeo": "3355",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Velille",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3356": [
            {
                "buscador_ubigeo": "alto pichigua espinar",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Alto Pichigua, Espinar",
                "id_padre_ubigeo": "3356",
                "id_ubigeo": "3364",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Alto Pichigua",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "condoroma espinar",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Condoroma, Espinar",
                "id_padre_ubigeo": "3356",
                "id_ubigeo": "3358",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Condoroma",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "coporaque espinar",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Coporaque, Espinar",
                "id_padre_ubigeo": "3356",
                "id_ubigeo": "3359",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Coporaque",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "espinar espinar",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Espinar, Espinar",
                "id_padre_ubigeo": "3356",
                "id_ubigeo": "3357",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Espinar",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ocoruro espinar",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Ocoruro, Espinar",
                "id_padre_ubigeo": "3356",
                "id_ubigeo": "3360",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ocoruro",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pallpata espinar",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Pallpata, Espinar",
                "id_padre_ubigeo": "3356",
                "id_ubigeo": "3361",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pallpata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pichigua espinar",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Pichigua, Espinar",
                "id_padre_ubigeo": "3356",
                "id_ubigeo": "3362",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pichigua",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "suyckutambo espinar",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Suyckutambo, Espinar",
                "id_padre_ubigeo": "3356",
                "id_ubigeo": "3363",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Suyckutambo",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3365": [
            {
                "buscador_ubigeo": "echarate la convencion",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Echarate, La Convencion",
                "id_padre_ubigeo": "3365",
                "id_ubigeo": "3367",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Echarate",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huayopata la convencion",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Huayopata, La Convencion",
                "id_padre_ubigeo": "3365",
                "id_ubigeo": "3368",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huayopata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "maranura la convencion",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Maranura, La Convencion",
                "id_padre_ubigeo": "3365",
                "id_ubigeo": "3369",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Maranura",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ocobamba la convencion",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Ocobamba, La Convencion",
                "id_padre_ubigeo": "3365",
                "id_ubigeo": "3370",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ocobamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pichari la convencion",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Pichari, La Convencion",
                "id_padre_ubigeo": "3365",
                "id_ubigeo": "3375",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pichari",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "quellouno la convencion",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Quellouno, La Convencion",
                "id_padre_ubigeo": "3365",
                "id_ubigeo": "3371",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Quellouno",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "quimbiri la convencion",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Quimbiri, La Convencion",
                "id_padre_ubigeo": "3365",
                "id_ubigeo": "3372",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Quimbiri",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa ana la convencion",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Santa Ana, La Convencion",
                "id_padre_ubigeo": "3365",
                "id_ubigeo": "3366",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Ana",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa teresa la convencion",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Santa Teresa, La Convencion",
                "id_padre_ubigeo": "3365",
                "id_ubigeo": "3373",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Teresa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "vilcabamba la convencion",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Vilcabamba, La Convencion",
                "id_padre_ubigeo": "3365",
                "id_ubigeo": "3374",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Vilcabamba",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3376": [
            {
                "buscador_ubigeo": "accha paruro",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Accha, Paruro",
                "id_padre_ubigeo": "3376",
                "id_ubigeo": "3378",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Accha",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ccapi paruro",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Ccapi, Paruro",
                "id_padre_ubigeo": "3376",
                "id_ubigeo": "3379",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ccapi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "colcha paruro",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Colcha, Paruro",
                "id_padre_ubigeo": "3376",
                "id_ubigeo": "3380",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Colcha",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huanoquite paruro",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Huanoquite, Paruro",
                "id_padre_ubigeo": "3376",
                "id_ubigeo": "3381",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huanoquite",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "omacha paruro",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Omacha, Paruro",
                "id_padre_ubigeo": "3376",
                "id_ubigeo": "3382",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Omacha",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "paccaritambo paruro",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Paccaritambo, Paruro",
                "id_padre_ubigeo": "3376",
                "id_ubigeo": "3383",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Paccaritambo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "paruro paruro",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Paruro, Paruro",
                "id_padre_ubigeo": "3376",
                "id_ubigeo": "3377",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Paruro",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pillpinto paruro",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Pillpinto, Paruro",
                "id_padre_ubigeo": "3376",
                "id_ubigeo": "3384",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pillpinto",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yaurisque paruro",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Yaurisque, Paruro",
                "id_padre_ubigeo": "3376",
                "id_ubigeo": "3385",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yaurisque",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3386": [
            {
                "buscador_ubigeo": "caicay paucartambo",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Caicay, Paucartambo",
                "id_padre_ubigeo": "3386",
                "id_ubigeo": "3388",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Caicay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "challabamba paucartambo",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Challabamba, Paucartambo",
                "id_padre_ubigeo": "3386",
                "id_ubigeo": "3389",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Challabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "colquepata paucartambo",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Colquepata, Paucartambo",
                "id_padre_ubigeo": "3386",
                "id_ubigeo": "3390",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Colquepata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huancarani paucartambo",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Huancarani, Paucartambo",
                "id_padre_ubigeo": "3386",
                "id_ubigeo": "3391",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huancarani",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "kosqipata paucartambo",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Kosqipata, Paucartambo",
                "id_padre_ubigeo": "3386",
                "id_ubigeo": "3392",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Kosqipata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "paucartambo paucartambo",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Paucartambo, Paucartambo",
                "id_padre_ubigeo": "3386",
                "id_ubigeo": "3387",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Paucartambo",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3393": [
            {
                "buscador_ubigeo": "andahuaylillas quispicanchi",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Andahuaylillas, Quispicanchi",
                "id_padre_ubigeo": "3393",
                "id_ubigeo": "3395",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Andahuaylillas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "camanti quispicanchi",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Camanti, Quispicanchi",
                "id_padre_ubigeo": "3393",
                "id_ubigeo": "3396",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Camanti",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ccarhuayo quispicanchi",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Ccarhuayo, Quispicanchi",
                "id_padre_ubigeo": "3393",
                "id_ubigeo": "3397",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ccarhuayo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ccatca quispicanchi",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Ccatca, Quispicanchi",
                "id_padre_ubigeo": "3393",
                "id_ubigeo": "3398",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ccatca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cusipata quispicanchi",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Cusipata, Quispicanchi",
                "id_padre_ubigeo": "3393",
                "id_ubigeo": "3399",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cusipata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huaro quispicanchi",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Huaro, Quispicanchi",
                "id_padre_ubigeo": "3393",
                "id_ubigeo": "3400",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huaro",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lucre quispicanchi",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Lucre, Quispicanchi",
                "id_padre_ubigeo": "3393",
                "id_ubigeo": "3401",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lucre",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "marcapata quispicanchi",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Marcapata, Quispicanchi",
                "id_padre_ubigeo": "3393",
                "id_ubigeo": "3402",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Marcapata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ocongate quispicanchi",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Ocongate, Quispicanchi",
                "id_padre_ubigeo": "3393",
                "id_ubigeo": "3403",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ocongate",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "oropesa quispicanchi",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Oropesa, Quispicanchi",
                "id_padre_ubigeo": "3393",
                "id_ubigeo": "3404",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Oropesa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "quiquijana quispicanchi",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Quiquijana, Quispicanchi",
                "id_padre_ubigeo": "3393",
                "id_ubigeo": "3405",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Quiquijana",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "urcos quispicanchi",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Urcos, Quispicanchi",
                "id_padre_ubigeo": "3393",
                "id_ubigeo": "3394",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Urcos",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3406": [
            {
                "buscador_ubigeo": "chinchero urubamba",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Chinchero, Urubamba",
                "id_padre_ubigeo": "3406",
                "id_ubigeo": "3408",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chinchero",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huayllabamba urubamba",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Huayllabamba, Urubamba",
                "id_padre_ubigeo": "3406",
                "id_ubigeo": "3409",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huayllabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "machupicchu urubamba",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Machupicchu, Urubamba",
                "id_padre_ubigeo": "3406",
                "id_ubigeo": "3410",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Machupicchu",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "maras urubamba",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Maras, Urubamba",
                "id_padre_ubigeo": "3406",
                "id_ubigeo": "3411",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Maras",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ollantaytambo urubamba",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Ollantaytambo, Urubamba",
                "id_padre_ubigeo": "3406",
                "id_ubigeo": "3412",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ollantaytambo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "urubamba urubamba",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Urubamba, Urubamba",
                "id_padre_ubigeo": "3406",
                "id_ubigeo": "3407",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Urubamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yucay urubamba",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Yucay, Urubamba",
                "id_padre_ubigeo": "3406",
                "id_ubigeo": "3413",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yucay",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3415": [
            {
                "buscador_ubigeo": "acobambilla huancavelica",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Acobambilla, Huancavelica",
                "id_padre_ubigeo": "3415",
                "id_ubigeo": "3417",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Acobambilla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "acoria huancavelica",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Acoria, Huancavelica",
                "id_padre_ubigeo": "3415",
                "id_ubigeo": "3418",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Acoria",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ascensión huancavelica",
                "codigo_ubigeo": "18",
                "etiqueta_ubigeo": "Ascensión, Huancavelica",
                "id_padre_ubigeo": "3415",
                "id_ubigeo": "3433",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ascensión",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "conayca huancavelica",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Conayca, Huancavelica",
                "id_padre_ubigeo": "3415",
                "id_ubigeo": "3419",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Conayca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cuenca huancavelica",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Cuenca, Huancavelica",
                "id_padre_ubigeo": "3415",
                "id_ubigeo": "3420",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cuenca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huachocolpa huancavelica",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Huachocolpa, Huancavelica",
                "id_padre_ubigeo": "3415",
                "id_ubigeo": "3421",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huachocolpa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huancavelica huancavelica",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Huancavelica, Huancavelica",
                "id_padre_ubigeo": "3415",
                "id_ubigeo": "3416",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huancavelica",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huando huancavelica",
                "codigo_ubigeo": "19",
                "etiqueta_ubigeo": "Huando, Huancavelica",
                "id_padre_ubigeo": "3415",
                "id_ubigeo": "3434",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huando",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huayllahuara huancavelica",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Huayllahuara, Huancavelica",
                "id_padre_ubigeo": "3415",
                "id_ubigeo": "3422",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huayllahuara",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "izcuchaca huancavelica",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Izcuchaca, Huancavelica",
                "id_padre_ubigeo": "3415",
                "id_ubigeo": "3423",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Izcuchaca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "laria huancavelica",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Laria, Huancavelica",
                "id_padre_ubigeo": "3415",
                "id_ubigeo": "3424",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Laria",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "manta huancavelica",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Manta, Huancavelica",
                "id_padre_ubigeo": "3415",
                "id_ubigeo": "3425",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Manta",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mariscal caceres huancavelica",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Mariscal Caceres, Huancavelica",
                "id_padre_ubigeo": "3415",
                "id_ubigeo": "3426",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mariscal Caceres",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "moya huancavelica",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Moya, Huancavelica",
                "id_padre_ubigeo": "3415",
                "id_ubigeo": "3427",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Moya",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "nuevo occoro huancavelica",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Nuevo Occoro, Huancavelica",
                "id_padre_ubigeo": "3415",
                "id_ubigeo": "3428",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Nuevo Occoro",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "palca huancavelica",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "Palca, Huancavelica",
                "id_padre_ubigeo": "3415",
                "id_ubigeo": "3429",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Palca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pilchaca huancavelica",
                "codigo_ubigeo": "15",
                "etiqueta_ubigeo": "Pilchaca, Huancavelica",
                "id_padre_ubigeo": "3415",
                "id_ubigeo": "3430",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pilchaca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "vilca huancavelica",
                "codigo_ubigeo": "16",
                "etiqueta_ubigeo": "Vilca, Huancavelica",
                "id_padre_ubigeo": "3415",
                "id_ubigeo": "3431",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Vilca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yauli huancavelica",
                "codigo_ubigeo": "17",
                "etiqueta_ubigeo": "Yauli, Huancavelica",
                "id_padre_ubigeo": "3415",
                "id_ubigeo": "3432",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yauli",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3435": [
            {
                "buscador_ubigeo": "acobamba acobamba",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Acobamba, Acobamba",
                "id_padre_ubigeo": "3435",
                "id_ubigeo": "3436",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Acobamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "andabamba acobamba",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Andabamba, Acobamba",
                "id_padre_ubigeo": "3435",
                "id_ubigeo": "3437",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Andabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "anta acobamba",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Anta, Acobamba",
                "id_padre_ubigeo": "3435",
                "id_ubigeo": "3438",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Anta",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "caja acobamba",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Caja, Acobamba",
                "id_padre_ubigeo": "3435",
                "id_ubigeo": "3439",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Caja",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "marcas acobamba",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Marcas, Acobamba",
                "id_padre_ubigeo": "3435",
                "id_ubigeo": "3440",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Marcas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "paucara acobamba",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Paucara, Acobamba",
                "id_padre_ubigeo": "3435",
                "id_ubigeo": "3441",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Paucara",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pomacocha acobamba",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Pomacocha, Acobamba",
                "id_padre_ubigeo": "3435",
                "id_ubigeo": "3442",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pomacocha",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "rosario acobamba",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Rosario, Acobamba",
                "id_padre_ubigeo": "3435",
                "id_ubigeo": "3443",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Rosario",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3444": [
            {
                "buscador_ubigeo": "anchonga angaraes",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Anchonga, Angaraes",
                "id_padre_ubigeo": "3444",
                "id_ubigeo": "3446",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Anchonga",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "callanmarca angaraes",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Callanmarca, Angaraes",
                "id_padre_ubigeo": "3444",
                "id_ubigeo": "3447",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Callanmarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ccochaccasa angaraes",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Ccochaccasa, Angaraes",
                "id_padre_ubigeo": "3444",
                "id_ubigeo": "3448",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ccochaccasa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chincho angaraes",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Chincho, Angaraes",
                "id_padre_ubigeo": "3444",
                "id_ubigeo": "3449",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chincho",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "congalla angaraes",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Congalla, Angaraes",
                "id_padre_ubigeo": "3444",
                "id_ubigeo": "3450",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Congalla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huanca-huanca angaraes",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Huanca-Huanca, Angaraes",
                "id_padre_ubigeo": "3444",
                "id_ubigeo": "3451",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huanca-Huanca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huayllay grande angaraes",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Huayllay Grande, Angaraes",
                "id_padre_ubigeo": "3444",
                "id_ubigeo": "3452",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huayllay Grande",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "julcamarca angaraes",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Julcamarca, Angaraes",
                "id_padre_ubigeo": "3444",
                "id_ubigeo": "3453",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Julcamarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lircay angaraes",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Lircay, Angaraes",
                "id_padre_ubigeo": "3444",
                "id_ubigeo": "3445",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lircay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san antonio de antaparco angaraes",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "San Antonio de Antaparco, Angaraes",
                "id_padre_ubigeo": "3444",
                "id_ubigeo": "3454",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Antonio de Antaparco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santo tomas de pata angaraes",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Santo Tomas de Pata, Angaraes",
                "id_padre_ubigeo": "3444",
                "id_ubigeo": "3455",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santo Tomas de Pata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "secclla angaraes",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Secclla, Angaraes",
                "id_padre_ubigeo": "3444",
                "id_ubigeo": "3456",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Secclla",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3457": [
            {
                "buscador_ubigeo": "arma castrovirreyna",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Arma, Castrovirreyna",
                "id_padre_ubigeo": "3457",
                "id_ubigeo": "3459",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Arma",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "aurahua castrovirreyna",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Aurahua, Castrovirreyna",
                "id_padre_ubigeo": "3457",
                "id_ubigeo": "3460",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Aurahua",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "capillas castrovirreyna",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Capillas, Castrovirreyna",
                "id_padre_ubigeo": "3457",
                "id_ubigeo": "3461",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Capillas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "castrovirreyna castrovirreyna",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Castrovirreyna, Castrovirreyna",
                "id_padre_ubigeo": "3457",
                "id_ubigeo": "3458",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Castrovirreyna",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chupamarca castrovirreyna",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Chupamarca, Castrovirreyna",
                "id_padre_ubigeo": "3457",
                "id_ubigeo": "3462",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chupamarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cocas castrovirreyna",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Cocas, Castrovirreyna",
                "id_padre_ubigeo": "3457",
                "id_ubigeo": "3463",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cocas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huachos castrovirreyna",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Huachos, Castrovirreyna",
                "id_padre_ubigeo": "3457",
                "id_ubigeo": "3464",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huachos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huamatambo castrovirreyna",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Huamatambo, Castrovirreyna",
                "id_padre_ubigeo": "3457",
                "id_ubigeo": "3465",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huamatambo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mollepampa castrovirreyna",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Mollepampa, Castrovirreyna",
                "id_padre_ubigeo": "3457",
                "id_ubigeo": "3466",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mollepampa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san juan castrovirreyna",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "San Juan, Castrovirreyna",
                "id_padre_ubigeo": "3457",
                "id_ubigeo": "3467",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Juan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa ana castrovirreyna",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Santa Ana, Castrovirreyna",
                "id_padre_ubigeo": "3457",
                "id_ubigeo": "3468",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Ana",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tantara castrovirreyna",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Tantara, Castrovirreyna",
                "id_padre_ubigeo": "3457",
                "id_ubigeo": "3469",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tantara",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ticrapo castrovirreyna",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Ticrapo, Castrovirreyna",
                "id_padre_ubigeo": "3457",
                "id_ubigeo": "3470",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ticrapo",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3471": [
            {
                "buscador_ubigeo": "anco churcampa",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Anco, Churcampa",
                "id_padre_ubigeo": "3471",
                "id_ubigeo": "3473",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Anco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chinchihuasi churcampa",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Chinchihuasi, Churcampa",
                "id_padre_ubigeo": "3471",
                "id_ubigeo": "3474",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chinchihuasi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "churcampa churcampa",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Churcampa, Churcampa",
                "id_padre_ubigeo": "3471",
                "id_ubigeo": "3472",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Churcampa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "el carmen churcampa",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "El Carmen, Churcampa",
                "id_padre_ubigeo": "3471",
                "id_ubigeo": "3475",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "El Carmen",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la merced churcampa",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "La Merced, Churcampa",
                "id_padre_ubigeo": "3471",
                "id_ubigeo": "3476",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Merced",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "locroja churcampa",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Locroja, Churcampa",
                "id_padre_ubigeo": "3471",
                "id_ubigeo": "3477",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Locroja",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pachamarca churcampa",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Pachamarca, Churcampa",
                "id_padre_ubigeo": "3471",
                "id_ubigeo": "3481",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pachamarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "paucarbamba churcampa",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Paucarbamba, Churcampa",
                "id_padre_ubigeo": "3471",
                "id_ubigeo": "3478",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Paucarbamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san miguel de mayocc churcampa",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "San Miguel de Mayocc, Churcampa",
                "id_padre_ubigeo": "3471",
                "id_ubigeo": "3479",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Miguel de Mayocc",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san pedro de coris churcampa",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "San Pedro de Coris, Churcampa",
                "id_padre_ubigeo": "3471",
                "id_ubigeo": "3480",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Pedro de Coris",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3482": [
            {
                "buscador_ubigeo": "ayavi huaytara",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Ayavi, Huaytara",
                "id_padre_ubigeo": "3482",
                "id_ubigeo": "3484",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ayavi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cordova huaytara",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Cordova, Huaytara",
                "id_padre_ubigeo": "3482",
                "id_ubigeo": "3485",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cordova",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huayacundo arma huaytara",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Huayacundo Arma, Huaytara",
                "id_padre_ubigeo": "3482",
                "id_ubigeo": "3486",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huayacundo Arma",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huaytara huaytara",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Huaytara, Huaytara",
                "id_padre_ubigeo": "3482",
                "id_ubigeo": "3483",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huaytara",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "laramarca huaytara",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Laramarca, Huaytara",
                "id_padre_ubigeo": "3482",
                "id_ubigeo": "3487",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Laramarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ocoyo huaytara",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Ocoyo, Huaytara",
                "id_padre_ubigeo": "3482",
                "id_ubigeo": "3488",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ocoyo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pilpichaca huaytara",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Pilpichaca, Huaytara",
                "id_padre_ubigeo": "3482",
                "id_ubigeo": "3489",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pilpichaca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "querco huaytara",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Querco, Huaytara",
                "id_padre_ubigeo": "3482",
                "id_ubigeo": "3490",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Querco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "quito-arma huaytara",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Quito-Arma, Huaytara",
                "id_padre_ubigeo": "3482",
                "id_ubigeo": "3491",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Quito-Arma",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san antonio de cusicancha huaytara",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "San Antonio de Cusicancha, Huaytara",
                "id_padre_ubigeo": "3482",
                "id_ubigeo": "3492",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Antonio de Cusicancha",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san francisco de sangayaico huaytara",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "San Francisco de Sangayaico, Huaytara",
                "id_padre_ubigeo": "3482",
                "id_ubigeo": "3493",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Francisco de Sangayaico",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san isidro huaytara",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "San Isidro, Huaytara",
                "id_padre_ubigeo": "3482",
                "id_ubigeo": "3494",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Isidro",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santiago de chocorvos huaytara",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Santiago de Chocorvos, Huaytara",
                "id_padre_ubigeo": "3482",
                "id_ubigeo": "3495",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santiago de Chocorvos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santiago de quirahuara huaytara",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "Santiago de Quirahuara, Huaytara",
                "id_padre_ubigeo": "3482",
                "id_ubigeo": "3496",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santiago de Quirahuara",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santo domingo de capillas huaytara",
                "codigo_ubigeo": "15",
                "etiqueta_ubigeo": "Santo Domingo de Capillas, Huaytara",
                "id_padre_ubigeo": "3482",
                "id_ubigeo": "3497",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santo Domingo de Capillas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tambo huaytara",
                "codigo_ubigeo": "16",
                "etiqueta_ubigeo": "Tambo, Huaytara",
                "id_padre_ubigeo": "3482",
                "id_ubigeo": "3498",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tambo",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3499": [
            {
                "buscador_ubigeo": "acostambo tayacaja",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Acostambo, Tayacaja",
                "id_padre_ubigeo": "3499",
                "id_ubigeo": "3501",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Acostambo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "acraquia tayacaja",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Acraquia, Tayacaja",
                "id_padre_ubigeo": "3499",
                "id_ubigeo": "3502",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Acraquia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ahuaycha tayacaja",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Ahuaycha, Tayacaja",
                "id_padre_ubigeo": "3499",
                "id_ubigeo": "3503",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ahuaycha",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "colcabamba tayacaja",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Colcabamba, Tayacaja",
                "id_padre_ubigeo": "3499",
                "id_ubigeo": "3504",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Colcabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "daniel hernandez tayacaja",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Daniel Hernandez, Tayacaja",
                "id_padre_ubigeo": "3499",
                "id_ubigeo": "3505",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Daniel Hernandez",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huachocolpa tayacaja",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Huachocolpa, Tayacaja",
                "id_padre_ubigeo": "3499",
                "id_ubigeo": "3506",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huachocolpa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huando tayacaja",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Huando, Tayacaja",
                "id_padre_ubigeo": "3499",
                "id_ubigeo": "3507",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huando",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huaribamba tayacaja",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Huaribamba, Tayacaja",
                "id_padre_ubigeo": "3499",
                "id_ubigeo": "3508",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huaribamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pachamarca tayacaja",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Pachamarca, Tayacaja",
                "id_padre_ubigeo": "3499",
                "id_ubigeo": "3511",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pachamarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pampas tayacaja",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Pampas, Tayacaja",
                "id_padre_ubigeo": "3499",
                "id_ubigeo": "3500",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pampas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pazos tayacaja",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Pazos, Tayacaja",
                "id_padre_ubigeo": "3499",
                "id_ubigeo": "3510",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pazos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "qahuimpuquio tayacaja",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Qahuimpuquio, Tayacaja",
                "id_padre_ubigeo": "3499",
                "id_ubigeo": "3509",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Qahuimpuquio",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "quishuar tayacaja",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Quishuar, Tayacaja",
                "id_padre_ubigeo": "3499",
                "id_ubigeo": "3512",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Quishuar",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "salcabamba tayacaja",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "Salcabamba, Tayacaja",
                "id_padre_ubigeo": "3499",
                "id_ubigeo": "3513",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Salcabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "salcahuasi tayacaja",
                "codigo_ubigeo": "15",
                "etiqueta_ubigeo": "Salcahuasi, Tayacaja",
                "id_padre_ubigeo": "3499",
                "id_ubigeo": "3514",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Salcahuasi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san marcos de rocchac tayacaja",
                "codigo_ubigeo": "16",
                "etiqueta_ubigeo": "San Marcos de Rocchac, Tayacaja",
                "id_padre_ubigeo": "3499",
                "id_ubigeo": "3515",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Marcos de Rocchac",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "surcubamba tayacaja",
                "codigo_ubigeo": "17",
                "etiqueta_ubigeo": "Surcubamba, Tayacaja",
                "id_padre_ubigeo": "3499",
                "id_ubigeo": "3516",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Surcubamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tintay puncu tayacaja",
                "codigo_ubigeo": "18",
                "etiqueta_ubigeo": "Tintay Puncu, Tayacaja",
                "id_padre_ubigeo": "3499",
                "id_ubigeo": "3517",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tintay Puncu",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3519": [
            {
                "buscador_ubigeo": "amarilis huanuco",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Amarilis, Huanuco",
                "id_padre_ubigeo": "3519",
                "id_ubigeo": "3521",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Amarilis",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chinchao huanuco",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Chinchao, Huanuco",
                "id_padre_ubigeo": "3519",
                "id_ubigeo": "3522",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chinchao",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "churubamba huanuco",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Churubamba, Huanuco",
                "id_padre_ubigeo": "3519",
                "id_ubigeo": "3523",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Churubamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huanuco huanuco",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Huanuco, Huanuco",
                "id_padre_ubigeo": "3519",
                "id_ubigeo": "3520",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huanuco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "margos huanuco",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Margos, Huanuco",
                "id_padre_ubigeo": "3519",
                "id_ubigeo": "3524",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Margos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pillcomarca huanuco",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Pillcomarca, Huanuco",
                "id_padre_ubigeo": "3519",
                "id_ubigeo": "3530",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pillcomarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "quisqui huanuco",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Quisqui, Huanuco",
                "id_padre_ubigeo": "3519",
                "id_ubigeo": "3525",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Quisqui",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san francisco de cayran huanuco",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "San Francisco de Cayran, Huanuco",
                "id_padre_ubigeo": "3519",
                "id_ubigeo": "3526",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Francisco de Cayran",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san pedro de chaulan huanuco",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "San Pedro de Chaulan, Huanuco",
                "id_padre_ubigeo": "3519",
                "id_ubigeo": "3527",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Pedro de Chaulan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa maria del valle huanuco",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Santa Maria del Valle, Huanuco",
                "id_padre_ubigeo": "3519",
                "id_ubigeo": "3528",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Maria del Valle",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yarumayo huanuco",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Yarumayo, Huanuco",
                "id_padre_ubigeo": "3519",
                "id_ubigeo": "3529",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yarumayo",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3531": [
            {
                "buscador_ubigeo": "ambo ambo",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Ambo, Ambo",
                "id_padre_ubigeo": "3531",
                "id_ubigeo": "3532",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ambo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cayna ambo",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Cayna, Ambo",
                "id_padre_ubigeo": "3531",
                "id_ubigeo": "3533",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cayna",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "colpas ambo",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Colpas, Ambo",
                "id_padre_ubigeo": "3531",
                "id_ubigeo": "3534",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Colpas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "conchamarca ambo",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Conchamarca, Ambo",
                "id_padre_ubigeo": "3531",
                "id_ubigeo": "3535",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Conchamarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huacar ambo",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Huacar, Ambo",
                "id_padre_ubigeo": "3531",
                "id_ubigeo": "3536",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huacar",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san francisco ambo",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "San Francisco, Ambo",
                "id_padre_ubigeo": "3531",
                "id_ubigeo": "3537",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Francisco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san rafael ambo",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "San Rafael, Ambo",
                "id_padre_ubigeo": "3531",
                "id_ubigeo": "3538",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Rafael",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tomay kichwa ambo",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Tomay Kichwa, Ambo",
                "id_padre_ubigeo": "3531",
                "id_ubigeo": "3539",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tomay Kichwa",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3540": [
            {
                "buscador_ubigeo": "chuquis dos de mayo",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Chuquis, Dos de Mayo",
                "id_padre_ubigeo": "3540",
                "id_ubigeo": "3542",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chuquis",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la union dos de mayo",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "La Union, Dos de Mayo",
                "id_padre_ubigeo": "3540",
                "id_ubigeo": "3541",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Union",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "marias dos de mayo",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Marias, Dos de Mayo",
                "id_padre_ubigeo": "3540",
                "id_ubigeo": "3543",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Marias",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pachas dos de mayo",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Pachas, Dos de Mayo",
                "id_padre_ubigeo": "3540",
                "id_ubigeo": "3544",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pachas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "quivilla dos de mayo",
                "codigo_ubigeo": "16",
                "etiqueta_ubigeo": "Quivilla, Dos de Mayo",
                "id_padre_ubigeo": "3540",
                "id_ubigeo": "3545",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Quivilla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ripan dos de mayo",
                "codigo_ubigeo": "17",
                "etiqueta_ubigeo": "Ripan, Dos de Mayo",
                "id_padre_ubigeo": "3540",
                "id_ubigeo": "3546",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ripan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "shunqui dos de mayo",
                "codigo_ubigeo": "21",
                "etiqueta_ubigeo": "Shunqui, Dos de Mayo",
                "id_padre_ubigeo": "3540",
                "id_ubigeo": "3547",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Shunqui",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sillapata dos de mayo",
                "codigo_ubigeo": "22",
                "etiqueta_ubigeo": "Sillapata, Dos de Mayo",
                "id_padre_ubigeo": "3540",
                "id_ubigeo": "3548",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sillapata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yanas dos de mayo",
                "codigo_ubigeo": "23",
                "etiqueta_ubigeo": "Yanas, Dos de Mayo",
                "id_padre_ubigeo": "3540",
                "id_ubigeo": "3549",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yanas",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3550": [
            {
                "buscador_ubigeo": "canchabamba huacaybamba",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Canchabamba, Huacaybamba",
                "id_padre_ubigeo": "3550",
                "id_ubigeo": "3552",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Canchabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cochabamba huacaybamba",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Cochabamba, Huacaybamba",
                "id_padre_ubigeo": "3550",
                "id_ubigeo": "3553",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cochabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huacaybamba huacaybamba",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Huacaybamba, Huacaybamba",
                "id_padre_ubigeo": "3550",
                "id_ubigeo": "3551",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huacaybamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pinra huacaybamba",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Pinra, Huacaybamba",
                "id_padre_ubigeo": "3550",
                "id_ubigeo": "3554",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pinra",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3555": [
            {
                "buscador_ubigeo": "arancay huamalies",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Arancay, Huamalies",
                "id_padre_ubigeo": "3555",
                "id_ubigeo": "3557",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Arancay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chavin de pariarca huamalies",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Chavin de Pariarca, Huamalies",
                "id_padre_ubigeo": "3555",
                "id_ubigeo": "3558",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chavin de Pariarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jacas grande huamalies",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Jacas Grande, Huamalies",
                "id_padre_ubigeo": "3555",
                "id_ubigeo": "3559",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jacas Grande",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jircan huamalies",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Jircan, Huamalies",
                "id_padre_ubigeo": "3555",
                "id_ubigeo": "3560",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jircan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "llata huamalies",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Llata, Huamalies",
                "id_padre_ubigeo": "3555",
                "id_ubigeo": "3556",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Llata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "miraflores huamalies",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Miraflores, Huamalies",
                "id_padre_ubigeo": "3555",
                "id_ubigeo": "3561",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Miraflores",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "monzon huamalies",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Monzon, Huamalies",
                "id_padre_ubigeo": "3555",
                "id_ubigeo": "3562",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Monzon",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "punchao huamalies",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Punchao, Huamalies",
                "id_padre_ubigeo": "3555",
                "id_ubigeo": "3563",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Punchao",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "puqos huamalies",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Puqos, Huamalies",
                "id_padre_ubigeo": "3555",
                "id_ubigeo": "3564",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Puqos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "singa huamalies",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Singa, Huamalies",
                "id_padre_ubigeo": "3555",
                "id_ubigeo": "3565",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Singa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tantamayo huamalies",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Tantamayo, Huamalies",
                "id_padre_ubigeo": "3555",
                "id_ubigeo": "3566",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tantamayo",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3567": [
            {
                "buscador_ubigeo": "daniel alomias robles leoncio prado",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Daniel Alomias Robles, Leoncio Prado",
                "id_padre_ubigeo": "3567",
                "id_ubigeo": "3569",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Daniel Alomias Robles",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "hermilio valdizan leoncio prado",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Hermilio Valdizan, Leoncio Prado",
                "id_padre_ubigeo": "3567",
                "id_ubigeo": "3570",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Hermilio Valdizan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jose crespo y castillo leoncio prado",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Jose Crespo y Castillo, Leoncio Prado",
                "id_padre_ubigeo": "3567",
                "id_ubigeo": "3571",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jose Crespo y Castillo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "luyando leoncio prado",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Luyando, Leoncio Prado",
                "id_padre_ubigeo": "3567",
                "id_ubigeo": "3572",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Luyando",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mariano damaso beraun leoncio prado",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Mariano Damaso Beraun, Leoncio Prado",
                "id_padre_ubigeo": "3567",
                "id_ubigeo": "3573",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mariano Damaso Beraun",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "rupa-rupa leoncio prado",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Rupa-Rupa, Leoncio Prado",
                "id_padre_ubigeo": "3567",
                "id_ubigeo": "3568",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Rupa-Rupa",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3574": [
            {
                "buscador_ubigeo": "cholon maraqon",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Cholon, Maraqon",
                "id_padre_ubigeo": "3574",
                "id_ubigeo": "3576",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cholon",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huacrachuco maraqon",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Huacrachuco, Maraqon",
                "id_padre_ubigeo": "3574",
                "id_ubigeo": "3575",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huacrachuco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san buenaventura maraqon",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "San Buenaventura, Maraqon",
                "id_padre_ubigeo": "3574",
                "id_ubigeo": "3577",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Buenaventura",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3578": [
            {
                "buscador_ubigeo": "chaglla pachitea",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Chaglla, Pachitea",
                "id_padre_ubigeo": "3578",
                "id_ubigeo": "3580",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chaglla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "molino pachitea",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Molino, Pachitea",
                "id_padre_ubigeo": "3578",
                "id_ubigeo": "3581",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Molino",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "panao pachitea",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Panao, Pachitea",
                "id_padre_ubigeo": "3578",
                "id_ubigeo": "3579",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Panao",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "umari pachitea",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Umari, Pachitea",
                "id_padre_ubigeo": "3578",
                "id_ubigeo": "3582",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Umari",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3583": [
            {
                "buscador_ubigeo": "codo del pozuzo puerto inca",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Codo del Pozuzo, Puerto Inca",
                "id_padre_ubigeo": "3583",
                "id_ubigeo": "3585",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Codo del Pozuzo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "honoria puerto inca",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Honoria, Puerto Inca",
                "id_padre_ubigeo": "3583",
                "id_ubigeo": "3586",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Honoria",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "puerto inca puerto inca",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Puerto Inca, Puerto Inca",
                "id_padre_ubigeo": "3583",
                "id_ubigeo": "3584",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Puerto Inca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tournavista puerto inca",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Tournavista, Puerto Inca",
                "id_padre_ubigeo": "3583",
                "id_ubigeo": "3587",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tournavista",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yuyapichis puerto inca",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Yuyapichis, Puerto Inca",
                "id_padre_ubigeo": "3583",
                "id_ubigeo": "3588",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yuyapichis",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3589": [
            {
                "buscador_ubigeo": "baqos lauricocha",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Baqos, Lauricocha",
                "id_padre_ubigeo": "3589",
                "id_ubigeo": "3591",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Baqos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jesus lauricocha",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Jesus, Lauricocha",
                "id_padre_ubigeo": "3589",
                "id_ubigeo": "3590",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jesus",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jivia lauricocha",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Jivia, Lauricocha",
                "id_padre_ubigeo": "3589",
                "id_ubigeo": "3592",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jivia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "queropalca lauricocha",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Queropalca, Lauricocha",
                "id_padre_ubigeo": "3589",
                "id_ubigeo": "3593",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Queropalca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "rondos lauricocha",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Rondos, Lauricocha",
                "id_padre_ubigeo": "3589",
                "id_ubigeo": "3594",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Rondos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san francisco de asis lauricocha",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "San Francisco de Asis, Lauricocha",
                "id_padre_ubigeo": "3589",
                "id_ubigeo": "3595",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Francisco de Asis",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san miguel de cauri lauricocha",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "San Miguel de Cauri, Lauricocha",
                "id_padre_ubigeo": "3589",
                "id_ubigeo": "3596",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Miguel de Cauri",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3597": [
            {
                "buscador_ubigeo": "cahuac yarowilca",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Cahuac, Yarowilca",
                "id_padre_ubigeo": "3597",
                "id_ubigeo": "3599",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cahuac",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chacabamba yarowilca",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Chacabamba, Yarowilca",
                "id_padre_ubigeo": "3597",
                "id_ubigeo": "3600",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chacabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chavinillo yarowilca",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Chavinillo, Yarowilca",
                "id_padre_ubigeo": "3597",
                "id_ubigeo": "3598",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chavinillo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "choras yarowilca",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Choras, Yarowilca",
                "id_padre_ubigeo": "3597",
                "id_ubigeo": "3605",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Choras",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chupan yarowilca",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Chupan, Yarowilca",
                "id_padre_ubigeo": "3597",
                "id_ubigeo": "3601",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chupan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jacas chico yarowilca",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Jacas Chico, Yarowilca",
                "id_padre_ubigeo": "3597",
                "id_ubigeo": "3602",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jacas Chico",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "obas yarowilca",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Obas, Yarowilca",
                "id_padre_ubigeo": "3597",
                "id_ubigeo": "3603",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Obas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pampamarca yarowilca",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Pampamarca, Yarowilca",
                "id_padre_ubigeo": "3597",
                "id_ubigeo": "3604",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pampamarca",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3607": [
            {
                "buscador_ubigeo": "ica ica",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Ica, Ica",
                "id_padre_ubigeo": "3607",
                "id_ubigeo": "3608",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ica",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la tinguiqa ica",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "La Tinguiqa, Ica",
                "id_padre_ubigeo": "3607",
                "id_ubigeo": "3609",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Tinguiqa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "los aquijes ica",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Los Aquijes, Ica",
                "id_padre_ubigeo": "3607",
                "id_ubigeo": "3610",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Los Aquijes",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ocucaje ica",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Ocucaje, Ica",
                "id_padre_ubigeo": "3607",
                "id_ubigeo": "3611",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ocucaje",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pachacutec ica",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Pachacutec, Ica",
                "id_padre_ubigeo": "3607",
                "id_ubigeo": "3612",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pachacutec",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "parcona ica",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Parcona, Ica",
                "id_padre_ubigeo": "3607",
                "id_ubigeo": "3613",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Parcona",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pueblo nuevo ica",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Pueblo Nuevo, Ica",
                "id_padre_ubigeo": "3607",
                "id_ubigeo": "3614",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pueblo Nuevo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "salas ica",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Salas, Ica",
                "id_padre_ubigeo": "3607",
                "id_ubigeo": "3615",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Salas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san jose de los molinos ica",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "San Jose de los Molinos, Ica",
                "id_padre_ubigeo": "3607",
                "id_ubigeo": "3616",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Jose de los Molinos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san juan bautista ica",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "San Juan Bautista, Ica",
                "id_padre_ubigeo": "3607",
                "id_ubigeo": "3617",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Juan Bautista",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santiago ica",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Santiago, Ica",
                "id_padre_ubigeo": "3607",
                "id_ubigeo": "3618",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santiago",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "subtanjalla ica",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Subtanjalla, Ica",
                "id_padre_ubigeo": "3607",
                "id_ubigeo": "3619",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Subtanjalla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tate ica",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Tate, Ica",
                "id_padre_ubigeo": "3607",
                "id_ubigeo": "3620",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tate",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yauca del rosario  1/ ica",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "Yauca del Rosario  1/, Ica",
                "id_padre_ubigeo": "3607",
                "id_ubigeo": "3621",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yauca del Rosario  1/",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3622": [
            {
                "buscador_ubigeo": "alto laran chincha",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Alto Laran, Chincha",
                "id_padre_ubigeo": "3622",
                "id_ubigeo": "3624",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Alto Laran",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chavin chincha",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Chavin, Chincha",
                "id_padre_ubigeo": "3622",
                "id_ubigeo": "3625",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chavin",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chincha alta chincha",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Chincha Alta, Chincha",
                "id_padre_ubigeo": "3622",
                "id_ubigeo": "3623",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chincha Alta",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chincha baja chincha",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Chincha Baja, Chincha",
                "id_padre_ubigeo": "3622",
                "id_ubigeo": "3626",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chincha Baja",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "el carmen chincha",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "El Carmen, Chincha",
                "id_padre_ubigeo": "3622",
                "id_ubigeo": "3627",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "El Carmen",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "grocio prado chincha",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Grocio Prado, Chincha",
                "id_padre_ubigeo": "3622",
                "id_ubigeo": "3628",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Grocio Prado",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pueblo nuevo chincha",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Pueblo Nuevo, Chincha",
                "id_padre_ubigeo": "3622",
                "id_ubigeo": "3629",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pueblo Nuevo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san juan de yanac chincha",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "San Juan de Yanac, Chincha",
                "id_padre_ubigeo": "3622",
                "id_ubigeo": "3630",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Juan de Yanac",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san pedro de huacarpana chincha",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "San Pedro de Huacarpana, Chincha",
                "id_padre_ubigeo": "3622",
                "id_ubigeo": "3631",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Pedro de Huacarpana",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sunampe chincha",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Sunampe, Chincha",
                "id_padre_ubigeo": "3622",
                "id_ubigeo": "3632",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sunampe",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tambo de mora chincha",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Tambo de Mora, Chincha",
                "id_padre_ubigeo": "3622",
                "id_ubigeo": "3633",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tambo de Mora",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3634": [
            {
                "buscador_ubigeo": "changuillo nazca",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Changuillo, Nazca",
                "id_padre_ubigeo": "3634",
                "id_ubigeo": "3636",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Changuillo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "el ingenio nazca",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "El Ingenio, Nazca",
                "id_padre_ubigeo": "3634",
                "id_ubigeo": "3637",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "El Ingenio",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "marcona nazca",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Marcona, Nazca",
                "id_padre_ubigeo": "3634",
                "id_ubigeo": "3638",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Marcona",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "nazca nazca",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Nazca, Nazca",
                "id_padre_ubigeo": "3634",
                "id_ubigeo": "3635",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Nazca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "vista alegre nazca",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Vista Alegre, Nazca",
                "id_padre_ubigeo": "3634",
                "id_ubigeo": "3639",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Vista Alegre",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3640": [
            {
                "buscador_ubigeo": "llipata palpa",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Llipata, Palpa",
                "id_padre_ubigeo": "3640",
                "id_ubigeo": "3642",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Llipata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "palpa palpa",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Palpa, Palpa",
                "id_padre_ubigeo": "3640",
                "id_ubigeo": "3641",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Palpa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "rio grande palpa",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Rio Grande, Palpa",
                "id_padre_ubigeo": "3640",
                "id_ubigeo": "3643",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Rio Grande",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa cruz palpa",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Santa Cruz, Palpa",
                "id_padre_ubigeo": "3640",
                "id_ubigeo": "3644",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Cruz",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tibillo palpa",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Tibillo, Palpa",
                "id_padre_ubigeo": "3640",
                "id_ubigeo": "3645",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tibillo",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3646": [
            {
                "buscador_ubigeo": "huancano pisco",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Huancano, Pisco",
                "id_padre_ubigeo": "3646",
                "id_ubigeo": "3648",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huancano",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "humay pisco",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Humay, Pisco",
                "id_padre_ubigeo": "3646",
                "id_ubigeo": "3649",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Humay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "independencia pisco",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Independencia, Pisco",
                "id_padre_ubigeo": "3646",
                "id_ubigeo": "3650",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Independencia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "paracas pisco",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Paracas, Pisco",
                "id_padre_ubigeo": "3646",
                "id_ubigeo": "3651",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Paracas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pisco pisco",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Pisco, Pisco",
                "id_padre_ubigeo": "3646",
                "id_ubigeo": "3647",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pisco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san andres pisco",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "San Andres, Pisco",
                "id_padre_ubigeo": "3646",
                "id_ubigeo": "3652",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Andres",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san clemente pisco",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "San Clemente, Pisco",
                "id_padre_ubigeo": "3646",
                "id_ubigeo": "3653",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Clemente",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tupac amaru inca pisco",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Tupac Amaru Inca, Pisco",
                "id_padre_ubigeo": "3646",
                "id_ubigeo": "3654",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tupac Amaru Inca",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3656": [
            {
                "buscador_ubigeo": "carhuacallanga huancayo",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Carhuacallanga, Huancayo",
                "id_padre_ubigeo": "3656",
                "id_ubigeo": "3658",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Carhuacallanga",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chacapampa huancayo",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Chacapampa, Huancayo",
                "id_padre_ubigeo": "3656",
                "id_ubigeo": "3659",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chacapampa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chicche huancayo",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Chicche, Huancayo",
                "id_padre_ubigeo": "3656",
                "id_ubigeo": "3660",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chicche",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chilca huancayo",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Chilca, Huancayo",
                "id_padre_ubigeo": "3656",
                "id_ubigeo": "3661",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chilca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chongos alto huancayo",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Chongos Alto, Huancayo",
                "id_padre_ubigeo": "3656",
                "id_ubigeo": "3662",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chongos Alto",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chupuro huancayo",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Chupuro, Huancayo",
                "id_padre_ubigeo": "3656",
                "id_ubigeo": "3663",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chupuro",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "colca huancayo",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Colca, Huancayo",
                "id_padre_ubigeo": "3656",
                "id_ubigeo": "3664",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Colca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cullhuas huancayo",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Cullhuas, Huancayo",
                "id_padre_ubigeo": "3656",
                "id_ubigeo": "3665",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cullhuas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "el tambo huancayo",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "El Tambo, Huancayo",
                "id_padre_ubigeo": "3656",
                "id_ubigeo": "3666",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "El Tambo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huacrapuquio huancayo",
                "codigo_ubigeo": "16",
                "etiqueta_ubigeo": "Huacrapuquio, Huancayo",
                "id_padre_ubigeo": "3656",
                "id_ubigeo": "3667",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huacrapuquio",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "hualhuas huancayo",
                "codigo_ubigeo": "17",
                "etiqueta_ubigeo": "Hualhuas, Huancayo",
                "id_padre_ubigeo": "3656",
                "id_ubigeo": "3668",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Hualhuas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huancan huancayo",
                "codigo_ubigeo": "19",
                "etiqueta_ubigeo": "Huancan, Huancayo",
                "id_padre_ubigeo": "3656",
                "id_ubigeo": "3669",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huancan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huancayo huancayo",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Huancayo, Huancayo",
                "id_padre_ubigeo": "3656",
                "id_ubigeo": "3657",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huancayo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huasicancha huancayo",
                "codigo_ubigeo": "20",
                "etiqueta_ubigeo": "Huasicancha, Huancayo",
                "id_padre_ubigeo": "3656",
                "id_ubigeo": "3670",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huasicancha",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huayucachi huancayo",
                "codigo_ubigeo": "21",
                "etiqueta_ubigeo": "Huayucachi, Huancayo",
                "id_padre_ubigeo": "3656",
                "id_ubigeo": "3671",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huayucachi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ingenio huancayo",
                "codigo_ubigeo": "22",
                "etiqueta_ubigeo": "Ingenio, Huancayo",
                "id_padre_ubigeo": "3656",
                "id_ubigeo": "3672",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ingenio",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pariahuanca huancayo",
                "codigo_ubigeo": "24",
                "etiqueta_ubigeo": "Pariahuanca, Huancayo",
                "id_padre_ubigeo": "3656",
                "id_ubigeo": "3673",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pariahuanca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pilcomayo huancayo",
                "codigo_ubigeo": "25",
                "etiqueta_ubigeo": "Pilcomayo, Huancayo",
                "id_padre_ubigeo": "3656",
                "id_ubigeo": "3674",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pilcomayo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pucara huancayo",
                "codigo_ubigeo": "26",
                "etiqueta_ubigeo": "Pucara, Huancayo",
                "id_padre_ubigeo": "3656",
                "id_ubigeo": "3675",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pucara",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "quichuay huancayo",
                "codigo_ubigeo": "27",
                "etiqueta_ubigeo": "Quichuay, Huancayo",
                "id_padre_ubigeo": "3656",
                "id_ubigeo": "3676",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Quichuay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "quilcas huancayo",
                "codigo_ubigeo": "28",
                "etiqueta_ubigeo": "Quilcas, Huancayo",
                "id_padre_ubigeo": "3656",
                "id_ubigeo": "3677",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Quilcas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san agustin huancayo",
                "codigo_ubigeo": "29",
                "etiqueta_ubigeo": "San Agustin, Huancayo",
                "id_padre_ubigeo": "3656",
                "id_ubigeo": "3678",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Agustin",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san jeronimo de tunan huancayo",
                "codigo_ubigeo": "30",
                "etiqueta_ubigeo": "San Jeronimo de Tunan, Huancayo",
                "id_padre_ubigeo": "3656",
                "id_ubigeo": "3679",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Jeronimo de Tunan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santo domingo de acobamba huancayo",
                "codigo_ubigeo": "35",
                "etiqueta_ubigeo": "Santo Domingo de Acobamba, Huancayo",
                "id_padre_ubigeo": "3656",
                "id_ubigeo": "3683",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santo Domingo de Acobamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sapallanga huancayo",
                "codigo_ubigeo": "33",
                "etiqueta_ubigeo": "Sapallanga, Huancayo",
                "id_padre_ubigeo": "3656",
                "id_ubigeo": "3681",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sapallanga",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "saqo huancayo",
                "codigo_ubigeo": "32",
                "etiqueta_ubigeo": "Saqo, Huancayo",
                "id_padre_ubigeo": "3656",
                "id_ubigeo": "3680",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Saqo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sicaya huancayo",
                "codigo_ubigeo": "34",
                "etiqueta_ubigeo": "Sicaya, Huancayo",
                "id_padre_ubigeo": "3656",
                "id_ubigeo": "3682",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sicaya",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "viques huancayo",
                "codigo_ubigeo": "36",
                "etiqueta_ubigeo": "Viques, Huancayo",
                "id_padre_ubigeo": "3656",
                "id_ubigeo": "3684",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Viques",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3685": [
            {
                "buscador_ubigeo": "aco concepcion",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Aco, Concepcion",
                "id_padre_ubigeo": "3685",
                "id_ubigeo": "3687",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Aco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "andamarca concepcion",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Andamarca, Concepcion",
                "id_padre_ubigeo": "3685",
                "id_ubigeo": "3688",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Andamarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chambara concepcion",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Chambara, Concepcion",
                "id_padre_ubigeo": "3685",
                "id_ubigeo": "3689",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chambara",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cochas concepcion",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Cochas, Concepcion",
                "id_padre_ubigeo": "3685",
                "id_ubigeo": "3690",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cochas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "comas concepcion",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Comas, Concepcion",
                "id_padre_ubigeo": "3685",
                "id_ubigeo": "3691",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Comas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "concepcion concepcion",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Concepcion, Concepcion",
                "id_padre_ubigeo": "3685",
                "id_ubigeo": "3686",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Concepcion",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "heroinas toledo concepcion",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Heroinas Toledo, Concepcion",
                "id_padre_ubigeo": "3685",
                "id_ubigeo": "3692",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Heroinas Toledo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "manzanares concepcion",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Manzanares, Concepcion",
                "id_padre_ubigeo": "3685",
                "id_ubigeo": "3693",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Manzanares",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mariscal castilla concepcion",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Mariscal Castilla, Concepcion",
                "id_padre_ubigeo": "3685",
                "id_ubigeo": "3694",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mariscal Castilla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "matahuasi concepcion",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Matahuasi, Concepcion",
                "id_padre_ubigeo": "3685",
                "id_ubigeo": "3695",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Matahuasi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mito concepcion",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Mito, Concepcion",
                "id_padre_ubigeo": "3685",
                "id_ubigeo": "3696",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mito",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "nueve de julio concepcion",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Nueve de Julio, Concepcion",
                "id_padre_ubigeo": "3685",
                "id_ubigeo": "3697",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Nueve de Julio",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "orcotuna concepcion",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Orcotuna, Concepcion",
                "id_padre_ubigeo": "3685",
                "id_ubigeo": "3698",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Orcotuna",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san jose de quero concepcion",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "San Jose de Quero, Concepcion",
                "id_padre_ubigeo": "3685",
                "id_ubigeo": "3699",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Jose de Quero",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa rosa de ocopa concepcion",
                "codigo_ubigeo": "15",
                "etiqueta_ubigeo": "Santa Rosa de Ocopa, Concepcion",
                "id_padre_ubigeo": "3685",
                "id_ubigeo": "3700",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Rosa de Ocopa",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3701": [
            {
                "buscador_ubigeo": "chanchamayo chanchamayo",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Chanchamayo, Chanchamayo",
                "id_padre_ubigeo": "3701",
                "id_ubigeo": "3702",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chanchamayo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "perene chanchamayo",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Perene, Chanchamayo",
                "id_padre_ubigeo": "3701",
                "id_ubigeo": "3703",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Perene",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pichanaqui chanchamayo",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Pichanaqui, Chanchamayo",
                "id_padre_ubigeo": "3701",
                "id_ubigeo": "3704",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pichanaqui",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san luis de shuaro chanchamayo",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "San Luis de Shuaro, Chanchamayo",
                "id_padre_ubigeo": "3701",
                "id_ubigeo": "3705",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Luis de Shuaro",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san ramon chanchamayo",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "San Ramon, Chanchamayo",
                "id_padre_ubigeo": "3701",
                "id_ubigeo": "3706",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Ramon",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "vitoc chanchamayo",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Vitoc, Chanchamayo",
                "id_padre_ubigeo": "3701",
                "id_ubigeo": "3707",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Vitoc",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3708": [
            {
                "buscador_ubigeo": "acolla jauja",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Acolla, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3710",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Acolla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "apata jauja",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Apata, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3711",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Apata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ataura jauja",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Ataura, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3712",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ataura",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "canchayllo jauja",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Canchayllo, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3713",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Canchayllo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "curicaca jauja",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Curicaca, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3714",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Curicaca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "el mantaro jauja",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "El Mantaro, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3715",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "El Mantaro",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huamali jauja",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Huamali, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3716",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huamali",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huaripampa jauja",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Huaripampa, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3717",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huaripampa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huertas jauja",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Huertas, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3718",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huertas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "janjaillo jauja",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Janjaillo, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3719",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Janjaillo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jauja jauja",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Jauja, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3709",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jauja",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "julcan jauja",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Julcan, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3720",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Julcan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "leonor ordoqez jauja",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Leonor Ordoqez, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3721",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Leonor Ordoqez",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "llocllapampa jauja",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "Llocllapampa, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3722",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Llocllapampa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "marco jauja",
                "codigo_ubigeo": "15",
                "etiqueta_ubigeo": "Marco, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3723",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Marco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "masma jauja",
                "codigo_ubigeo": "16",
                "etiqueta_ubigeo": "Masma, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3724",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Masma",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "masma chicche jauja",
                "codigo_ubigeo": "17",
                "etiqueta_ubigeo": "Masma Chicche, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3725",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Masma Chicche",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "molinos jauja",
                "codigo_ubigeo": "18",
                "etiqueta_ubigeo": "Molinos, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3726",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Molinos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "monobamba jauja",
                "codigo_ubigeo": "19",
                "etiqueta_ubigeo": "Monobamba, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3727",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Monobamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "muqui jauja",
                "codigo_ubigeo": "20",
                "etiqueta_ubigeo": "Muqui, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3728",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Muqui",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "muquiyauyo jauja",
                "codigo_ubigeo": "21",
                "etiqueta_ubigeo": "Muquiyauyo, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3729",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Muquiyauyo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "paca jauja",
                "codigo_ubigeo": "22",
                "etiqueta_ubigeo": "Paca, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3730",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Paca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "paccha jauja",
                "codigo_ubigeo": "23",
                "etiqueta_ubigeo": "Paccha, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3731",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Paccha",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pancan jauja",
                "codigo_ubigeo": "24",
                "etiqueta_ubigeo": "Pancan, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3732",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pancan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "parco jauja",
                "codigo_ubigeo": "25",
                "etiqueta_ubigeo": "Parco, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3733",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Parco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pomacancha jauja",
                "codigo_ubigeo": "26",
                "etiqueta_ubigeo": "Pomacancha, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3734",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pomacancha",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ricran jauja",
                "codigo_ubigeo": "27",
                "etiqueta_ubigeo": "Ricran, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3735",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ricran",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san lorenzo jauja",
                "codigo_ubigeo": "28",
                "etiqueta_ubigeo": "San Lorenzo, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3736",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Lorenzo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san pedro de chunan jauja",
                "codigo_ubigeo": "29",
                "etiqueta_ubigeo": "San Pedro de Chunan, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3737",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Pedro de Chunan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sausa jauja",
                "codigo_ubigeo": "30",
                "etiqueta_ubigeo": "Sausa, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3738",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sausa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sincos jauja",
                "codigo_ubigeo": "31",
                "etiqueta_ubigeo": "Sincos, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3739",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sincos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tunan marca jauja",
                "codigo_ubigeo": "32",
                "etiqueta_ubigeo": "Tunan Marca, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3740",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tunan Marca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yauli jauja",
                "codigo_ubigeo": "33",
                "etiqueta_ubigeo": "Yauli, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3741",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yauli",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yauyos jauja",
                "codigo_ubigeo": "34",
                "etiqueta_ubigeo": "Yauyos, Jauja",
                "id_padre_ubigeo": "3708",
                "id_ubigeo": "3742",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yauyos",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3743": [
            {
                "buscador_ubigeo": "carhuamayo junin",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Carhuamayo, Junin",
                "id_padre_ubigeo": "3743",
                "id_ubigeo": "3745",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Carhuamayo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "junin junin",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Junin, Junin",
                "id_padre_ubigeo": "3743",
                "id_ubigeo": "3744",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Junin",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ondores junin",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Ondores, Junin",
                "id_padre_ubigeo": "3743",
                "id_ubigeo": "3746",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ondores",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ulcumayo junin",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Ulcumayo, Junin",
                "id_padre_ubigeo": "3743",
                "id_ubigeo": "3747",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ulcumayo",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3748": [
            {
                "buscador_ubigeo": "coviriali satipo",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Coviriali, Satipo",
                "id_padre_ubigeo": "3748",
                "id_ubigeo": "3750",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Coviriali",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "llaylla satipo",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Llaylla, Satipo",
                "id_padre_ubigeo": "3748",
                "id_ubigeo": "3751",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Llaylla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mazamari satipo",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Mazamari, Satipo",
                "id_padre_ubigeo": "3748",
                "id_ubigeo": "3752",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mazamari",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pampa hermosa satipo",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Pampa Hermosa, Satipo",
                "id_padre_ubigeo": "3748",
                "id_ubigeo": "3753",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pampa Hermosa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pangoa satipo",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Pangoa, Satipo",
                "id_padre_ubigeo": "3748",
                "id_ubigeo": "3754",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pangoa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "rio negro satipo",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Rio Negro, Satipo",
                "id_padre_ubigeo": "3748",
                "id_ubigeo": "3755",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Rio Negro",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "rio tambo satipo",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Rio Tambo, Satipo",
                "id_padre_ubigeo": "3748",
                "id_ubigeo": "3756",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Rio Tambo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "satipo satipo",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Satipo, Satipo",
                "id_padre_ubigeo": "3748",
                "id_ubigeo": "3749",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Satipo",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3757": [
            {
                "buscador_ubigeo": "acobamba tarma",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Acobamba, Tarma",
                "id_padre_ubigeo": "3757",
                "id_ubigeo": "3759",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Acobamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huaricolca tarma",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Huaricolca, Tarma",
                "id_padre_ubigeo": "3757",
                "id_ubigeo": "3760",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huaricolca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huasahuasi tarma",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Huasahuasi, Tarma",
                "id_padre_ubigeo": "3757",
                "id_ubigeo": "3761",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huasahuasi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la union tarma",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "La Union, Tarma",
                "id_padre_ubigeo": "3757",
                "id_ubigeo": "3762",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Union",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "palca tarma",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Palca, Tarma",
                "id_padre_ubigeo": "3757",
                "id_ubigeo": "3763",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Palca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "palcamayo tarma",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Palcamayo, Tarma",
                "id_padre_ubigeo": "3757",
                "id_ubigeo": "3764",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Palcamayo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san pedro de cajas tarma",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "San Pedro de Cajas, Tarma",
                "id_padre_ubigeo": "3757",
                "id_ubigeo": "3765",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Pedro de Cajas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tapo tarma",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Tapo, Tarma",
                "id_padre_ubigeo": "3757",
                "id_ubigeo": "3766",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tapo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tarma tarma",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Tarma, Tarma",
                "id_padre_ubigeo": "3757",
                "id_ubigeo": "3758",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tarma",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3767": [
            {
                "buscador_ubigeo": "chacapalpa yauli",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Chacapalpa, Yauli",
                "id_padre_ubigeo": "3767",
                "id_ubigeo": "3769",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chacapalpa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huay-huay yauli",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Huay-Huay, Yauli",
                "id_padre_ubigeo": "3767",
                "id_ubigeo": "3770",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huay-Huay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la oroya yauli",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "La Oroya, Yauli",
                "id_padre_ubigeo": "3767",
                "id_ubigeo": "3768",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Oroya",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "marcapomacocha yauli",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Marcapomacocha, Yauli",
                "id_padre_ubigeo": "3767",
                "id_ubigeo": "3771",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Marcapomacocha",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "morococha yauli",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Morococha, Yauli",
                "id_padre_ubigeo": "3767",
                "id_ubigeo": "3772",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Morococha",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "paccha yauli",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Paccha, Yauli",
                "id_padre_ubigeo": "3767",
                "id_ubigeo": "3773",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Paccha",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa barbara de carhuacayan yauli",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Santa Barbara de Carhuacayan, Yauli",
                "id_padre_ubigeo": "3767",
                "id_ubigeo": "3774",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Barbara de Carhuacayan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa rosa de sacco yauli",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Santa Rosa de Sacco, Yauli",
                "id_padre_ubigeo": "3767",
                "id_ubigeo": "3775",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Rosa de Sacco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "suitucancha yauli",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Suitucancha, Yauli",
                "id_padre_ubigeo": "3767",
                "id_ubigeo": "3776",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Suitucancha",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yauli yauli",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Yauli, Yauli",
                "id_padre_ubigeo": "3767",
                "id_ubigeo": "3777",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yauli",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3778": [
            {
                "buscador_ubigeo": "ahuac chupaca",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Ahuac, Chupaca",
                "id_padre_ubigeo": "3778",
                "id_ubigeo": "3780",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ahuac",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chongos bajo chupaca",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Chongos Bajo, Chupaca",
                "id_padre_ubigeo": "3778",
                "id_ubigeo": "3781",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chongos Bajo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chupaca chupaca",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Chupaca, Chupaca",
                "id_padre_ubigeo": "3778",
                "id_ubigeo": "3779",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chupaca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huachac chupaca",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Huachac, Chupaca",
                "id_padre_ubigeo": "3778",
                "id_ubigeo": "3782",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huachac",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huamancaca chico chupaca",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Huamancaca Chico, Chupaca",
                "id_padre_ubigeo": "3778",
                "id_ubigeo": "3783",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huamancaca Chico",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san juan de iscos chupaca",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "San Juan de Iscos, Chupaca",
                "id_padre_ubigeo": "3778",
                "id_ubigeo": "3784",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Juan de Iscos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san juan de jarpa chupaca",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "San Juan de Jarpa, Chupaca",
                "id_padre_ubigeo": "3778",
                "id_ubigeo": "3785",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Juan de Jarpa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tres de diciembre chupaca",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Tres de Diciembre, Chupaca",
                "id_padre_ubigeo": "3778",
                "id_ubigeo": "3786",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tres de Diciembre",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yanacancha chupaca",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Yanacancha, Chupaca",
                "id_padre_ubigeo": "3778",
                "id_ubigeo": "3787",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yanacancha",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3789": [
            {
                "buscador_ubigeo": "el porvenir trujillo",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "El Porvenir, Trujillo",
                "id_padre_ubigeo": "3789",
                "id_ubigeo": "3791",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "El Porvenir",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "florencia de mora trujillo",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Florencia de Mora, Trujillo",
                "id_padre_ubigeo": "3789",
                "id_ubigeo": "3792",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Florencia de Mora",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huanchaco trujillo",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Huanchaco, Trujillo",
                "id_padre_ubigeo": "3789",
                "id_ubigeo": "3793",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huanchaco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la esperanza trujillo",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "La Esperanza, Trujillo",
                "id_padre_ubigeo": "3789",
                "id_ubigeo": "3794",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Esperanza",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "laredo trujillo",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Laredo, Trujillo",
                "id_padre_ubigeo": "3789",
                "id_ubigeo": "3795",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Laredo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "moche trujillo",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Moche, Trujillo",
                "id_padre_ubigeo": "3789",
                "id_ubigeo": "3796",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Moche",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "poroto trujillo",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Poroto, Trujillo",
                "id_padre_ubigeo": "3789",
                "id_ubigeo": "3797",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Poroto",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "salaverry trujillo",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Salaverry, Trujillo",
                "id_padre_ubigeo": "3789",
                "id_ubigeo": "3798",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Salaverry",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "simbal trujillo",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Simbal, Trujillo",
                "id_padre_ubigeo": "3789",
                "id_ubigeo": "3799",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Simbal",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "trujillo trujillo",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Trujillo, Trujillo",
                "id_padre_ubigeo": "3789",
                "id_ubigeo": "3790",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Trujillo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "victor larco herrera trujillo",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Victor Larco Herrera, Trujillo",
                "id_padre_ubigeo": "3789",
                "id_ubigeo": "3800",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Victor Larco Herrera",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3801": [
            {
                "buscador_ubigeo": "ascope ascope",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Ascope, Ascope",
                "id_padre_ubigeo": "3801",
                "id_ubigeo": "3802",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ascope",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "casa grande ascope",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Casa Grande, Ascope",
                "id_padre_ubigeo": "3801",
                "id_ubigeo": "3809",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Casa Grande",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chicama ascope",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Chicama, Ascope",
                "id_padre_ubigeo": "3801",
                "id_ubigeo": "3803",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chicama",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chocope ascope",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Chocope, Ascope",
                "id_padre_ubigeo": "3801",
                "id_ubigeo": "3804",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chocope",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "magdalena de cao ascope",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Magdalena de Cao, Ascope",
                "id_padre_ubigeo": "3801",
                "id_ubigeo": "3805",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Magdalena de Cao",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "paijan ascope",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Paijan, Ascope",
                "id_padre_ubigeo": "3801",
                "id_ubigeo": "3806",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Paijan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "razuri ascope",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Razuri, Ascope",
                "id_padre_ubigeo": "3801",
                "id_ubigeo": "3807",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Razuri",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santiago de cao ascope",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Santiago de Cao, Ascope",
                "id_padre_ubigeo": "3801",
                "id_ubigeo": "3808",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santiago de Cao",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3810": [
            {
                "buscador_ubigeo": "bambamarca bolivar",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Bambamarca, Bolivar",
                "id_padre_ubigeo": "3810",
                "id_ubigeo": "3812",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Bambamarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "bolivar bolivar",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Bolivar, Bolivar",
                "id_padre_ubigeo": "3810",
                "id_ubigeo": "3811",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Bolivar",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "condormarca bolivar",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Condormarca, Bolivar",
                "id_padre_ubigeo": "3810",
                "id_ubigeo": "3813",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Condormarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "longotea bolivar",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Longotea, Bolivar",
                "id_padre_ubigeo": "3810",
                "id_ubigeo": "3814",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Longotea",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "uchumarca bolivar",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Uchumarca, Bolivar",
                "id_padre_ubigeo": "3810",
                "id_ubigeo": "3815",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Uchumarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ucuncha bolivar",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Ucuncha, Bolivar",
                "id_padre_ubigeo": "3810",
                "id_ubigeo": "3816",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ucuncha",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3817": [
            {
                "buscador_ubigeo": "chepen chepen",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Chepen, Chepen",
                "id_padre_ubigeo": "3817",
                "id_ubigeo": "3818",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chepen",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pacanga chepen",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Pacanga, Chepen",
                "id_padre_ubigeo": "3817",
                "id_ubigeo": "3819",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pacanga",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pueblo nuevo chepen",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Pueblo Nuevo, Chepen",
                "id_padre_ubigeo": "3817",
                "id_ubigeo": "3820",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pueblo Nuevo",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3821": [
            {
                "buscador_ubigeo": "calamarca julcan",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Calamarca, Julcan",
                "id_padre_ubigeo": "3821",
                "id_ubigeo": "3823",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Calamarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "carabamba julcan",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Carabamba, Julcan",
                "id_padre_ubigeo": "3821",
                "id_ubigeo": "3824",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Carabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huaso julcan",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Huaso, Julcan",
                "id_padre_ubigeo": "3821",
                "id_ubigeo": "3825",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huaso",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "julcan julcan",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Julcan, Julcan",
                "id_padre_ubigeo": "3821",
                "id_ubigeo": "3822",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Julcan",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3826": [
            {
                "buscador_ubigeo": "agallpampa otuzco",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Agallpampa, Otuzco",
                "id_padre_ubigeo": "3826",
                "id_ubigeo": "3828",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Agallpampa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "charat otuzco",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Charat, Otuzco",
                "id_padre_ubigeo": "3826",
                "id_ubigeo": "3829",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Charat",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huaranchal otuzco",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Huaranchal, Otuzco",
                "id_padre_ubigeo": "3826",
                "id_ubigeo": "3830",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huaranchal",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la cuesta otuzco",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "La Cuesta, Otuzco",
                "id_padre_ubigeo": "3826",
                "id_ubigeo": "3831",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Cuesta",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mache otuzco",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Mache, Otuzco",
                "id_padre_ubigeo": "3826",
                "id_ubigeo": "3832",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mache",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "otuzco otuzco",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Otuzco, Otuzco",
                "id_padre_ubigeo": "3826",
                "id_ubigeo": "3827",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Otuzco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "paranday otuzco",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Paranday, Otuzco",
                "id_padre_ubigeo": "3826",
                "id_ubigeo": "3833",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Paranday",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "salpo otuzco",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Salpo, Otuzco",
                "id_padre_ubigeo": "3826",
                "id_ubigeo": "3834",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Salpo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sinsicap otuzco",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Sinsicap, Otuzco",
                "id_padre_ubigeo": "3826",
                "id_ubigeo": "3835",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sinsicap",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "usquil otuzco",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "Usquil, Otuzco",
                "id_padre_ubigeo": "3826",
                "id_ubigeo": "3836",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Usquil",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3837": [
            {
                "buscador_ubigeo": "guadalupe pacasmayo",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Guadalupe, Pacasmayo",
                "id_padre_ubigeo": "3837",
                "id_ubigeo": "3839",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Guadalupe",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jequetepeque pacasmayo",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Jequetepeque, Pacasmayo",
                "id_padre_ubigeo": "3837",
                "id_ubigeo": "3840",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jequetepeque",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pacasmayo pacasmayo",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Pacasmayo, Pacasmayo",
                "id_padre_ubigeo": "3837",
                "id_ubigeo": "3841",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pacasmayo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san jose pacasmayo",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "San Jose, Pacasmayo",
                "id_padre_ubigeo": "3837",
                "id_ubigeo": "3842",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Jose",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san pedro de lloc pacasmayo",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "San Pedro de Lloc, Pacasmayo",
                "id_padre_ubigeo": "3837",
                "id_ubigeo": "3838",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Pedro de Lloc",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3843": [
            {
                "buscador_ubigeo": "buldibuyo pataz",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Buldibuyo, Pataz",
                "id_padre_ubigeo": "3843",
                "id_ubigeo": "3845",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Buldibuyo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chillia pataz",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Chillia, Pataz",
                "id_padre_ubigeo": "3843",
                "id_ubigeo": "3846",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chillia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huancaspata pataz",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Huancaspata, Pataz",
                "id_padre_ubigeo": "3843",
                "id_ubigeo": "3847",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huancaspata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huaylillas pataz",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Huaylillas, Pataz",
                "id_padre_ubigeo": "3843",
                "id_ubigeo": "3848",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huaylillas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huayo pataz",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Huayo, Pataz",
                "id_padre_ubigeo": "3843",
                "id_ubigeo": "3849",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huayo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ongon pataz",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Ongon, Pataz",
                "id_padre_ubigeo": "3843",
                "id_ubigeo": "3850",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ongon",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "parcoy pataz",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Parcoy, Pataz",
                "id_padre_ubigeo": "3843",
                "id_ubigeo": "3851",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Parcoy",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pataz pataz",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Pataz, Pataz",
                "id_padre_ubigeo": "3843",
                "id_ubigeo": "3852",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pataz",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pias pataz",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Pias, Pataz",
                "id_padre_ubigeo": "3843",
                "id_ubigeo": "3853",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pias",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santiago de challas pataz",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Santiago de Challas, Pataz",
                "id_padre_ubigeo": "3843",
                "id_ubigeo": "3854",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santiago de Challas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "taurija pataz",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Taurija, Pataz",
                "id_padre_ubigeo": "3843",
                "id_ubigeo": "3855",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Taurija",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tayabamba pataz",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Tayabamba, Pataz",
                "id_padre_ubigeo": "3843",
                "id_ubigeo": "3844",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tayabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "urpay pataz",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Urpay, Pataz",
                "id_padre_ubigeo": "3843",
                "id_ubigeo": "3856",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Urpay",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3857": [
            {
                "buscador_ubigeo": "chugay sanchez carrion",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Chugay, Sanchez Carrion",
                "id_padre_ubigeo": "3857",
                "id_ubigeo": "3859",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chugay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cochorco sanchez carrion",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Cochorco, Sanchez Carrion",
                "id_padre_ubigeo": "3857",
                "id_ubigeo": "3860",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cochorco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "curgos sanchez carrion",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Curgos, Sanchez Carrion",
                "id_padre_ubigeo": "3857",
                "id_ubigeo": "3861",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Curgos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huamachuco sanchez carrion",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Huamachuco, Sanchez Carrion",
                "id_padre_ubigeo": "3857",
                "id_ubigeo": "3858",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huamachuco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "marcabal sanchez carrion",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Marcabal, Sanchez Carrion",
                "id_padre_ubigeo": "3857",
                "id_ubigeo": "3862",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Marcabal",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sanagoran sanchez carrion",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Sanagoran, Sanchez Carrion",
                "id_padre_ubigeo": "3857",
                "id_ubigeo": "3863",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sanagoran",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sarin sanchez carrion",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Sarin, Sanchez Carrion",
                "id_padre_ubigeo": "3857",
                "id_ubigeo": "3864",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sarin",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sartimbamba sanchez carrion",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Sartimbamba, Sanchez Carrion",
                "id_padre_ubigeo": "3857",
                "id_ubigeo": "3865",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sartimbamba",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3866": [
            {
                "buscador_ubigeo": "angasmarca santiago de chuco",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Angasmarca, Santiago de Chuco",
                "id_padre_ubigeo": "3866",
                "id_ubigeo": "3868",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Angasmarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cachicadan santiago de chuco",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Cachicadan, Santiago de Chuco",
                "id_padre_ubigeo": "3866",
                "id_ubigeo": "3869",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cachicadan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mollebamba santiago de chuco",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Mollebamba, Santiago de Chuco",
                "id_padre_ubigeo": "3866",
                "id_ubigeo": "3870",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mollebamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mollepata santiago de chuco",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Mollepata, Santiago de Chuco",
                "id_padre_ubigeo": "3866",
                "id_ubigeo": "3871",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mollepata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "quiruvilca santiago de chuco",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Quiruvilca, Santiago de Chuco",
                "id_padre_ubigeo": "3866",
                "id_ubigeo": "3872",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Quiruvilca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa cruz de chuca santiago de chuco",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Santa Cruz de Chuca, Santiago de Chuco",
                "id_padre_ubigeo": "3866",
                "id_ubigeo": "3873",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Cruz de Chuca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santiago de chuco santiago de chuco",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Santiago de Chuco, Santiago de Chuco",
                "id_padre_ubigeo": "3866",
                "id_ubigeo": "3867",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santiago de Chuco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sitabamba santiago de chuco",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Sitabamba, Santiago de Chuco",
                "id_padre_ubigeo": "3866",
                "id_ubigeo": "3874",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sitabamba",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3875": [
            {
                "buscador_ubigeo": "cascas gran chimu",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Cascas, Gran Chimu",
                "id_padre_ubigeo": "3875",
                "id_ubigeo": "3876",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cascas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lucma gran chimu",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Lucma, Gran Chimu",
                "id_padre_ubigeo": "3875",
                "id_ubigeo": "3877",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lucma",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "marmot gran chimu",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Marmot, Gran Chimu",
                "id_padre_ubigeo": "3875",
                "id_ubigeo": "3878",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Marmot",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sayapullo gran chimu",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Sayapullo, Gran Chimu",
                "id_padre_ubigeo": "3875",
                "id_ubigeo": "3879",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sayapullo",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3880": [
            {
                "buscador_ubigeo": "chao viru",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Chao, Viru",
                "id_padre_ubigeo": "3880",
                "id_ubigeo": "3882",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chao",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "guadalupito viru",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Guadalupito, Viru",
                "id_padre_ubigeo": "3880",
                "id_ubigeo": "3883",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Guadalupito",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "viru viru",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Viru, Viru",
                "id_padre_ubigeo": "3880",
                "id_ubigeo": "3881",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Viru",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3885": [
            {
                "buscador_ubigeo": "cayaltí chiclayo",
                "codigo_ubigeo": "16",
                "etiqueta_ubigeo": "Cayaltí, Chiclayo",
                "id_padre_ubigeo": "3885",
                "id_ubigeo": "3901",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cayaltí",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chiclayo chiclayo",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Chiclayo, Chiclayo",
                "id_padre_ubigeo": "3885",
                "id_ubigeo": "3886",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chiclayo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chongoyape chiclayo",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Chongoyape, Chiclayo",
                "id_padre_ubigeo": "3885",
                "id_ubigeo": "3887",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chongoyape",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "eten chiclayo",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Eten, Chiclayo",
                "id_padre_ubigeo": "3885",
                "id_ubigeo": "3888",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Eten",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "eten puerto chiclayo",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Eten Puerto, Chiclayo",
                "id_padre_ubigeo": "3885",
                "id_ubigeo": "3889",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Eten Puerto",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jose leonardo ortiz chiclayo",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Jose Leonardo Ortiz, Chiclayo",
                "id_padre_ubigeo": "3885",
                "id_ubigeo": "3890",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jose Leonardo Ortiz",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la victoria chiclayo",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "La Victoria, Chiclayo",
                "id_padre_ubigeo": "3885",
                "id_ubigeo": "3891",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Victoria",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lagunas chiclayo",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Lagunas, Chiclayo",
                "id_padre_ubigeo": "3885",
                "id_ubigeo": "3892",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lagunas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "monsefu chiclayo",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Monsefu, Chiclayo",
                "id_padre_ubigeo": "3885",
                "id_ubigeo": "3893",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Monsefu",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "nueva arica chiclayo",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Nueva Arica, Chiclayo",
                "id_padre_ubigeo": "3885",
                "id_ubigeo": "3894",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Nueva Arica",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "oyotun chiclayo",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Oyotun, Chiclayo",
                "id_padre_ubigeo": "3885",
                "id_ubigeo": "3895",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Oyotun",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "patapo chiclayo",
                "codigo_ubigeo": "17",
                "etiqueta_ubigeo": "Patapo, Chiclayo",
                "id_padre_ubigeo": "3885",
                "id_ubigeo": "3902",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Patapo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "picsi chiclayo",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Picsi, Chiclayo",
                "id_padre_ubigeo": "3885",
                "id_ubigeo": "3896",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Picsi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pimentel chiclayo",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Pimentel, Chiclayo",
                "id_padre_ubigeo": "3885",
                "id_ubigeo": "3897",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pimentel",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pomalca chiclayo",
                "codigo_ubigeo": "18",
                "etiqueta_ubigeo": "Pomalca, Chiclayo",
                "id_padre_ubigeo": "3885",
                "id_ubigeo": "3903",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pomalca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pucalá chiclayo",
                "codigo_ubigeo": "19",
                "etiqueta_ubigeo": "Pucalá, Chiclayo",
                "id_padre_ubigeo": "3885",
                "id_ubigeo": "3904",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pucalá",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "reque chiclayo",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Reque, Chiclayo",
                "id_padre_ubigeo": "3885",
                "id_ubigeo": "3898",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Reque",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa rosa chiclayo",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "Santa Rosa, Chiclayo",
                "id_padre_ubigeo": "3885",
                "id_ubigeo": "3899",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Rosa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "saqa chiclayo",
                "codigo_ubigeo": "15",
                "etiqueta_ubigeo": "Saqa, Chiclayo",
                "id_padre_ubigeo": "3885",
                "id_ubigeo": "3900",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Saqa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tumán chiclayo",
                "codigo_ubigeo": "20",
                "etiqueta_ubigeo": "Tumán, Chiclayo",
                "id_padre_ubigeo": "3885",
                "id_ubigeo": "3905",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tumán",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3906": [
            {
                "buscador_ubigeo": "caqaris ferreqafe",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Caqaris, Ferreqafe",
                "id_padre_ubigeo": "3906",
                "id_ubigeo": "3908",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Caqaris",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ferreqafe ferreqafe",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Ferreqafe, Ferreqafe",
                "id_padre_ubigeo": "3906",
                "id_ubigeo": "3907",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ferreqafe",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "incahuasi ferreqafe",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Incahuasi, Ferreqafe",
                "id_padre_ubigeo": "3906",
                "id_ubigeo": "3909",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Incahuasi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "manuel antonio mesones muro ferreqafe",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Manuel Antonio Mesones Muro, Ferreqafe",
                "id_padre_ubigeo": "3906",
                "id_ubigeo": "3910",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Manuel Antonio Mesones Muro",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pitipo ferreqafe",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Pitipo, Ferreqafe",
                "id_padre_ubigeo": "3906",
                "id_ubigeo": "3911",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pitipo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pueblo nuevo ferreqafe",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Pueblo Nuevo, Ferreqafe",
                "id_padre_ubigeo": "3906",
                "id_ubigeo": "3912",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pueblo Nuevo",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3913": [
            {
                "buscador_ubigeo": "chochope lambayeque",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Chochope, Lambayeque",
                "id_padre_ubigeo": "3913",
                "id_ubigeo": "3915",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chochope",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "illimo lambayeque",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Illimo, Lambayeque",
                "id_padre_ubigeo": "3913",
                "id_ubigeo": "3916",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Illimo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jayanca lambayeque",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Jayanca, Lambayeque",
                "id_padre_ubigeo": "3913",
                "id_ubigeo": "3917",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jayanca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lambayeque lambayeque",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Lambayeque, Lambayeque",
                "id_padre_ubigeo": "3913",
                "id_ubigeo": "3914",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lambayeque",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mochumi lambayeque",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Mochumi, Lambayeque",
                "id_padre_ubigeo": "3913",
                "id_ubigeo": "3918",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mochumi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "morrope lambayeque",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Morrope, Lambayeque",
                "id_padre_ubigeo": "3913",
                "id_ubigeo": "3919",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Morrope",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "motupe lambayeque",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Motupe, Lambayeque",
                "id_padre_ubigeo": "3913",
                "id_ubigeo": "3920",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Motupe",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "olmos lambayeque",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Olmos, Lambayeque",
                "id_padre_ubigeo": "3913",
                "id_ubigeo": "3921",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Olmos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pacora lambayeque",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Pacora, Lambayeque",
                "id_padre_ubigeo": "3913",
                "id_ubigeo": "3922",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pacora",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "salas lambayeque",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Salas, Lambayeque",
                "id_padre_ubigeo": "3913",
                "id_ubigeo": "3923",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Salas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san jose lambayeque",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "San Jose, Lambayeque",
                "id_padre_ubigeo": "3913",
                "id_ubigeo": "3924",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Jose",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tucume lambayeque",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Tucume, Lambayeque",
                "id_padre_ubigeo": "3913",
                "id_ubigeo": "3925",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tucume",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3927": [
            {
                "buscador_ubigeo": "ancon lima",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Ancon, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3929",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ancon",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ate lima",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Ate, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3930",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ate",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "barranco lima",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Barranco, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3931",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Barranco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "brena lima",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Breña, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3932",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Breña",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "carabayllo lima",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Carabayllo, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3933",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Carabayllo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cercado de lima lima",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Cercado de Lima, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3928",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cercado de Lima",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chaclacayo lima",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Chaclacayo, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3934",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chaclacayo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chorrillos lima",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Chorrillos, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3935",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chorrillos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cieneguilla lima",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Cieneguilla, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3936",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cieneguilla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "comas lima",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Comas, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3937",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Comas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "el agustino lima",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "El Agustino, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3938",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "El Agustino",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "independencia lima",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Independencia, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3939",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Independencia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jesus maria lima",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Jesus Maria, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3940",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jesus Maria",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la molina lima",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "La Molina, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3941",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Molina",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la victoria lima",
                "codigo_ubigeo": "15",
                "etiqueta_ubigeo": "La Victoria, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3942",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Victoria",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lince lima",
                "codigo_ubigeo": "16",
                "etiqueta_ubigeo": "Lince, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3943",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lince",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "los olivos lima",
                "codigo_ubigeo": "17",
                "etiqueta_ubigeo": "Los Olivos, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3944",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Los Olivos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lurigancho lima",
                "codigo_ubigeo": "18",
                "etiqueta_ubigeo": "Lurigancho, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3945",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lurigancho",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lurin lima",
                "codigo_ubigeo": "19",
                "etiqueta_ubigeo": "Lurin, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3946",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lurin",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "magdalena del mar lima",
                "codigo_ubigeo": "20",
                "etiqueta_ubigeo": "Magdalena del Mar, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3947",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Magdalena del Mar",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "miraflores lima",
                "codigo_ubigeo": "22",
                "etiqueta_ubigeo": "Miraflores, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3949",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Miraflores",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pachacamac lima",
                "codigo_ubigeo": "23",
                "etiqueta_ubigeo": "Pachacamac, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3950",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pachacamac",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pucusana lima",
                "codigo_ubigeo": "24",
                "etiqueta_ubigeo": "Pucusana, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3951",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pucusana",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pueblo libre lima",
                "codigo_ubigeo": "21",
                "etiqueta_ubigeo": "Pueblo Libre, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3948",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pueblo Libre",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "puente piedra lima",
                "codigo_ubigeo": "25",
                "etiqueta_ubigeo": "Puente Piedra, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3952",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Puente Piedra",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "punta hermosa lima",
                "codigo_ubigeo": "26",
                "etiqueta_ubigeo": "Punta Hermosa, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3953",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Punta Hermosa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "punta negra lima",
                "codigo_ubigeo": "27",
                "etiqueta_ubigeo": "Punta Negra, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3954",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Punta Negra",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "rimac lima",
                "codigo_ubigeo": "28",
                "etiqueta_ubigeo": "Rimac, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3955",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Rimac",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san bartolo lima",
                "codigo_ubigeo": "29",
                "etiqueta_ubigeo": "San Bartolo, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3956",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Bartolo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san borja lima",
                "codigo_ubigeo": "30",
                "etiqueta_ubigeo": "San Borja, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3957",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Borja",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san isidro lima",
                "codigo_ubigeo": "31",
                "etiqueta_ubigeo": "San Isidro, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3958",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Isidro",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san juan de lurigancho lima",
                "codigo_ubigeo": "32",
                "etiqueta_ubigeo": "San Juan de Lurigancho, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3959",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Juan de Lurigancho",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san juan de miraflores lima",
                "codigo_ubigeo": "33",
                "etiqueta_ubigeo": "San Juan de Miraflores, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3960",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Juan de Miraflores",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san luis lima",
                "codigo_ubigeo": "34",
                "etiqueta_ubigeo": "San Luis, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3961",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Luis",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san martin de porres lima",
                "codigo_ubigeo": "35",
                "etiqueta_ubigeo": "San Martin de Porres, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3962",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Martin de Porres",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san miguel lima",
                "codigo_ubigeo": "36",
                "etiqueta_ubigeo": "San Miguel, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3963",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Miguel",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa anita lima",
                "codigo_ubigeo": "37",
                "etiqueta_ubigeo": "Santa Anita, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3964",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Anita",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa maria del mar lima",
                "codigo_ubigeo": "38",
                "etiqueta_ubigeo": "Santa Maria del Mar, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3965",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Maria del Mar",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa rosa lima",
                "codigo_ubigeo": "39",
                "etiqueta_ubigeo": "Santa Rosa, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3966",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Rosa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santiago de surco lima",
                "codigo_ubigeo": "40",
                "etiqueta_ubigeo": "Santiago de Surco, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3967",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santiago de Surco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "surquillo lima",
                "codigo_ubigeo": "41",
                "etiqueta_ubigeo": "Surquillo, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3968",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Surquillo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "villa el salvador lima",
                "codigo_ubigeo": "42",
                "etiqueta_ubigeo": "Villa El Salvador, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3969",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Villa El Salvador",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "villa maria del triunfo lima",
                "codigo_ubigeo": "43",
                "etiqueta_ubigeo": "Villa Maria del Triunfo, Lima",
                "id_padre_ubigeo": "3927",
                "id_ubigeo": "3970",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Villa Maria del Triunfo",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3971": [
            {
                "buscador_ubigeo": "barranca barranca",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Barranca, Barranca",
                "id_padre_ubigeo": "3971",
                "id_ubigeo": "3972",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Barranca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "paramonga barranca",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Paramonga, Barranca",
                "id_padre_ubigeo": "3971",
                "id_ubigeo": "3973",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Paramonga",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pativilca barranca",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Pativilca, Barranca",
                "id_padre_ubigeo": "3971",
                "id_ubigeo": "3974",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pativilca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "supe barranca",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Supe, Barranca",
                "id_padre_ubigeo": "3971",
                "id_ubigeo": "3975",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Supe",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "supe puerto barranca",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Supe Puerto, Barranca",
                "id_padre_ubigeo": "3971",
                "id_ubigeo": "3976",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Supe Puerto",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3977": [
            {
                "buscador_ubigeo": "cajatambo cajatambo",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Cajatambo, Cajatambo",
                "id_padre_ubigeo": "3977",
                "id_ubigeo": "3978",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cajatambo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "copa cajatambo",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Copa, Cajatambo",
                "id_padre_ubigeo": "3977",
                "id_ubigeo": "3979",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Copa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "gorgor cajatambo",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Gorgor, Cajatambo",
                "id_padre_ubigeo": "3977",
                "id_ubigeo": "3980",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Gorgor",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huancapon cajatambo",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Huancapon, Cajatambo",
                "id_padre_ubigeo": "3977",
                "id_ubigeo": "3981",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huancapon",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "manas cajatambo",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Manas, Cajatambo",
                "id_padre_ubigeo": "3977",
                "id_ubigeo": "3982",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Manas",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3983": [
            {
                "buscador_ubigeo": "arahuay canta",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Arahuay, Canta",
                "id_padre_ubigeo": "3983",
                "id_ubigeo": "3985",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Arahuay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "canta canta",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Canta, Canta",
                "id_padre_ubigeo": "3983",
                "id_ubigeo": "3984",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Canta",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huamantanga canta",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Huamantanga, Canta",
                "id_padre_ubigeo": "3983",
                "id_ubigeo": "3986",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huamantanga",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huaros canta",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Huaros, Canta",
                "id_padre_ubigeo": "3983",
                "id_ubigeo": "3987",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huaros",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lachaqui canta",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Lachaqui, Canta",
                "id_padre_ubigeo": "3983",
                "id_ubigeo": "3988",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lachaqui",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san buenaventura canta",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "San Buenaventura, Canta",
                "id_padre_ubigeo": "3983",
                "id_ubigeo": "3989",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Buenaventura",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa rosa de quives canta",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Santa Rosa de Quives, Canta",
                "id_padre_ubigeo": "3983",
                "id_ubigeo": "3990",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Rosa de Quives",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "3991": [
            {
                "buscador_ubigeo": "asia caqete",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Asia, Caqete",
                "id_padre_ubigeo": "3991",
                "id_ubigeo": "3993",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Asia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "calango caqete",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Calango, Caqete",
                "id_padre_ubigeo": "3991",
                "id_ubigeo": "3994",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Calango",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cerro azul caqete",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Cerro Azul, Caqete",
                "id_padre_ubigeo": "3991",
                "id_ubigeo": "3995",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cerro Azul",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chilca caqete",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Chilca, Caqete",
                "id_padre_ubigeo": "3991",
                "id_ubigeo": "3996",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chilca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "coayllo caqete",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Coayllo, Caqete",
                "id_padre_ubigeo": "3991",
                "id_ubigeo": "3997",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Coayllo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "imperial caqete",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Imperial, Caqete",
                "id_padre_ubigeo": "3991",
                "id_ubigeo": "3998",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Imperial",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lunahuana caqete",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Lunahuana, Caqete",
                "id_padre_ubigeo": "3991",
                "id_ubigeo": "3999",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lunahuana",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mala caqete",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Mala, Caqete",
                "id_padre_ubigeo": "3991",
                "id_ubigeo": "4000",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mala",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "nuevo imperial caqete",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Nuevo Imperial, Caqete",
                "id_padre_ubigeo": "3991",
                "id_ubigeo": "4001",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Nuevo Imperial",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pacaran caqete",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Pacaran, Caqete",
                "id_padre_ubigeo": "3991",
                "id_ubigeo": "4002",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pacaran",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "quilmana caqete",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Quilmana, Caqete",
                "id_padre_ubigeo": "3991",
                "id_ubigeo": "4003",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Quilmana",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san antonio caqete",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "San Antonio, Caqete",
                "id_padre_ubigeo": "3991",
                "id_ubigeo": "4004",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Antonio",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san luis caqete",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "San Luis, Caqete",
                "id_padre_ubigeo": "3991",
                "id_ubigeo": "4005",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Luis",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san vicente de cañete cañete",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "San Vicente de Cañete, Cañete",
                "id_padre_ubigeo": "3991",
                "id_ubigeo": "3992",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Vicente de Cañete",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa cruz de flores caqete",
                "codigo_ubigeo": "15",
                "etiqueta_ubigeo": "Santa Cruz de Flores, Caqete",
                "id_padre_ubigeo": "3991",
                "id_ubigeo": "4006",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Cruz de Flores",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "zuqiga caqete",
                "codigo_ubigeo": "16",
                "etiqueta_ubigeo": "Zuqiga, Caqete",
                "id_padre_ubigeo": "3991",
                "id_ubigeo": "4007",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Zuqiga",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4008": [
            {
                "buscador_ubigeo": "atavillos alto huaral",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Atavillos Alto, Huaral",
                "id_padre_ubigeo": "4008",
                "id_ubigeo": "4010",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Atavillos Alto",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "atavillos bajo huaral",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Atavillos Bajo, Huaral",
                "id_padre_ubigeo": "4008",
                "id_ubigeo": "4011",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Atavillos Bajo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "aucallama huaral",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Aucallama, Huaral",
                "id_padre_ubigeo": "4008",
                "id_ubigeo": "4012",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Aucallama",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chancay huaral",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Chancay, Huaral",
                "id_padre_ubigeo": "4008",
                "id_ubigeo": "4013",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chancay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huaral huaral",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Huaral, Huaral",
                "id_padre_ubigeo": "4008",
                "id_ubigeo": "4009",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huaral",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ihuari huaral",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Ihuari, Huaral",
                "id_padre_ubigeo": "4008",
                "id_ubigeo": "4014",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ihuari",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lampian huaral",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Lampian, Huaral",
                "id_padre_ubigeo": "4008",
                "id_ubigeo": "4015",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lampian",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pacaraos huaral",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Pacaraos, Huaral",
                "id_padre_ubigeo": "4008",
                "id_ubigeo": "4016",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pacaraos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san miguel de acos huaral",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "San Miguel de Acos, Huaral",
                "id_padre_ubigeo": "4008",
                "id_ubigeo": "4017",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Miguel de Acos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa cruz de andamarca huaral",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Santa Cruz de Andamarca, Huaral",
                "id_padre_ubigeo": "4008",
                "id_ubigeo": "4018",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Cruz de Andamarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sumbilca huaral",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Sumbilca, Huaral",
                "id_padre_ubigeo": "4008",
                "id_ubigeo": "4019",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sumbilca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "veintisiete de noviembre huaral",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Veintisiete de Noviembre, Huaral",
                "id_padre_ubigeo": "4008",
                "id_ubigeo": "4020",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Veintisiete de Noviembre",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4021": [
            {
                "buscador_ubigeo": "antioquia huarochiri",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Antioquia, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4023",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Antioquia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "callahuanca huarochiri",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Callahuanca, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4024",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Callahuanca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "carampoma huarochiri",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Carampoma, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4025",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Carampoma",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chicla huarochiri",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Chicla, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4026",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chicla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cuenca huarochiri",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Cuenca, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4027",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cuenca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huachupampa huarochiri",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Huachupampa, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4028",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huachupampa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huanza huarochiri",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Huanza, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4029",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huanza",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huarochiri huarochiri",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Huarochiri, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4030",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huarochiri",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lahuaytambo huarochiri",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Lahuaytambo, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4031",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lahuaytambo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "langa huarochiri",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Langa, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4032",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Langa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "laraos huarochiri",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Laraos, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4033",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Laraos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mariatana huarochiri",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Mariatana, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4034",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mariatana",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "matucana huarochiri",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Matucana, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4022",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Matucana",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ricardo palma huarochiri",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "Ricardo Palma, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4035",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ricardo Palma",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san andres de tupicocha huarochiri",
                "codigo_ubigeo": "15",
                "etiqueta_ubigeo": "San Andres de Tupicocha, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4036",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Andres de Tupicocha",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san antonio huarochiri",
                "codigo_ubigeo": "16",
                "etiqueta_ubigeo": "San Antonio, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4037",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Antonio",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san bartolome huarochiri",
                "codigo_ubigeo": "17",
                "etiqueta_ubigeo": "San Bartolome, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4038",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Bartolome",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san damian huarochiri",
                "codigo_ubigeo": "18",
                "etiqueta_ubigeo": "San Damian, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4039",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Damian",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san juan de iris huarochiri",
                "codigo_ubigeo": "19",
                "etiqueta_ubigeo": "San Juan de Iris, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4040",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Juan de Iris",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san juan de tantaranche huarochiri",
                "codigo_ubigeo": "20",
                "etiqueta_ubigeo": "San Juan de Tantaranche, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4041",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Juan de Tantaranche",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san lorenzo de quinti huarochiri",
                "codigo_ubigeo": "21",
                "etiqueta_ubigeo": "San Lorenzo de Quinti, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4042",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Lorenzo de Quinti",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san mateo huarochiri",
                "codigo_ubigeo": "22",
                "etiqueta_ubigeo": "San Mateo, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4043",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Mateo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san mateo de otao huarochiri",
                "codigo_ubigeo": "23",
                "etiqueta_ubigeo": "San Mateo de Otao, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4044",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Mateo de Otao",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san pedro de casta huarochiri",
                "codigo_ubigeo": "24",
                "etiqueta_ubigeo": "San Pedro de Casta, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4045",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Pedro de Casta",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san pedro de huancayre huarochiri",
                "codigo_ubigeo": "25",
                "etiqueta_ubigeo": "San Pedro de Huancayre, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4046",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Pedro de Huancayre",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sangallaya huarochiri",
                "codigo_ubigeo": "26",
                "etiqueta_ubigeo": "Sangallaya, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4047",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sangallaya",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa cruz de cocachacra huarochiri",
                "codigo_ubigeo": "27",
                "etiqueta_ubigeo": "Santa Cruz de Cocachacra, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4048",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Cruz de Cocachacra",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa eulalia huarochiri",
                "codigo_ubigeo": "28",
                "etiqueta_ubigeo": "Santa Eulalia, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4049",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Eulalia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santiago de anchucaya huarochiri",
                "codigo_ubigeo": "29",
                "etiqueta_ubigeo": "Santiago de Anchucaya, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4050",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santiago de Anchucaya",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santiago de tuna huarochiri",
                "codigo_ubigeo": "30",
                "etiqueta_ubigeo": "Santiago de Tuna, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4051",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santiago de Tuna",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santo domingo de los olleros huarochiri",
                "codigo_ubigeo": "31",
                "etiqueta_ubigeo": "Santo Domingo de los Olleros, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4052",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santo Domingo de los Olleros",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "surco huarochiri",
                "codigo_ubigeo": "32",
                "etiqueta_ubigeo": "Surco, Huarochiri",
                "id_padre_ubigeo": "4021",
                "id_ubigeo": "4053",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Surco",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4054": [
            {
                "buscador_ubigeo": "ambar huaura",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Ambar, Huaura",
                "id_padre_ubigeo": "4054",
                "id_ubigeo": "4056",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ambar",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "caleta de carquin huaura",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Caleta de Carquin, Huaura",
                "id_padre_ubigeo": "4054",
                "id_ubigeo": "4057",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Caleta de Carquin",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "checras huaura",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Checras, Huaura",
                "id_padre_ubigeo": "4054",
                "id_ubigeo": "4058",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Checras",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huacho huaura",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Huacho, Huaura",
                "id_padre_ubigeo": "4054",
                "id_ubigeo": "4055",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huacho",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "hualmay huaura",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Hualmay, Huaura",
                "id_padre_ubigeo": "4054",
                "id_ubigeo": "4059",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Hualmay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huaura huaura",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Huaura, Huaura",
                "id_padre_ubigeo": "4054",
                "id_ubigeo": "4060",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huaura",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "leoncio prado huaura",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Leoncio Prado, Huaura",
                "id_padre_ubigeo": "4054",
                "id_ubigeo": "4061",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Leoncio Prado",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "paccho huaura",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Paccho, Huaura",
                "id_padre_ubigeo": "4054",
                "id_ubigeo": "4062",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Paccho",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa leonor huaura",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Santa Leonor, Huaura",
                "id_padre_ubigeo": "4054",
                "id_ubigeo": "4063",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Leonor",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa maria huaura",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Santa Maria, Huaura",
                "id_padre_ubigeo": "4054",
                "id_ubigeo": "4064",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Maria",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sayan huaura",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Sayan, Huaura",
                "id_padre_ubigeo": "4054",
                "id_ubigeo": "4065",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sayan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "vegueta huaura",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Vegueta, Huaura",
                "id_padre_ubigeo": "4054",
                "id_ubigeo": "4066",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Vegueta",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4067": [
            {
                "buscador_ubigeo": "andajes oyon",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Andajes, Oyon",
                "id_padre_ubigeo": "4067",
                "id_ubigeo": "4069",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Andajes",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "caujul oyon",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Caujul, Oyon",
                "id_padre_ubigeo": "4067",
                "id_ubigeo": "4070",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Caujul",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cochamarca oyon",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Cochamarca, Oyon",
                "id_padre_ubigeo": "4067",
                "id_ubigeo": "4071",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cochamarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "navan oyon",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Navan, Oyon",
                "id_padre_ubigeo": "4067",
                "id_ubigeo": "4072",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Navan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "oyon oyon",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Oyon, Oyon",
                "id_padre_ubigeo": "4067",
                "id_ubigeo": "4068",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Oyon",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pachangara oyon",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Pachangara, Oyon",
                "id_padre_ubigeo": "4067",
                "id_ubigeo": "4073",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pachangara",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4074": [
            {
                "buscador_ubigeo": "alis yauyos",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Alis, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4076",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Alis",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ayauca yauyos",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Ayauca, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4077",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ayauca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ayaviri yauyos",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Ayaviri, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4078",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ayaviri",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "azangaro yauyos",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Azangaro, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4079",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Azangaro",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cacra yauyos",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Cacra, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4080",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cacra",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "carania yauyos",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Carania, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4081",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Carania",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "catahuasi yauyos",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Catahuasi, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4082",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Catahuasi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chocos yauyos",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Chocos, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4083",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chocos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cochas yauyos",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Cochas, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4084",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cochas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "colonia yauyos",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Colonia, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4085",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Colonia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "hongos yauyos",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Hongos, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4086",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Hongos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huampara yauyos",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Huampara, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4087",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huampara",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huancaya yauyos",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "Huancaya, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4088",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huancaya",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huangascar yauyos",
                "codigo_ubigeo": "15",
                "etiqueta_ubigeo": "Huangascar, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4089",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huangascar",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huantan yauyos",
                "codigo_ubigeo": "16",
                "etiqueta_ubigeo": "Huantan, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4090",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huantan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huaqec yauyos",
                "codigo_ubigeo": "17",
                "etiqueta_ubigeo": "Huaqec, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4091",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huaqec",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "laraos yauyos",
                "codigo_ubigeo": "18",
                "etiqueta_ubigeo": "Laraos, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4092",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Laraos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lincha yauyos",
                "codigo_ubigeo": "19",
                "etiqueta_ubigeo": "Lincha, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4093",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lincha",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "madean yauyos",
                "codigo_ubigeo": "20",
                "etiqueta_ubigeo": "Madean, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4094",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Madean",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "miraflores yauyos",
                "codigo_ubigeo": "21",
                "etiqueta_ubigeo": "Miraflores, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4095",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Miraflores",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "omas yauyos",
                "codigo_ubigeo": "22",
                "etiqueta_ubigeo": "Omas, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4096",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Omas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "putinza yauyos",
                "codigo_ubigeo": "23",
                "etiqueta_ubigeo": "Putinza, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4097",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Putinza",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "quinches yauyos",
                "codigo_ubigeo": "24",
                "etiqueta_ubigeo": "Quinches, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4098",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Quinches",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "quinocay yauyos",
                "codigo_ubigeo": "25",
                "etiqueta_ubigeo": "Quinocay, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4099",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Quinocay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san joaquin yauyos",
                "codigo_ubigeo": "26",
                "etiqueta_ubigeo": "San Joaquin, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4100",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Joaquin",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san pedro de pilas yauyos",
                "codigo_ubigeo": "27",
                "etiqueta_ubigeo": "San Pedro de Pilas, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4101",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Pedro de Pilas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tanta yauyos",
                "codigo_ubigeo": "28",
                "etiqueta_ubigeo": "Tanta, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4102",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tanta",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tauripampa yauyos",
                "codigo_ubigeo": "29",
                "etiqueta_ubigeo": "Tauripampa, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4103",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tauripampa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tomas yauyos",
                "codigo_ubigeo": "30",
                "etiqueta_ubigeo": "Tomas, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4104",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tomas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tupe yauyos",
                "codigo_ubigeo": "31",
                "etiqueta_ubigeo": "Tupe, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4105",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tupe",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "viqac yauyos",
                "codigo_ubigeo": "32",
                "etiqueta_ubigeo": "Viqac, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4106",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Viqac",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "vitis yauyos",
                "codigo_ubigeo": "33",
                "etiqueta_ubigeo": "Vitis, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4107",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Vitis",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yauyos yauyos",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Yauyos, Yauyos",
                "id_padre_ubigeo": "4074",
                "id_ubigeo": "4075",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yauyos",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4109": [
            {
                "buscador_ubigeo": "alto nanay maynas",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Alto Nanay, Maynas",
                "id_padre_ubigeo": "4109",
                "id_ubigeo": "4111",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Alto Nanay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "belén maynas",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Belén, Maynas",
                "id_padre_ubigeo": "4109",
                "id_ubigeo": "4121",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Belén",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "fernando lores maynas",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Fernando Lores, Maynas",
                "id_padre_ubigeo": "4109",
                "id_ubigeo": "4112",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Fernando Lores",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "indiana maynas",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Indiana, Maynas",
                "id_padre_ubigeo": "4109",
                "id_ubigeo": "4113",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Indiana",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "iquitos maynas",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Iquitos, Maynas",
                "id_padre_ubigeo": "4109",
                "id_ubigeo": "4110",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Iquitos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "las amazonas maynas",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Las Amazonas, Maynas",
                "id_padre_ubigeo": "4109",
                "id_ubigeo": "4114",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Las Amazonas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mazan maynas",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Mazan, Maynas",
                "id_padre_ubigeo": "4109",
                "id_ubigeo": "4115",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mazan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "napo maynas",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Napo, Maynas",
                "id_padre_ubigeo": "4109",
                "id_ubigeo": "4116",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Napo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "punchana maynas",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Punchana, Maynas",
                "id_padre_ubigeo": "4109",
                "id_ubigeo": "4117",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Punchana",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "putumayo maynas",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Putumayo, Maynas",
                "id_padre_ubigeo": "4109",
                "id_ubigeo": "4118",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Putumayo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san juan bautista maynas",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "San Juan Bautista, Maynas",
                "id_padre_ubigeo": "4109",
                "id_ubigeo": "4122",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Juan Bautista",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "torres causana maynas",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Torres Causana, Maynas",
                "id_padre_ubigeo": "4109",
                "id_ubigeo": "4119",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Torres Causana",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yaquerana maynas",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Yaquerana, Maynas",
                "id_padre_ubigeo": "4109",
                "id_ubigeo": "4120",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yaquerana",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4123": [
            {
                "buscador_ubigeo": "balsapuerto alto amazonas",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Balsapuerto, Alto Amazonas",
                "id_padre_ubigeo": "4123",
                "id_ubigeo": "4125",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Balsapuerto",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "barranca alto amazonas",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Barranca, Alto Amazonas",
                "id_padre_ubigeo": "4123",
                "id_ubigeo": "4126",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Barranca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cahuapanas alto amazonas",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Cahuapanas, Alto Amazonas",
                "id_padre_ubigeo": "4123",
                "id_ubigeo": "4127",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cahuapanas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jeberos alto amazonas",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Jeberos, Alto Amazonas",
                "id_padre_ubigeo": "4123",
                "id_ubigeo": "4128",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jeberos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lagunas alto amazonas",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Lagunas, Alto Amazonas",
                "id_padre_ubigeo": "4123",
                "id_ubigeo": "4129",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lagunas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "manseriche alto amazonas",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Manseriche, Alto Amazonas",
                "id_padre_ubigeo": "4123",
                "id_ubigeo": "4130",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Manseriche",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "morona alto amazonas",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Morona, Alto Amazonas",
                "id_padre_ubigeo": "4123",
                "id_ubigeo": "4131",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Morona",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pastaza alto amazonas",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Pastaza, Alto Amazonas",
                "id_padre_ubigeo": "4123",
                "id_ubigeo": "4132",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pastaza",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa cruz alto amazonas",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Santa Cruz, Alto Amazonas",
                "id_padre_ubigeo": "4123",
                "id_ubigeo": "4133",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Cruz",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "teniente cesar lopez rojas alto amazonas",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Teniente Cesar Lopez Rojas, Alto Amazonas",
                "id_padre_ubigeo": "4123",
                "id_ubigeo": "4134",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Teniente Cesar Lopez Rojas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yurimaguas alto amazonas",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Yurimaguas, Alto Amazonas",
                "id_padre_ubigeo": "4123",
                "id_ubigeo": "4124",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yurimaguas",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4135": [
            {
                "buscador_ubigeo": "nauta loreto",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Nauta, Loreto",
                "id_padre_ubigeo": "4135",
                "id_ubigeo": "4136",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Nauta",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "parinari loreto",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Parinari, Loreto",
                "id_padre_ubigeo": "4135",
                "id_ubigeo": "4137",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Parinari",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tigre loreto",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Tigre, Loreto",
                "id_padre_ubigeo": "4135",
                "id_ubigeo": "4138",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tigre",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "trompeteros loreto",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Trompeteros, Loreto",
                "id_padre_ubigeo": "4135",
                "id_ubigeo": "4139",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Trompeteros",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "urarinas loreto",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Urarinas, Loreto",
                "id_padre_ubigeo": "4135",
                "id_ubigeo": "4140",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Urarinas",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4141": [
            {
                "buscador_ubigeo": "pebas mariscal ramon castilla",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Pebas, Mariscal Ramon Castilla",
                "id_padre_ubigeo": "4141",
                "id_ubigeo": "4143",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pebas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ramon castilla mariscal ramon castilla",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Ramon Castilla, Mariscal Ramon Castilla",
                "id_padre_ubigeo": "4141",
                "id_ubigeo": "4142",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ramon Castilla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san pablo mariscal ramon castilla",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "San Pablo, Mariscal Ramon Castilla",
                "id_padre_ubigeo": "4141",
                "id_ubigeo": "4145",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Pablo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yavari mariscal ramon castilla",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Yavari, Mariscal Ramon Castilla",
                "id_padre_ubigeo": "4141",
                "id_ubigeo": "4144",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yavari",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4146": [
            {
                "buscador_ubigeo": "alto tapiche requena",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Alto Tapiche, Requena",
                "id_padre_ubigeo": "4146",
                "id_ubigeo": "4148",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Alto Tapiche",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "capelo requena",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Capelo, Requena",
                "id_padre_ubigeo": "4146",
                "id_ubigeo": "4149",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Capelo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "emilio san martin requena",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Emilio San Martin, Requena",
                "id_padre_ubigeo": "4146",
                "id_ubigeo": "4150",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Emilio San Martin",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jenaro herrera requena",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Jenaro Herrera, Requena",
                "id_padre_ubigeo": "4146",
                "id_ubigeo": "4156",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jenaro Herrera",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "maquia requena",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Maquia, Requena",
                "id_padre_ubigeo": "4146",
                "id_ubigeo": "4151",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Maquia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "puinahua requena",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Puinahua, Requena",
                "id_padre_ubigeo": "4146",
                "id_ubigeo": "4152",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Puinahua",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "requena requena",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Requena, Requena",
                "id_padre_ubigeo": "4146",
                "id_ubigeo": "4147",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Requena",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "saquena requena",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Saquena, Requena",
                "id_padre_ubigeo": "4146",
                "id_ubigeo": "4153",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Saquena",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "soplin requena",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Soplin, Requena",
                "id_padre_ubigeo": "4146",
                "id_ubigeo": "4154",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Soplin",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tapiche requena",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Tapiche, Requena",
                "id_padre_ubigeo": "4146",
                "id_ubigeo": "4155",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tapiche",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yaquerana requena",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Yaquerana, Requena",
                "id_padre_ubigeo": "4146",
                "id_ubigeo": "4157",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yaquerana",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4158": [
            {
                "buscador_ubigeo": "contamana ucayali",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Contamana, Ucayali",
                "id_padre_ubigeo": "4158",
                "id_ubigeo": "4159",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Contamana",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "inahuaya ucayali",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Inahuaya, Ucayali",
                "id_padre_ubigeo": "4158",
                "id_ubigeo": "4160",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Inahuaya",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "padre marquez ucayali",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Padre Marquez, Ucayali",
                "id_padre_ubigeo": "4158",
                "id_ubigeo": "4161",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Padre Marquez",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pampa hermosa ucayali",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Pampa Hermosa, Ucayali",
                "id_padre_ubigeo": "4158",
                "id_ubigeo": "4162",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pampa Hermosa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sarayacu ucayali",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Sarayacu, Ucayali",
                "id_padre_ubigeo": "4158",
                "id_ubigeo": "4163",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sarayacu",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "vargas guerra ucayali",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Vargas Guerra, Ucayali",
                "id_padre_ubigeo": "4158",
                "id_ubigeo": "4164",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Vargas Guerra",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4166": [
            {
                "buscador_ubigeo": "inambari tambopata",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Inambari, Tambopata",
                "id_padre_ubigeo": "4166",
                "id_ubigeo": "4168",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Inambari",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "laberinto tambopata",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Laberinto, Tambopata",
                "id_padre_ubigeo": "4166",
                "id_ubigeo": "4170",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Laberinto",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "las piedras tambopata",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Las Piedras, Tambopata",
                "id_padre_ubigeo": "4166",
                "id_ubigeo": "4169",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Las Piedras",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tambopata tambopata",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Tambopata, Tambopata",
                "id_padre_ubigeo": "4166",
                "id_ubigeo": "4167",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tambopata",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4171": [
            {
                "buscador_ubigeo": "fitzcarrald manu",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Fitzcarrald, Manu",
                "id_padre_ubigeo": "4171",
                "id_ubigeo": "4173",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Fitzcarrald",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huepetuhe manu",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Huepetuhe, Manu",
                "id_padre_ubigeo": "4171",
                "id_ubigeo": "4175",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huepetuhe",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "madre de dios manu",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Madre de Dios, Manu",
                "id_padre_ubigeo": "4171",
                "id_ubigeo": "4174",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Madre de Dios",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "manu manu",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Manu, Manu",
                "id_padre_ubigeo": "4171",
                "id_ubigeo": "4172",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Manu",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4176": [
            {
                "buscador_ubigeo": "iberia tahuamanu",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Iberia, Tahuamanu",
                "id_padre_ubigeo": "4176",
                "id_ubigeo": "4178",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Iberia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "iqapari tahuamanu",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Iqapari, Tahuamanu",
                "id_padre_ubigeo": "4176",
                "id_ubigeo": "4177",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Iqapari",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tahuamanu tahuamanu",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Tahuamanu, Tahuamanu",
                "id_padre_ubigeo": "4176",
                "id_ubigeo": "4179",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tahuamanu",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4181": [
            {
                "buscador_ubigeo": "carumas mariscal nieto",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Carumas, Mariscal Nieto",
                "id_padre_ubigeo": "4181",
                "id_ubigeo": "4183",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Carumas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cuchumbaya mariscal nieto",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Cuchumbaya, Mariscal Nieto",
                "id_padre_ubigeo": "4181",
                "id_ubigeo": "4184",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cuchumbaya",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "moquegua mariscal nieto",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Moquegua, Mariscal Nieto",
                "id_padre_ubigeo": "4181",
                "id_ubigeo": "4182",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Moquegua",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "samegua mariscal nieto",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Samegua, Mariscal Nieto",
                "id_padre_ubigeo": "4181",
                "id_ubigeo": "4185",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Samegua",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san cristobal mariscal nieto",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "San Cristobal, Mariscal Nieto",
                "id_padre_ubigeo": "4181",
                "id_ubigeo": "4186",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Cristobal",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "torata mariscal nieto",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Torata, Mariscal Nieto",
                "id_padre_ubigeo": "4181",
                "id_ubigeo": "4187",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Torata",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4188": [
            {
                "buscador_ubigeo": "chojata general sanchez cerro",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Chojata, General Sanchez Cerro",
                "id_padre_ubigeo": "4188",
                "id_ubigeo": "4190",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chojata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "coalaque general sanchez cerro",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Coalaque, General Sanchez Cerro",
                "id_padre_ubigeo": "4188",
                "id_ubigeo": "4191",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Coalaque",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ichuqa general sanchez cerro",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Ichuqa, General Sanchez Cerro",
                "id_padre_ubigeo": "4188",
                "id_ubigeo": "4192",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ichuqa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la capilla general sanchez cerro",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "La Capilla, General Sanchez Cerro",
                "id_padre_ubigeo": "4188",
                "id_ubigeo": "4193",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Capilla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lloque general sanchez cerro",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Lloque, General Sanchez Cerro",
                "id_padre_ubigeo": "4188",
                "id_ubigeo": "4194",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lloque",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "matalaque general sanchez cerro",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Matalaque, General Sanchez Cerro",
                "id_padre_ubigeo": "4188",
                "id_ubigeo": "4195",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Matalaque",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "omate general sanchez cerro",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Omate, General Sanchez Cerro",
                "id_padre_ubigeo": "4188",
                "id_ubigeo": "4189",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Omate",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "puquina general sanchez cerro",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Puquina, General Sanchez Cerro",
                "id_padre_ubigeo": "4188",
                "id_ubigeo": "4196",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Puquina",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "quinistaquillas general sanchez cerro",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Quinistaquillas, General Sanchez Cerro",
                "id_padre_ubigeo": "4188",
                "id_ubigeo": "4197",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Quinistaquillas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ubinas general sanchez cerro",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Ubinas, General Sanchez Cerro",
                "id_padre_ubigeo": "4188",
                "id_ubigeo": "4198",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ubinas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yunga general sanchez cerro",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Yunga, General Sanchez Cerro",
                "id_padre_ubigeo": "4188",
                "id_ubigeo": "4199",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yunga",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4200": [
            {
                "buscador_ubigeo": "el algarrobal ilo",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "El Algarrobal, Ilo",
                "id_padre_ubigeo": "4200",
                "id_ubigeo": "4202",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "El Algarrobal",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ilo ilo",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Ilo, Ilo",
                "id_padre_ubigeo": "4200",
                "id_ubigeo": "4201",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ilo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pacocha ilo",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Pacocha, Ilo",
                "id_padre_ubigeo": "4200",
                "id_ubigeo": "4203",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pacocha",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4205": [
            {
                "buscador_ubigeo": "chaupimarca pasco",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Chaupimarca, Pasco",
                "id_padre_ubigeo": "4205",
                "id_ubigeo": "4206",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chaupimarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huachon pasco",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Huachon, Pasco",
                "id_padre_ubigeo": "4205",
                "id_ubigeo": "4207",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huachon",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huariaca pasco",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Huariaca, Pasco",
                "id_padre_ubigeo": "4205",
                "id_ubigeo": "4208",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huariaca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huayllay pasco",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Huayllay, Pasco",
                "id_padre_ubigeo": "4205",
                "id_ubigeo": "4209",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huayllay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ninacaca pasco",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Ninacaca, Pasco",
                "id_padre_ubigeo": "4205",
                "id_ubigeo": "4210",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ninacaca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pallanchacra pasco",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Pallanchacra, Pasco",
                "id_padre_ubigeo": "4205",
                "id_ubigeo": "4211",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pallanchacra",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "paucartambo pasco",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Paucartambo, Pasco",
                "id_padre_ubigeo": "4205",
                "id_ubigeo": "4212",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Paucartambo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san fco.de asis de yarusyacan pasco",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "San Fco.De Asis de Yarusyacan, Pasco",
                "id_padre_ubigeo": "4205",
                "id_ubigeo": "4213",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Fco.De Asis de Yarusyacan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "simon bolivar pasco",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Simon Bolivar, Pasco",
                "id_padre_ubigeo": "4205",
                "id_ubigeo": "4214",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Simon Bolivar",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ticlacayan pasco",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Ticlacayan, Pasco",
                "id_padre_ubigeo": "4205",
                "id_ubigeo": "4215",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ticlacayan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tinyahuarco pasco",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Tinyahuarco, Pasco",
                "id_padre_ubigeo": "4205",
                "id_ubigeo": "4216",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tinyahuarco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "vicco pasco",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Vicco, Pasco",
                "id_padre_ubigeo": "4205",
                "id_ubigeo": "4217",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Vicco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yanacancha pasco",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Yanacancha, Pasco",
                "id_padre_ubigeo": "4205",
                "id_ubigeo": "4218",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yanacancha",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4219": [
            {
                "buscador_ubigeo": "chacayan daniel alcides carrion",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Chacayan, Daniel Alcides Carrion",
                "id_padre_ubigeo": "4219",
                "id_ubigeo": "4221",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chacayan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "goyllarisquizga daniel alcides carrion",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Goyllarisquizga, Daniel Alcides Carrion",
                "id_padre_ubigeo": "4219",
                "id_ubigeo": "4222",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Goyllarisquizga",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "paucar daniel alcides carrion",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Paucar, Daniel Alcides Carrion",
                "id_padre_ubigeo": "4219",
                "id_ubigeo": "4223",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Paucar",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san pedro de pillao daniel alcides carrion",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "San Pedro de Pillao, Daniel Alcides Carrion",
                "id_padre_ubigeo": "4219",
                "id_ubigeo": "4224",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Pedro de Pillao",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa ana de tusi daniel alcides carrion",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Santa Ana de Tusi, Daniel Alcides Carrion",
                "id_padre_ubigeo": "4219",
                "id_ubigeo": "4225",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Ana de Tusi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tapuc daniel alcides carrion",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Tapuc, Daniel Alcides Carrion",
                "id_padre_ubigeo": "4219",
                "id_ubigeo": "4226",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tapuc",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "vilcabamba daniel alcides carrion",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Vilcabamba, Daniel Alcides Carrion",
                "id_padre_ubigeo": "4219",
                "id_ubigeo": "4227",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Vilcabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yanahuanca daniel alcides carrion",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Yanahuanca, Daniel Alcides Carrion",
                "id_padre_ubigeo": "4219",
                "id_ubigeo": "4220",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yanahuanca",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4228": [
            {
                "buscador_ubigeo": "chontabamba oxapampa",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Chontabamba, Oxapampa",
                "id_padre_ubigeo": "4228",
                "id_ubigeo": "4230",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chontabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huancabamba oxapampa",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Huancabamba, Oxapampa",
                "id_padre_ubigeo": "4228",
                "id_ubigeo": "4231",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huancabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "oxapampa oxapampa",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Oxapampa, Oxapampa",
                "id_padre_ubigeo": "4228",
                "id_ubigeo": "4229",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Oxapampa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "palcazu oxapampa",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Palcazu, Oxapampa",
                "id_padre_ubigeo": "4228",
                "id_ubigeo": "4232",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Palcazu",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pozuzo oxapampa",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Pozuzo, Oxapampa",
                "id_padre_ubigeo": "4228",
                "id_ubigeo": "4233",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pozuzo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "puerto bermudez oxapampa",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Puerto Bermudez, Oxapampa",
                "id_padre_ubigeo": "4228",
                "id_ubigeo": "4234",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Puerto Bermudez",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "villa rica oxapampa",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Villa Rica, Oxapampa",
                "id_padre_ubigeo": "4228",
                "id_ubigeo": "4235",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Villa Rica",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4237": [
            {
                "buscador_ubigeo": "castilla piura",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Castilla, Piura",
                "id_padre_ubigeo": "4237",
                "id_ubigeo": "4239",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Castilla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "catacaos piura",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Catacaos, Piura",
                "id_padre_ubigeo": "4237",
                "id_ubigeo": "4240",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Catacaos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cura mori piura",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Cura Mori, Piura",
                "id_padre_ubigeo": "4237",
                "id_ubigeo": "4241",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cura Mori",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "el tallan piura",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "El Tallan, Piura",
                "id_padre_ubigeo": "4237",
                "id_ubigeo": "4242",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "El Tallan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la arena piura",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "La Arena, Piura",
                "id_padre_ubigeo": "4237",
                "id_ubigeo": "4243",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Arena",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la union piura",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "La Union, Piura",
                "id_padre_ubigeo": "4237",
                "id_ubigeo": "4244",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Union",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "las lomas piura",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Las Lomas, Piura",
                "id_padre_ubigeo": "4237",
                "id_ubigeo": "4245",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Las Lomas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "piura piura",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Piura, Piura",
                "id_padre_ubigeo": "4237",
                "id_ubigeo": "4238",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Piura",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tambo grande piura",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "Tambo Grande, Piura",
                "id_padre_ubigeo": "4237",
                "id_ubigeo": "4246",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tambo Grande",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4247": [
            {
                "buscador_ubigeo": "ayabaca ayabaca",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Ayabaca, Ayabaca",
                "id_padre_ubigeo": "4247",
                "id_ubigeo": "4248",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ayabaca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "frias ayabaca",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Frias, Ayabaca",
                "id_padre_ubigeo": "4247",
                "id_ubigeo": "4249",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Frias",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jilili ayabaca",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Jilili, Ayabaca",
                "id_padre_ubigeo": "4247",
                "id_ubigeo": "4250",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jilili",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lagunas ayabaca",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Lagunas, Ayabaca",
                "id_padre_ubigeo": "4247",
                "id_ubigeo": "4251",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lagunas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "montero ayabaca",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Montero, Ayabaca",
                "id_padre_ubigeo": "4247",
                "id_ubigeo": "4252",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Montero",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pacaipampa ayabaca",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Pacaipampa, Ayabaca",
                "id_padre_ubigeo": "4247",
                "id_ubigeo": "4253",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pacaipampa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "paimas ayabaca",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Paimas, Ayabaca",
                "id_padre_ubigeo": "4247",
                "id_ubigeo": "4254",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Paimas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sapillica ayabaca",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Sapillica, Ayabaca",
                "id_padre_ubigeo": "4247",
                "id_ubigeo": "4255",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sapillica",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sicchez ayabaca",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Sicchez, Ayabaca",
                "id_padre_ubigeo": "4247",
                "id_ubigeo": "4256",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sicchez",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "suyo ayabaca",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Suyo, Ayabaca",
                "id_padre_ubigeo": "4247",
                "id_ubigeo": "4257",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Suyo",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4258": [
            {
                "buscador_ubigeo": "canchaque huancabamba",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Canchaque, Huancabamba",
                "id_padre_ubigeo": "4258",
                "id_ubigeo": "4260",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Canchaque",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "el carmen de la frontera huancabamba",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "El Carmen de la Frontera, Huancabamba",
                "id_padre_ubigeo": "4258",
                "id_ubigeo": "4261",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "El Carmen de la Frontera",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huancabamba huancabamba",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Huancabamba, Huancabamba",
                "id_padre_ubigeo": "4258",
                "id_ubigeo": "4259",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huancabamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huarmaca huancabamba",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Huarmaca, Huancabamba",
                "id_padre_ubigeo": "4258",
                "id_ubigeo": "4262",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huarmaca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lalaquiz huancabamba",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Lalaquiz, Huancabamba",
                "id_padre_ubigeo": "4258",
                "id_ubigeo": "4263",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lalaquiz",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san miguel de el faique huancabamba",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "San Miguel de El Faique, Huancabamba",
                "id_padre_ubigeo": "4258",
                "id_ubigeo": "4264",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Miguel de El Faique",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sondor huancabamba",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Sondor, Huancabamba",
                "id_padre_ubigeo": "4258",
                "id_ubigeo": "4265",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sondor",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sondorillo huancabamba",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Sondorillo, Huancabamba",
                "id_padre_ubigeo": "4258",
                "id_ubigeo": "4266",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sondorillo",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4267": [
            {
                "buscador_ubigeo": "buenos aires morropon",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Buenos Aires, Morropon",
                "id_padre_ubigeo": "4267",
                "id_ubigeo": "4269",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Buenos Aires",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chalaco morropon",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Chalaco, Morropon",
                "id_padre_ubigeo": "4267",
                "id_ubigeo": "4270",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chalaco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chulucanas morropon",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Chulucanas, Morropon",
                "id_padre_ubigeo": "4267",
                "id_ubigeo": "4268",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chulucanas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la matanza morropon",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "La Matanza, Morropon",
                "id_padre_ubigeo": "4267",
                "id_ubigeo": "4271",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Matanza",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "morropon morropon",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Morropon, Morropon",
                "id_padre_ubigeo": "4267",
                "id_ubigeo": "4272",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Morropon",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "salitral morropon",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Salitral, Morropon",
                "id_padre_ubigeo": "4267",
                "id_ubigeo": "4273",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Salitral",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san juan de bigote morropon",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "San Juan de Bigote, Morropon",
                "id_padre_ubigeo": "4267",
                "id_ubigeo": "4274",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Juan de Bigote",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa catalina de mossa morropon",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Santa Catalina de Mossa, Morropon",
                "id_padre_ubigeo": "4267",
                "id_ubigeo": "4275",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Catalina de Mossa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santo domingo morropon",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Santo Domingo, Morropon",
                "id_padre_ubigeo": "4267",
                "id_ubigeo": "4276",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santo Domingo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yamango morropon",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Yamango, Morropon",
                "id_padre_ubigeo": "4267",
                "id_ubigeo": "4277",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yamango",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4278": [
            {
                "buscador_ubigeo": "amotape paita",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Amotape, Paita",
                "id_padre_ubigeo": "4278",
                "id_ubigeo": "4280",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Amotape",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "arenal paita",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Arenal, Paita",
                "id_padre_ubigeo": "4278",
                "id_ubigeo": "4281",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Arenal",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "colan paita",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Colan, Paita",
                "id_padre_ubigeo": "4278",
                "id_ubigeo": "4282",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Colan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la huaca paita",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "La Huaca, Paita",
                "id_padre_ubigeo": "4278",
                "id_ubigeo": "4283",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Huaca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "paita paita",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Paita, Paita",
                "id_padre_ubigeo": "4278",
                "id_ubigeo": "4279",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Paita",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tamarindo paita",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Tamarindo, Paita",
                "id_padre_ubigeo": "4278",
                "id_ubigeo": "4284",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tamarindo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "vichayal paita",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Vichayal, Paita",
                "id_padre_ubigeo": "4278",
                "id_ubigeo": "4285",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Vichayal",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4286": [
            {
                "buscador_ubigeo": "bellavista sullana",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Bellavista, Sullana",
                "id_padre_ubigeo": "4286",
                "id_ubigeo": "4288",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Bellavista",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ignacio escudero sullana",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Ignacio Escudero, Sullana",
                "id_padre_ubigeo": "4286",
                "id_ubigeo": "4289",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ignacio Escudero",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lancones sullana",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Lancones, Sullana",
                "id_padre_ubigeo": "4286",
                "id_ubigeo": "4290",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lancones",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "marcavelica sullana",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Marcavelica, Sullana",
                "id_padre_ubigeo": "4286",
                "id_ubigeo": "4291",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Marcavelica",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "miguel checa sullana",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Miguel Checa, Sullana",
                "id_padre_ubigeo": "4286",
                "id_ubigeo": "4292",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Miguel Checa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "querecotillo sullana",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Querecotillo, Sullana",
                "id_padre_ubigeo": "4286",
                "id_ubigeo": "4293",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Querecotillo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "salitral sullana",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Salitral, Sullana",
                "id_padre_ubigeo": "4286",
                "id_ubigeo": "4294",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Salitral",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sullana sullana",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Sullana, Sullana",
                "id_padre_ubigeo": "4286",
                "id_ubigeo": "4287",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sullana",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4295": [
            {
                "buscador_ubigeo": "el alto talara",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "El Alto, Talara",
                "id_padre_ubigeo": "4295",
                "id_ubigeo": "4297",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "El Alto",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la brea talara",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "La Brea, Talara",
                "id_padre_ubigeo": "4295",
                "id_ubigeo": "4298",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Brea",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lobitos talara",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Lobitos, Talara",
                "id_padre_ubigeo": "4295",
                "id_ubigeo": "4299",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lobitos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "los organos talara",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Los Organos, Talara",
                "id_padre_ubigeo": "4295",
                "id_ubigeo": "4300",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Los Organos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "mancora talara",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Mancora, Talara",
                "id_padre_ubigeo": "4295",
                "id_ubigeo": "4301",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Mancora",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pariqas talara",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Pariqas, Talara",
                "id_padre_ubigeo": "4295",
                "id_ubigeo": "4296",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pariqas",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4302": [
            {
                "buscador_ubigeo": "bellavista de la union sechura",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Bellavista de la Union, Sechura",
                "id_padre_ubigeo": "4302",
                "id_ubigeo": "4304",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Bellavista de la Union",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "bernal sechura",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Bernal, Sechura",
                "id_padre_ubigeo": "4302",
                "id_ubigeo": "4305",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Bernal",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cristo nos valga sechura",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Cristo Nos Valga, Sechura",
                "id_padre_ubigeo": "4302",
                "id_ubigeo": "4306",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cristo Nos Valga",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "rinconada llicuar sechura",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Rinconada Llicuar, Sechura",
                "id_padre_ubigeo": "4302",
                "id_ubigeo": "4308",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Rinconada Llicuar",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sechura sechura",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Sechura, Sechura",
                "id_padre_ubigeo": "4302",
                "id_ubigeo": "4303",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sechura",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "vice sechura",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Vice, Sechura",
                "id_padre_ubigeo": "4302",
                "id_ubigeo": "4307",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Vice",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4310": [
            {
                "buscador_ubigeo": "acora puno",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Acora, Puno",
                "id_padre_ubigeo": "4310",
                "id_ubigeo": "4312",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Acora",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "amantani puno",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Amantani, Puno",
                "id_padre_ubigeo": "4310",
                "id_ubigeo": "4313",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Amantani",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "atuncolla puno",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Atuncolla, Puno",
                "id_padre_ubigeo": "4310",
                "id_ubigeo": "4314",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Atuncolla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "capachica puno",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Capachica, Puno",
                "id_padre_ubigeo": "4310",
                "id_ubigeo": "4315",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Capachica",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chucuito puno",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Chucuito, Puno",
                "id_padre_ubigeo": "4310",
                "id_ubigeo": "4316",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chucuito",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "coata puno",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Coata, Puno",
                "id_padre_ubigeo": "4310",
                "id_ubigeo": "4317",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Coata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huata puno",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Huata, Puno",
                "id_padre_ubigeo": "4310",
                "id_ubigeo": "4318",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "maqazo puno",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Maqazo, Puno",
                "id_padre_ubigeo": "4310",
                "id_ubigeo": "4319",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Maqazo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "paucarcolla puno",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Paucarcolla, Puno",
                "id_padre_ubigeo": "4310",
                "id_ubigeo": "4320",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Paucarcolla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pichacani puno",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Pichacani, Puno",
                "id_padre_ubigeo": "4310",
                "id_ubigeo": "4321",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pichacani",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "plateria puno",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "Plateria, Puno",
                "id_padre_ubigeo": "4310",
                "id_ubigeo": "4322",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Plateria",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "puno puno",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Puno, Puno",
                "id_padre_ubigeo": "4310",
                "id_ubigeo": "4311",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Puno",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san antonio puno",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "San Antonio, Puno",
                "id_padre_ubigeo": "4310",
                "id_ubigeo": "4323",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Antonio",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tiquillaca puno",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "Tiquillaca, Puno",
                "id_padre_ubigeo": "4310",
                "id_ubigeo": "4324",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tiquillaca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "vilque puno",
                "codigo_ubigeo": "15",
                "etiqueta_ubigeo": "Vilque, Puno",
                "id_padre_ubigeo": "4310",
                "id_ubigeo": "4325",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Vilque",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4326": [
            {
                "buscador_ubigeo": "achaya azangaro",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Achaya, Azangaro",
                "id_padre_ubigeo": "4326",
                "id_ubigeo": "4328",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Achaya",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "arapa azangaro",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Arapa, Azangaro",
                "id_padre_ubigeo": "4326",
                "id_ubigeo": "4329",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Arapa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "asillo azangaro",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Asillo, Azangaro",
                "id_padre_ubigeo": "4326",
                "id_ubigeo": "4330",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Asillo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "azangaro azangaro",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Azangaro, Azangaro",
                "id_padre_ubigeo": "4326",
                "id_ubigeo": "4327",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Azangaro",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "caminaca azangaro",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Caminaca, Azangaro",
                "id_padre_ubigeo": "4326",
                "id_ubigeo": "4331",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Caminaca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chupa azangaro",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Chupa, Azangaro",
                "id_padre_ubigeo": "4326",
                "id_ubigeo": "4332",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chupa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jose domingo choquehuanca azangaro",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Jose Domingo Choquehuanca, Azangaro",
                "id_padre_ubigeo": "4326",
                "id_ubigeo": "4333",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jose Domingo Choquehuanca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "muqani azangaro",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Muqani, Azangaro",
                "id_padre_ubigeo": "4326",
                "id_ubigeo": "4334",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Muqani",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "potoni azangaro",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Potoni, Azangaro",
                "id_padre_ubigeo": "4326",
                "id_ubigeo": "4335",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Potoni",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "saman azangaro",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Saman, Azangaro",
                "id_padre_ubigeo": "4326",
                "id_ubigeo": "4336",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Saman",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san anton azangaro",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "San Anton, Azangaro",
                "id_padre_ubigeo": "4326",
                "id_ubigeo": "4337",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Anton",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san jose azangaro",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "San Jose, Azangaro",
                "id_padre_ubigeo": "4326",
                "id_ubigeo": "4338",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Jose",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san juan de salinas azangaro",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "San Juan de Salinas, Azangaro",
                "id_padre_ubigeo": "4326",
                "id_ubigeo": "4339",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Juan de Salinas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santiago de pupuja azangaro",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "Santiago de Pupuja, Azangaro",
                "id_padre_ubigeo": "4326",
                "id_ubigeo": "4340",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santiago de Pupuja",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tirapata azangaro",
                "codigo_ubigeo": "15",
                "etiqueta_ubigeo": "Tirapata, Azangaro",
                "id_padre_ubigeo": "4326",
                "id_ubigeo": "4341",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tirapata",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4342": [
            {
                "buscador_ubigeo": "ajoyani carabaya",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Ajoyani, Carabaya",
                "id_padre_ubigeo": "4342",
                "id_ubigeo": "4344",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ajoyani",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ayapata carabaya",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Ayapata, Carabaya",
                "id_padre_ubigeo": "4342",
                "id_ubigeo": "4345",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ayapata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "coasa carabaya",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Coasa, Carabaya",
                "id_padre_ubigeo": "4342",
                "id_ubigeo": "4346",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Coasa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "corani carabaya",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Corani, Carabaya",
                "id_padre_ubigeo": "4342",
                "id_ubigeo": "4347",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Corani",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "crucero carabaya",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Crucero, Carabaya",
                "id_padre_ubigeo": "4342",
                "id_ubigeo": "4348",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Crucero",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ituata carabaya",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Ituata, Carabaya",
                "id_padre_ubigeo": "4342",
                "id_ubigeo": "4349",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ituata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "macusani carabaya",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Macusani, Carabaya",
                "id_padre_ubigeo": "4342",
                "id_ubigeo": "4343",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Macusani",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ollachea carabaya",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Ollachea, Carabaya",
                "id_padre_ubigeo": "4342",
                "id_ubigeo": "4350",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ollachea",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san gaban carabaya",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "San Gaban, Carabaya",
                "id_padre_ubigeo": "4342",
                "id_ubigeo": "4351",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Gaban",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "usicayos carabaya",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Usicayos, Carabaya",
                "id_padre_ubigeo": "4342",
                "id_ubigeo": "4352",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Usicayos",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4353": [
            {
                "buscador_ubigeo": "desaguadero chucuito",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Desaguadero, Chucuito",
                "id_padre_ubigeo": "4353",
                "id_ubigeo": "4355",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Desaguadero",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huacullani chucuito",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Huacullani, Chucuito",
                "id_padre_ubigeo": "4353",
                "id_ubigeo": "4356",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huacullani",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "juli chucuito",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Juli, Chucuito",
                "id_padre_ubigeo": "4353",
                "id_ubigeo": "4354",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Juli",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "kelluyo chucuito",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Kelluyo, Chucuito",
                "id_padre_ubigeo": "4353",
                "id_ubigeo": "4357",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Kelluyo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pisacoma chucuito",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Pisacoma, Chucuito",
                "id_padre_ubigeo": "4353",
                "id_ubigeo": "4358",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pisacoma",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pomata chucuito",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Pomata, Chucuito",
                "id_padre_ubigeo": "4353",
                "id_ubigeo": "4359",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pomata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "zepita chucuito",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Zepita, Chucuito",
                "id_padre_ubigeo": "4353",
                "id_ubigeo": "4360",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Zepita",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4361": [
            {
                "buscador_ubigeo": "capazo el collao",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Capazo, El Collao",
                "id_padre_ubigeo": "4361",
                "id_ubigeo": "4363",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Capazo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "conduriri el collao",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Conduriri, El Collao",
                "id_padre_ubigeo": "4361",
                "id_ubigeo": "4366",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Conduriri",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ilave el collao",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Ilave, El Collao",
                "id_padre_ubigeo": "4361",
                "id_ubigeo": "4362",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ilave",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pilcuyo el collao",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Pilcuyo, El Collao",
                "id_padre_ubigeo": "4361",
                "id_ubigeo": "4364",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pilcuyo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa rosa el collao",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Santa Rosa, El Collao",
                "id_padre_ubigeo": "4361",
                "id_ubigeo": "4365",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Rosa",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4367": [
            {
                "buscador_ubigeo": "cojata huancane",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Cojata, Huancane",
                "id_padre_ubigeo": "4367",
                "id_ubigeo": "4369",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cojata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huancane huancane",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Huancane, Huancane",
                "id_padre_ubigeo": "4367",
                "id_ubigeo": "4368",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huancane",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huatasani huancane",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Huatasani, Huancane",
                "id_padre_ubigeo": "4367",
                "id_ubigeo": "4370",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huatasani",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "inchupalla huancane",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Inchupalla, Huancane",
                "id_padre_ubigeo": "4367",
                "id_ubigeo": "4371",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Inchupalla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pusi huancane",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Pusi, Huancane",
                "id_padre_ubigeo": "4367",
                "id_ubigeo": "4372",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pusi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "rosaspata huancane",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Rosaspata, Huancane",
                "id_padre_ubigeo": "4367",
                "id_ubigeo": "4373",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Rosaspata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "taraco huancane",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Taraco, Huancane",
                "id_padre_ubigeo": "4367",
                "id_ubigeo": "4374",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Taraco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "vilque chico huancane",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Vilque Chico, Huancane",
                "id_padre_ubigeo": "4367",
                "id_ubigeo": "4375",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Vilque Chico",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4376": [
            {
                "buscador_ubigeo": "cabanilla lampa",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Cabanilla, Lampa",
                "id_padre_ubigeo": "4376",
                "id_ubigeo": "4378",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cabanilla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "calapuja lampa",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Calapuja, Lampa",
                "id_padre_ubigeo": "4376",
                "id_ubigeo": "4379",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Calapuja",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lampa lampa",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Lampa, Lampa",
                "id_padre_ubigeo": "4376",
                "id_ubigeo": "4377",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lampa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "nicasio lampa",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Nicasio, Lampa",
                "id_padre_ubigeo": "4376",
                "id_ubigeo": "4380",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Nicasio",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ocuviri lampa",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Ocuviri, Lampa",
                "id_padre_ubigeo": "4376",
                "id_ubigeo": "4381",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ocuviri",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "palca lampa",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Palca, Lampa",
                "id_padre_ubigeo": "4376",
                "id_ubigeo": "4382",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Palca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "paratia lampa",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Paratia, Lampa",
                "id_padre_ubigeo": "4376",
                "id_ubigeo": "4383",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Paratia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pucara lampa",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Pucara, Lampa",
                "id_padre_ubigeo": "4376",
                "id_ubigeo": "4384",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pucara",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa lucia lampa",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Santa Lucia, Lampa",
                "id_padre_ubigeo": "4376",
                "id_ubigeo": "4385",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Lucia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "vilavila lampa",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Vilavila, Lampa",
                "id_padre_ubigeo": "4376",
                "id_ubigeo": "4386",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Vilavila",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4387": [
            {
                "buscador_ubigeo": "antauta melgar",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Antauta, Melgar",
                "id_padre_ubigeo": "4387",
                "id_ubigeo": "4389",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Antauta",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ayaviri melgar",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Ayaviri, Melgar",
                "id_padre_ubigeo": "4387",
                "id_ubigeo": "4388",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ayaviri",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cupi melgar",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Cupi, Melgar",
                "id_padre_ubigeo": "4387",
                "id_ubigeo": "4390",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cupi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "llalli melgar",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Llalli, Melgar",
                "id_padre_ubigeo": "4387",
                "id_ubigeo": "4391",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Llalli",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "macari melgar",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Macari, Melgar",
                "id_padre_ubigeo": "4387",
                "id_ubigeo": "4392",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Macari",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "nuqoa melgar",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Nuqoa, Melgar",
                "id_padre_ubigeo": "4387",
                "id_ubigeo": "4393",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Nuqoa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "orurillo melgar",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Orurillo, Melgar",
                "id_padre_ubigeo": "4387",
                "id_ubigeo": "4394",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Orurillo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa rosa melgar",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Santa Rosa, Melgar",
                "id_padre_ubigeo": "4387",
                "id_ubigeo": "4395",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Rosa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "umachiri melgar",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Umachiri, Melgar",
                "id_padre_ubigeo": "4387",
                "id_ubigeo": "4396",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Umachiri",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4397": [
            {
                "buscador_ubigeo": "conima moho",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Conima, Moho",
                "id_padre_ubigeo": "4397",
                "id_ubigeo": "4399",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Conima",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huayrapata moho",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Huayrapata, Moho",
                "id_padre_ubigeo": "4397",
                "id_ubigeo": "4400",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huayrapata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "moho moho",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Moho, Moho",
                "id_padre_ubigeo": "4397",
                "id_ubigeo": "4398",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Moho",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tilali moho",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Tilali, Moho",
                "id_padre_ubigeo": "4397",
                "id_ubigeo": "4401",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tilali",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4402": [
            {
                "buscador_ubigeo": "ananea san antonio de putina",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Ananea, San Antonio de Putina",
                "id_padre_ubigeo": "4402",
                "id_ubigeo": "4404",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ananea",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pedro vilca apaza san antonio de putina",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Pedro Vilca Apaza, San Antonio de Putina",
                "id_padre_ubigeo": "4402",
                "id_ubigeo": "4405",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pedro Vilca Apaza",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "putina san antonio de putina",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Putina, San Antonio de Putina",
                "id_padre_ubigeo": "4402",
                "id_ubigeo": "4403",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Putina",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "quilcapuncu san antonio de putina",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Quilcapuncu, San Antonio de Putina",
                "id_padre_ubigeo": "4402",
                "id_ubigeo": "4406",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Quilcapuncu",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sina san antonio de putina",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Sina, San Antonio de Putina",
                "id_padre_ubigeo": "4402",
                "id_ubigeo": "4407",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sina",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4408": [
            {
                "buscador_ubigeo": "cabana san roman",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Cabana, San Roman",
                "id_padre_ubigeo": "4408",
                "id_ubigeo": "4410",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cabana",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cabanillas san roman",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Cabanillas, San Roman",
                "id_padre_ubigeo": "4408",
                "id_ubigeo": "4411",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cabanillas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "caracoto san roman",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Caracoto, San Roman",
                "id_padre_ubigeo": "4408",
                "id_ubigeo": "4412",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Caracoto",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "juliaca san roman",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Juliaca, San Roman",
                "id_padre_ubigeo": "4408",
                "id_ubigeo": "4409",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Juliaca",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4413": [
            {
                "buscador_ubigeo": "alto inambari sandia",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Alto Inambari, Sandia",
                "id_padre_ubigeo": "4413",
                "id_ubigeo": "4422",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Alto Inambari",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cuyocuyo sandia",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Cuyocuyo, Sandia",
                "id_padre_ubigeo": "4413",
                "id_ubigeo": "4415",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cuyocuyo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "limbani sandia",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Limbani, Sandia",
                "id_padre_ubigeo": "4413",
                "id_ubigeo": "4416",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Limbani",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "patambuco sandia",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Patambuco, Sandia",
                "id_padre_ubigeo": "4413",
                "id_ubigeo": "4417",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Patambuco",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "phara sandia",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Phara, Sandia",
                "id_padre_ubigeo": "4413",
                "id_ubigeo": "4418",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Phara",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "quiaca sandia",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Quiaca, Sandia",
                "id_padre_ubigeo": "4413",
                "id_ubigeo": "4419",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Quiaca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san juan del oro sandia",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "San Juan del Oro, Sandia",
                "id_padre_ubigeo": "4413",
                "id_ubigeo": "4420",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Juan del Oro",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sandia sandia",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Sandia, Sandia",
                "id_padre_ubigeo": "4413",
                "id_ubigeo": "4414",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sandia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yanahuaya sandia",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Yanahuaya, Sandia",
                "id_padre_ubigeo": "4413",
                "id_ubigeo": "4421",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yanahuaya",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4423": [
            {
                "buscador_ubigeo": "anapia yunguyo",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Anapia, Yunguyo",
                "id_padre_ubigeo": "4423",
                "id_ubigeo": "4425",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Anapia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "copani yunguyo",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Copani, Yunguyo",
                "id_padre_ubigeo": "4423",
                "id_ubigeo": "4426",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Copani",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cuturapi yunguyo",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Cuturapi, Yunguyo",
                "id_padre_ubigeo": "4423",
                "id_ubigeo": "4427",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cuturapi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ollaraya yunguyo",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Ollaraya, Yunguyo",
                "id_padre_ubigeo": "4423",
                "id_ubigeo": "4428",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ollaraya",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tinicachi yunguyo",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Tinicachi, Yunguyo",
                "id_padre_ubigeo": "4423",
                "id_ubigeo": "4429",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tinicachi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "unicachi yunguyo",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Unicachi, Yunguyo",
                "id_padre_ubigeo": "4423",
                "id_ubigeo": "4430",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Unicachi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yunguyo yunguyo",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Yunguyo, Yunguyo",
                "id_padre_ubigeo": "4423",
                "id_ubigeo": "4424",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yunguyo",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4432": [
            {
                "buscador_ubigeo": "calzada moyobamba",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Calzada, Moyobamba",
                "id_padre_ubigeo": "4432",
                "id_ubigeo": "4434",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Calzada",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "habana moyobamba",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Habana, Moyobamba",
                "id_padre_ubigeo": "4432",
                "id_ubigeo": "4435",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Habana",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "jepelacio moyobamba",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Jepelacio, Moyobamba",
                "id_padre_ubigeo": "4432",
                "id_ubigeo": "4436",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Jepelacio",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "moyobamba moyobamba",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Moyobamba, Moyobamba",
                "id_padre_ubigeo": "4432",
                "id_ubigeo": "4433",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Moyobamba",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "soritor moyobamba",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Soritor, Moyobamba",
                "id_padre_ubigeo": "4432",
                "id_ubigeo": "4437",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Soritor",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yantalo moyobamba",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Yantalo, Moyobamba",
                "id_padre_ubigeo": "4432",
                "id_ubigeo": "4438",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yantalo",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4439": [
            {
                "buscador_ubigeo": "alto biavo bellavista",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Alto Biavo, Bellavista",
                "id_padre_ubigeo": "4439",
                "id_ubigeo": "4441",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Alto Biavo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "bajo biavo bellavista",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Bajo Biavo, Bellavista",
                "id_padre_ubigeo": "4439",
                "id_ubigeo": "4442",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Bajo Biavo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "bellavista bellavista",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Bellavista, Bellavista",
                "id_padre_ubigeo": "4439",
                "id_ubigeo": "4440",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Bellavista",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huallaga bellavista",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Huallaga, Bellavista",
                "id_padre_ubigeo": "4439",
                "id_ubigeo": "4443",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huallaga",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san pablo bellavista",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "San Pablo, Bellavista",
                "id_padre_ubigeo": "4439",
                "id_ubigeo": "4444",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Pablo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san rafael bellavista",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "San Rafael, Bellavista",
                "id_padre_ubigeo": "4439",
                "id_ubigeo": "4445",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Rafael",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4446": [
            {
                "buscador_ubigeo": "agua blanca el dorado",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Agua Blanca, El Dorado",
                "id_padre_ubigeo": "4446",
                "id_ubigeo": "4448",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Agua Blanca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san jose de sisa el dorado",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "San Jose de Sisa, El Dorado",
                "id_padre_ubigeo": "4446",
                "id_ubigeo": "4447",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Jose de Sisa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san martin el dorado",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "San Martin, El Dorado",
                "id_padre_ubigeo": "4446",
                "id_ubigeo": "4449",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Martin",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "santa rosa el dorado",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Santa Rosa, El Dorado",
                "id_padre_ubigeo": "4446",
                "id_ubigeo": "4450",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Santa Rosa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "shatoja el dorado",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Shatoja, El Dorado",
                "id_padre_ubigeo": "4446",
                "id_ubigeo": "4451",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Shatoja",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4452": [
            {
                "buscador_ubigeo": "alto saposoa huallaga",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Alto Saposoa, Huallaga",
                "id_padre_ubigeo": "4452",
                "id_ubigeo": "4454",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Alto Saposoa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "el eslabon huallaga",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "El Eslabon, Huallaga",
                "id_padre_ubigeo": "4452",
                "id_ubigeo": "4455",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "El Eslabon",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "piscoyacu huallaga",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Piscoyacu, Huallaga",
                "id_padre_ubigeo": "4452",
                "id_ubigeo": "4456",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Piscoyacu",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sacanche huallaga",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Sacanche, Huallaga",
                "id_padre_ubigeo": "4452",
                "id_ubigeo": "4457",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sacanche",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "saposoa huallaga",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Saposoa, Huallaga",
                "id_padre_ubigeo": "4452",
                "id_ubigeo": "4453",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Saposoa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tingo de saposoa huallaga",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Tingo de Saposoa, Huallaga",
                "id_padre_ubigeo": "4452",
                "id_ubigeo": "4458",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tingo de Saposoa",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4459": [
            {
                "buscador_ubigeo": "alonso de alvarado lamas",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Alonso de Alvarado, Lamas",
                "id_padre_ubigeo": "4459",
                "id_ubigeo": "4461",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Alonso de Alvarado",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "barranquita lamas",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Barranquita, Lamas",
                "id_padre_ubigeo": "4459",
                "id_ubigeo": "4462",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Barranquita",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "caynarachi lamas",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Caynarachi, Lamas",
                "id_padre_ubigeo": "4459",
                "id_ubigeo": "4463",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Caynarachi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cuqumbuqui lamas",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Cuqumbuqui, Lamas",
                "id_padre_ubigeo": "4459",
                "id_ubigeo": "4464",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cuqumbuqui",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "lamas lamas",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Lamas, Lamas",
                "id_padre_ubigeo": "4459",
                "id_ubigeo": "4460",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Lamas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pinto recodo lamas",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Pinto Recodo, Lamas",
                "id_padre_ubigeo": "4459",
                "id_ubigeo": "4465",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pinto Recodo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "rumisapa lamas",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Rumisapa, Lamas",
                "id_padre_ubigeo": "4459",
                "id_ubigeo": "4466",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Rumisapa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san roque de cumbaza lamas",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "San Roque de Cumbaza, Lamas",
                "id_padre_ubigeo": "4459",
                "id_ubigeo": "4467",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Roque de Cumbaza",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "shanao lamas",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Shanao, Lamas",
                "id_padre_ubigeo": "4459",
                "id_ubigeo": "4468",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Shanao",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tabalosos lamas",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Tabalosos, Lamas",
                "id_padre_ubigeo": "4459",
                "id_ubigeo": "4469",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tabalosos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "zapatero lamas",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Zapatero, Lamas",
                "id_padre_ubigeo": "4459",
                "id_ubigeo": "4470",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Zapatero",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4471": [
            {
                "buscador_ubigeo": "campanilla mariscal caceres",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Campanilla, Mariscal Caceres",
                "id_padre_ubigeo": "4471",
                "id_ubigeo": "4473",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Campanilla",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huicungo mariscal caceres",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Huicungo, Mariscal Caceres",
                "id_padre_ubigeo": "4471",
                "id_ubigeo": "4474",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huicungo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "juanjui mariscal caceres",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Juanjui, Mariscal Caceres",
                "id_padre_ubigeo": "4471",
                "id_ubigeo": "4472",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Juanjui",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pachiza mariscal caceres",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Pachiza, Mariscal Caceres",
                "id_padre_ubigeo": "4471",
                "id_ubigeo": "4475",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pachiza",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pajarillo mariscal caceres",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Pajarillo, Mariscal Caceres",
                "id_padre_ubigeo": "4471",
                "id_ubigeo": "4476",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pajarillo",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4477": [
            {
                "buscador_ubigeo": "buenos aires picota",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Buenos Aires, Picota",
                "id_padre_ubigeo": "4477",
                "id_ubigeo": "4479",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Buenos Aires",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "caspisapa picota",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Caspisapa, Picota",
                "id_padre_ubigeo": "4477",
                "id_ubigeo": "4480",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Caspisapa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "picota picota",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Picota, Picota",
                "id_padre_ubigeo": "4477",
                "id_ubigeo": "4478",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Picota",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pilluana picota",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Pilluana, Picota",
                "id_padre_ubigeo": "4477",
                "id_ubigeo": "4481",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pilluana",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pucacaca picota",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Pucacaca, Picota",
                "id_padre_ubigeo": "4477",
                "id_ubigeo": "4482",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pucacaca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san cristobal picota",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "San Cristobal, Picota",
                "id_padre_ubigeo": "4477",
                "id_ubigeo": "4483",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Cristobal",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san hilarion picota",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "San Hilarion, Picota",
                "id_padre_ubigeo": "4477",
                "id_ubigeo": "4484",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Hilarion",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "shamboyacu picota",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Shamboyacu, Picota",
                "id_padre_ubigeo": "4477",
                "id_ubigeo": "4485",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Shamboyacu",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tingo de ponasa picota",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Tingo de Ponasa, Picota",
                "id_padre_ubigeo": "4477",
                "id_ubigeo": "4486",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tingo de Ponasa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tres unidos picota",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Tres Unidos, Picota",
                "id_padre_ubigeo": "4477",
                "id_ubigeo": "4487",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tres Unidos",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4488": [
            {
                "buscador_ubigeo": "awajun rioja",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Awajun, Rioja",
                "id_padre_ubigeo": "4488",
                "id_ubigeo": "4490",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Awajun",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "elias soplin vargas rioja",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Elias Soplin Vargas, Rioja",
                "id_padre_ubigeo": "4488",
                "id_ubigeo": "4491",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Elias Soplin Vargas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "nueva cajamarca rioja",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Nueva Cajamarca, Rioja",
                "id_padre_ubigeo": "4488",
                "id_ubigeo": "4492",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Nueva Cajamarca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pardo miguel rioja",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Pardo Miguel, Rioja",
                "id_padre_ubigeo": "4488",
                "id_ubigeo": "4493",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pardo Miguel",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "posic rioja",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Posic, Rioja",
                "id_padre_ubigeo": "4488",
                "id_ubigeo": "4494",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Posic",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "rioja rioja",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Rioja, Rioja",
                "id_padre_ubigeo": "4488",
                "id_ubigeo": "4489",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Rioja",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san fernando rioja",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "San Fernando, Rioja",
                "id_padre_ubigeo": "4488",
                "id_ubigeo": "4495",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Fernando",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yorongos rioja",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Yorongos, Rioja",
                "id_padre_ubigeo": "4488",
                "id_ubigeo": "4496",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yorongos",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yuracyacu rioja",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Yuracyacu, Rioja",
                "id_padre_ubigeo": "4488",
                "id_ubigeo": "4497",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yuracyacu",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4498": [
            {
                "buscador_ubigeo": "alberto leveau san martin",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Alberto Leveau, San Martin",
                "id_padre_ubigeo": "4498",
                "id_ubigeo": "4500",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Alberto Leveau",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cacatachi san martin",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Cacatachi, San Martin",
                "id_padre_ubigeo": "4498",
                "id_ubigeo": "4501",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cacatachi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chazuta san martin",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Chazuta, San Martin",
                "id_padre_ubigeo": "4498",
                "id_ubigeo": "4502",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chazuta",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "chipurana san martin",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Chipurana, San Martin",
                "id_padre_ubigeo": "4498",
                "id_ubigeo": "4503",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chipurana",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "el porvenir san martin",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "El Porvenir, San Martin",
                "id_padre_ubigeo": "4498",
                "id_ubigeo": "4504",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "El Porvenir",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huimbayoc san martin",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Huimbayoc, San Martin",
                "id_padre_ubigeo": "4498",
                "id_ubigeo": "4505",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huimbayoc",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "juan guerra san martin",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Juan Guerra, San Martin",
                "id_padre_ubigeo": "4498",
                "id_ubigeo": "4506",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Juan Guerra",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la banda de shilcayo san martin",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "La Banda de Shilcayo, San Martin",
                "id_padre_ubigeo": "4498",
                "id_ubigeo": "4507",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Banda de Shilcayo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "morales san martin",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Morales, San Martin",
                "id_padre_ubigeo": "4498",
                "id_ubigeo": "4508",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Morales",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "papaplaya san martin",
                "codigo_ubigeo": "11",
                "etiqueta_ubigeo": "Papaplaya, San Martin",
                "id_padre_ubigeo": "4498",
                "id_ubigeo": "4509",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Papaplaya",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san antonio san martin",
                "codigo_ubigeo": "12",
                "etiqueta_ubigeo": "San Antonio, San Martin",
                "id_padre_ubigeo": "4498",
                "id_ubigeo": "4510",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Antonio",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sauce san martin",
                "codigo_ubigeo": "13",
                "etiqueta_ubigeo": "Sauce, San Martin",
                "id_padre_ubigeo": "4498",
                "id_ubigeo": "4511",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sauce",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "shapaja san martin",
                "codigo_ubigeo": "14",
                "etiqueta_ubigeo": "Shapaja, San Martin",
                "id_padre_ubigeo": "4498",
                "id_ubigeo": "4512",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Shapaja",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tarapoto san martin",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Tarapoto, San Martin",
                "id_padre_ubigeo": "4498",
                "id_ubigeo": "4499",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tarapoto",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4513": [
            {
                "buscador_ubigeo": "nuevo progreso tocache",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Nuevo Progreso, Tocache",
                "id_padre_ubigeo": "4513",
                "id_ubigeo": "4515",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Nuevo Progreso",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "polvora tocache",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Polvora, Tocache",
                "id_padre_ubigeo": "4513",
                "id_ubigeo": "4516",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Polvora",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "shunte tocache",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Shunte, Tocache",
                "id_padre_ubigeo": "4513",
                "id_ubigeo": "4517",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Shunte",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tocache tocache",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Tocache, Tocache",
                "id_padre_ubigeo": "4513",
                "id_ubigeo": "4514",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tocache",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "uchiza tocache",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Uchiza, Tocache",
                "id_padre_ubigeo": "4513",
                "id_ubigeo": "4518",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Uchiza",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4520": [
            {
                "buscador_ubigeo": "alto de la alianza tacna",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Alto de la Alianza, Tacna",
                "id_padre_ubigeo": "4520",
                "id_ubigeo": "4522",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Alto de la Alianza",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "calana tacna",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Calana, Tacna",
                "id_padre_ubigeo": "4520",
                "id_ubigeo": "4523",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Calana",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ciudad nueva tacna",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Ciudad Nueva, Tacna",
                "id_padre_ubigeo": "4520",
                "id_ubigeo": "4524",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ciudad Nueva",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "cor gregorio albarracín tacna",
                "codigo_ubigeo": "10",
                "etiqueta_ubigeo": "Cor Gregorio Albarracín, Tacna",
                "id_padre_ubigeo": "4520",
                "id_ubigeo": "4530",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cor Gregorio Albarracín",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "inclan tacna",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Inclan, Tacna",
                "id_padre_ubigeo": "4520",
                "id_ubigeo": "4525",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Inclan",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pachia tacna",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Pachia, Tacna",
                "id_padre_ubigeo": "4520",
                "id_ubigeo": "4526",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pachia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "palca tacna",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Palca, Tacna",
                "id_padre_ubigeo": "4520",
                "id_ubigeo": "4527",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Palca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pocollay tacna",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Pocollay, Tacna",
                "id_padre_ubigeo": "4520",
                "id_ubigeo": "4528",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pocollay",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sama tacna",
                "codigo_ubigeo": "09",
                "etiqueta_ubigeo": "Sama, Tacna",
                "id_padre_ubigeo": "4520",
                "id_ubigeo": "4529",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sama",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tacna tacna",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Tacna, Tacna",
                "id_padre_ubigeo": "4520",
                "id_ubigeo": "4521",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tacna",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4531": [
            {
                "buscador_ubigeo": "cairani candarave",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Cairani, Candarave",
                "id_padre_ubigeo": "4531",
                "id_ubigeo": "4533",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Cairani",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "camilaca candarave",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Camilaca, Candarave",
                "id_padre_ubigeo": "4531",
                "id_ubigeo": "4534",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Camilaca",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "candarave candarave",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Candarave, Candarave",
                "id_padre_ubigeo": "4531",
                "id_ubigeo": "4532",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Candarave",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "curibaya candarave",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Curibaya, Candarave",
                "id_padre_ubigeo": "4531",
                "id_ubigeo": "4535",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Curibaya",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "huanuara candarave",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Huanuara, Candarave",
                "id_padre_ubigeo": "4531",
                "id_ubigeo": "4536",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Huanuara",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "quilahuani candarave",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Quilahuani, Candarave",
                "id_padre_ubigeo": "4531",
                "id_ubigeo": "4537",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Quilahuani",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4538": [
            {
                "buscador_ubigeo": "ilabaya jorge basadre",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Ilabaya, Jorge Basadre",
                "id_padre_ubigeo": "4538",
                "id_ubigeo": "4540",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ilabaya",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ite jorge basadre",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Ite, Jorge Basadre",
                "id_padre_ubigeo": "4538",
                "id_ubigeo": "4541",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ite",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "locumba jorge basadre",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Locumba, Jorge Basadre",
                "id_padre_ubigeo": "4538",
                "id_ubigeo": "4539",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Locumba",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4542": [
            {
                "buscador_ubigeo": "chucatamani tarata",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Chucatamani, Tarata",
                "id_padre_ubigeo": "4542",
                "id_ubigeo": "4544",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Chucatamani",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "estique tarata",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Estique, Tarata",
                "id_padre_ubigeo": "4542",
                "id_ubigeo": "4545",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Estique",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "estique-pampa tarata",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Estique-Pampa, Tarata",
                "id_padre_ubigeo": "4542",
                "id_ubigeo": "4546",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Estique-Pampa",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sitajara tarata",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Sitajara, Tarata",
                "id_padre_ubigeo": "4542",
                "id_ubigeo": "4547",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sitajara",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "susapaya tarata",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Susapaya, Tarata",
                "id_padre_ubigeo": "4542",
                "id_ubigeo": "4548",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Susapaya",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tarata tarata",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Tarata, Tarata",
                "id_padre_ubigeo": "4542",
                "id_ubigeo": "4543",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tarata",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tarucachi tarata",
                "codigo_ubigeo": "07",
                "etiqueta_ubigeo": "Tarucachi, Tarata",
                "id_padre_ubigeo": "4542",
                "id_ubigeo": "4549",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tarucachi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "ticaco tarata",
                "codigo_ubigeo": "08",
                "etiqueta_ubigeo": "Ticaco, Tarata",
                "id_padre_ubigeo": "4542",
                "id_ubigeo": "4550",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Ticaco",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4552": [
            {
                "buscador_ubigeo": "corrales tumbes",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Corrales, Tumbes",
                "id_padre_ubigeo": "4552",
                "id_ubigeo": "4554",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Corrales",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "la cruz tumbes",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "La Cruz, Tumbes",
                "id_padre_ubigeo": "4552",
                "id_ubigeo": "4555",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "La Cruz",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "pampas de hospital tumbes",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Pampas de Hospital, Tumbes",
                "id_padre_ubigeo": "4552",
                "id_ubigeo": "4556",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Pampas de Hospital",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san jacinto tumbes",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "San Jacinto, Tumbes",
                "id_padre_ubigeo": "4552",
                "id_ubigeo": "4557",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Jacinto",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "san juan de la virgen tumbes",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "San Juan de la Virgen, Tumbes",
                "id_padre_ubigeo": "4552",
                "id_ubigeo": "4558",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "San Juan de la Virgen",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tumbes tumbes",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Tumbes, Tumbes",
                "id_padre_ubigeo": "4552",
                "id_ubigeo": "4553",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tumbes",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4559": [
            {
                "buscador_ubigeo": "casitas contralmirante villar",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Casitas, Contralmirante Villar",
                "id_padre_ubigeo": "4559",
                "id_ubigeo": "4561",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Casitas",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "zorritos contralmirante villar",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Zorritos, Contralmirante Villar",
                "id_padre_ubigeo": "4559",
                "id_ubigeo": "4560",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Zorritos",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4562": [
            {
                "buscador_ubigeo": "aguas verdes zarumilla",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Aguas Verdes, Zarumilla",
                "id_padre_ubigeo": "4562",
                "id_ubigeo": "4564",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Aguas Verdes",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "matapalo zarumilla",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Matapalo, Zarumilla",
                "id_padre_ubigeo": "4562",
                "id_ubigeo": "4565",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Matapalo",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "papayal zarumilla",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Papayal, Zarumilla",
                "id_padre_ubigeo": "4562",
                "id_ubigeo": "4566",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Papayal",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "zarumilla zarumilla",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Zarumilla, Zarumilla",
                "id_padre_ubigeo": "4562",
                "id_ubigeo": "4563",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Zarumilla",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4568": [
            {
                "buscador_ubigeo": "calleria coronel portillo",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Calleria, Coronel Portillo",
                "id_padre_ubigeo": "4568",
                "id_ubigeo": "4569",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Calleria",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "campoverde coronel portillo",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Campoverde, Coronel Portillo",
                "id_padre_ubigeo": "4568",
                "id_ubigeo": "4570",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Campoverde",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "iparia coronel portillo",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Iparia, Coronel Portillo",
                "id_padre_ubigeo": "4568",
                "id_ubigeo": "4571",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Iparia",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "masisea coronel portillo",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Masisea, Coronel Portillo",
                "id_padre_ubigeo": "4568",
                "id_ubigeo": "4572",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Masisea",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "nueva requena coronel portillo",
                "codigo_ubigeo": "06",
                "etiqueta_ubigeo": "Nueva Requena, Coronel Portillo",
                "id_padre_ubigeo": "4568",
                "id_ubigeo": "4574",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Nueva Requena",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yarinacocha coronel portillo",
                "codigo_ubigeo": "05",
                "etiqueta_ubigeo": "Yarinacocha, Coronel Portillo",
                "id_padre_ubigeo": "4568",
                "id_ubigeo": "4573",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yarinacocha",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4575": [
            {
                "buscador_ubigeo": "raymondi atalaya",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Raymondi, Atalaya",
                "id_padre_ubigeo": "4575",
                "id_ubigeo": "4576",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Raymondi",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "sepahua atalaya",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Sepahua, Atalaya",
                "id_padre_ubigeo": "4575",
                "id_ubigeo": "4577",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Sepahua",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "tahuania atalaya",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Tahuania, Atalaya",
                "id_padre_ubigeo": "4575",
                "id_ubigeo": "4578",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Tahuania",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "yurua atalaya",
                "codigo_ubigeo": "04",
                "etiqueta_ubigeo": "Yurua, Atalaya",
                "id_padre_ubigeo": "4575",
                "id_ubigeo": "4579",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Yurua",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4580": [
            {
                "buscador_ubigeo": "curimana padre abad",
                "codigo_ubigeo": "03",
                "etiqueta_ubigeo": "Curimana, Padre Abad",
                "id_padre_ubigeo": "4580",
                "id_ubigeo": "4583",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Curimana",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "irazola padre abad",
                "codigo_ubigeo": "02",
                "etiqueta_ubigeo": "Irazola, Padre Abad",
                "id_padre_ubigeo": "4580",
                "id_ubigeo": "4582",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Irazola",
                "numero_hijos_ubigeo": "0"
            },
            {
                "buscador_ubigeo": "padre abad padre abad",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Padre Abad, Padre Abad",
                "id_padre_ubigeo": "4580",
                "id_ubigeo": "4581",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Padre Abad",
                "numero_hijos_ubigeo": "0"
            }
        ],
        "4584": [
            {
                "buscador_ubigeo": "purus purus",
                "codigo_ubigeo": "01",
                "etiqueta_ubigeo": "Purus, Purus",
                "id_padre_ubigeo": "4584",
                "id_ubigeo": "4585",
                "nivel_ubigeo": "3",
                "nombre_ubigeo": "Purus",
                "numero_hijos_ubigeo": "0"
            }
        ]
    }
    return dist[val]
}