<template>

    <v-dialog
        v-model="$store.state.dialogomedidas"
        max-width="460px"
    >
    <div>
      <v-system-bar window dark>
        <v-icon @click="$store.commit('dialogomedidas')">mdi-close</v-icon>
        <v-spacer></v-spacer>
      </v-system-bar>
    </div>

    <v-card>
              
  <v-simple-table>
    <template v-slot:default>
      <thead>
          
        <tr>
          <th class="text-left">
            Nombre
          </th>
          <th class="text-left">
            SUNAT
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in $store.state.medidassunat"
          :key="item.nombre"
        >
          <td>{{ item.nombre }}</td>
          <td>{{ item.corto }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>

    </v-card>
 
    </v-dialog>
</template>
<script>
import store from '@/store/index'
export default {

 data () {
      return {
      
      }
    },

}
</script>