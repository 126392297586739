<template>
<div grid-list-xs class="mb-6 pa-4">

    <v-row dense class="text-center">
        <v-col cols="6" md="4" xs="6">
            <h4>
                <v-icon @click="regresa()" color="red">mdi-keyboard-return</v-icon> CARTA "{{$route.params.nombre}}"
            </h4>
        </v-col>
        <v-col cols="6" md="4" xs="6">
            <v-btn dense color="success" x-small block @click="dial_productos = !dial_productos">
                AGREGA PRODUCTOS
            </v-btn>
        </v-col>
        <v-col cols="6" md="4" xs="6">
            <v-btn dense color="info" x-small block @click="dial_observaciones = !dial_observaciones">
                Observaciones
            </v-btn>
        </v-col>
    </v-row>
    <v-row class="mt-3" dense>
        <v-col v-for="item in desserts" :key="item.id" cols="12" md="6" sm="6" xs="12">
            <v-card height="100" style="text-align:left">
                <v-row dense no-gutters>
                    <v-col cols="4">
                        <v-card-text>
                            <p class="black--text mt-2" style="font-size:15px;font-style:italic;font-weight: bold">{{item.nombre}}</p>
                            <p class="black--text mt-n5" style="font-size:12px">{{item.descripcion}}</p>
                        </v-card-text>
                    </v-col>
                    <v-col cols="2">
                        <v-card-text>
                            <v-avatar color="orange" size="40" class="mt-3">
                                <p class="white--text mt-4" style="font-size:15px">S/.{{item.precio}}</p>
                            </v-avatar>
                           
                        </v-card-text>
                    </v-col>
                    <v-col cols="4">
                        <v-img :src="item.url_foto" aspect-ratio="1" class="mt-2" :width="135" :height="85">
                            <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                    </v-col>
                    <v-col cols="2">
                        <v-row dense no-gutters>
                            <v-icon color="red" class="ml-2 mt-8" @click="edita_pro(item)">mdi-cog</v-icon>
                        </v-row>
                    </v-col>

                </v-row>
            </v-card>
        </v-col>
    </v-row>
    <v-dialog v-model="dial_productos" max-width="460px">
        <div>
            <v-system-bar window dark>
                <v-icon @click="dial_productos=!dial_productos">mdi-close</v-icon>
                <v-spacer></v-spacer>

            </v-system-bar>
        </div>
        <v-card class="pa-6">
            <v-simple-table fixed-header height="60vh" dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Nombre
                            </th>
                            <th class="text-left">
                                Accion
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in listafiltrada" :key="item.id">
                            <td>{{ item.nombre }}</td>
                            <td>
                                <v-icon @click="agrega(item)" color="green">mdi-plus</v-icon>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>

    </v-dialog>
    <v-dialog v-model="dial_edita_pro" max-width="460px">
        <div>
            <v-system-bar window dark>
                <v-icon @click="dial_edita_pro=!dial_edita_pro">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <v-icon large color="error" @click="elimina_pro()">mdi-delete</v-icon>
                <v-icon large color="success" @click="guarda_pro()">mdi-content-save</v-icon>
            </v-system-bar>
        </div>
        <v-card class="pa-3">
            <v-row class="mt-1" dense>
                <v-col cols="12">
                    <v-textarea readonly outlined dense v-model="item_selecto.nombre" auto-grow filled label="Nombre Producto" rows="1"></v-textarea>
                </v-col>
                <v-col cols="12" class="mt-n6">
                    <v-textarea outlined dense v-model="descripcion" auto-grow filled label="Descripcion" rows="1"></v-textarea>
                </v-col>
                <v-col cols="12" class="mt-n6">
                    <v-text-field outlined dense v-model="grupo_obs" label="Grupo Observacion" append-icon="mdi-magnify" @click:append="dial_obs=true"></v-text-field>
                </v-col>
                <v-col cols="12" class="mt-n6 text--center">
                    <croppa :placeholder-font-size="19" canvas-color="orange" placeholder-color="#000" :width="325" :height="190" :initial-image="imagenURL" v-model="myCroppa">
                    </croppa>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
    <v-dialog v-model="progress" max-width="200">
        <v-card>
            <v-progress-circular :rotate="90" :size="200" :width="15" :value="Uploadvalue" color="red">
                {{ Uploadvalue }}
            </v-progress-circular>
        </v-card>

    </v-dialog>
    <obs_restaurante v-if="dial_observaciones" :dial_activo="dial_observaciones" @cierra="dial_observaciones = $event"/>
    <select_obs v-if="dial_obs" :dial_activo="dial_obs" @cierra="dial_obs = $event" @seleccion="selecciona($event)"/>
</div>
</template>

<script>
import {
    db,
    guardaArchivo,
    allProductos,
    editaProducto
} from '../../db'
import obs_restaurante from '@/components/dial_restaurante/dial_observaciones'
import select_obs from '@/components/dial_restaurante/dial_select_obs'
import store from '@/store/index'

export default {
    components: {
        obs_restaurante,
        select_obs
    },
    data: () => ({
        dial_observaciones:false,
        dial_productos: false,
        array_productos: [],
        desserts: [],
        dial_edita_pro: false,
        item_selecto: [],
        descripcion: '',
        myCroppa: {},
        imagenURL: '',
        progress: false,
        selectedFile: null,
        Uploadvalue: 0,
        grupo_obs:'',
        dial_obs:false
    }),
    computed: {
        listafiltrada() {
            var a = store.state.productos
            return a.filter((item) => (item.categoria == this.$route.params.nombre))
        }
    },
    mounted() {
        allProductos().on("value", this.onDataChange);
    },
    beforeDestroy() {
        allProductos().off("value", this.onDataChange);
    },
    methods: {
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                if (data.carta && data.categoria == this.$route.params.nombre) {
                    array.push(data);
                }
            });
            this.desserts = array;

        },
        regresa() {
            this.$router.push({
                name: 'gestion_carta'
            })
        },
        agrega(item) {
            console.log(item)
            editaProducto(item.id, "carta", true)
            this.dial_productos = false
        },
        edita_pro(item) {
            this.descripcion = ''
            this.grupo_obs = ''
            if (item.descripcion != undefined) {
                this.descripcion = item.descripcion
            }
            if (item.grupo_obs != undefined) {
                this.grupo_obs = item.grupo_obs
            }
            this.item_selecto = item
            this.dial_edita_pro = true
        },
        elimina_pro() {
            editaProducto(this.item_selecto.id, "carta", false)
            this.descripcion = ''
            this.selectedFile = ''
            this.dial_edita_pro = false
        },
        selecciona(data){
            this.grupo_obs = data.nombre
            this.dial_obs = false            
        },
        guarda_pro() {
            this.uploadCroppedImage()
            if (Boolean(this.selectedFile)) {
                // this.onupload()
            }
            editaProducto(this.item_selecto.id, "descripcion", this.descripcion)
            editaProducto(this.item_selecto.id, "grupo_obs", this.grupo_obs)
            this.descripcion = ''
            this.selectedFile = ''
            this.grupo_obs = ''
            this.dial_edita_pro = false
        },
        uploadCroppedImage() {
            this.myCroppa.generateBlob((blob) => {
                if (blob != null) {
                    this.progress = true
                    var task = guardaArchivo(store.state.baseDatos.bd, blob, 'productos/' + this.item_selecto.id)
                    task.on('state_changed', snapshot => {
                            let porcentaje = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                            this.Uploadvalue = porcentaje
                        }, error => {
                            console.log(error.message)
                        },
                        () => {
                            this.Uploadvalue = 100;
                            task.snapshot.ref.getDownloadURL().then((url) => {
                                editaProducto(this.item_selecto.id, "url_foto", url)
                                this.progress = false
                            })
                        }
                    )
                }
            }, 'image/webp', 0.8) // 80% compressed jpeg file

        },

    },
}
</script>
