<template>
    <v-dialog v-model="dial" max-width="450">
        <div>
            <v-system-bar window dark>
                <v-icon @click="cierra()" color="red" large>mdi-close</v-icon>
                <v-spacer></v-spacer>
                <v-icon color="green" large v-if="!mesa" @click="graba_separacion()">mdi-content-save</v-icon>
            </v-system-bar>
        </div>
        <v-card class="pa-3">
            <h4 class="text-center">SOLO PARA DIVIDIR CUENTAS</h4>
            <v-simple-table v-if="mesa" height="50vh">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Numero Mesa
                            </th>
                            <th class="text-left">
                                Accion
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in lista_comandas" :key="item.id">
                            <td>{{ item.nombre }}</td>
                            <td>
                                <v-icon @click="copia_comanda(item)" color="red">mdi-magnify</v-icon>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>

            <v-simple-table v-if="!mesa" fixed-header height="50vh" dense>
                <template v-slot:default>

                    <thead>
                        <tr>

                            <th class="text-left">
                                Descripcion
                            </th>
                            <th class="text-left">
                                Consumido
                            </th>
                            <th class="text-left">
                                Cobrar
                            </th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr v-for="item in lista_productos" :key="item.uuid">

                            <td>{{ item.nombre }}</td>
                            <td>{{ item.cantidad }}</td>
                            <td>
                                <v-icon large @click="suma_separa(item)" color="green">mdi-plus</v-icon>
                                {{ item.cantidadedita }}
                                <v-icon large @click="resta_separa(item)" color="red">mdi-minus</v-icon>
                            </td>
                        </tr>
                    </tbody>
                </template>

            </v-simple-table>
        </v-card>

    </v-dialog>
</template>

<script>
import {
    allComandas,
    buscaComanda
} from '../../db'
import store from '@/store/index'
export default {
    name: 'caja',

    data() {
        return {
            dial: false,
            mesa: true,
            lista_comandas: [],
            lista_productos: []
        }
    },
    async created() {
        store.commit("dialogoprogress")
        var snapshot = await allComandas().once('value')
        if (snapshot.exists()) {
            snapshot.forEach((item) => {
                var key = item.key
                this.lista_comandas.push({
                    id: key,
                    nombre: key
                })
            })
            store.commit("dialogoprogress")
        } else {
            alert('Mesas Vacias')
            store.commit("dialogoprogress")
            return
        }
        this.dial = true
    },
    methods: {
        graba_separacion() {
            var array = []
            var array_nuevo = []
            for (var i = 0; i < this.lista_productos.length; i++) {
                var data = this.lista_productos[i]
                if (data.cantidadedita != 0) {
                    array.push(data)
                }
                var result = parseFloat(data.cantidad) - parseFloat(data.cantidadedita)
                if (result != 0) {
                    array_nuevo.push({
                        barra: data.barra,
                        cantidad: result,
                        cocina: data.cocina,
                        estado: data.estado,
                        id: data.id,
                        idmesa: data.idmesa,
                        nombre: data.nombre,
                        observacion: data.observacion,
                        precio: data.precio,
                        uuid: data.uuid,
                        operacion: data.operacion,
                        tipoproducto: data.tipoproducto,
                        cargoxconsumo: data.cargoxconsumo,
                        controstock: data.controstock,
                        costo: data.costo,
                        medida: data.medida,
                    })
                }
            }
            var res = [array, array_nuevo, this.id_comanda]
            this.$emit('termina', res)
        },
        async copia_comanda(val) {

            store.commit("dialogoprogress")
            var snap = await buscaComanda(val.id).once('value')
            if (snap.exists()) {
                this.id_comanda = val.id

                snap.forEach((item) => {
                    var data = item.val()

                    var producto = store.state.productos.find(item => item.id == data.id)
                    //console.log(producto)
                    if (producto == undefined) {
                        data.operacion = 'GRAVADA'
                        data.tipoproducto = 'BIEN'
                        data.cargoxconsumo = false
                        data.controstock = false
                        data.costo = 0
                        data.medida = 'UNIDAD'
                        data.cantidadedita = 0
                    } else {
                        data.operacion = producto.operacion
                        data.tipoproducto = producto.tipoproducto
                        data.cargoxconsumo = producto.cargoxconsumo
                        data.controstock = producto.controstock
                        data.costo = producto.costo
                        data.medida = producto.medida
                        data.cantidadedita = 0
                    }
                    this.lista_productos.push(data)
                })
            } else {
                alert('Error')
                store.commit("dialogoprogress")
                return
            }
            this.mesa = false
            this.dial_comandas = false
            store.commit("dialogoprogress")
        },
        suma_separa(item) {
            var pos = this.lista_productos.map(e => e.uuid).indexOf(item.uuid)
            if (this.lista_productos[pos].cantidadedita < this.lista_productos[pos].cantidad) {
                this.lista_productos[pos].cantidadedita++
            }
        },
        resta_separa(item) {
            var pos = this.lista_productos.map(e => e.uuid).indexOf(item.uuid)
            if (this.lista_productos[pos].cantidadedita > 0) {
                this.lista_productos[pos].cantidadedita--
            }
        },
        cierra() {
            this.$emit('cierra', false)
        }
    }
}
</script>
