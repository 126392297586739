<template>
<v-container class="mt-8">

                <v-row class="mt-3" dense>
                    <v-col
                        v-for="item in desserts"
                        :key="item.id"
                        cols="6"                          
                        md="3"
                        sm="4"                        
                        xs="4">
                        <v-card :href="item.link"
                        target="_blank"
                         height="100" style="text-align:center">                          
                         {{item.nombre}}
                      </v-card>
                    </v-col>                   
        </v-row>   
</v-container>              
</template>

<script>

export default {
 data () {
      return {
          desserts:[]
      }
    },

    created () {
        this.desserts  = [{
          id: 1,
          nombre: 'RAW-BT',
          posicion: 0,
          icono: "",
          link: "https://firebasestorage.googleapis.com/v0/b/mitienda-f5ef8.appspot.com/o/catalogo%2FRawBT_v5.0.2.apk?alt=media&token=4755ba9c-c41a-4b5d-a4b0-9694087a1964"
        }
    ]
    },

     methods: {
         
     }

}
</script>
<style>

</style>