<template>
    <div class="pa-3">
        <v-alert type="warning" v-if="false">
            <v-row align="center">
                <v-col class="grow">
                    ESTAMOS MEJORANDO NUESTRO SISTEMA!! PRONTO TENDREMOS MÁS NOVEDADES
                </v-col>
                <v-col class="shrink" v-if="false">
                    <v-btn color="primary" @click="abre_link()">AQUÍ</v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <div>
            <v-alert v-if="false" v-model="alert" dismissible color="black" border="left" elevation="2" colored-border
                icon="mdi-school" prominent>
                RECOMIENDA NUESTRO SISTEMA Y OBTÉN HASTA <span class="red--text">1 MES GRATIS!!!</span>
            </v-alert>
        </div>
        <v-row class="mb-4" dense>
            <v-col v-if="$store.state.permisos.modulocaja && $store.state.baseDatos.caja2" cols="6" class="pa-1" md="4"
                sm="4" xs="6">
                <v-card class="hoverable" @click.prevent="opcionCaja()">
                    <v-container class="text-center">
                        <v-img class="mx-auto" height="70" width="70" src="/caja.png"></v-img>
                        <h4 class="text-center pa-1">Caja</h4>
                    </v-container>
                </v-card>
            </v-col>
            <v-col v-if="$store.state.permisos.moduloproductos" cols="6" class="pa-1 mx-auto" md="4" sm="4" xs="6">
                <v-card class="hoverable" @click.prevent="router('productos')">
                    <v-container class="text-center">
                        <v-img class="mx-auto" height="70" width="70" src="/productos.png"></v-img>
                        <h4 class="text-center pa-1">Productos</h4>
                    </v-container>
                </v-card>
            </v-col>
            <v-col v-if="$store.state.permisos.moduloreportes" cols="6" class="pa-1 mx-auto" md="4" sm="4" xs="6">
                <v-card class="hoverable" @click.prevent="router('comprobantes')">
                    <v-container class="text-center">
                        <v-img class="mx-auto" height="70" width="70" src="/comprobante.png"></v-img>
                        <h4 class="text-center pa-1">Comprobantes</h4>
                    </v-container>
                </v-card>
            </v-col>
            <v-col v-if="$store.state.permisos.modulomesas && $store.state.baseDatos.restaurante" cols="6"
                class="pa-1 mx-auto" md="4" sm="4" xs="6">
                <v-card class="hoverable" @click.prevent="router('lista_mesas')">
                    <v-container class="text-center">
                        <v-img class="mx-auto" height="70" width="70" src="/mesa.png"></v-img>
                        <h4 class="text-center pa-1">Mesas</h4>
                    </v-container>
                </v-card>
            </v-col>
            <v-col v-if="$store.state.permisos.modulomesas && $store.state.baseDatos.restaurante" cols="6"
                class="pa-1 mx-auto" md="4" sm="4" xs="6">
                <v-card class="hoverable" @click.prevent="router('delivery')">
                    <v-container class="text-center">
                        <v-img class="mx-auto" height="70" width="70" src="/delivery.png"></v-img>
                        <h4 class="text-center pa-1">Delivery</h4>
                    </v-container>
                </v-card>
            </v-col>
            <v-col
                v-if="$store.state.permisos.modulococina && $store.state.baseDatos.restaurante && $store.state.configuracion.monitorcocina && $store.state.configuracion.comandacocina"
                cols="6" class="pa-1 mx-auto" md="4" sm="4" xs="6">
                <v-card class="hoverable" @click.prevent="router('cocina')">
                    <v-container class="text-center">
                        <v-img class="mx-auto" height="70" width="70" src="/cocina.png"></v-img>
                        <h4 class="text-center pa-1">Monitor Cocina</h4>
                    </v-container>
                </v-card>
            </v-col>
            <v-col v-if="$store.state.baseDatos.restaurante" cols="6" class="pa-1 mx-auto" md="4" sm="4" xs="6">
                <v-card class="hoverable" @click.prevent="router('gestion_carta')">
                    <v-container class="text-center">
                        <v-img class="mx-auto" height="70" width="120" src="/QR.png"></v-img>
                        <h4 class="text-center pa-1">Carta Online</h4>
                    </v-container>
                </v-card>
            </v-col>
            <v-col v-if="false" cols="6" class="pa-1 mx-auto" md="4" sm="4" xs="6">
                <v-card class="hoverable"
                    :href="`https://api.whatsapp.com/send?phone=$+51902135696&text=Deseo informacion de sistema de publicidad`"
                    target="_blank">
                    <v-container class="text-center">
                        <v-img class="mx-auto" height="70" width="70" src="/what.png"></v-img>
                        <h5 class="text-center pa-1">Publicidad x WhatsApp</h5>
                    </v-container>
                </v-card>
            </v-col>
            <v-col v-if="$store.state.permisos.moduloreportes" cols="6" class="pa-1 mx-auto" md="4" sm="4" xs="6">
                <v-card class="hoverable" @click.prevent="router('reportes')">
                    <v-container class="text-center">
                        <v-img class="mx-auto" height="70" width="70" src="/report.png"></v-img>
                        <h4 class="text-center pa-1">Mis Reportes</h4>
                    </v-container>
                </v-card>
            </v-col>
            <v-col v-if="$store.state.permisos.moduloempresa" cols="6" class="pa-1 mx-auto" md="4" sm="4" xs="6">
                <v-card class="hoverable" @click.prevent="router('soporte')">
                    <v-container class="text-center">
                        <v-img class="mx-auto" height="70" width="70" src="/licen.png"></v-img>
                        <h4 class="text-center pa-1">Soporte</h4>
                    </v-container>
                </v-card>
            </v-col>
            <v-col v-if="$store.state.permisos.moduloempresa" cols="6" class="pa-1 mx-auto" md="4" sm="4" xs="6">
                <v-card class="hoverable" @click.prevent="router('miempresa')">
                    <v-container class="text-center">
                        <v-img class="mx-auto" height="70" width="70" src="/setting.png"></v-img>
                        <h4 class="text-center pa-1">Config</h4>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
// @ is an alias to /src
import store from '@/store/index'

export default {
    name: 'panel',

    data() {
        return {
            user: '',
            alert: true
        }
    },
    created() {

    },
    methods: {
        router(view) {
            this.$router.push({
                name: view
            })
        },
        opcionCaja() {
            if (store.state.baseDatos.caja2) {
                this.$router.push({
                    name: 'caja'
                })
            } else {
                this.$router.push({
                    name: 'caja'
                })
            }
        },
        abre_link() {
            var message = 'No soy un restaurante, necesito Ayuda.'
            var url = "whatsapp://send?text=" + encodeURIComponent(message) + "&phone=" + encodeURIComponent('+51936052681')
            window.open(url);
        }

    },

}
</script>
<style scoped>
.pa-3 {
    padding: 1rem;
}

.hoverable {
    transition: box-shadow 0.3s ease-in-out;
}

.hoverable:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.v-card {
    border-radius: 8px;
}

.v-container {
    padding-top: 1rem;
}

.text-center {
    font-size: 1.2rem;
    font-weight: bold;
    color: #424242;
}
</style>