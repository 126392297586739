<template>

    <v-dialog
        v-model="$store.state.dialogocontadores"
        max-width="460px"
    >
    <div>
      <v-system-bar window dark>
        <v-icon @click="$store.commit('dialogocontadores')">mdi-close</v-icon>
        <v-spacer></v-spacer>
         <v-icon large color="green" @click="grabaContador()">mdi-content-save</v-icon>
      </v-system-bar>
    </div>


     <v-card class="pa-4">


      <v-row>
             <v-col cols="6" >
            <v-text-field        
                dense                           
                v-model="ordenproducto"
                label="Orden Productos"
            ></v-text-field>
             </v-col>  
             <v-col cols="6" > 
            <v-text-field   
            dense                                
                v-model="flujocaja"
                label="Orden Flujo"
            ></v-text-field>   
            </v-col>              
      </v-row>
            <v-row class="mt-n6">
                 <v-col cols="6" >
            <v-text-field       
            dense            
                v-model="ordenboleta"
                label="Orden Boletas"
            ></v-text-field>
                 </v-col>
             <v-col cols="6" >
            <v-text-field   
            dense                                
                v-model="ordenfactura"
                label="Orden Factura"
            ></v-text-field>   
             </v-col>
      </v-row>
        <v-row class="mt-n6">
             <v-col cols="6" >
            <v-text-field   
            dense                
                v-model="ordenticket"
                label="Orden Ticket"
            ></v-text-field>    
             </v-col>
            <v-col cols="6" >
            <v-text-field      
            dense             
                v-model="ordenresumen"
                label="Orden ResumenBAja"
            ></v-text-field>    
             </v-col>
      </v-row>
        <v-row class="mt-n6">
             <v-col cols="6" >
            <v-text-field    
            dense               
                v-model="ordenncredito"
                label="Orden N.Credito"
            ></v-text-field>    
             </v-col>
            <v-col cols="6" >
            <v-text-field   
            dense                
                v-model="ordenndebito"
                label="Orden N.Debito"
            ></v-text-field>    
             </v-col>
        </v-row>
                <v-row class="mt-n6">
             <v-col cols="6" >
            <v-text-field    
            dense               
                v-model="ordenclientes"
                label="Orden clientes"
            ></v-text-field>    
             </v-col>
            <v-col cols="6" >   
            <v-text-field    
            dense               
                v-model="ordenentradas"
                label="Orden Entradas"
            ></v-text-field>    
             </v-col>
        </v-row>
        <v-row class="mt-n6">
             <v-col cols="6" >
            <v-text-field    
            dense               
                v-model="ordenproveedor"
                label="Orden Proveedor"
            ></v-text-field>    
             </v-col>
            <v-col cols="6" >   
            <v-text-field    
            dense               
                v-model="ordeninsumos"
                label="Orden insumos"
            ></v-text-field>  
             </v-col>
        </v-row>
            <v-row class="mt-n6">
             <v-col cols="6" >
            <v-text-field    
                dense               
                v-model="ordenrecetas"
                label="Orden Recetas"
            ></v-text-field>    
             </v-col>
            <v-col cols="6" >   
            <v-text-field    
                dense               
                v-model="ordenproformas"
                label="Orden Proformas"
            ></v-text-field>   
             </v-col>
        </v-row>
        <v-row class="mt-n6">
             <v-col cols="6" >
            <v-text-field    
                dense               
                v-model="ordenresumenc"
                label="Orden ResumenCompr."
            ></v-text-field>    
             </v-col>
            <v-col cols="6" >   
            <v-text-field    
                dense               
                v-model="ordencosteo"
                label="Orden Costeo"
            ></v-text-field> 
             </v-col>
        </v-row>
            <v-row class="mt-n6">
             <v-col cols="6" >
            <v-text-field    
                dense               
                v-model="ordentrabajadores"
                label="Orden trabajadores."
            ></v-text-field>    
             </v-col>
            <v-col cols="6" >   
                <v-text-field    
                dense               
                v-model="orden_delivery"
                label="Orden Delivery."
            ></v-text-field>   
             </v-col>
        </v-row>
        <v-row class="mt-n6">
             <v-col cols="6" >
            <v-text-field    
                dense               
                v-model="orden_movimientos"
                label="Orden Movimientos."
            ></v-text-field>    
             </v-col>
            <v-col cols="6" >   
 
             </v-col>
        </v-row>
        
     </v-card>
              
 
    </v-dialog>
</template>

<script>
import { obtenContador,actualizaContador} from '../../db'
import store from '@/store/index'

export default {

 data () {
      return {
          ordenproducto:'10001',
          ordenboleta:'00000001',
          ordenfactura:'00000001',
          ordenticket:'00000001',
          flujocaja:'0001',
          ordenresumen:'0001',
          ordenncredito:'0001',
          ordenndebito:'0001',
          ordenclientes:'0001',
          ordenentradas:'0001',
          ordenproveedor: '0001',
          ordeninsumos:'0001',
          ordenrecetas:'0001',
          ordenproformas: '00000001',
          ordenresumenc : '0001',
          ordencosteo: '0001',
          ordentrabajadores:'0001',
          orden_delivery:'00001',
          orden_movimientos:'00001'
      }
    },
    mounted() {
      this.initialize()
      },
     methods: {
      initialize(){         
            obtenContador().once("value").then((snapshot) => {              
              this.ordenproducto=snapshot.val().ordenproducto
              this.ordenboleta=snapshot.val().ordenboleta
              this.ordenfactura=snapshot.val().ordenfactura
              this.ordenticket=snapshot.val().ordenticket
              this.flujocaja=snapshot.val().flujocaja
              this.ordenresumen=snapshot.val().ordenresumen
              this.ordenncredito=snapshot.val().ordenncredito
              this.ordenndebito=snapshot.val().ordenndebito
              this.ordenclientes=snapshot.val().ordenclientes
              this.ordenentradas=snapshot.val().ordenentradas
              this.ordenproveedor=snapshot.val().ordenproveedor
              this.ordeninsumos=snapshot.val().ordeninsumos
              this.ordenrecetas=snapshot.val().ordenrecetas
              this.ordenproformas=snapshot.val().ordenproformas
              this.ordenresumenc = snapshot.val().ordenresumenc
              this.ordencosteo = snapshot.val().ordencosteo
              this.ordentrabajadores = snapshot.val().ordentrabajadores
              this.orden_delivery = snapshot.val().orden_delivery
              this.orden_movimientos = snapshot.val().orden_movimientos
              })      
      },
      grabaContador(){
          actualizaContador('ordenproducto',this.ordenproducto)
          actualizaContador('ordenboleta',this.ordenboleta)
          actualizaContador('ordenfactura',this.ordenfactura)
          actualizaContador('ordenticket',this.ordenticket)
          actualizaContador('flujocaja',this.flujocaja)
          actualizaContador('ordenresumen',this.ordenresumen)
          actualizaContador('ordenncredito',this.ordenncredito)
          actualizaContador('ordenndebito',this.ordenndebito)
          actualizaContador('ordenclientes',this.ordenclientes)
          actualizaContador('ordenentradas',this.ordenentradas)
          actualizaContador('ordenproveedor',this.ordenproveedor)
          actualizaContador('ordeninsumos',this.ordeninsumos)
          actualizaContador('ordenrecetas',this.ordenrecetas)
          actualizaContador('ordenproformas',this.ordenproformas)
          actualizaContador('ordenresumenc',this.ordenresumenc)
          actualizaContador('ordencosteo',this.ordencosteo)
          actualizaContador('ordentrabajadores',this.ordentrabajadores)
          actualizaContador('orden_delivery',this.orden_delivery)
          actualizaContador('orden_movimientos',this.orden_movimientos)
          store.commit("dialogocontadores")
      }
 
     }

}
</script>