<template>
<v-container>
    <v-card>
        <v-card-text>
            <h4 class="text-center">RECIBE LOS PEDIDOS DE TUS CLIENTES DIRECTAMENTE CON NOSOTROS.</h4>
            <h4 class="text-center">SOLO ENVIA EL LINK DE PEDIDOS A TU CLIENTE, INGRESARA SU PEDIDO DESDE NUESTRA PLATAFORMA Y PODRAS VER SU ORDEN EN TIEMPO REAL.</h4>
            <h4 class="text-center">CONSULTA A NUESTROS ASESORES PARA ACTIAR EL SERVICIO</h4>
        </v-card-text>
    </v-card>
    <v-row class="mb-4 mt-4">
        <v-col cols="4" class="pa-3">
            <v-card>
                <v-container>
                    <v-img class="mx-auto" height="70" width="70" src="/pedidos.png"></v-img>
                    <h4 block class="text-center pa-1">Mi Carta Online</h4>
                </v-container>
            </v-card>
        </v-col>
        <v-col cols="4" class="pa-3">
            <v-card @click="ver_pedidos()">
                <v-container>
                    <v-img class="mx-auto" height="70" width="70" src="/maps.png"></v-img>
                    <h4 block class="text-center pa-1">Ver Pedidos</h4>
                </v-container>
            </v-card>
        </v-col>
        <v-col cols="4" class="pa-3">
            <v-card>
                <v-container>
                    <v-img class="mx-auto" height="70" width="70" src="/what.png"></v-img>
                    <h4 block class="text-center pa-1">Genera Link</h4>
                </v-container>
            </v-card>
        </v-col>
        <v-col cols="4" class="pa-3">
            <v-card>
                <v-container>
                    <v-img class="mx-auto" height="70" width="70" src="/chat.png"></v-img>
                    <h4 block class="text-center pa-1">Mi ChatBot</h4>
                </v-container>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
// @ is an alias to /src
import store from '@/store/index'
import {
    allCronogramapago
} from '../../db'
export default {
    name: 'panel',

    data() {
        return {

        }
    },
    created() {

    },
    methods: {
        ver_pedidos(){
            this.$router.push({
                path: '/reporte_pedidos'
            })
        }
    },

}
</script>
