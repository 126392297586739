<template>
  <div class="pa-3">

    <h3>LISTA DE INSUMOS</h3>
    <v-row>
      <v-col cols="6">
        <v-btn rounded elevation="10" block color="info" @click="obtenordenproducto()" small>NUEVO</v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn rounded elevation="10" block color="error" @click="irCategorias()" small>CATEGORIAS</v-btn>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="12">
        <v-text-field outlined dense v-model="buscar" append-icon="mdi-magnify" label="BUSCAR"></v-text-field>
      </v-col>
    </v-row>
    <v-card>
      <v-data-table :headers="headers" :items="listafiltrada" :search="buscar" dense>
        <template v-slot:item.actions="{ item }">
          <v-row>
            <v-col cols="12">
              <v-icon color="green" @click="editar(item.id), itemelimina = item.id">mdi-lead-pencil</v-icon>
            </v-col>
          </v-row>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize">
            SHOW
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogo" max-width="500px">
      <div>
        <v-system-bar window dark>
          <v-icon color="red" large @click="dialogo = !dialogo">mdi-close</v-icon>
          <v-switch v-model="activo" color="green" label="ACTIVO"></v-switch>
          <v-spacer></v-spacer>
          <v-icon color="green" large @click="save()">mdi-content-save</v-icon>
          <v-icon disabled color="red" large @click="dialogoElimina = !dialogoElimina">mdi-delete</v-icon>
        </v-system-bar>
      </div>

      <v-card class="pa-4">

        <v-row class="mt-n2">
          <v-col cols="6" sm="6" md="6">
            <v-text-field dense disabled v-model="id" label="ID"></v-text-field>
          </v-col>
          <v-col cols="6" sm="6" md="6">
            <v-select dense v-model="categoria" :items="arraycategoria" menu-props="auto" hide-details
              label="Categoria"></v-select>
          </v-col>
        </v-row>

        <v-row class="mt-n7">
          <v-col cols="12">
            <v-textarea dense outlined v-model="nombre" auto-grow filled label="Descripcion" rows="1"></v-textarea>
          </v-col>
        </v-row>

        <v-row class="mt-n9">
          <v-col cols="6" sm="6" md="6" xs="6">
            <v-text-field type="number" dense v-model="stock" label="Stock"></v-text-field>
          </v-col>

          <v-col cols="6" sm="6" md="6" xs="6">
            <v-select dense v-model="medida" :items="$store.state.medidas" menu-props="auto" hide-details
              label="Medida"></v-select>
          </v-col>
        </v-row>

        <v-row class="mt-n7">
          <v-col cols="6" sm="6" md="6">
            <v-text-field dense type="number" v-model="stock_min" label="Stock min"></v-text-field>
          </v-col>
          <v-col cols="6" sm="6" md="6">
            <v-text-field dense type="number" v-model="costo" label="Costo"></v-text-field>
          </v-col>
        </v-row>

      </v-card>

    </v-dialog>

    <v-dialog v-model="dialogoElimina" max-width="460px">
      <div>
        <v-system-bar window dark>
          <v-icon @click="dialogoElimina = !dialogoElimina">mdi-close</v-icon>
        </v-system-bar>
      </div>
      <v-card>
        <v-card-text>
          <h3> DESEA ELIMINAR PERMANENTEMENTE EL PRODUCTO? {{ itemelimina }}</h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="eliminar()">si</v-btn>
          <v-btn color="error" @click="dialogoElimina = !dialogoElimina">no</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogoFiltro" max-width="460px">
      <div>
        <v-system-bar window dark>
          <v-icon @click="dialogoFiltro = !dialogoFiltro">mdi-close</v-icon>
        </v-system-bar>
      </div>
      <v-card class="pa-3">
        <v-card-text>
          <v-select :items="arrayfiltroStock" label="Stock" outlined v-model="filtrostock"></v-select>
          <v-select :items="arrayfiltroestado" label="ESTADO" outlined v-model="filtroestado"></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="dialogoFiltro = !dialogoFiltro">FILTRAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import {
  allInsumos,
  nuevoInsumos,
  eliminaInsumos,
  obtenContador,
  allCategoriasInsumos,
  sumaContador,
  allCategorias
} from '../../db'
import store from '@/store/index'

export default {

  data: () => ({
    sumacon: false,
    itemelimina: '',
    dialogoElimina: false,
    dialogoFiltro: false,
    buscar: '',
    headers: [{
      text: 'id',
      align: 'start',
      sortable: false,
      value: 'id',
    },
    {
      text: 'nombre',
      value: 'nombre'
    },
    {
      text: 'categoria',
      value: 'categoria'
    },
    {
      text: 'costo',
      value: 'costo'
    },
    {
      text: 'stock',
      value: 'stock'
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    },
    ],
    desserts: [],
    id: '',
    activo: true,
    categoria: '',
    nombre: '',
    medida: 'UNIDAD',
    stock: '',
    stock_min: '',
    costo: '0',
    arraycategoria: [],
    dialogo: false,
    arrayfiltroStock: ['incluir 0', 'excluir 0'],
    filtrostock: 'incluir 0',
    arrayfiltroestado: ['TODOS', 'ACTIVO', 'INACTIVO'],
    filtroestado: 'ACTIVO',
  }),

  created() {
    this.initialize()
  },

  mounted() {
    allInsumos().on("value", this.onDataChange);
  },
  beforeDestroy() {
    allInsumos().off("value", this.onDataChange);
  },

  computed: {
    listafiltrada() {
      allInsumos().on("value", this.onDataChange);
      return this.desserts
    }

  },

  methods: {
    onDataChange(items) {
      let array = [];
      items.forEach((item) => {
        let data = item.val();
        let key = item.key;
        array.push(data);

      });

      this.desserts = array;

    },
    initialize() {
      allCategoriasInsumos().orderByChild("posicion").once("value").then((snapshot) => {
        snapshot.forEach((item) => {
          this.arraycategoria.push(
            item.val().nombre)
        })
      })
    },

    obtenordenproducto() {
      store.commit("dialogoprogress")
      this.sumacon = true
      obtenContador().once("value").then((snapshot) => {
        if (snapshot.exists()) {
          this.id = snapshot.val().ordeninsumos
          this.categoria = '1'
          this.nombre = ''
          this.medida = 'UNIDAD'
          this.stock = 1
          this.stock_min = 1
          this.costo = 0
          this.dialogo = true
          this.activo = true
        }
        store.commit("dialogoprogress")
      })
    },
    editar(id) {
      this.sumacon = false
      for (var i = 0; i < this.desserts.length; i++) {
        if (this.desserts[i].id == id) {
          this.id = this.desserts[i].id
          this.activo = this.desserts[i].activo
          this.categoria = this.desserts[i].categoria
          this.nombre = this.desserts[i].nombre
          this.medida = this.desserts[i].medida
          this.stock = this.desserts[i].stock
          this.stock_min = this.desserts[i].stock_min
          this.costo = this.desserts[i].costo

          this.dialogo = true
        }
      }
    },
    save() {
      if (this.categoria == 1 || this.categoria == '') {
        store.commit('dialogosnackbar', "DEBE CONSIDERAR UNA CATEGORIA VALIDA")
      } else {
        var array = {
          id: this.id,
          activo: this.activo,
          nombre: this.nombre.trim(),
          categoria: this.categoria,
          medida: this.medida,
          stock: this.stock,
          stock_min: this.stock_min,
          costo: this.costo,
        }
        nuevoInsumos(this.id, array)
        if (this.sumacon == true) {
          this.sumacontador()
        }
        this.dialogo = false

      }
    },
    eliminar() {
      eliminaInsumos(this.itemelimina)
      this.itemelimina = ''
      this.dialogoElimina = false
      this.dialogo = false
    },
    sumacontador() {
      sumaContador("ordeninsumos", parseInt(this.id) + 1)
    },
    irCategorias() {
      this.$router.push({
        name: "categoriasinsumos"
      })
    },

    redondear(valor) {
      return parseFloat(valor).toFixed(store.state.configuracion.decimal)
    },
  },
}
</script>
