<template>
  <v-dialog v-model="$store.state.dialogoinsumo" max-width="600px" persistent>
    <div>
      <v-system-bar window dark>
        <v-icon @click="$store.commit('dialogoinsumo')">mdi-close</v-icon>
        <v-spacer></v-spacer>
      </v-system-bar>
    </div>
    <v-card>


      <div>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-text-field class="mb-n1 mt-n2" outlined dense v-model="buscar" append-icon="mdi-magnify" label="Buscar"
            single-line hide-details></v-text-field>
        </v-card-title>

        <v-simple-table fixed-header height="400px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Nombre
                </th>
                <th class="text-left">
                  Medida
                </th>
                <th class="text-left">
                  Stock
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in listafiltrada" :key="item.id"
                @click="agregaCatalogo(item), $store.commit('dialogoinsumo')">
                <td>{{ item.nombre }}</td>
                <td>{{ item.medida }}</td>
                <td>{{ item.stock }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-card>

  </v-dialog>
</template>

<script>
import store from '@/store/index'
import { allInsumos } from '../../db'
export default {

  data() {
    return {
      arrayiconos: [],
      tamañoarray: 0,
      categoriaselecta: '',
      buscar: '',
      desserts: []
    }
  },
  created() {
    allInsumos().on("value", this.onDataChange);
  },

  mounted() {
    allInsumos().on("value", this.onDataChange);
  },
  beforeDestroy() {
    allInsumos().off("value", this.onDataChange);
  },
  methods: {
    onDataChange(items) {
      let array = [];
      items.forEach((item) => {
        let data = item.val();
        let key = item.key;
        array.push({
          id: key,
          activo: data.activo,
          nombre: data.nombre,
          categoria: data.categoria,
          medida: data.medida,
          stock: data.stock,
          costo: data.costo,
        });

      });

      this.desserts = array;

    },
    initialize() {
      //this.activaproductos=false
      this.arrayiconos = store.state.categorias
    },
    iraproductos(item) {
      this.buscar = ''
      this.categoriaselecta = item.nombre
      if (this.listafiltrada != '') {

      }

    },
    agregaCatalogo(item) {
      this.$emit('array', item)

    }
  },
  computed: {
    listafiltrada() {
      var invent = this.desserts
      if (store.state.configuracion.ordencategorias) {
        return invent.filter((item) =>
          (item.activo) == true)
          .filter((item) => (item.categoria)
            .toLowerCase().includes(this.categoriaselecta.toLowerCase()))
          .filter((item) => (item.nombre)
            .toLowerCase().includes(this.buscar.toLowerCase()))
      } else {
        return invent.filter((item) =>
          (item.activo) == true)
          .filter((item) => (item.nombre)
            .toLowerCase().includes(this.buscar.toLowerCase()))
      }


    }
  }
}
</script>