<template>
  <v-container>

    <v-row class="mb-4">
      <v-col cols="6" class="pa-3" md="6" sm="6" xs="6">
        <v-card @click.prevent="router('paneladmin')">
          <v-container>
            <v-img class="mx-auto" height="70" width="70" src="/tienda.png"></v-img>
            <h4 block class="text-center pa-1">EMPRESAS</h4>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="6" class="pa-3" md="6" sm="6" xs="6">
        <v-card @click.prevent="$store.commit('dialogoiconos', true)">
          <v-container>
            <v-img class="mx-auto" height="70" width="70" src="/paint.png"></v-img>
            <h4 block class="text-center pa-1">ICONOS</h4>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="6" class="pa-3" md="6" sm="6" xs="6">
        <v-card @click.prevent="router('lista_pagos')">
          <v-container>
            <v-img class="mx-auto" height="70" width="70" src="/cash.png"></v-img>
            <h4 block class="text-center pa-1">PAGOS</h4>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="6" class="pa-3" md="6" sm="6" xs="6">
        <v-card @click.prevent="router('revision')">
          <v-container>
            <v-img class="mx-auto" height="70" width="70" src="/sunat.png"></v-img>
            <h4 block class="text-center pa-1">REVISION</h4>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <dialogoiconos />
  </v-container>
</template>

<script>
// @ is an alias to /src
import dialogoiconos from '@/components/dialogos/iconos'
import store from '@/store/index'
import {
  allCronogramapago
} from '../../db'
export default {
  name: 'panel',

  components: {
    dialogoiconos
  },
  data() {
    return {

    }
  },
  created() {

  },
  methods: {

    router(view) {
      this.$router.push({
        name: view
      })
    },
  },

}
</script>
