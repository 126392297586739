<template>
    <div grid-list-xs class="pa-6">
        <v-row v-if="true">
            <v-col cols="6">
                <v-btn x-small color="success" block @click="dial_obten = true">Obtener Empresas</v-btn>
                <v-btn class="mt-2" x-small color="info" block @click="ingresa_pagos()">Ingresa Pagos</v-btn>
            </v-col>
            <v-col cols="6">
                <v-text-field dense outlined v-model="buscar" append-icon="mdi-magnify" label="BUSCAR"></v-text-field>
            </v-col>
        </v-row>

        <v-simple-table class="elevation-4" fixed-header height="70vh" dense>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">
                            BD
                        </th>
                        <th class="text-left">
                            EMPRESA
                        </th>
                        <th class="text-left">
                            Telefono
                        </th>
                        <th class="text-left">
                            Monto
                        </th>
                        <th class="text-left">
                            Plan
                        </th>
                        <th class="text-left">
                            Fecha Pagos
                        </th>
                        <th class="text-left">
                            Nota
                        </th>
                        <th class="text-left">
                            Accion
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in listafiltrada" :key="item.id">
                        <td style="font-size:75%;">{{ item.bd }}</td>
                        <td style="font-size:75%;">{{ item.ruc }} - {{ item.name }} - {{ extrae(item.usuario) }}</td>
                        <td style="font-size:75%;">{{ item.telefono }}</td>
                        <td style="font-size:75%;">S/.{{ item.monto_pago }}</td>
                        <td style="font-size:75%;">{{ item.modo }}</td>
                        <td style="font-size:75%;">{{ conviertefecha(item.fecha_inicio) }}</td>
                        <td style="font-size:75%;">{{ item.nota }}</td>
                        <td style="font-size:75%;">
                            <v-row>
                                <v-col cols="12">
                                    <v-icon color="green" @click="editar_emp(item)">mdi-lead-pencil</v-icon>
                                </v-col>
                            </v-row>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <v-dialog v-model="dial_datos" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_datos = !dial_datos">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-switch v-model="activo" color="green" label="ACTIVO"></v-switch>
                    <v-spacer></v-spacer>
                    <v-icon color="green" large @click="save()">mdi-content-save</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-row class="" dense>
                    <v-col cols="6">
                        <v-text-field type="date" outlined dense v-model="fecha_inicio" label="Fecha Inicio"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field outlined type="number" dense v-model="telefono" label="Telefono"></v-text-field>
                    </v-col>
                    <v-col cols="6" class="mt-n6">
                        <v-select outlined dense v-model="modo" :items="['mensual', 'anual']" menu-props="auto" hide-details
                            label="Plan"></v-select>
                    </v-col>
                    <v-col cols="6" class="mt-n6">
                        <v-text-field outlined type="number" dense v-model="monto_pago" label="Monto Pago"></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mt-n6">
                        <v-textarea outlined dense v-model="nota" auto-grow filled label="Nota" rows="1"></v-textarea>
                    </v-col>
                </v-row>

            </v-card>
        </v-dialog>

        <v-dialog v-model="dial_obten" max-width="460px">
            <div>
                <v-system-bar window dark>

                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-row class="" dense>
                    <v-col cols="12">
                        <v-btn class="mt-2" small color="info" block @click="obtener_empresas()">Sincroniza
                            Domotica-peru</v-btn>
                    </v-col>
                    <v-col cols="12">
                        <v-btn class="mt-2" small color="success" block @click="factura_peru()">Sincroniza
                            factura-peru</v-btn>
                    </v-col>
                    <v-col cols="12">
                        <v-btn class="mt-2" small color="warning" block @click="dial_agrega = true">Agrega Manual</v-btn>
                    </v-col>
                </v-row>

            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_agrega" max-width="960px">

            <v-card>


                <v-container>
                    <v-row>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="name" label="Empresa"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="namecomercial" label="Nombre Comercial"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="ruc" type="number" append-icon="mdi-magnify"
                                @click:append="BuscarDocumento()" @keyup.enter="BuscarDocumento()"
                                label="Ruc"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="departamento" label="Departamento"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="provincia" label="Provincia"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="distrito" label="Distrito"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="direccion" label="Direccion"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="usuario" label="Usuario"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="bd" label="BD"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="link" label="LINK"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>


                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false">
                        Cancel
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="guarda_empresa()">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {
    allempresas_pagos,
    allEmpresas,
    nueva_lista_pago
} from '../../db'
import firebase from 'firebase'
import axios from "axios"
import store from '@/store/index'
import moment from 'moment'
export default {
    data: () => ({
        dial_obten: false,
        dial_agrega: false,
        buscar: '',
        desserts: [],
        dial_datos: false,
        item_selecto: [],
        fecha_inicio: moment(String(new Date)).format('YYYY-MM-DD'),
        telefono: '',
        modo: 'mensual',
        monto_pago: '',
        nota: '',
        activo: true,
        name: '',
        namecomercial: '',
        ruc: '',
        departamento: '',
        provincia: '',
        distrito: '',
        direccion: '',
        usuario: '',
        bd: '',
        link: ''
    }),
    mounted() {
        allempresas_pagos().on("value", this.onDataChange);
    },
    beforeDestroy() {
        allempresas_pagos().off("value", this.onDataChange);
    },

    computed: {
        listafiltrada() {
            return this.desserts.filter((item) => (item.bd + item.ruc + item.name + item.usuario)
                .toLowerCase().includes(this.buscar.toLowerCase()))
        }

    },


    methods: {
        async guarda_empresa() {
            if (!confirm('Esta seguro de guardar?')) {
                return
            }
            store.commit("dialogoprogress")
            var array = {
                id: this.bd + this.ruc,
                name: this.name,
                namecomercial: this.namecomercial,
                ruc: this.ruc,
                departamento: this.departamento,
                provincia: this.provincia,
                distrito: this.distrito,
                direccion: this.direccion,
                ubigeo: '',
                anexo: '',
                usuario: this.usuario,
                clave: '',
                usuariosol: '',
                clavesol: '',
                nombrefirma: '',
                passfirma: '',
                PersonaID: '',
                Token: '',
                bd: this.bd,
                restaurante: false,
                pruebas: false,
                caja2: false,
                ubicacion: this.link
            }
            await nueva_lista_pago(array.id, array)
            this.dial_agrega = false
            store.commit("dialogoprogress")
        },
        async save() {
            store.commit("dialogoprogress")
            nueva_lista_pago(this.item_selecto.id + '/telefono', this.telefono)
            nueva_lista_pago(this.item_selecto.id + '/modo', this.modo)
            nueva_lista_pago(this.item_selecto.id + '/monto_pago', this.monto_pago)
            nueva_lista_pago(this.item_selecto.id + '/nota', this.nota)
            nueva_lista_pago(this.item_selecto.id + '/activo', this.activo)
            await nueva_lista_pago(this.item_selecto.id + '/fecha_inicio', moment(String(this.fecha_inicio)) / 1000)
            this.dial_datos = false
            store.commit("dialogoprogress")
        },
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let key = item.key;
                let data = item.val();
                data.key = key
                array.push(data);
            });
            this.desserts = array;
        },
        editar_emp(data) {
            console.log(data)
            if (confirm('Esta seguro de editar?')) {
                this.item_selecto = data
                this.telefono = data.telefono
                this.modo = data.modo
                this.monto_pago = data.monto_pago
                this.nota = data.nota
                this.activo = data.activo
                this.fecha_inicio = moment.unix(data.fecha_inicio).format('YYYY-MM-DD')
                this.dial_datos = true
            }
        },
        async factura_peru() {
            store.commit("dialogoprogress")
            var array = await this.api_rest('', 'lista_empresas')

            for (var i = 0; i < array.length; i++) {
                var data = array[i]
                var arr = {
                    id: data.bd + data.ruc,
                    name: data.name,
                    namecomercial: data.namecomercial,
                    ruc: data.ruc,
                    departamento: data.departamento,
                    provincia: data.provincia,
                    distrito: data.distrito,
                    direccion: data.direccion,
                    ubigeo: data.ubigeo,
                    anexo: data.anexo,
                    usuario: data.usuario,
                    clave: data.clave,
                    usuariosol: data.usuariosol,
                    clavesol: data.clavesol,
                    nombrefirma: data.nombrefirma,
                    passfirma: data.passfirma,
                    PersonaID: data.PersonaID,
                    Token: data.Token,
                    bd: data.bd,
                    restaurante: data.restaurante,
                    pruebas: data.pruebas,
                    caja2: data.caja2,
                    ubicacion: 'factura_peru'
                }
                var a = this.desserts.find(item => item.id == arr.id)
                if (a == undefined) {
                    console.log(arr.id, arr)
                    await nueva_lista_pago(arr.id, arr)
                }

            }
            store.commit("dialogoprogress")
        },
        api_rest(data, metodo) {
            var a = axios({
                method: 'POST',
                url: 'https://us-central1-factura-peru.cloudfunctions.net/prueba',
                //url: 'http://localhost:5000/factura-peru/us-central1/prueba',
                headers: {},
                data: {
                    "bd": store.state.baseDatos.bd,
                    "data": data,
                    "metodo": metodo
                }
            }).then(response => {
                return response.data.data
            })
            return a
        },
        async obtener_empresas() {
            var array = []
            store.commit("dialogoprogress")
            var snap = await allEmpresas().once('value')
            snap.forEach((item) => {
                array.push(item.val())
            })
            for (var i = 0; i < array.length; i++) {
                var data = array[i]
                var arr = {
                    id: data.bd + data.ruc,
                    name: data.name,
                    namecomercial: data.namecomercial,
                    ruc: data.ruc,
                    departamento: data.departamento,
                    provincia: data.provincia,
                    distrito: data.distrito,
                    direccion: data.direccion,
                    ubigeo: data.ubigeo,
                    anexo: data.anexo,
                    usuario: data.usuario,
                    clave: data.clave,
                    usuariosol: data.usuariosol,
                    clavesol: data.clavesol,
                    nombrefirma: data.nombrefirma,
                    passfirma: data.passfirma,
                    PersonaID: data.PersonaID,
                    Token: data.Token,
                    bd: data.bd,
                    restaurante: data.restaurante,
                    pruebas: data.pruebas,
                    caja2: data.caja2,
                    ubicacion: 'domotica-peru'
                }
                var a = this.desserts.find(item => item.id == arr.id)
                if (a == undefined) {
                    await nueva_lista_pago(arr.id, arr)
                }

            }
            store.commit("dialogoprogress")
        },

        conviertefecha(date) {
            return moment.unix(date).format('DD/MM/YYYY')
        },
        extrae(data) {
            return data.substring(0, data.indexOf('@') + 1)
        },
        ingresa_pagos() {
            this.$router.push({
                name: 'ingresa_pagos'
            })
        },
        BuscarDocumento() {
            if (this.ruc.length == 11) {
                store.commit("dialogoprogress")
                var self = this,
                    token = '80a4a1c5f2e97c2d78fcd5074cd64ff0a29007ef91880ad2c214af67a084433d'
                axios
                    .get('https://apiperu.dev/api/ruc/' + this.ruc, {
                        headers: {
                            Content_Type: 'application/json',
                            authorization: ' Bearer ' + token
                        }
                    })
                    .then(response => (this.info = response.data,
                        self.llenardatos(response.data.data),
                        store.commit("dialogoprogress")
                    ))
                    .catch(error => {
                        store.commit("dialogoprogress"),
                            store.commit('dialogosnackbar', "DOCUMENTO INVALIDO")
                    })
            } else {
                store.commit('dialogosnackbar', "DOCUMENTO INVALIDO")
            }
        },
        llenardatos(data) {
            this.name = data.nombre_o_razon_social
            this.direccion = data.direccion
            this.departamento = data.departamento
            this.provincia = data.provincia
            this.distrito = data.distrito
            this.ubigeo = data.ubigeo_sunat

        },
    },
}
</script>
