<template>
    <v-dialog v-model="$store.state.dialogoConfiguracion" max-width="480px">
        <div>
            <v-system-bar window dark>
                <v-icon @click="$store.commit('dialogoConfiguracion')">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <v-icon large color="green" @click="graba()">mdi-content-save</v-icon>
            </v-system-bar>
        </div>

        <v-card class="pa-5">
            <div>

                <v-row dense>
                    <v-col cols="6">
                        <v-checkbox dense v-model="inventario" label="Control Inventario"></v-checkbox>
                    </v-col>

                    <v-col cols="6">
                        <v-checkbox dense v-model="sunat" label="Envio Directo Sunat"></v-checkbox>
                    </v-col>
                    <v-col dense cols="6">
                        <v-checkbox dense v-model="flujocaja" label="Flujo caja"></v-checkbox>
                    </v-col>
                    <v-col dense cols="6">
                        <v-checkbox dense v-model="calvuelto" label="Calcula vuelto"></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                        <v-checkbox dense v-model="codbarras" label="Codigo Barras"></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                        <v-checkbox dense v-model="ordencategorias" label="X Categorias"></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                        <v-checkbox dense v-model="obscomprobante" label="Obs comprobantes"></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                        <v-checkbox dense v-model="comisiones" label="Comisiones Empleado"></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                        <v-checkbox dense v-model="persistencia_catalogo" label="Persistencia catalogo"></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                        <v-checkbox dense v-model="mostrar_yape" label="Mostrar YAPE"></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                        <v-checkbox dense v-model="ventas_credito" label="Venta Credito"></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field dense v-model="decimal" label="Decimales"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field dense v-model="igv" label="IGV" suffix="%"></v-text-field>
                    </v-col>

                    <v-col cols="6">
                        <v-text-field dense v-model="icbper" label="ICBPER"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-select dense v-model="pordefecto" :items="arraydoc" menu-props="auto" hide-details
                            label="Comprobante Defecto"></v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field type="number" dense v-model="cargoxconsumo" label="Cargo x cons. %"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-select :items="arrayOperacion" label="Operacion X Defecto" dense outlined
                            v-model="operacion"></v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-checkbox dense v-model="guarda_mesas" label="Guarda hist. mesas"></v-checkbox>
                    </v-col>
                </v-row>
            </div>

        </v-card>

    </v-dialog>
</template>

<script>
import {
    allConfigura,
    grabaConfigura
} from '../../db'
import store from '@/store/index'

export default {
    data() {
        return {
            inventario: false,
            sunat: false,
            codbarras: false,
            flujocaja: false,
            puntosclientes: false,
            controlplatos: false,
            ordencategorias: false,
            calvuelto: false,
            obscomprobante: false,
            comisiones: false,
            persistencia_catalogo: false,
            ventas_credito: false,
            cargoxconsumo: 0,
            decimal: 2,
            igv: '18',
            icbper: '0.30',
            pordefecto: 'T',
            arraydoc: ['T', 'B', 'F'],
            arrayOperacion: [
                'GRAVADA',
                'EXONERADA'
            ],
            operacion: 'GRAVADA',
            guarda_mesas: false,
            mostrar_yape: false,
        }
    },
    created() {
        this.inicializar()
    },
    methods: {
        inicializar() {
            allConfigura().once("value").then((snapshot) => {
                if (snapshot.val() != null) {
                    this.sunat = snapshot.val().sunat
                    this.inventario = snapshot.val().inventario
                    this.decimal = snapshot.val().decimal
                    this.codbarras = snapshot.val().codbarras
                    this.igv = snapshot.val().igv
                    this.icbper = snapshot.val().icbper
                    this.flujocaja = snapshot.val().flujocaja
                    this.pordefecto = snapshot.val().defecto
                    this.controlplatos = snapshot.val().controlplatos
                    this.ordencategorias = snapshot.val().ordencategorias
                    this.calvuelto = snapshot.val().calvuelto
                    this.obscomprobante = snapshot.val().obscomprobante
                    this.comisiones = snapshot.val().comisiones
                    this.cargoxconsumo = snapshot.val().cargoxconsumo
                    this.persistencia_catalogo = snapshot.val().persistencia_catalogo
                    this.mostrar_yape = snapshot.val().mostrar_yape
                    this.ventas_credito = snapshot.val().ventas_credito
                    if (snapshot.val().ventas_credito == undefined) {
                        this.ventas_credito = false
                    }
                    if (snapshot.val().mostrar_yape == undefined) {
                        this.mostrar_yape = false
                    }
                    if (snapshot.val().operacion == undefined) {
                        this.operacion = 'GRAVADA'
                    } else {
                        this.operacion = snapshot.val().operacion
                    }
                    if (snapshot.val().guarda_mesas == undefined) {
                        this.guarda_mesas = false
                    } else {
                        this.guarda_mesas = snapshot.val().guarda_mesas
                    }
                }

            })
        },
        graba() {
            if (parseFloat(this.igv) > 0) {
                grabaConfigura("inventario", this.inventario)
                grabaConfigura("sunat", this.sunat)
                grabaConfigura("decimal", this.decimal)
                grabaConfigura("codbarras", this.codbarras)
                grabaConfigura("igv", this.igv)
                grabaConfigura("icbper", this.icbper)
                grabaConfigura("flujocaja", this.flujocaja)
                grabaConfigura("defecto", this.pordefecto)
                grabaConfigura("controlplatos", this.controlplatos)
                grabaConfigura("ordencategorias", this.ordencategorias)
                grabaConfigura("calvuelto", this.calvuelto)
                grabaConfigura("obscomprobante", this.obscomprobante)
                grabaConfigura("comisiones", this.comisiones)
                grabaConfigura("cargoxconsumo", this.cargoxconsumo)
                grabaConfigura("persistencia_catalogo", this.persistencia_catalogo)
                grabaConfigura('operacion', this.operacion)
                grabaConfigura('guarda_mesas', this.guarda_mesas)
                grabaConfigura('mostrar_yape', this.mostrar_yape)
                grabaConfigura('ventas_credito', this.ventas_credito)
                store.commit('dialogoConfiguracion')
            } else {
                alert('IGV NO PUEDE SER IGUAL A 0')
            }
        }
    }
}
</script>
