<template>
    <div class="pa-3">
        <v-card class="mt-1">

            <v-card-text>
                <v-row class="mt-n4">
                    <v-col cols="6" xs="6">
                        <v-text-field type="date" outlined dense v-model="date" label="Emision"></v-text-field>
                    </v-col>
                    <v-col cols="6" xs="6">
                        <v-text-field type="number" outlined dense rounded id="cod" v-model="codigo" label="Codigo"
                            append-icon="mdi-barcode-scan" @click:append="buscaProducto()"
                            @keyup.enter="buscaProducto()"
                            :autofocus="$store.state.configuracion.codbarras"></v-text-field>
                    </v-col>

                </v-row>
            </v-card-text>
            <v-row class="mx-auto mt-n8" dense>
                <v-col cols="4" class="text-center">
                    <v-btn small elevation="3" rounded color="success" @click="$store.commit('dialogoproductos')">
                        Catalogo
                        <v-icon color="white" class="mx-auto text--center" small>mdi-archive-arrow-down</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="4" class="text-center">
                    <v-btn small elevation="6" rounded color="info" @click="dialogAgrega = !dialogAgrega">
                        Agregar
                        <v-icon color="white" class="mx-auto text--center" small>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="4" class="text-center" v-if="$store.state.baseDatos.restaurante == true">
                    <v-btn small elevation="6" rounded color="warning" @click="buscaMesa(), separacuentas = true">
                        Dividir <v-icon color="white" class="mx-auto text--center" small>mdi-content-cut</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>

        <v-card class="mt-5">

            <v-simple-table dark fixed-header dense height="50vh">
                <template v-slot:default>

                    <thead>
                        <tr>
                            <th class="text-left">
                                Und.
                            </th>
                            <th class="text-left">
                                Descripcion
                            </th>
                            <th class="text-left">
                                Precio
                            </th>
                            <th v-if="sumaDescuentos() != 0" class="text-left">
                                Desc.
                            </th>
                            <th class="text-left">
                                Total
                            </th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr v-for="item in listaproductos" :key="item.id" @click.prevent="editaProducto(item.id)">
                            <td>{{ item.cantidad }}</td>
                            <td>{{ item.nombre }}</td>
                            <td>S/.{{ item.precioedita }}</td>
                            <td class="red--text" v-if="sumaDescuentos() != 0">S/.{{ redondear(item.preciodescuento) }}
                            </td>
                            <td>S/.{{ redondear((item.precioedita * item.cantidad) - item.preciodescuento) }}</td>
                        </tr>
                    </tbody>
                    <v-row v-if="listaproductos == '' && $store.state.baseDatos.restaurante == true" class="mt-8">
                        <v-icon color="red" @click="buscaMesa(), esdelivery = false, separacuentas = false"
                            class="mx-auto text--center" large>mdi-table-chair</v-icon>
                    </v-row>
                    <v-row v-if="listaproductos == '' && $store.state.baseDatos.restaurante == true" class="mt-8">
                        <v-icon color="red" @click="buscaDelivery(), esdelivery = true" class="mx-auto text--center"
                            large>mdi-moped-electric-outline</v-icon>
                    </v-row>
                </template>

            </v-simple-table>

        </v-card>

        <v-card class="mt-3 pa-2">
            <v-row>
                <v-col cols="12" md="4" sm="4" xs="12">
                    <v-list-item-content class="ml-3">
                        <v-list-item-subtitle v-if="sumaDescuentos() != 0">
                            <h3>SubTotal: S/.{{ sumaTotal() }}</h3>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="sumaDescuentos() != 0">
                            <h4 class="red--text">Descuentos: S/.{{ sumaDescuentos() }}</h4>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>

                            <h2>Total: S/.{{ redondear(sumaTotal() - sumaDescuentos()) }}</h2>

                        </v-list-item-subtitle>

                    </v-list-item-content>

                </v-col>

                <v-col cols="6" md="4" sm="4" xs="6">

                </v-col>
                <v-col cols="6" md="4" sm="4" xs="6">
                    <v-btn block elevation="15" rounded v-if="listaproductos != ''" color="error"
                        @click="consultaCorrelativo()">
                        Cobrar
                    </v-btn>
                </v-col>

            </v-row>
        </v-card>

        <v-dialog v-model="dialogo_separa" max-width="490">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogo_separa = false">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <h4 class="text-center">SOLO PARA DIVIDIR CUENTAS</h4>
                <v-simple-table dark fixed-header height="350px" dense>
                    <template v-slot:default>

                        <thead>
                            <tr>

                                <th class="text-left">
                                    Descripcion
                                </th>
                                <th class="text-left">
                                    Consumido
                                </th>
                                <th class="text-left">
                                    cantidad.
                                </th>
                            </tr>
                        </thead>

                        <tbody>

                            <tr v-for="item in array_separa" :key="item.uuid">

                                <td>{{ item.nombre }}</td>
                                <td>{{ item.cantidad }}</td>
                                <td>
                                    <v-icon large @click="suma_separa(item)" color="green">mdi-plus</v-icon>
                                    {{ item.cantidadedita }}
                                    <v-icon large @click="resta_separa(item)" color="red">mdi-minus</v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </template>

                </v-simple-table>
                <v-card-title primary-title>

                </v-card-title>
                <v-btn color="success" block @click="graba_separacion()">Continuar</v-btn>

            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogAgrega" max-width="390">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogAgrega = false">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-row class="mb-n12">
                    <v-col cols="6" sm="6" md="6">
                        <v-select :items="arraytipoProducto" label="Tipo" dense outlined
                            v-model="tipoproducto"></v-select>
                    </v-col>
                    <v-col cols="6" sm="6" md="6">
                        <v-select :items="arrayOperacion" label="Operacion" dense outlined
                            v-model="tipooperacion"></v-select>
                    </v-col>
                </v-row>
                <v-row class="mx-auto mt-4">
                    <v-col cols="6" xs="6">
                        <v-text-field type="number" v-model="cantidadSinCodigo" label="Cantidad"></v-text-field>
                    </v-col>
                    <v-col cols="6" xs="6">
                        <v-text-field type="number" v-model="precioSinCodigo" label="Precio"></v-text-field>
                    </v-col>

                </v-row>
                <v-select class="pa-3" v-model="medidasincodigo" :items="$store.state.medidas" menu-props="auto"
                    hide-details label="Medida"></v-select>

                <v-text-field @keyup.enter="agregaSinCatalogo()" type="text" class="pa-3" v-model="nombreSincodigo"
                    label="Descripcion"></v-text-field>

                <v-card-actions>

                    <v-spacer></v-spacer>

                    <v-btn color="green darken-1" text @click="agregaSinCatalogo()">
                        Agregar
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

        <v-dialog v-model="dialogoProducto" max-width="390">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogoProducto = false">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">

                <v-row class="mx-auto mt-4 text-center" dense v-if="$store.state.permisos.caja_edita_cantidad">

                    <v-col cols="4" xs="4">
                        <v-icon @click="suma()" color="green">mdi-plus</v-icon>
                    </v-col>

                    <v-col cols="4" xs="4">
                        <v-text-field dense @keyup.enter="grabaEdita()" type="number" outlined v-model="cantidadEdita"
                            label="Cantidad"></v-text-field>
                    </v-col>
                    <v-col cols="4" xs="4">
                        <v-icon @click="resta()" color="red">mdi-minus</v-icon>
                    </v-col>

                </v-row>
                <v-row class="mx-auto text-center" dense>
                    <v-col cols="12" class="mb-n4 mt-n4">
                        <v-text-field v-if="$store.state.permisos.caja_edita_precio" dense @keyup.enter="grabaEdita()"
                            class="pa-3" v-model="nombreEdita" label="Nombre"></v-text-field>
                    </v-col>
                    <v-col cols="6" xs="6" v-if="$store.state.permisos.caja_edita_precio">
                        <v-text-field dense @keyup.enter="grabaEdita()" type="number" class="pa-3" v-model="precioedita"
                            label="Precio"></v-text-field>
                    </v-col>
                    <v-col cols="6" xs="6">
                        <v-text-field dense @keyup.enter="grabaEdita()" type="number" class="pa-3"
                            v-model="preciodescuento" label="Descuento"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mx-auto mt-n6 text-center" dense>
                    <v-col cols="6" xs="6">
                        <v-text-field dense @keyup.enter="grabaEdita()" type="number" class="pa-3" v-model="porcentaje"
                            append-icon="%" label="Descuento %"></v-text-field>
                    </v-col>
                    <v-col cols="6" xs="6">
                        <h4 class="pa-3">S/.{{ conversion }}</h4>
                    </v-col>
                </v-row>

                <v-card-actions class="mt-n6">

                    <v-btn color="red darken-1" text @click="eliminaedita()">
                        Elimina
                    </v-btn>

                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="grabaEdita()">
                        Graba
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog v-model="dialogoMesas" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogoMesas = !dialogoMesas">mdi-close</v-icon>
                </v-system-bar>
            </div>

            <v-card>

                <v-simple-table height="500px">
                    <template v-slot:default>
                        <thead>

                            <tr>
                                <th class="text-left">
                                    Numero Mesa
                                </th>
                                <th class="text-left">
                                    Accion
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in mesasActivas" :key="item.numeromesa">
                                <td>{{ item.cliente }}</td>
                                <td>
                                    <v-icon @click="copiaDataMesa(item.numeromesa)" color="red">mdi-magnify</v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>

            </v-card>

        </v-dialog>
        <cobrar />
        <cateogrias @array="agregaCatalogo($event)" />
    </div>
</template>

<script>
import {
    nuevaComanda,
    buscaComanda,
    allComandas,
    alldelivery,
    eliminaComanda,
    eliminaComandaDelivery,
    eliminaDelivery,
    grabaEstadomesa,
    buscaComandaDelivery,
    grabaObservacionMesa
} from '../db'
import {
    guardaComprobante
} from '../servidorsunat'
import moment from 'moment'
import {
    pdfGenera
} from '../pdf'
import store from '@/store/index'
import axios from "axios"
import cateogrias from '@/components/dialogos/dialogocatalogo'
import cobrar from '@/components/dialogos/dialogo_cobrar'
export default {
    name: 'caja',

    components: {
        cateogrias,
        cobrar
    },

    data() {
        return {
            date: moment(String(new Date)).format('YYYY-MM-DD'),
            dialogAgrega: false,
            dialogoProducto: false,
            dialogCobra: false,
            dialogoMesas: false,
            codigo: '',
            listaproductos: [],
            mesasActivas: [],
            dataMesa: [],
            idmesa: 'xx',
            buscar: '',
            codigoedita: '',
            cantidadEdita: '',
            precioedita: '',
            nombreEdita: '',
            preciodescuento: '',
            cantidadSinCodigo: 1,
            precioSinCodigo: '',
            medidasincodigo: 'UNIDAD',
            nombreSincodigo: '',
            tipocomprobante: 'T',
            correlativo: '',
            radios: 'EFECTIVO',
            documentos: [
                'DNI',
                'RUC',
                'Pasaporte',
                'Carnet de Extranjeria'
            ],
            documento: 'DNI',
            ordenboleta: '',
            ordenticket: '',
            ordenfactura: '',
            ordenproformas: '',
            numero: '',
            nombreCompleto: '',
            direccion: '',
            esdelivery: false,
            ordenflujo: '',
            modopagos: [],
            arraytipoProducto: ['BIEN', 'SERVICIO'],
            arrayOperacion: [
                'GRAVADA',
                'EXONERADA'
            ],
            tipooperacion: 'GRAVADA',
            tipoproducto: "BIEN",
            separacuentas: false,
            dialogocliente: false,
            clienteslista: [],
            telefono: '',
            refcliente: '',
            telfcliente: '',
            e1: 1,
            calculavuelto: 0,
            paravuelto: '',
            focusvuelto: true,
            dialogocredito: false,
            cuotasCredito: [],
            observacion: '',
            dialogoempleado: false,
            empleadoslista: [],
            empleado: '',
            dniempleado: '',
            dialogo_separa: false,
            array_separa: [],
            array_comanda: [],
            mesero: '',
            porcentaje: 0,
            obs: ''
        }
    },
    created() {
        if (Boolean(store.state.configuracion.operacion)) {
            console.log(store.state.configuracion.operacion)
            this.tipooperacion = store.state.configuracion.operacion
        }
    },

    mounted() {

    },
    methods: {
        suma_separa(item) {
            for (var i = 0; i < this.array_separa.length; i++) {
                if (this.array_separa[i].uuid == item.uuid) {
                    if (this.array_separa[i].cantidadedita < this.array_separa[i].cantidad) {
                        this.array_separa[i].cantidadedita++
                    }
                }
            }
        },
        resta_separa(item) {
            for (var i = 0; i < this.array_separa.length; i++) {
                if (this.array_separa[i].uuid == item.uuid) {
                    if (this.array_separa[i].cantidadedita > 0) {
                        this.array_separa[i].cantidadedita--
                    }

                }
            }
        },
        graba_separacion() {
            this.listaproductos = []
            for (var i = 0; i < this.array_separa.length; i++) {
                var data = this.array_separa[i]
                if (data.cantidadedita != 0) {
                    this.listaproductos.push({
                        id: data.id,
                        codbarra: data.id,
                        cantidad: data.cantidadedita,
                        nombre: data.nombre,
                        medida: data.medida,
                        categoria: data.categoria,
                        precio: data.precio,
                        stock: data.stock,
                        precioedita: data.precio,
                        preciodescuento: data.preciodescuento,
                        costo: 0,
                        cocina: data.cocina,
                        tipoproducto: data.tipoproducto,
                        operacion: data.operacion,
                        icbper: 'false',
                        controstock: data.controstock,
                        cargoxconsumo: data.cargoxconsumo
                    })
                }
            }
            this.dialogo_separa = false
        },
        elimina_separa() {
            console.log("aqui")
            var array = []
            for (var e = 0; e < this.array_separa.length; e++) {
                if (this.array_separa[e].cantidadedita < this.array_separa[e].cantidad && this.array_separa[e].cantidadedita != 0) {
                    var sum = parseFloat(this.array_separa[e].cantidad) - parseFloat(this.array_separa[e].cantidadedita)
                    this.array_comanda[e].cantidad = sum
                    array.push(this.array_comanda[e])
                }
                if (this.array_separa[e].cantidadedita == 0) {
                    array.push(this.array_comanda[e])
                }
            }
            if (array == '') {
                grabaEstadomesa(this.idmesa, "#46FF00")
                grabaObservacionMesa(this.idmesa, "")
                eliminaComanda(this.idmesa)
            } else {
                nuevaComanda(this.idmesa, array).then(a => {
                    this.separacuentas = false
                })
            }

        },
        buscaProducto() {
            var self = this
            if (store.state.configuracion.inventario) {
                var array = this.buscarArrayproducto(store.state.productos, this.codigo)
            } else {
                var array = this.buscarArrayproductosinstock(store.state.productos, this.codigo)
            }

            if (this.listaproductos != '') {
                if (this.buscarArray(this.listaproductos, array.id) == '') {
                    if (array != '') {
                        this.listaproductos.push(array)
                        this.codigo = ''
                    } else {
                        store.commit('dialogosnackbar', "Producto no Existe o sin Stock")
                        this.codigo = ''
                    }

                } else {
                    this.sumaproductos(array)
                    this.codigo = ''
                }
            } else {
                if (array != '') {
                    this.listaproductos.push(array)
                    this.codigo = ''
                } else {
                    store.commit('dialogosnackbar', "Producto no Existe o sin Stock")
                    this.codigo = ''
                }
            }

        },
        buscarArrayproducto(array, id) {
            var a = ''
            if (store.state.configuracion.codbarras) {
                for (var i = 0; i < array.length; i++) {
                    if (array[i].codbarra == id && array[i].stock > 0) {
                        a = {
                            id: array[i].id,
                            codbarra: array[i].codbarra,
                            cantidad: 1,
                            nombre: array[i].nombre,
                            medida: array[i].medida,
                            categoria: array[i].categoria,
                            precio: array[i].precio,
                            stock: array[i].stock,
                            precioedita: array[i].precio,
                            preciodescuento: 0,
                            costo: array[i].costo,
                            cocina: array[i].cocina,
                            tipoproducto: array[i].tipoproducto,
                            operacion: array[i].operacion,
                            icbper: array[i].icbper,
                            controstock: array[i].controstock,
                            cargoxconsumo: array[i].cargoxconsumo
                        }
                    }
                }
            } else {
                for (var i = 0; i < array.length; i++) {
                    if (array[i].id == id && array[i].stock > 0) {
                        a = {
                            id: array[i].id,
                            codbarra: array[i].codbarra,
                            cantidad: 1,
                            nombre: array[i].nombre,
                            medida: array[i].medida,
                            categoria: array[i].categoria,
                            precio: array[i].precio,
                            stock: array[i].stock,
                            precioedita: array[i].precio,
                            preciodescuento: 0,
                            costo: array[i].costo,
                            cocina: array[i].cocina,
                            tipoproducto: array[i].tipoproducto,
                            operacion: array[i].operacion,
                            icbper: array[i].icbper,
                            controstock: array[i].controstock,
                            cargoxconsumo: array[i].cargoxconsumo
                        }
                    }
                }
            }
            return a
        },
        buscarArrayproductosinstock(array, id) {
            var a = ''
            if (store.state.configuracion.codbarras) {
                for (var i = 0; i < array.length; i++) {
                    if (array[i].codbarra == id) {
                        a = {
                            id: array[i].id,
                            codbarra: array[i].codbarra,
                            cantidad: 1,
                            nombre: array[i].nombre,
                            medida: array[i].medida,
                            categoria: array[i].categoria,
                            precio: array[i].precio,
                            stock: array[i].stock,
                            precioedita: array[i].precio,
                            preciodescuento: 0,
                            costo: array[i].costo,
                            cocina: array[i].cocina,
                            tipoproducto: array[i].tipoproducto,
                            operacion: array[i].operacion,
                            icbper: array[i].icbper,
                            controstock: array[i].controstock,
                            cargoxconsumo: array[i].cargoxconsumo
                        }
                    }
                }
            } else {
                for (var i = 0; i < array.length; i++) {
                    if (array[i].id == id) {
                        a = {
                            id: array[i].id,
                            codbarra: array[i].codbarra,
                            cantidad: 1,
                            nombre: array[i].nombre,
                            medida: array[i].medida,
                            categoria: array[i].categoria,
                            precio: array[i].precio,
                            stock: array[i].stock,
                            precioedita: array[i].precio,
                            preciodescuento: 0,
                            costo: array[i].costo,
                            cocina: array[i].cocina,
                            tipoproducto: array[i].tipoproducto,
                            operacion: array[i].operacion,
                            icbper: array[i].icbper,
                            controstock: array[i].controstock,
                            cargoxconsumo: array[i].cargoxconsumo
                        }
                    }
                }
            }
            return a
        },
        agregaCatalogo(value) {
            this.dialogoCatalogo = false

            if (value.stock <= 0 && store.state.configuracion.inventario) {
                store.commit('dialogosnackbar', 'NO TIENE STOCK DISPONIBLE')
            } else {
                if (this.listaproductos.indexOf(this.buscarArray(this.listaproductos, value.id)) == -1) {
                    this.listaproductos.push({
                        id: value.id,
                        codbarra: value.codbarra,
                        cantidad: value.cantidad,
                        nombre: value.nombre,
                        categoria: value.categoria,
                        medida: value.medida,
                        precio: value.precio,
                        stock: value.stock,
                        precioedita: value.precio,
                        preciodescuento: 0,
                        costo: value.costo,
                        cocina: value.cocina,
                        tipoproducto: value.tipoproducto,
                        operacion: value.operacion,
                        icbper: value.icbper,
                        controstock: value.controstock,
                        cargoxconsumo: value.cargoxconsumo
                    })
                } else {
                    this.sumaproductos(value)
                }
            }

        },
        agregaSinCatalogo() {
            if (this.nombreSincodigo == '' || this.medidasincodigo == '' ||
                this.precioSinCodigo == '' || this.cantidadSinCodigo == '') {
                store.commit('dialogosnackbar', 'REVISE PRODUCTO')
            } else {
                this.listaproductos.push({
                    id: this.create_UUID().substring(29),
                    codbarra: this.create_UUID().substring(29),
                    cantidad: this.cantidadSinCodigo.toString().trim(),
                    nombre: this.nombreSincodigo,
                    medida: this.medidasincodigo,
                    categoria: 'varios',
                    precio: this.redondear(this.precioSinCodigo.toString().trim()),
                    stock: 9090909,
                    precioedita: this.redondear(this.precioSinCodigo.toString().trim()),
                    preciodescuento: 0,
                    costo: 0,
                    cocina: false,
                    tipoproducto: this.tipoproducto,
                    operacion: this.tipooperacion,
                    icbper: 'false',
                    controstock: false,
                    cargoxconsumo: false
                })
                this.dialogAgrega = false
                this.nombreSincodigo = ''
                this.medidasincodigo = ''
                this.precioSinCodigo = ''
            }
        },
        copiaDataMesa(mesa) {
            this.array_separa = []
            store.commit("dialogoprogress")
            if (this.esdelivery) {
                this.copiadelivery(mesa)
            } else {
                this.idmesa = mesa
                buscaComanda(mesa).once("value").then((snapshot) => {
                    snapshot.forEach((item) => {
                        this.mesero = item.val().usuario
                        if (!this.separacuentas) {
                            this.listaproductos.push({
                                id: item.val().id,
                                codbarra: item.val().id,
                                cantidad: item.val().cantidad,
                                nombre: item.val().nombre,
                                medida: item.val().medida,
                                categoria: item.val().categoria,
                                precio: item.val().precio,
                                stock: item.val().stock,
                                precioedita: item.val().precio,
                                preciodescuento: item.val().preciodescuento,
                                costo: 0,
                                cocina: item.val().cocina,
                                tipoproducto: item.val().tipoproducto,
                                operacion: item.val().operacion,
                                icbper: 'false',
                                controstock: item.val().controstock,
                                cargoxconsumo: item.val().cargoxconsumo,
                                uuid: item.val().uuid
                            })
                        } else {
                            this.array_comanda.push(item.val())
                            this.array_separa.push({
                                id: item.val().id,
                                codbarra: item.val().id,
                                cantidad: item.val().cantidad,
                                cantidadedita: item.val().cantidad,
                                nombre: item.val().nombre,
                                medida: item.val().medida,
                                categoria: item.val().categoria,
                                precio: item.val().precio,
                                stock: item.val().stock,
                                precioedita: item.val().precio,
                                preciodescuento: item.val().preciodescuento,
                                costo: 0,
                                cocina: item.val().cocina,
                                tipoproducto: item.val().tipoproducto,
                                operacion: item.val().operacion,
                                icbper: 'false',
                                controstock: item.val().controstock,
                                cargoxconsumo: item.val().cargoxconsumo,
                                uuid: item.val().uuid
                            })
                        }

                    })
                    store.commit("dialogoprogress")
                    this.dialogoMesas = false
                    if (this.separacuentas) {
                        this.dialogo_separa = true
                    }
                })

            }
        },
        copiadelivery(id) {
            this.idmesa = id
            buscaComandaDelivery(id).once("value").then((snapshot) => {
                snapshot.forEach((item) => {
                    this.listaproductos.push({
                        id: item.val().id,
                        codbarra: item.val().id,
                        cantidad: item.val().cantidad,
                        nombre: item.val().nombre,
                        medida: item.val().medida,
                        categoria: item.val().categoria,
                        precio: item.val().precio,
                        stock: item.val().stock,
                        precioedita: item.val().precio,
                        preciodescuento: item.val().preciodescuento,
                        costo: 0,
                        cocina: item.val().cocina,
                        tipoproducto: item.val().tipoproducto,
                        operacion: item.val().operacion,
                        icbper: 'false',
                        controstock: item.val().controstock,
                        cargoxconsumo: item.val().cargoxconsumo
                    })
                })
                store.commit("dialogoprogress")
                this.dialogoMesas = false
            })

        },
        sumaproductos(item) {
            for (var i = 0; i < this.listaproductos.length; i++) {
                if (this.listaproductos[i].id == item.id) {
                    if (this.listaproductos[i].cantidad >= this.listaproductos[i].stock &&
                        this.listaproductos[i].controstock && store.state.configuracion.inventario
                    ) {
                        store.commit('dialogosnackbar', 'NO TIENE MAS STOCK')
                    } else {
                        this.listaproductos[i].cantidad = this.listaproductos[i].cantidad + 1
                        this.codigoedita = i
                    }
                }
            }

        },
        editaProducto(id) {

            this.dialogoProducto = true
            for (var i = 0; i < this.listaproductos.length; i++) {
                if (this.listaproductos[i].id == id) {
                    this.codigoedita = i
                    this.cantidadEdita = this.listaproductos[i].cantidad
                    this.precioedita = this.listaproductos[i].precioedita
                    this.preciodescuento = this.listaproductos[i].preciodescuento
                    this.nombreEdita = this.listaproductos[i].nombre
                }
            }

        },
        suma() {

            if (this.cantidadEdita >= this.listaproductos[this.codigoedita].stock &&
                this.listaproductos[this.codigoedita].controstock && store.state.configuracion.inventario
            ) {
                store.commit('dialogosnackbar', 'NO TIENE MAS STOCK')
            } else {
                this.cantidadEdita = parseInt(this.cantidadEdita) + 1
            }
        },
        resta() {
            if (this.cantidadEdita > 1) {
                this.cantidadEdita = parseInt(this.cantidadEdita) - 1
            }
        },
        grabaEdita() {
            if (parseFloat(this.cantidadEdita) > parseFloat(this.listaproductos[this.codigoedita].stock) &&
                this.listaproductos[this.codigoedita].controstock && store.state.configuracion.inventario
            ) {
                store.commit('dialogosnackbar', 'NO TIENE MAS STOCK')
            } else {
                if (this.preciodescuento == '') {
                    this.preciodescuento = 0
                }
                if (this.porcentaje != '' && this.porcentaje != 0) {
                    this.preciodescuento = this.conversion
                }
                if (parseFloat(this.listaproductos[this.codigoedita].precioedita) >= parseFloat(this.preciodescuento.toString().trim())) {
                    this.listaproductos[this.codigoedita].cantidad = this.cantidadEdita.toString().trim()
                    this.listaproductos[this.codigoedita].precioedita = this.redondear(this.precioedita.toString().trim())
                    this.listaproductos[this.codigoedita].preciodescuento = this.redondear(this.preciodescuento.toString().trim())
                    this.listaproductos[this.codigoedita].nombre = this.nombreEdita.toString().trim()
                    this.dialogoProducto = false
                } else {
                    store.commit('dialogosnackbar', 'REVISE DESCUENTO')
                }
            }
        },

        eliminaedita() {
            if (this.separacuentas) {
                this.listaproductos = []
                this.separacuentas = false
            }
            if (this.listaproductos.length <= 1) {
                console.log(this.listaproductos.length)
                this.idmesa = 'xx'
            }
            this.listaproductos.splice(this.codigoedita, 1)
            this.dialogoProducto = false
        },
        buscarArray(array, id) {
            var a = ''
            for (var i = 0; i < array.length; i++) {
                if (array[i].id == id) {
                    a = array[i]
                }
            }
            return a
        },
        sumaTotal() {
            if (store.state.completa_cobro) {
                if (!this.separacuentas) {
                    if (this.idmesa != 'xx') {
                        if (this.esdelivery) {
                            eliminaComandaDelivery(this.idmesa)
                            eliminaDelivery(this.idmesa).then(() => {
                                this.idmesa = 'xx'
                            })
                        } else {
                            grabaEstadomesa(this.idmesa, "#46FF00")
                            grabaObservacionMesa(this.idmesa, "")
                            eliminaComanda(this.idmesa).then(() => {
                                this.idmesa = 'xx'
                            })
                        }
                    }
                } else {
                    this.elimina_separa()
                }

                this.listaproductos = []
                store.commit("completa_cobro", false)
            }
            var suma = 0
            for (var i = 0; i < this.listaproductos.length; i++) {
                suma = suma + (this.listaproductos[i].cantidad * this.listaproductos[i].precioedita)
            }
            this.totalDocumento = suma.toFixed(2)
            return suma.toFixed(2)
        },
        sumaDescuentos() {
            var suma = 0
            for (var i = 0; i < this.listaproductos.length; i++) {
                suma = suma + parseFloat(this.listaproductos[i].preciodescuento)
            }
            this.totalDescuento = this.redondear(suma)
            return this.redondear(suma)
        },
        sumaTotalSeparado() {
            var suma = 0
            for (var i = 0; i < this.listaproductos.length; i++) {
                if (this.listaproductos[i].cuentaseparada) {
                    suma = suma + (this.listaproductos[i].cantidad * this.listaproductos[i].precioedita)
                }
            }
            this.totalDocumento = suma.toFixed(2)
            return suma.toFixed(2)
        },
        sumaDescuentosSeparado() {
            var suma = 0
            for (var i = 0; i < this.listaproductos.length; i++) {
                if (this.listaproductos[i].cuentaseparada) {
                    suma = suma + parseFloat(this.listaproductos[i].preciodescuento)
                }
            }
            this.totalDescuento = this.redondear(suma)
            return this.redondear(suma)
        },

        consultaCorrelativo() {
            this.completa_items(this.listaproductos).then((r) => {
                console.log(r)
                this.abre_cobro(r)
            })
        },
        comparafecha() {
            var hoy = moment(String(new Date())).add(-3, 'd').format('YYYY-MM-DD')
            var fecha = moment(String(this.date)).format('YYYY-MM-DD')
            if (moment(fecha).isAfter(hoy)) {
                return true
            } else {
                return false
            }
        },
        abre_cobro(data) {
            if (this.comparafecha()) {
                var fechahoy = this.verdate()
                var totalcuenta = this.sumaTotal()
                var totaldescuentos = this.sumaDescuentos()
                var mesero = this.mesero
                if (this.mesero == '') {
                    mesero = store.state.permisos.nombre
                }
                var arrayCabecera = {
                    fecha: fechahoy,
                    vencimientoDoc: fechahoy,
                    estado: 'PENDIENTE',
                    mensajeSunat: '',
                    hash: '',
                    documentId: '',
                    vendedor: store.state.permisos.token,
                    total: totalcuenta,
                    descuentos: totaldescuentos,
                    total_op_gravadas: data.totaloperaGravada,
                    igv: data.totalIGV,
                    porcentaje_igv: store.state.configuracion.igv,
                    porcentaje_cargo: store.state.configuracion.cargoxconsumo,
                    total_op_exoneradas: data.totaloperaExonerada,
                    total_cargo: data.total_cargo,
                    mesero: mesero,
                    id_mesa: this.idmesa
                }
                if (this.esdelivery || this.idmesa == 'xx') {
                    arrayCabecera.id_mesa = false
                }
                console.log(arrayCabecera)
                store.commit('productos_caja', data.item)
                store.commit('cabecera_caja', arrayCabecera)
                store.commit('modopago_caja', this.corremodoPago_r())
                store.commit('dialogo_cobrar')
            } else {
                store.commit('dialogosnackbar', 'FECHA EXCEDE EL LIMITE PERMITIDO')
            }
        },
        completa_items(arrays) {
            var item = []
            var totaloperaGravada = 0
            var totaloperaExonerada = 0
            var totalIGV = 0
            var total_cargo = 0
            var porcentaje_igv = store.state.configuracion.igv / 100
            var porcentaje_cargo = store.state.configuracion.cargoxconsumo / 100
            var a = new Promise((resolve, reject) => {
                arrays.forEach((items, index, array) => {
                    var data = items
                    var descuentositem = parseFloat(data.preciodescuento)
                    var precio_item = parseFloat(this.redondear(data.precioedita - (descuentositem / data.cantidad)))
                    if (data.cargoxconsumo && porcentaje_cargo != 0) {
                        var cargo = parseFloat(this.redondear(precio_item / (parseFloat(porcentaje_cargo) + 1)))
                        var sumcargo = parseFloat(precio_item - cargo)
                        precio_item = cargo
                        total_cargo = total_cargo + parseFloat(sumcargo * data.cantidad)
                    }
                    if (data.operacion == "GRAVADA") {
                        var precioVentaUnitario = precio_item
                        var valor_unitario = precioVentaUnitario / (1 + (porcentaje_igv))
                        var igv = valor_unitario * data.cantidad * porcentaje_igv
                        var valorTotal = valor_unitario * data.cantidad
                        var antesimpuesto = valor_unitario * data.cantidad
                        var totalImpuesto = valor_unitario * data.cantidad * porcentaje_igv
                        totaloperaGravada = totaloperaGravada + parseFloat(antesimpuesto)
                        totaloperaExonerada = totaloperaExonerada + 0
                        totalIGV = totalIGV + parseFloat(totalImpuesto)
                    }
                    if (data.operacion == "EXONERADA") {
                        var precioVentaUnitario = precio_item
                        var valor_unitario = precioVentaUnitario
                        var igv = 0.00
                        var valorTotal = valor_unitario * data.cantidad
                        var antesimpuesto = valor_unitario * data.cantidad
                        var totalImpuesto = 0.00
                        totaloperaGravada = totaloperaGravada + 0
                        totaloperaExonerada = totaloperaExonerada + parseFloat(antesimpuesto)
                        totalIGV = totalIGV + parseFloat(totalImpuesto)
                    }

                    if (data.tipoproducto == undefined) {
                        data.tipoproducto = 'BIEN'
                    }
                    item.push({
                        id: data.id,
                        cantidad: data.cantidad,
                        nombre: data.nombre,
                        medida: data.medida,
                        cod_medida: data.cod_medida,
                        cod_medida: this.obtencodigomedida(data.medida, data.tipoproducto),
                        categoria: data.categoria,
                        precio: data.precio,
                        stock: data.stock,
                        precioedita: data.precioedita,
                        preciodescuento: data.preciodescuento,
                        costo: data.costo,
                        tipoproducto: data.tipoproducto,
                        operacion: data.operacion,
                        icbper: data.icbper,
                        cargoxconsumo: data.cargoxconsumo,
                        controstock: data.controstock,
                        valor_unitario: valor_unitario.toFixed(5),
                        valor_total: valorTotal.toFixed(2),
                        igv: igv.toFixed(2),
                        valor_icbper: 0.00,
                        factor_icbper: store.state.configuracion.icbper,
                        total_antes_impuestos: antesimpuesto.toFixed(2),
                        total_impuestos: totalImpuesto.toFixed(2),
                        precioVentaUnitario: this.redondear(precioVentaUnitario)
                    })
                    if (index === array.length - 1) {
                        var data = {
                            item: item,
                            totaloperaGravada: totaloperaGravada.toFixed(2),
                            totaloperaExonerada: totaloperaExonerada.toFixed(2),
                            totalIGV: totalIGV.toFixed(2),
                            total_cargo: total_cargo.toFixed(2)
                        }
                        resolve(data)
                    };
                })

            })
            a.then((value) => {
                return value
            })
            return a
        },
        corremodoPago_r() {
            var totalcuenta = this.sumaTotal()
            var totaldescuentos = this.sumaDescuentos()
            var modopagos = [{
                modo: 'EFECTIVO',
                monto: this.redondear(totalcuenta - totaldescuentos)
            }]
            return modopagos
        },
        obtencodigomedida(medida, tipoproducto) {
            if (tipoproducto == 'SERVICIO') {
                return 'ZZ'
            } else {
                var array = store.state.medidassunat
                var nomenclatura = 'NIU'
                for (var i = 0; i < array.length; i++) {
                    if (array[i].nombre == medida) {
                        nomenclatura = array[i].corto
                    }
                }
                return nomenclatura
            }
        },
        obtenarrayseparada() {
            var arrays = []
            var data = this.listaproductos
            var queda = []
            for (var i = 0; i < data.length; i++) {
                if (data[i].cuentaseparada) {
                    arrays.push(data[i])
                } else {
                    queda.push(data[i])
                }
            }
            this.listaproductos = queda
            return arrays

        },
        sumaTotalmodopago() {
            var suma = 0
            for (var i = 0; i < this.modopagos.length; i++) {
                suma = suma + parseFloat(this.modopagos[i].monto)
            }
            return parseFloat(suma).toFixed(2)
        },

        conviertefecha(date) {
            return moment.unix(date).format('YYYY-MM-DD')
        },
        redondear(valor) {
            return parseFloat(valor).toFixed(store.state.configuracion.decimal)
        },
        verdate() {
            var fecha = moment(String(this.date)) / 1000
            if (moment(String(new Date())).format('YYYY-MM-DD') == moment(String(this.date)).format('YYYY-MM-DD')) {
                fecha = moment().unix()
            }
            return fecha

        },

        create_UUID() {
            var dt = new Date().getTime();
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid;
        },
        buscaMesa() {
            store.commit("dialogoprogress")
            this.mesasActivas = []
            this.consultacomanda().then((r) => {
                //console.log(r)
                store.commit("dialogoprogress")
                this.dialogoMesas = true
            })
        },
        consultacomanda() {
            var a = allComandas().once("value").then((snapshot) => {
                if (snapshot.exists()) {
                    snapshot.forEach((item) => {
                        let key = item.key
                        this.mesasActivas.push({
                            numeromesa: key,
                            cliente: key
                        })
                    });
                }
                return true
            })
            return a
        },
        buscaDelivery() {
            store.commit("dialogoprogress")
            this.mesasActivas = []
            this.esdelivery = true
            this.consultadelivery().then((r) => {
                store.commit("dialogoprogress")
                this.dialogoMesas = true
            })

        },
        consultadelivery() {
            var a = alldelivery().once("value").then((snapshot) => {
                if (snapshot.exists()) {
                    snapshot.forEach((item) => {
                        let key = item.key
                        this.mesasActivas.push({
                            numeromesa: key,
                            cliente: item.val().cliente
                        })
                    });
                }
                return true
            })
            return a
        },
        irFlujo() {
            this.$router.push({
                path: '/flujocaja'
            })
        },

    },
    computed: {
        conversion() {
            var total = this.precioedita * (this.porcentaje / 100)
            return total.toFixed(2)
        },
        listafiltradaclientes() {
            return this.clienteslista.filter((item) =>
                (item.alias + item.nombre + item.telefono)
                    .toLowerCase().includes(this.buscar.toLowerCase()))
        },
        cuadravuelto() {
            var resta = 0
            resta = this.paravuelto - this.calculavuelto
            if (resta < 0) {
                return "MONTO MENOR A S/.0"
            } else {
                return "El vuelto debe ser: S/." + resta.toFixed(2)
            }

        },
        listafiltradaempleados() {
            return this.empleadoslista.filter((item) =>
                (item.alias + item.nombre + item.telefono)
                    .toLowerCase().includes(this.buscar.toLowerCase()))
        },

    },

}
</script>
