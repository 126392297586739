<template>
<div>
    <v-row dense>
        <v-col cols="4">
            <v-btn block color="success" small @click="ubicarme()">Ubicame</V-btn>
        </v-col>
        <v-col cols="4">
            <v-btn block color="warning" small @click="eliminar_todo()">Limpiar</V-btn>
        </v-col>
        <v-col cols="4">
            <v-btn block color="info" small @click="guarda()">Siguiente</V-btn>
        </v-col>
    </v-row>
    <v-card class="pa-1">
        {{listafiltrada}}
        <GmapMap :center="center" :options="options" :zoom="zoom" style="width: 100%; height: 35vh" @click="mark">

            <GmapMarker :key="index" v-for="(m, index) in markers" :position="m.position" :title="m.title" :clickable="true" :draggable="true" @click="center=m.position,punto_selecto=m,window_open=true">
                <Gmap-info-window @closeclick="window_open=false" :opened="window_open" :position="m.position" :options="{
          pixelOffset: {
            width: 0,
            height: -35
          }
        }">
                    {{m.title}}
                </Gmap-info-window>
            </GmapMarker>
        </GmapMap>
    </v-card>

    <v-dialog v-model="dialogo" max-width="200px">
        <div>
            <v-system-bar window dark>
                <v-icon @click="dialogo=false">mdi-close</v-icon>
                <v-spacer></v-spacer>
            </v-system-bar>
        </div>
        <v-card class="pa-4">
            <v-card-text>

            </v-card-text>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import store from '@/store/index'
import {
    nuevo_campoCliente
} from '../../db'
export default {
    props: {
        cliente: [],
    },
    data() {
        return {
            zoom: 12,
            dialogo: false,
            window_open: true,
            punto_selecto: [],
            options: {
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                disableDefaultUi: false
            },
            center: {
                lat: -8.106452954120423,
                lng: -79.02572202917145
            },
            markers: []
        }
    },
    async mounted() {
        await this.$gmapApiPromiseLazy();
    },
    created(){
        this.ubicarme()
    },
    computed: {
        listafiltrada() {

            this.zoom = 12
            this.center = {
                    lat: -8.106452954120423,
                    lng: -79.02572202917145
                },
                this.markers = []

        }
    },
    methods: {

        mark(event) {
            this.markers = []
            this.markers = [{
                id: 0,
                position: {
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng()
                },
                title: "Aqui estoy"
            }]
        },
        eliminar() {
            var array = this.markers
            for (var i = 0; i < array.length; i++) {
                if (array[i].id == this.punto_selecto.id)
                    this.markers.splice(i, 1)
            }
            this.dialogo = false
        },
        eliminar_todo() {
            store.commit("dialogoprogress")
            this.$getLocation({}).then(coordinates => {                
                this.markers = []
                this.center = {
                    lat: coordinates.lat,
                    lng: coordinates.lng
                }
                this.zoom = 12
                store.commit("dialogoprogress")
            })
        },
        ubicarme() {
            store.commit("dialogoprogress")
            this.window_open = true
            this.markers = []
            this.$getLocation({}).then(coordinates => {
                this.markers = [{
                    id: 0,
                    position: {
                        lat: coordinates.lat,
                        lng: coordinates.lng
                    },
                    title: "Aqui estoy"
                }]
                this.center = {
                    lat: coordinates.lat,
                    lng: coordinates.lng
                }
                this.zoom = 16
                store.commit("dialogoprogress")
            })
        },
        guarda() {
            
            this.$emit('cierra', this.markers)
        }
    }
}
</script>

<style>
.button {
    border: none;
    color: white;
    padding: 5px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 2px 1px;
    cursor: pointer;
}

.button1 {
    background-color: #4CAF50;
}

/* Green */
.button2 {
    background-color: #008CBA;
}

/* Blue */
.button3 {
    background-color: #ff0000;
}

/* Blue */
.button4 {
    background-color: #ff7b00;
}

/* Blue */
.button5 {
    background-color: #050302;
}

/* Blue */
</style>
