<template>
  <v-dialog v-model="$store.state.dialogo_caja" max-width="850px" persistent>
      <div>
          <v-system-bar window dark>
              <v-icon @click="$store.commit('dialogo_caja')">mdi-close</v-icon>
              <v-spacer></v-spacer>
          </v-system-bar>
      </div>
      <v-card class="pa-1">
  
          <v-container class="mb-6 mt-4" grid-list-xs>
              <v-card class="mt-n6">
                  <v-card-text>
                      <v-row class="mt-n4">
                          <v-col cols="6" xs="6">
                              <v-text-field type="date" outlined dense v-model="date" label="Emision"></v-text-field>
                          </v-col>
                          <v-col cols="6" xs="6">
  
                          </v-col>
  
                      </v-row>
                  </v-card-text>
  
              </v-card>
  
              <v-card class="mt-5">
  
                  <v-simple-table dark fixed-header height="55vh" dense>
                      <template v-slot:default>
  
                          <thead>
                              <tr>
                                  <th class="text-left">
                                      Und.
                                  </th>
                                  <th class="text-left">
                                      Descripcion
                                  </th>
                                  <th class="text-left">
                                      Precio
                                  </th>
                                  <th v-if="sumaDescuentos()!=0" class="text-left">
                                      Desc.
                                  </th>
                                  <th class="text-left">
                                      Total
                                  </th>
                              </tr>
                          </thead>
  
                          <tbody>
  
                              <tr v-for="item in listaproductos" :key="item.id">
                                  <td>{{ item.cantidad }}</td>
                                  <td>{{ item.nombre }}</td>
                                  <td>S/.{{ item.precioedita }}</td>
                                  <td class="red--text" v-if="sumaDescuentos()!=0">S/.{{ redondear(item.preciodescuento) }}</td>
                                  <td>S/.{{ redondear((item.precioedita*item.cantidad)-item.preciodescuento) }}</td>
                              </tr>
                          </tbody>
  
                      </template>
  
                  </v-simple-table>
  
              </v-card>
  
              <v-card class="mt-3 pa-2">
                  <v-row>
                      <v-col cols="12" md="4" sm="4" xs="12">
                          <v-list-item-content class="ml-3">
                              <v-list-item-subtitle v-if="sumaDescuentos()!=0">
                                  <h3>SubTotal: S/.{{sumaTotal()}}</h3>
                              </v-list-item-subtitle>
                              <v-list-item-subtitle v-if="sumaDescuentos()!=0">
                                  <h4 class="red--text">Descuentos: S/.{{sumaDescuentos()}}</h4>
                              </v-list-item-subtitle>
                              <v-list-item-subtitle>
  
                                  <h2>Total: S/.{{redondear(sumaTotal()-sumaDescuentos())}}</h2>
  
                              </v-list-item-subtitle>
  
                          </v-list-item-content>
  
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="6" md="4" sm="4" xs="6">
                          <v-btn block elevation="15" rounded v-if="listaproductos!=''" color="error" @click="consultaCorrelativo()">
                              Cobrar
                          </v-btn>
                      </v-col>
  
                  </v-row>
              </v-card>
  
              <clientes @array="agregacliente($event)" />
              <cobrar />
          </v-container>
      </v-card>
  </v-dialog>
  </template>
  
  <script>
  import {
      allEmpleados,
      buscaCliente,
      buscaPuntosCliente,
      allProformas
  } from '../../db'
  import moment from 'moment'
  import store from '@/store/index'
  import axios from "axios"
  import cateogrias from '@/components/dialogos/dialogocatalogo'
  import clientes from '@/components/dialogos/dialogoClientes'
  import cobrar from '@/components/dialogos/dialogo_cobrar'
  export default {
      name: 'caja',
  
      components: {
          cateogrias,
          clientes,
          cobrar
      },
  
      data() {
          return {
              date: moment(String(new Date)).format('YYYY-MM-DD'),
              dialogAgrega: false,
              dialogoProducto: false,
              dialogo_selecc_cliente: false,
              dialogo_proforma: false,
              codigo: '',
              listaproductos: [],
              buscar: '',
              codigoedita: '',
              cantidadEdita: '',
              precioedita: '',
              nombreEdita: '',
              preciodescuento: '',
              cantidadSinCodigo: 1,
              precioSinCodigo: '',
              medidasincodigo: 'UNIDAD',
              nombreSincodigo: '',
              modopagos: [],
              arraytipoProducto: ['BIEN', 'SERVICIO'],
              arrayOperacion: [
                  'GRAVADA',
                  'EXONERADA',
              ],
              tipooperacion: 'GRAVADA',
              tipoproducto: "BIEN",
              dialogCliente: false,
              info_cliente: [],
              array_cliente: '',
              id_cliente: '',
              documento: '',
              numero: '',
              nombreCompleto: '',
              direccion: '',
              canje_puntos: '',
              date_proforma: moment(String(new Date)).format('YYYY-MM-DD'),
              lista_comprobantes: [],
              ruc_proforma: '',
          }
      },
  
      methods: {
  
          obtencodigomedida(medida, tipoproducto) {
              if (tipoproducto == 'SERVICIO') {
                  return 'ZZ'
              } else {
                  var array = store.state.medidassunat
                  var nomenclatura = 'NIU'
                  for (var i = 0; i < array.length; i++) {
                      if (array[i].nombre == medida) {
                          nomenclatura = array[i].corto
                      }
                  }
                  return nomenclatura
              }
          },
  
          sumaproductos(item) {
              for (var i = 0; i < this.listaproductos.length; i++) {
                  if (this.listaproductos[i].id == item.id) {
                      if (this.listaproductos[i].cantidad >= this.listaproductos[i].stock &&
                          this.listaproductos[i].controstock && store.state.configuracion.inventario
                      ) {
                          store.commit('dialogosnackbar', 'NO TIENE MAS STOCK')
                      } else {
                          this.listaproductos[i].cantidad = this.listaproductos[i].cantidad + 1
                          this.codigoedita = i
                      }
                  }
              }
          },
  
          buscarArray(array, id) {
              var a = ''
              for (var i = 0; i < array.length; i++) {
                  if (array[i].id == id) {
                      a = array[i]
                  }
              }
              return a
          },
          sumaTotal() {
              this.listaproductos = store.state.productos_consolida
              if (store.state.completa_cobro) {
                  this.listaproductos = []
                  store.commit("completa_cobro", false)
              }
              var suma = 0
              for (var i = 0; i < this.listaproductos.length; i++) {
                  suma = suma + (this.listaproductos[i].cantidad * this.listaproductos[i].precioedita)
              }
              this.totalDocumento = suma.toFixed(2)
              return suma.toFixed(2)
          },
          sumaDescuentos() {
              var suma = 0
              for (var i = 0; i < this.listaproductos.length; i++) {
                  suma = suma + parseFloat(this.listaproductos[i].preciodescuento)
              }
              this.totalDescuento = this.redondear(suma)
              return this.redondear(suma)
          },
          consultaCorrelativo() {
              this.completa_items(this.listaproductos).then((r) => {
                  //console.log(r)
                  this.abre_cobro(r)
              })
          },
          comparafecha() {
              var hoy = moment(String(new Date())).add(-3, 'd').format('YYYY-MM-DD')
              var fecha = moment(String(this.date)).format('YYYY-MM-DD')
              if (moment(fecha).isAfter(hoy)) {
                  return true
              } else {
                  return false
              }
          },
          abre_cobro(data) {
              if (this.comparafecha()) {
                  var fechahoy = this.verdate()
                  var totalcuenta = this.sumaTotal()
                  var totaldescuentos = this.sumaDescuentos()
  
                  var arrayCabecera = {
                      fecha: fechahoy,
                      vencimientoDoc: fechahoy,
                      estado: 'PENDIENTE',
                      mensajeSunat: '',
                      hash: '',
                      documentId: '',
                      vendedor: store.state.permisos.token,
                      total: totalcuenta,
                      descuentos: totaldescuentos,
                      total_op_gravadas: data.totaloperaGravada,
                      igv: data.totalIGV,
                      porcentaje_igv: store.state.configuracion.igv,
                      total_op_exoneradas: data.totaloperaExonerada,
                      total_cargo: data.total_cargo,
                  }
                  //console.log(arrayCabecera)
                  store.commit('productos_caja', data.item)
                  store.commit('cabecera_caja', arrayCabecera)
                  store.commit('modopago_caja', this.corremodoPago_r())
                  store.commit('dialogo_cobrar')
              } else {
                  store.commit('dialogosnackbar', 'FECHA EXCEDE EL LIMITE PERMITIDO')
              }
          },
          completa_items(arrays) {
              var item = []
              var totaloperaGravada = 0
              var totaloperaExonerada = 0
              var totalIGV = 0
              var total_cargo = 0
              var porcentaje_igv = store.state.configuracion.igv / 100
              var porcentaje_cargo = store.state.configuracion.cargoxconsumo
              var a = new Promise((resolve, reject) => {
                  arrays.forEach((items, index, array) => {
                      var data = items
                      var descuentositem = parseFloat(data.preciodescuento)
                      /* if(data.cargoxconsumo && porcentaje_cargo!=0){
                         var cargo  =  parseFloat(redondear(data.precioedita / ((parseFloat(porcentaje_cargo)/100)+1)))
                         var sumcargo =  parseFloat(redondear(data.precioedita - cargo))
                         data.precioedita = cargo
                         total_cargo = total_cargo +  parseFloat(redondear(sumcargo*data.cantidad))
                       }*/
                      if (data.operacion == "GRAVADA") {
                          var precioVentaUnitario = data.precioedita - this.redondear(descuentositem / data.cantidad)
                          var valor_unitario = precioVentaUnitario / (1 + (porcentaje_igv))
                          var igv = valor_unitario * data.cantidad * porcentaje_igv
                          var valorTotal = this.redondear(valor_unitario * data.cantidad)
                          var antesimpuesto = valor_unitario * data.cantidad
                          var totalImpuesto = valor_unitario * data.cantidad * porcentaje_igv
                          totaloperaGravada = totaloperaGravada + parseFloat(antesimpuesto)
                          totaloperaExonerada = totaloperaExonerada + 0
                          totalIGV = totalIGV + parseFloat(totalImpuesto)
                      }
                      if (data.operacion == "EXONERADA") {
                          var precioVentaUnitario = data.precioedita - this.redondear(descuentositem / data.cantidad)
                          var valor_unitario = precioVentaUnitario
                          var igv = 0.00
                          var valorTotal = this.redondear(valor_unitario * data.cantidad)
                          var antesimpuesto = valor_unitario * data.cantidad
                          var totalImpuesto = 0.00
                          totaloperaGravada = totaloperaGravada + 0
                          totaloperaExonerada = totaloperaExonerada + parseFloat(antesimpuesto)
                          totalIGV = totalIGV + parseFloat(totalImpuesto)
                      }
                      item.push({
                          id: data.id,
                          cantidad: data.cantidad,
                          nombre: data.nombre,
                          medida: data.medida,
                          cod_medida: data.cod_medida,
                          precio: data.precio,
                          precioedita: data.precioedita,
                          preciodescuento: data.preciodescuento,
                          tipoproducto: data.tipoproducto,
                          operacion: data.operacion,
                          valor_unitario: this.redondear(valor_unitario),
                          valor_total: this.redondear(valorTotal),
                          igv: this.redondear(igv),
                          valor_icbper: 0.00,
                          factor_icbper: store.state.configuracion.icbper,
                          total_antes_impuestos: this.redondear(antesimpuesto),
                          total_impuestos: this.redondear(totalImpuesto),
                          precioVentaUnitario: this.redondear(precioVentaUnitario)
                      })
                      console.log(item)
                      if (index === array.length - 1) {
                          var data = {
                              item: item,
                              totaloperaGravada: this.redondear(totaloperaGravada),
                              totaloperaExonerada: this.redondear(totaloperaExonerada),
                              totalIGV: this.redondear(totalIGV),
                              total_cargo: total_cargo
                          }
                          resolve(data)
                      };
                  })
  
              })
              a.then((value) => {
                  return value
              })
              return a
          },
          corremodoPago_r() {
              var totalcuenta = this.sumaTotal()
              var totaldescuentos = this.sumaDescuentos()
              var modopagos = [{
                  modo: 'EFECTIVO',
                  monto: this.redondear(totalcuenta - totaldescuentos)
              }]
              return modopagos
          },
          conviertefecha(date) {
              return moment.unix(date).format('YYYY-MM-DD')
          },
          redondear(valor) {
              return parseFloat(valor).toFixed(store.state.configuracion.decimal)
          },
          verdate() {
              var fecha = moment(String(this.date)) / 1000
              if (moment(String(new Date())).format('YYYY-MM-DD') == moment(String(this.date)).format('YYYY-MM-DD')) {
                  fecha = moment().unix()
              }
              return fecha
  
          },
          BuscarDocumento() {
              if (this.numero != '' && this.documento == "RUC" || this.documento == "DNI" &&
                  this.numero.length == 8 || this.numero.length == 11) {
                  store.commit("dialogoprogress")
  
                  if (this.numero.length == 11) {
                      this.documento = "RUC"
                  } else {
                      this.documento = "DNI"
                  }
                  if (store.state.configuracion.dataclientes) {
                      buscaCliente()
                          .orderByChild("documento")
                          .equalTo(this.numero)
                          .once("value")
                          .then((snapshot) => {
                              if (snapshot.exists()) {
                                  snapshot.forEach((item) => {
                                      this.nombreCompleto = item.val().nombre
                                      this.direccion = item.val().direccion
                                      this.refcliente = item.val().referencia
                                      this.telfcliente = item.val().telefono
                                  })
                                  store.commit("dialogoprogress")
                              } else {
                                  this.consultaApiPeru()
                              }
                          })
                  } else {
                      this.consultaApiPeru()
                  }
  
              } else {
                  if (store.state.configuracion.dataclientes) {
  
                      store.commit('dialogoClientesnuevos')
                  } else {
                      store.commit('dialogosnackbar', 'INGRESE DOCUMENTO')
                  }
  
              }
          },
  
          elimina_cliente() {
              this.array_cliente = []
              this.id_cliente = ''
              this.documento = 'DNI'
              this.numero = ''
              this.nombreCompleto = ''
              this.telfcliente = ''
              this.direccion = ''
              this.refcliente = ''
              this.dialogo_selecc_cliente = false
          },
          consultaApiPeru() {
              var self = this,
                  token = '80a4a1c5f2e97c2d78fcd5074cd64ff0a29007ef91880ad2c214af67a084433d'
              axios
                  .get('https://apiperu.dev/api/' + this.documento.toLowerCase() + '/' + this.numero, {
                      headers: {
                          Content_Type: 'application/json',
                          authorization: ' Bearer ' + token
                      }
                  })
                  .then(response => (this.info = response.data,
                      store.commit("dialogoprogress"),
                      self.llenardatos(response.data.data)
                      // console.log(response.data) 
                  ))
                  .catch(error => {
                      store.commit("dialogoprogress"),
                          // console.log(error.response)
                          store.commit('dialogosnackbar', 'Documento Invalido')
                  })
          },
          llenardatos(data) {
              if (this.documento == 'DNI') {
                  this.nombreCompleto = data.nombre_completo
                  this.direccion = ''
                  this.telfcliente = ''
                  this.refcliente = ''
              }
              if (this.documento == 'RUC') {
                  this.nombreCompleto = data.nombre_o_razon_social
                  this.direccion = data.direccion_completa
                  this.telfcliente = ''
                  this.refcliente = ''
              }
          },
          create_UUID() {
              var dt = new Date().getTime();
              var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                  var r = (dt + Math.random() * 16) % 16 | 0;
                  dt = Math.floor(dt / 16);
                  return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
              });
              return uuid;
          },
          irFlujo() {
              this.$router.push({
                  path: '/flujocaja'
              })
          },
  
          consultacliente(data) {
              buscaCliente()
                  .orderByChild("documento")
                  .equalTo(data)
                  .once("value")
                  .then((snapshot) => {
                      if (snapshot.exists()) {
                          snapshot.forEach((item) => {
                              this.info_cliente = item.val()
                              this.documento = item.val().tipodoc
                              this.numero = item.val().documento
                              this.nombreCompleto = item.val().nombre
                              this.direccion = item.val().direccion
                          })
                      }
                  })
          },
  
          eliminar_ultimo() {
              this.listaproductos.pop()
          }
  
      },
      computed: {
          listafiltradaclientes() {
              return this.clienteslista.filter((item) =>
                  (item.documento + item.nombre + item.telefono)
                  .toLowerCase().includes(this.buscar.toLowerCase()))
          },
  
      },
      mounted() {
  
      },
  
  }
  </script>
  