<template>
    <v-container mb-5>
        <v-row dense class="text-center">
            <v-col cols="6">
                <h4>Usuario: {{ permisosususario.nombre }}</h4>
                <h4>Correo: {{ permisosususario.correo }}</h4>
            </v-col>
            <v-col cols="3">
                <v-btn elevation="10" rounded color="green" @click="grabaPermiso()">
                    <v-icon color="white" class="mx-auto text--center" medium>mdi-content-save</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="3">
                <v-btn elevation="10" rounded color="red" @click="regresar()">
                    <v-icon color="white" class="mx-auto text--center" medium>mdi-keyboard-return</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <v-card class="pa-4">
            <v-row dense>
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.moduloempresa" label="Configuracion"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.modulosunat" label="Modulo Sunat"></v-checkbox>
                </v-col>
            </v-row>
            <v-row dense class="mt-n6">
                <v-col cols="6">

                </v-col>
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.modulokardex" label="Kardex"></v-checkbox>
                </v-col>
            </v-row>

        </v-card>

        <v-card class="pa-4 mt-3">
            <v-card-title class="mt-n6">
                Productos
            </v-card-title>
            <v-row dense class="mt-n6">
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.moduloproductos" label="Modulo Productos"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.productos_visualiza_stock"
                        label="visualiza Stock"></v-checkbox>
                </v-col>
            </v-row>
        </v-card>

        <v-card class="pa-4 mt-3">
            <v-card-title class="mt-n6">
                Caja
            </v-card-title>
            <v-row dense class="mt-n6">
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.modulocaja" label="Modulo Ventas"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.caja_edita_cantidad" label="Editar Cantidad"></v-checkbox>
                </v-col>
            </v-row>
            <v-row dense class="mt-n6">
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.caja_edita_precio" label="Editar Precio"></v-checkbox>
                </v-col>
            </v-row>
        </v-card>
        <v-card class="pa-4 mt-3">

            <v-card-title class="mt-n6">
                Restaurante
            </v-card-title>
            <v-row dense class="mt-n6">
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.modulomesas" label="Modulo Mesas"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.modulococina" label="Cocina"></v-checkbox>
                </v-col>
            </v-row>
            <v-row dense class="mt-n6">
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.limpia_comanda" label="Limpiar Mesa"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.trans_comanda" label="Transferir mesa"></v-checkbox>
                </v-col>
            </v-row>
            <v-row dense class="mt-n6">
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.unir_comanda" label="Unir mesa"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.editar_comanda" label="Edita Comanda"></v-checkbox>
                </v-col>
            </v-row>
        </v-card>

        <v-card class="pa-4 mt-3">
            <v-card-title class="mt-n6">
                Clientes
            </v-card-title>
            <v-row dense class="mt-n6">
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.moduloclientes" label="Modulo Clientes"></v-checkbox>
                </v-col>
            </v-row>
        </v-card>
        <v-card class="pa-4 mt-3">
            <v-card-title class="mt-n6">
                Empleados
            </v-card-title>
            <v-row dense class="mt-n6">
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.modulo_empleados" label="Modulo Empleados"></v-checkbox>
                </v-col>
            </v-row>
        </v-card>
        <v-card class="pa-4 mt-3">
            <v-card-title class="mt-n6">
                Reportes
            </v-card-title>
            <v-row dense class="mt-n6">
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.moduloreportes" label="Modulo Reportes"></v-checkbox>
                </v-col>
            </v-row>
        </v-card>

    </v-container>
</template>

<script>
import {
    buscarUsuarios,
    nuevoUsuario
} from '../db'
import store from '@/store/index'
export default {

    data() {
        return {
            token: '',
            nombre: '',
            correo: '',
            pass: '',
            valid: true,
            desserts: [],
            dialogPermisos: false,
            permisosususario: [],

        }
    },
    created() {
        this.inicio()
    },
    methods: {
        inicio() {

            buscarUsuarios(this.$route.params.id).once("value").then((snapshot) => {
                if (snapshot.exists()) {
                    this.permisosususario = snapshot.val()
                }
            })
        },

        cargaPermisos(items) {
            this.dialogPermisos = true
            this.token = items.token
            this.correo = items.correo
            this.nombre = items.nombre
            this.pass = items.pass
            var funciones = {
                token: items.token,
                nombre: items.nombre,
                correo: items.correo,
                bd: items.bd,
                pass: items.pass,
                moduloempresa: items.moduloempresa,
                modulokardex: items.modulokardex,
                modulosunat: items.modulosunat,
                moduloproductos: items.moduloproductos,
                productos_visualiza_stock: items.productos_visualiza_stock,
                moduloclientes: items.moduloclientes,
                modulomesas: items.modulomesas,
                modulococina: items.modulococina,
                limpia_comanda: items.limpia_comanda,
                trans_comanda: items.trans_comanda,
                unir_comanda: items.unir_comanda,
                editar_comanda: items.editar_comanda,
                modulocaja: items.modulocaja,
                modulo_empleados: items.modulo_empleados,
                caja_edita_cantidad: items.caja_edita_cantidad,
                caja_edita_precio: items.caja_edita_precio,
                moduloreportes: items.moduloreportes,
            }

            this.permisosususario = funciones
        },

        grabaPermiso() {
            var items = this.permisosususario
            var corre = ''
            var passsw = ''
            if (items.correo != '') {
                corre = items.correo
                passsw = items.pass
            } else {
                corre = 'Pendiente'
                passsw = ''
            }
            var array = {
                token: items.token,
                nombre: items.nombre,
                correo: items.correo,
                bd: items.bd,
                pass: items.pass,
                moduloempresa: items.moduloempresa,
                modulokardex: items.modulokardex,
                modulosunat: items.modulosunat,
                moduloproductos: items.moduloproductos,
                productos_visualiza_stock: items.productos_visualiza_stock,
                moduloclientes: items.moduloclientes,
                modulomesas: items.modulomesas,
                modulococina: items.modulococina,
                limpia_comanda: items.limpia_comanda,
                trans_comanda: items.trans_comanda,
                unir_comanda: items.unir_comanda,
                editar_comanda: items.editar_comanda,
                modulocaja: items.modulocaja,
                modulo_empleados: items.modulo_empleados,
                caja_edita_cantidad: items.caja_edita_cantidad,
                caja_edita_precio: items.caja_edita_precio,
                moduloreportes: items.moduloreportes,
            }

            nuevoUsuario(items.token, array).then(() => {
                store.commit('dialogosnackbar', "GUARDADO CON EXITO")
            })
        },
        regresar() {
            this.$router.push({
                path: "/gestionUsuarios/"
            })
        },
        modificarpermisos() {

            for (var i = 0; i < this.desserts.length; i++) {
                console.log(this.desserts[i].token)
                //  nuevoCampoUsuario(this.desserts[i].token)
            }

            //nuevoUsuario(this.token,array)
        }
    }

}
</script>
