<template>
     <v-dialog     
     v-model="$store.state.dialogoClientesnuevos"
      max-width="650"
      >
    <div>
      <v-system-bar window dark>
        <v-icon @click="$store.commit('dialogoClientesnuevos')">mdi-close</v-icon>
        <v-spacer></v-spacer>
      </v-system-bar>
    </div>
  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="buscar"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        dense        
      ></v-text-field>
    </v-card-title>

  <v-simple-table
    fixed-header
    height="55vh"
    dense
  >
    <template v-slot:default >
      <thead>
        <tr>
          <th class="text-left">
            Cliente
          </th>
            <th class="text-left">
            direccion
          </th>
          <th class="text-left">
            Telefono
          </th>
        </tr>
      </thead>
      <tbody >
        <tr
          v-for="item in listafiltradaclientes"
          :key="item.id"
           @click="agregacliente(item),$store.commit('dialogoClientesnuevos')"
        >
          <td>{{ item.documento }}-{{ item.nombre }}</td>
          <td>{{ item.direccion }}</td>
          <td>{{ item.telefono }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>

  </v-card>


     </v-dialog>
     
</template>

<script>
import store from '@/store/index'
import {allClientes,sumaContador,obtenContador,nuevoCliente} from '../../db'

import axios from "axios"
export default {

 data () {
      return {
        buscar:'',
        item_selecto : '',
        clienteslista:[],
        dialogo_agrega:false,
        dialogoubicaciones:false,
        arrayComprobante:['T','B','F'],
        tipocomprobante:'T',
        arraydocumento:['DNI','RUC'],
        activo:true,
        id:'',
        tipodoc:'DNI',
        documento:'',
        nombre:'',
        direccion:'',
        telefono:'',
        alias:'',
        nota:'',
        referencia:'',
        ubigeo:'',
        arraydepa:[],
        arrayprov:[],
        arraydistr:[],
        departamento:'',
        provincia:'',
        distrito:'',
        ubigeodepa:'',
        ubigeoprov:'',
        tipoubicacion:'',
        permiso_edita:false,
      }
    },
    created() {

    },
    mounted() {
      this.initialize()
      },
     methods: {
      initialize(){         
    allClientes().once("value").then((snapshot) => {
          let array = [];
      snapshot.forEach((item) => {
        let key = item.key;
        let data = item.val();
        array.push(data);
      });
        this.clienteslista = array
       })
      },  
      agregacliente(item){            
          this.buscar = ''
          this.$emit('array',item)         
      },
      
      obtenordenproducto(){
        this.permiso_edita=false
        this.sumacon=true
        obtenContador().once("value").then((snapshot) => {
          if(snapshot.exists()){
            this.activo=true
            this.id = snapshot.val().ordenclientes
            this.tipodoc='DNI',
            this.documento='',
            this.nombre='',
            this.departamento='',
            this.provincia='',
            this.distrito='',
            this.ubigeo
            this.direccion='',
            this.telefono='',
            this.alias='',
            this.nota='',
            this.referencia='',
            this.tipocomprobante = "T",
            this.dialogo_agrega=true
          }     
            })
            
      },
            save () {
        var array ={
            activo:this.activo,
            id:this.id,
            tipodoc:this.tipodoc,
            documento:this.documento,
            nombre:this.nombre,
            departamento:this.departamento,
            provincia:this.provincia,
            distrito:this.distrito,
            ubigeo:this.ubigeo,
            direccion:this.direccion,
            telefono:this.telefono,
            alias:this.alias,
            nota:this.nota,
            referencia:this.referencia,
            tipocomprobante: this.tipocomprobante,
        }
        nuevoCliente(this.id,array)
        if(this.sumacon==true){
          this.sumacontador() 
        }        
        this.dialogo_agrega=false
        this.initialize()
      },  
            sumacontador(){
        if(!store.state.configuracion.idcliente){
          sumaContador("ordenclientes",(parseInt(this.id)+1).toString().padStart(4, 0))
        }
      },
          BuscarDocumento(){
      if(this.documento!=''&& this.tipodoc=="RUC" || this.tipodoc=="DNI" 
      && this.documento.length==8 || this.documento.length==11){
        if(this.documento.length==8){
          this.tipodoc == "DNI"
        }
        if(this.documento.length==11){
          this.tipodoc == "RUC"
        }
        store.commit("dialogoprogress")
        var self = this,
        token = '80a4a1c5f2e97c2d78fcd5074cd64ff0a29007ef91880ad2c214af67a084433d'
        axios
          .get('https://apiperu.dev/api/'+this.tipodoc.toLowerCase()+'/'+this.documento, 
          { headers: { 
            Content_Type: 'application/json',
            authorization:' Bearer '+ token }})
          .then(response => (this.info = response.data,
           store.commit("dialogoprogress"),
            self.llenardatos(response.data.data)
            ))     
            .catch(error => {
            store.commit("dialogoprogress"),
            self.snack('Documento Invalido')
            })
      }else{
        this.snack("Ingrese Documento")
      }
    },
        llenardatos(data){
        if(this.tipodoc=='DNI'){
          this.nombre = data.nombre_completo
        }
        if(this.tipodoc=='RUC'){
          this.nombre = data.nombre_o_razon_social
          this.direccion= data.direccion
          this.departamento= data.departamento
          this.provincia=data.provincia
          this.distrito=data.distrito
          this.ubigeo = data.ubigeo_sunat
        }
    }
    },
     computed: {
        listafiltradaclientes(){
         return this.clienteslista.filter((item)=> 
            (item.documento+item.nombre+item.telefono)
            .toLowerCase().includes(this.buscar.toLowerCase())) 
        },
                arrayubicaciones(){  
          var array = []
          switch (this.tipoubicacion) {
          case 1:
            array = this.arraydepa
          break;
          case 2:
            array = this.arrayprov
          break;
          case 3:
            array = this.arraydistr
          break;
          default:
            break;
        }
          return array
        },
    }
}
</script>