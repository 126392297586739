<template>
<v-container class="mb-6">
<v-card>
    <v-card-title>
      <v-row class="mx-auto text-center">
          <v-col cols="6" xs="6">
                <v-text-field
                outlined
                dense
                @click="$store.commit('dialogoFecha')"
                v-model="date"                   
                label="Inicio"
                readonly
              ></v-text-field>
          </v-col>

          <v-col cols="6" xs="6">
                <v-text-field
                outlined
                dense
                @click="$store.commit('dialogoFecha2')"
                v-model="date2"                   
                label="Fin"
                readonly
              ></v-text-field>
          </v-col>
      
      </v-row>
    </v-card-title>
    <v-row class="mt-n6">
      <v-col cols="10">
        <h4 class="text-center">Total: S/. {{totaliza}} </h4>
      </v-col>
        <v-col cols="2">    
    <v-icon @click="imprimir()" color="red">mdi-text-box-search-outline</v-icon>
  </v-col>
    </v-row>


 <v-simple-table
    fixed-header
    height="66vh"
    dense
  >
    <template v-slot:default >
      <thead>
        <tr>
          <th class="text-left">
            Trabajador
          </th>
            <th class="text-left">
            Total
          </th>
        </tr>
      </thead>
      <tbody >
        <tr
          v-for="item in listafiltrada"
          :key="item.id"
        >
          <td >{{ item.nombre }}</td>
          <td>S/.{{item.total}} </td>
         
        </tr>
      </tbody>
    </template>
  </v-simple-table>

  </v-card>
  <fecha @fecha="date = $event"/>
  <fecha2 @fecha="date2 = $event"/>



</v-container>
</template>
<script>
import { allCabecera,consultaDetalle,allEmpleados } from '../../db'
import store from '@/store/index'
import fecha from '@/components/dialogos/dialogFecha'
import fecha2 from '@/components/dialogos/dialogFecha2'
import moment from 'moment'
import { ReporteVendedor} from '../../pdf'
  export default {
    components:{
      fecha,
      fecha2
    },

    data: () => ({
      documento : '',
      desserts: [ ],
      dialog:false,
      arrayConsolidar:[],
      buscar:'',
      date: moment(String(new Date)).format('YYYY-MM-DD'),
      date2: moment(String(new Date)).format('YYYY-MM-DD'),
      rechazados:0,
      error:'',
      seleccionado:'',
      empleadoslista:[],
      totaliza:0
    }),
      mounted() {
          this.inicio()
      allCabecera().orderByChild('fecha').startAt(moment(String(this.date))/1000).endAt(moment(String(this.date2)).add(23,'h').add(59,'m').add(59,'s')/1000).on("value", this.onDataChange);
    // allCabecera().on("value", this.onDataChange);
     },
    beforeDestroy() {
      allCabecera().off("value", this.onDataChange);
    },
     computed: {
        listafiltrada() {
            allCabecera().orderByChild('fecha').startAt(moment(String(this.date))/1000).endAt(moment(String(this.date2)).add(23,'h').add(59,'m').add(59,'s')/1000).on("value", this.onDataChange);
        return this.desserts
        }
     },

    methods: {
        inicio(){
            allEmpleados().once("value").then((snapshot) => {
                    let array = [];
                    snapshot.forEach((item) => {
                        let key = item.key;
                        let data = item.val();
                        array.push({
                        id: key,
                        activo:data.activo,
                        tipodoc:data.tipodoc,
                        documento:data.documento,
                        nombre:data.nombre,
                        });
                    });

                        this.empleadoslista = array
                    })
        },

        onDataChange(items) {
          let array = [];
          items.forEach((item) => {
            let data = item.val();     
          //  if(data.estado=='aprobado'){
                array.push({
                  tipoDocumento: data.tipoDocumento,
                  numeracion : data.numeracion,
                  total: data.total,
                  fecha: data.fecha,
                  estado: data.estado,
                  tipocomprobante: data.tipocomprobante,
                  modopago: data.modopago,
                  descuentos: data.descuentos,
                  serie:data.serie,
                  correlativoDocEmitido:data.correlativoDocEmitido,
                  vencimientoDoc: data.vencimientoDoc,
                  forma_pago: data.forma_pago,
                  cuotas: data.cuotas,
                  observacion: data.observacion,
                  nomempleado : data.nomempleado,
                  dniempleado : data.dniempleado
                });
           // }
          });

          this.consolidaEmpleado(array)
        },
        consolidaEmpleado(array){
            var consolida = []
            var totalizados = 0
            for(var i=0;i<array.length;i++){   
               for(var e=0;e<this.empleadoslista.length;e++){  
                    if(this.empleadoslista[e].documento==array[i].dniempleado){
                        totalizados = totalizados + parseFloat(array[i].total)
                        if(this.buscarArray(consolida,array[i].dniempleado)==''){
                            consolida.push({
                                id : this.empleadoslista[e].documento,
                                nombre : this.empleadoslista[e].nombre,
                                total : array[i].total
                            })
                        }else{
                            var pos = this.buscarposicion(consolida,array[i].dniempleado)
                            var suma = parseFloat(consolida[pos].total) + parseFloat(array[i].total)
                            consolida[pos].total = this.redondear(suma)
                        }

                    }
               }
            }
            this.desserts = consolida
            this.totaliza = this.redondear(totalizados)
        },
        buscarArray(array,id){
          var a= ''
                  for(var i=0;i<array.length;i++){
                    if(array[i].id==id){      
                      a=array[i]
                     }
                  }
              return a
        },
        buscarposicion(array,id){
          var a= ''
                  for(var i=0;i<array.length;i++){
                    if(array[i].id==id){      
                      a= i
                     }
                  }
              return a
        },
        conviertefecha(date){
            return moment.unix(date).format('DD/MM/YYYY hh:mm A')
        },
        
      redondear(valor){
          return parseFloat(valor).toFixed(store.state.configuracion.decimal)
      },
      imprimir(){
        ReporteVendedor(this.date,this.date2,this.listafiltrada,this.totaliza)
      }

    }
  }
</script>