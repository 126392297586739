<template>
<v-container class="mb-6">
        <v-dialog
      persistent
      v-model="dialogoprogress"
      max-width="200"
      >
    <v-card class="pa-12">
        <v-progress-circular
          :rotate="90"
          :size="100"
          :width="15"
          color="primary"
          indeterminate
        ></v-progress-circular>
    </v-card>
      </v-dialog>
<v-card>
          <v-row class="mx-auto">
            <v-col cols="6" xs="6">
              <v-btn block v-if="!tablaconsolida"
              color="success" @click="cambiaconsolida()">Consolida</v-btn>
             <v-btn block v-if="tablaconsolida"
              color="success" @click="tablaconsolida=false,arrayConsolidar=[]">Detallado</v-btn>
            </v-col>
            <v-col cols="6" xs="6">
              <v-btn block
              color="error" @click="dialogoElimina=!dialogoElimina">Elimina</v-btn>
              
            </v-col>
  </v-row>
    <v-card-title>
      <v-row class="mx-auto text-center">
          <v-col cols="6" xs="6">
                <v-text-field
                @click="$store.commit('dialogoFecha')"
                v-model="date"                   
                label="Inicio"
                readonly
              ></v-text-field>
          </v-col>

          <v-col cols="6" xs="6">
                <v-text-field
                @click="$store.commit('dialogoFecha2')"
                v-model="date2"                   
                label="Fin"
                readonly
              ></v-text-field>
          </v-col>
      
      </v-row>
    </v-card-title>
<v-row class="mt-n10">
  <v-spacer></v-spacer>
  <v-col cols="2">    
    <v-icon @click="exporta()" color="green">mdi-microsoft-excel</v-icon>
  </v-col>
</v-row>
 <v-simple-table
    v-if="tablaconsolida"
    fixed-header
    height="400px"
  >
    <template v-slot:default >
      <thead>
        <tr>
          <th>
            Cant
          </th>
          <th class="text-left">
            Nombre
          </th>
        </tr>
      </thead>
      <tbody >
        <tr
          v-for="item in arrayConsolidar"
          :key="item.id"
        >
          <td >{{ item.cantidad }}</td>
          <td >{{ item.nombre }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>

 <v-simple-table
    v-if="!tablaconsolida"
    fixed-header
    height="400px"
  >
    <template v-slot:default >
      <thead>
        <tr>
          <th>
            Cant
          </th>
          <th class="text-left">
            Nombre
          </th>
          <th class="text-left">
            Fecha
          </th>
          <th class="text-left">
            Cliente
          </th>
            <th class="text-left">
            Estado
          </th>
        </tr>
      </thead>
      <tbody >
        <tr
          v-for="item in listafiltrada"
          :key="item.id"
          @click="dialogo=!dialogo,itemselecto=item"
        >
          <td >{{ item.cantidad }}</td>
          <td >{{ item.nombre }}</td>
          <td>{{ conviertefecha(item.fecha) }}</td>
          <td v-if="!item.delivery"> Mesa: {{item.cliente }}</td>
          <td v-if="item.delivery"> Delivery: {{item.cliente }}</td>
          <td ><v-icon :color="item.colorEstado">mdi-circle</v-icon></td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>

  </v-card>
  <fecha @fecha="date = $event"/>
  <fecha2 @fecha="date2 = $event"/>

    <v-dialog
      v-model="dialogo"
      max-width="300"
      >
    <div>
      <v-system-bar window dark>
        <v-icon @click="dialogo = false">mdi-close</v-icon>
      </v-system-bar>
    </div>
      <v-card>
          <v-card-title primary-title>
              Detalle de pedido
          </v-card-title>
          <v-card-text>
              <p>Atendido por: <span class="red--text">{{itemselecto.usuario}}</span></p>
             <p>Observacion : <span class="red--text">{{itemselecto.observacion}}</span></p>             
          </v-card-text>
      </v-card>
  </v-dialog>

    <v-dialog
      v-model="dialogoElimina"
      max-width="300"
      >
    <div>
      <v-system-bar window dark>
        <v-icon @click="dialogoElimina = false">mdi-close</v-icon>
      </v-system-bar>
    </div>
      <v-card class="pa-3">
             <h2> Eliminar la informacion mejora el rendimiento! seguro que desea proceder?</h2>
      <v-card-actions>
        <v-spacer></v-spacer>
          <v-btn 
          color="error" @click="Borradata()">Si</v-btn>
          <v-btn 
          color="success" @click="dialogoElimina = false">No</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</v-container>
</template>

<script>
import moment from 'moment'
import fecha from '@/components/dialogos/dialogFecha'
import fecha2 from '@/components/dialogos/dialogFecha2'
import { allreporteCocina,EliminareporteCocina,allreporteBarra,EliminareporteBarra } from '../../db'
import XLSX from 'xlsx'
export default {
    components:{
      fecha,
      fecha2
    },
     data: () => ({
         tablaconsolida:false,
         itemselecto:[],
        dialogoprogress:false,
        dialogo:false,
        dialogoElimina:false,
        desserts:[],
        arrayExporta:[],
        arrayConsolidar:[],
        date: moment(String(new Date)).format('YYYY-MM-DD'),
        date2: moment(String(new Date)).format('YYYY-MM-DD'),
        obtenReporte:'',
        eliminaReporte:''
     }),
     created(){
       if(this.$route.params.id=='cocina'){
         this.obtenReporte = allreporteCocina()
       }
       else{
         this.obtenReporte = allreporteBarra()
       }
     },
     mounted(){
       this.obtenReporte.orderByChild('fecha').startAt(moment(String(this.date))/1000).endAt(moment(String(this.date2)).add(23,'h').add(59,'m').add(59,'s')/1000).on("value", this.onDataChange);
    },
    destroyed() {
      this.obtenReporte.off("value");
    },
     computed: {
        listafiltrada() {
            this.obtenReporte.orderByChild('fecha').startAt(moment(String(this.date))/1000).endAt(moment(String(this.date2)).add(23,'h').add(59,'m').add(59,'s')/1000).on("value", this.onDataChange);
           
        return this.desserts
        }
     },

    methods:{
        onDataChange(items) {    
          let array = [];
          items.forEach((item) => {
            let data = item.val();
            if(data.estado=='atendido'){
                var color = '#46FF00'
            }else{
                var color = '#FF0000'
            }
                array.push({
                  idproducto:data.idproducto,
                  nombre: data.nombre,
                  cantidad: data.cantidad,
                  medida: data.medida,
                  fecha: data.fecha,
                  cliente:data.cliente,
                  idmesa: data.idmesa,
                  cocina: data.cocina,
                  delivery:data.delivery,
                  estado: data.estado,
                  observacion: data.observacion,
                  usuario:data.usuario,
                  colorEstado: color
                });            
          });
          this.desserts=[]
          this.desserts = array.reverse();
        },
        conviertefecha(date){
            return moment.unix(date).format('DD/MM hh:mm A')
        },
        Borradata(){
            if(this.$route.params.id=='cocina'){
              EliminareporteCocina()
            }else{
              EliminareporteBarra()
            }
            this.dialogoElimina=false
        },
        cambiaconsolida(){
            this.cargaData().then((r)=>{
                this.tablaconsolida=true
            })
        },
        cargaData(){    
            var a= new Promise((resolve, reject)=>{
            this.desserts.forEach((item, index, array) => {
                if(item.estado=='atendido'){
                    if(this.buscarArray(this.arrayConsolidar,item.idproducto)==''){
                        this.arrayConsolidar.push({
                        id : item.idproducto,
                        nombre : item.nombre,
                        cantidad : item.cantidad,                        
                        })
                    }else{                        
                        this.sumaproductos(item.idproducto, item.cantidad)
                    }
                }
                if (index === array.length -1){ resolve("true")}; 
            })
            
            })
             return a
        },
      sumaproductos(id,cantidad){
            for(var i=0;i<this.arrayConsolidar.length;i++){
                if(this.arrayConsolidar[i].id==id){      
                    this.arrayConsolidar[i].cantidad=parseInt(this.arrayConsolidar[i].cantidad)+parseInt(cantidad)
                }
            }
        },
        buscarArray(array,id){
          var a= ''
                  for(var i=0;i<array.length;i++){
                    if(array[i].id==id){      
                      a=array[i]
                     }
                  }
              return a
        },
        router(view){
        this.$router.push({name:view})
    },
    exporta(){
      var array = this.desserts
        var a= new Promise((resolve, reject)=>{
            array.forEach((items, index, array)=>{ 
              
              this.arrayExporta.push({
                  codigo:items.idproducto,
                  nombre: items.nombre,
                  cantidad: items.cantidad,
                  medida: items.medida,
                  fecha: moment.unix(items.fecha).format('DD/MM/YYYY hh:mm A'),
                  cliente:items.cliente,
                  idmesa: items.idmesa,
                  estado: items.estado,
                  observacion: items.observacion,
                  responsable:items.usuario,
              })  

              if (index === array.length -1){ this.exportaExcel()}; 
             })
        })
        a.then((value)=>{
          return value
        })  
        return a
     },
      exportaExcel(){
      var data = XLSX.utils.json_to_sheet(this.arrayExporta)
      const workbook = XLSX.utils.book_new()
      const filename = 'REPORTE'
      XLSX.utils.book_append_sheet(workbook, data, "REPORTE")
      XLSX.writeFile(workbook, `${filename}.xlsx`)
      },
    }
}
</script>

<style>

</style>