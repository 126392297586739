<template>
    <v-dialog v-model="dial" max-width="600px" persistent>
        <div>
            <v-system-bar window dark>
                <v-icon @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-icon v-if="tabla == 'productos'" large color="red"
                    @click="tabla = 'categorias'">mdi-keyboard-return</v-icon>
            </v-system-bar>
        </div>
        <v-card>
            <div>
                <v-card-title v-if="tabla == 'productos'">
                    <v-spacer></v-spacer>
                    <v-text-field class="mb-n1 mt-n2" outlined dense v-model="buscar" append-icon="mdi-magnify"
                        label="Buscar" single-line hide-details></v-text-field>
                </v-card-title>
                <v-simple-table fixed-header max-width="600px" class="pa-1" v-if="tabla == 'categorias'">
                    <template v-slot:default>
                        <thead>
                        </thead>
                        <tbody>

                            <v-row class="mt-1 mb-4 mx-auto" style="text-align:center" dense>
                                <v-col v-for="item in array_categorias" :key="item.nombre" cols="6" class="pa-1" md="4"
                                    sm="4" xs="4">
                                    <v-card height="80" @click.prevent="iraproductos(item)">
                                        <v-card-text class="red--text" style="font-size:medium">
                                            <span class="black--text">{{ item.nombre }}</span>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>

                        </tbody>
                    </template>
                </v-simple-table>
                <v-simple-table fixed-header height="400px" v-if="tabla == 'productos'">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Nombre
                                </th>
                                <th class="text-left">
                                    Medida
                                </th>
                                <th class="text-left">
                                    Stock
                                </th>
                                <th class="text-left">
                                    Costo
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in listafiltrada" :key="item.id" @click="abre_cantidad(item)">
                                <td>{{ item.nombre }}</td>
                                <td>{{ item.medida }}</td>
                                <td>{{ item.stock }}</td>
                                <td>{{ item.costo }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </div>
        </v-card>
        <v-dialog v-model="dialo_cantidad" max-width="200px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialo_cantidad = !dialo_cantidad">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card>

                <v-card-title> <v-text-field type="number" autofocus outlined dense v-model="costo" label="Costo"
                        @focus="$event.target.select()" @keyup.enter="agregaCatalogo()"></v-text-field>
                    <v-text-field type="number" autofocus outlined dense v-model="cantidad" label="CANTIDAD"
                        @keyup.enter="agregaCatalogo()"></v-text-field>
                </v-card-title>

                <v-btn class="mt-n6" color="red" @click="agregaCatalogo()" block>OK</v-btn>

            </v-card>
        </v-dialog>

    </v-dialog>
</template>

<script>
import store from '@/store/index'
import { allInsumos, allCategoriasInsumos } from '../../db'
export default {

    data() {
        return {
            tabla: 'categorias',
            dialo_cantidad: false,
            dial: false,
            buscar: '',
            desserts: [],
            array_categorias: [],
            selecto: [],
            cantidad: 1,
            costo: '',
            cate_selecta: ''
        }
    },
    async created() {
        await this.obten_categoria()
        this.dial = true
        allInsumos().on("value", this.onDataChange);

    },

    mounted() {
        allInsumos().on("value", this.onDataChange);
    },
    beforeDestroy() {
        allInsumos().off("value", this.onDataChange);
    },
    computed: {
        listafiltrada() {
            var invent = this.desserts.filter(item => item.categoria == this.cate_selecta)
            console.log(invent)
            return invent.filter((item) =>
                (item.activo) == true)
                .filter((item) => (item.nombre)
                    .toLowerCase().includes(this.buscar.toLowerCase()))
        }
    },
    methods: {
        iraproductos(data) {
            console.log(data)
            this.cate_selecta = data.nombre
            this.tabla = 'productos'
        },
        async obten_categoria() {
            var array = []
            var snap = await allCategoriasInsumos().once('value')
            if (snap.exists()) {
                snap.forEach((item) => {
                    let data = item.val();
                    array.push(data);

                });
            }
            this.array_categorias = array
        },
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                let key = item.key;
                array.push(data);

            });
            this.desserts = array;
        },
        abre_cantidad(item) {
            this.cantidad = 1
            this.costo = item.costo
            this.selecto = item
            this.dialo_cantidad = true
        },
        agregaCatalogo(item) {

            var item = this.selecto
            item.cantidad = this.cantidad
            item.costo = this.costo
            this.$emit('array', item)

        },

        cierra() {
            this.$emit('cierra', false)
        }
    },

}
</script>