<template>
    <v-container class="mb-7">
         <v-row class="mx-auto text-center">
          <v-col cols="6" xs="6">
                <v-text-field
                dense
                type="date"
                v-model="date"                   
                label="Inicio"      
                outlined          
              ></v-text-field>
          </v-col>

          <v-col cols="6" xs="6">
                <v-text-field
                dense
                type="date"
                v-model="date2"                   
                label="Fin"
                outlined
              ></v-text-field>
          </v-col>
      
      </v-row>
         <v-row class="mx-auto text-center mt-n6">
          <v-col cols="6" xs="6">
              <v-btn block color="success" @click="dialog=!dialog">Comprobantes</v-btn>
          </v-col>
          <v-col cols="6">    
            <v-icon @click="exportaExcel()" color="green">mdi-microsoft-excel</v-icon>
          </v-col>
          </v-row>


           <v-dialog
      v-model="dialog"
      max-width="590"
      >
    <div>
      <v-system-bar window dark>
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-system-bar>
    </div>
    <v-card>
  
 <v-simple-table
    fixed-header
    height="450px"
    dense
  >
    <template v-slot:default >
      <thead>
        <tr>
          <th class="text-left">
            Comprobante
          </th>
          <th class="text-left">
            Fecha
          </th>
            <th class="text-left">
            Total
          </th>
        </tr>
      </thead>
      <tbody >
        <tr
          v-for="item in listafiltrada"
          :key="item.id"
        >
        <td>{{ item.numeracion }}</td>
        <td>{{ conviertefecha(item.fecha) }}</td>
        <td>{{ item.total - item.descuentos }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  <v-card-actions>
    <v-btn block color="success" @click="Procesa()">Procesa Reporte</v-btn>
  </v-card-actions>
        </v-card>
  </v-dialog>

          <v-simple-table
    fixed-header
    height="450px"
    dense
  >
    <template v-slot:default >
      <thead>
        <tr>
          <th class="text-left">
            FECHA
          </th>
            <th class="text-left">
            SERIE
          </th>
            <th class="text-left">
            CORRELATIVO
          </th>
            <th class="text-left">
            CLIENTE N°
          </th>
          <th class="text-left">
            RAZON SOC.
          </th>
          <th class="text-left" >
            EXONERADA
          </th>
          <th class="text-left">
            BASE IMP.
          </th>
          <th class="text-left">
            IGV.
          </th>
        <th class="text-left">
            TOTAL
          </th>
        </tr>
      </thead>
      <tbody >
        <tr
          v-for="item in  arrayConsolidar"
          :key="item.id"
        >
          <td>{{item.fecha}}</td>
          <td>{{item.serie}}</td>
          <td>{{item.correlativo}}</td>
          <td>{{item.clientenum}}</td>
          <td>{{}}</td>
          <td>{{item.exonerada}}</td>
          <td>{{item.baseimp}}</td>
          <td>{{item.igv}}</td>
          <td>{{item.total}}</td>

        </tr>
      </tbody>
    </template>
  </v-simple-table>

    <h5>T.EXONERADA: S/.{{texonerada.toFixed(2)}}</h5>
    <h5>T.Base imp: S/.{{tbaseimpo.toFixed(2)}}</h5>
    <h5>T.IGV: S/.{{tigv.toFixed(2)}}</h5>
    <h5>Total General: S/.{{ttotal.toFixed(2)}}</h5>


    </v-container>
</template>
<script>
import moment from 'moment'
import XLSX from 'xlsx'
import { allCabecera,consultaDetalle,buscaProductos } from '../../db'
  export default {
    
    data: () => ({
        date: moment(String(new Date)).format('YYYY-MM-DD'),
        date2: moment(String(new Date)).format('YYYY-MM-DD'),
        desserts: [ ],
        dialog: false,
        arrayConsolidar:[],
        arrayExporta:[],
        texonerada : 0,
        tbaseimpo:0,
        tigv:0,
        ttotal:0
     
    }),
    mounted(){
       allCabecera().orderByChild('fecha').startAt(moment(String(this.date))/1000).endAt(moment(String(this.date2)).add(23,'h').add(59,'m').add(59,'s')/1000).on("value", this.onDataChange);
    },
    destroyed() {
      allCabecera().off("value");
    },
     computed: {
        listafiltrada() {
            allCabecera().orderByChild('fecha').startAt(moment(String(this.date))/1000).endAt(moment(String(this.date2)).add(23,'h').add(59,'m').add(59,'s')/1000).on("value", this.onDataChange);
        return this.desserts
        }
     },
    methods: {
        onDataChange(items) {
          let array = [];
          items.forEach((item) => {
            let data = item.val();   
            if(data.tipocomprobante !='T'){
                array.push({
                  numeracion : data.numeracion,
                  total: data.total,
                  fecha: data.fecha,
                  estado: data.estado,
                  documentId: data.documentId,
                  tipocomprobante: data.tipocomprobante,
                  modopago: data.modopago,
                  descuentos: data.descuentos,
                  cliente: data.cliente,
                  dni: data.dni,
                  serie: data.serie,
                  correlativo : data.correlativoDocEmitido,
                });
            }
          });

          this.desserts = array;
        },

        Procesa(){
            this.arrayConsolidar=[]
            this.texonerada = 0
            this.tbaseimpo = 0
            this.tigv = 0
            this.ttotal = 0
            var array = this.listafiltrada
            for(var i=0;i<array.length;i++){   
                 this.cargaData(array[i])
            }
            this.dialog = false
      
 
        },
        cargaData(value){
            var array = []
            var ope = 0
            var exo = 0                           
            var total = 0                           
            var baseimp = 0
            var igv = 0
                consultaDetalle(value.numeracion).once("value").then((snapshot) => {
                   snapshot.forEach((item) => {
                       if(item.val().operacion=='GRAVADA'){
                            ope = 'GRAVADA'                      
                            total = total + parseFloat(item.val().precioedita) * parseFloat(item.val().cantidad)                           
                            baseimp = baseimp+  parseFloat(item.val().precioedita) * parseFloat(item.val().cantidad)/1.18
                            igv = parseFloat(baseimp)*0.18
                       }else{
                            ope = 'EXONERADA'
                            exo = exo + parseFloat(item.val().precioedita) * parseFloat(item.val().cantidad)                       
                            total = total + parseFloat(item.val().precioedita) * parseFloat(item.val().cantidad)                        
                       }

                   })
                     this.arrayConsolidar.push({
                        fecha:this.conviertefecha(value.fecha),
                        serie:value.serie,
                        correlativo : value.correlativo,
                        clientenum : value.dni,
                        clientenom : value.cliente,
                        exonerada : exo.toFixed(2), 
                        baseimp : baseimp.toFixed(2),
                        igv : igv.toFixed(2),
                        total : total.toFixed(2)
                        })   
                        this.texonerada = this.texonerada + parseFloat(exo)
                        this.tbaseimpo = this.tbaseimpo + parseFloat(baseimp)
                        this.tigv = this.tigv + parseFloat(igv)
                        this.ttotal = this.ttotal + parseFloat(total)
                  })       
    
      
        },

      sumaproductos(array){
            for(var i=0;i<array.length;i++){

            }
        },
        buscarArray(array,id){
          var a= ''
                  for(var i=0;i<array.length;i++){
                    if(array[i].id==id){      
                      a=array[i]
                     }
                  }
              return a
        },

        conviertefecha(date){
            return moment.unix(date).format('DD/MM/YYYY')
        },

      redondear(valor){
          return parseFloat(valor).toFixed(2)
      },
   
      exportaExcel(){
      var data = XLSX.utils.json_to_sheet(this.arrayConsolidar)
      const workbook = XLSX.utils.book_new()
      const filename = 'REPORTE'
      XLSX.utils.book_append_sheet(workbook, data, "REPORTE")
      XLSX.writeFile(workbook, `${filename}.xlsx`)
      },
        
    }
  }
</script>
