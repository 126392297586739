<template>
    <v-dialog v-model="dial_activo" max-width="60vh" transition="dialog-top-transition" persistent>
        <div>
            <v-system-bar window dark>
                <v-icon @click="cierra()">mdi-close</v-icon>
            </v-system-bar>
        </div>
        <v-card class="pa-2">
            <v-card-title primary-title class="mt-n5 mb-n2">
                OBSERVACIONES :
            </v-card-title>
    
            <v-data-table :headers="dessertHeaders" :items="listafiltrada" item-key="nombre" dense show-expand :expanded.sync="expanded">
                <template v-slot:item.actions="{ item }">
                    <v-row>
                        <v-col cols="12">
                            <v-icon color="green" @click="agrega(item)">mdi-plus</v-icon>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="pa-2">
                        <v-simple-table fixed-header dense>
                            <template v-slot:default>
                                <tbody>
                                    <tr v-for="items in item.lista" :key="items.id">
                                        <td>{{ items.nombre }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </td>
                </template>
            </v-data-table>
    
        </v-card>
    </v-dialog>
    </template>
    
    <script>
    import {
        all_grupo_obs,
    } from '../../db'
    export default {
        props: {
            dial_activo: '',
        },
        name: 'caja',
        data() {
            return {
                expanded: [],
                singleExpand: false,
                dessertHeaders: [{
                        text: 'GRUPO',
                        value: 'nombre'
                    },
                    {
                        text: 'Actions',
                        value: 'actions',
                        sortable: false
                    },
                ],
                desserts: [],
            }
        },
        mounted() {
            all_grupo_obs().on("value", this.onDataChange);
        },
        beforeDestroy() {
            all_grupo_obs().on("value", this.onDataChange);
        },
        computed: {
            listafiltrada() {
                all_grupo_obs().on("value", this.onDataChange);
                return this.desserts
            }
        },
        methods: {
            onDataChange(items) {
                let array = [];
                items.forEach((item) => {
                    let data = item.val();
                    array.push(data);
                });
    
                this.desserts = array;
            },          
            cierra() {
                this.$emit('cierra', false)
            },
            agrega(data){
                this.$emit('seleccion', data)
            }
        },
    
    }
    </script>
    