<template>
    <v-dialog v-model="$store.state.dialogoRestaurante" max-width="460px">
        <div>
            <v-system-bar window dark>
                <v-icon @click="$store.commit('dialogoRestaurante')">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <v-icon large color="green" @click="graba()">mdi-content-save</v-icon>
            </v-system-bar>
        </div>

        <v-card class="pa-5">
            <v-row dense>
                <v-col cols="6">
                    <v-checkbox dense v-model="edita_comandas" label="No Editar Comandas luego de guardar"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox dense v-model="stock_comandas" label="Control de Stock Comandas"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox dense v-model="mosos_mesa" label="Nombre mosos en mesa"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox dense v-model="proformas" label="Proformas"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox dense v-model="guard_mesa" label="Guard. Borrados en mesa"></v-checkbox>
                </v-col>
            </v-row>
        </v-card>

    </v-dialog>
</template>

<script>
import {
    allConfigura,
    grabaConfigura
} from '../../db'
import store from '@/store/index'

export default {
    data() {
        return {
            edita_comandas: false,
            stock_comandas: false,
            mosos_mesa: false,
            proformas: false,
            guard_mesa: false,
        }
    },
    created() {
        this.inicializar()
    },
    methods: {
        inicializar() {
            allConfigura().once("value").then((snapshot) => {
                if (snapshot.val() != null || snapshot.exists()) {
                    this.edita_comandas = snapshot.val().edita_comandas
                    this.stock_comandas = snapshot.val().stock_comandas
                    this.mosos_mesa = snapshot.val().mosos_mesa
                    this.proformas = snapshot.val().proformas
                    this.guard_mesa = snapshot.val().guard_mesa
                } else {
                    this.edita_comandas = false
                    this.stock_comandas = false
                    this.mosos_mesa = false
                    this.proformas = false
                    this.guard_mesa = false
                }

            })
        },
        graba() {

            grabaConfigura('edita_comandas', this.edita_comandas || false)
            grabaConfigura('stock_comandas', this.stock_comandas || false)
            grabaConfigura('mosos_mesa', this.mosos_mesa || false)
            grabaConfigura('proformas', this.proformas || false)
            grabaConfigura('guard_mesa', this.guard_mesa || false)
            store.commit('dialogoRestaurante')
        }
    }
}
</script>
