<template>
    <div grid-list-xs class="mb-6 pa-4">
        <v-row dense class="text-center">
            <v-col cols="6" md="3" xs="6">
                <v-btn dense color="success" small block @click="dialogocategoria = !dialogocategoria">
                    AGREGA CATEGORIAS
                </v-btn>
            </v-col>
            <v-col cols="6" md="3" xs="6">
                <v-btn dense color="warning" small block @click="abre_qr()">
                    GENERA MI QR
                </v-btn>
            </v-col>
            <v-col cols="6" md="3" xs="6">
                <v-btn dense color="error" small block @click="vista_previa()">
                    VISTA PREVIA
                </v-btn>
            </v-col>
            <v-col cols="6" md="3" xs="6">
                <v-btn dense color="error" disabled small block>
                    CONFIGURACIONES
                </v-btn>
            </v-col>
        </v-row>

        <h4></h4>
        <v-row class="mt-3" dense>
            <v-col v-for="item in desserts" :key="item.id" cols="12" md="6" sm="2" xs="12">
                <v-card height="100" style="text-align:left">
                    <v-row dense no-gutters>
                        <v-col cols="6">
                            <v-card-text>
                                <p class="black--text mt-2" style="font-size:15px;font-style:italic;font-weight: bold">
                                    {{ item.nombre }}</p>
                                <p class="black--text mt-n5" style="font-size:12px">{{ item.descripcion }}</p>

                            </v-card-text>
                        </v-col>

                        <v-col cols="4">
                            <v-img :src="item.url_foto" aspect-ratio="1" class="mt-2" :width="135" :height="85">
                                <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                        </v-col>
                        <v-col cols="2">
                            <v-row dense no-gutters>
                                <v-icon color="success" class="ml-2 mt-5" @click="irA(item)">mdi-list-status</v-icon>
                            </v-row>
                            <v-row dense no-gutters>
                                <v-icon color="red" class="ml-2 mt-3" @click="edita_cate(item)">mdi-cog</v-icon>
                            </v-row>
                        </v-col>

                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="dialogocategoria" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogocategoria = !dialogocategoria">mdi-close</v-icon>
                    <v-spacer></v-spacer>

                </v-system-bar>
            </div>
            <v-card class="pa-6">
                <v-simple-table fixed-header height="60vh" dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Nombre
                                </th>
                                <th class="text-left">
                                    Posicion
                                </th>
                                <th class="text-left">
                                    Accion
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in array_categorias" :key="item.id">
                                <td>{{ item.nombre }}</td>
                                <td>
                                    <v-icon @click="sube(item)" color="green">mdi-plus</v-icon>
                                    {{ item.posicion }}
                                    <v-icon @click="baja(item)" color="red">mdi-minus</v-icon>
                                </td>
                                <td>
                                    <v-icon @click="agrega(item)" color="green">mdi-plus</v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>

        </v-dialog>
        <v-dialog v-model="dial_edita_cate" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_edita_cate = !dial_edita_cate">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-icon large color="error" @click="elimina_cate()">mdi-delete</v-icon>
                    <v-icon large color="success" @click="guarda_cate()">mdi-content-save</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-row class="mt-1" dense>
                    <v-col cols="12">
                        <v-textarea readonly outlined dense v-model="item_selecto.nombre" auto-grow filled
                            label="Nombre Categoria" rows="1"></v-textarea>
                    </v-col>
                    <v-col cols="12" class="mt-n6">
                        <v-textarea outlined dense v-model="descripcion" auto-grow filled label="Descripcion"
                            rows="1"></v-textarea>
                    </v-col>
                    <v-col cols="12" class="mt-n6 text--center">
                        <croppa :placeholder-font-size="19" canvas-color="orange" placeholder-color="#000" :width="325"
                            :height="190" :initial-image="imagenURL" v-model="myCroppa">
                        </croppa>
                    </v-col>
                </v-row>
            </v-card>

        </v-dialog>
        <v-dialog v-model="dial_qr" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_qr = !dial_qr">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3 text-center">
                <vue-qr class="" :text="value" :size="200" :logoSrc="logo_src"></vue-qr>
                <h5 class="text--center"> CLICK DERECHO PARA GUARDAR IMAGEN</h5>
            </v-card>

        </v-dialog>
        <v-dialog v-model="progress" max-width="200">
            <v-card>
                <v-progress-circular :rotate="90" :size="200" :width="15" :value="Uploadvalue" color="red">
                    {{ Uploadvalue }}
                </v-progress-circular>
            </v-card>

        </v-dialog>
    </div>
</template>

<script>
import VueQr from 'vue-qr'
import {
    db,
    guardaArchivo,
    allCategorias,
    editaCategoria
} from '../../db'

import store from '@/store/index'

export default {
    components: {
        VueQr
    },
    data: () => ({
        dial_qr: false,
        desserts: [],
        array_categorias: [],
        dialogocategoria: false,
        dial_edita_cate: false,
        item_selecto: [],
        descripcion: '',
        selectedFile: '',
        progress: false,
        selectedFile: null,
        Uploadvalue: 0,
        myCroppa: {},
        imagenURL: '',
        logo_src: '',
        value: ''
    }),
    mounted() {
        allCategorias().on("value", this.onDataChange);
    },
    beforeDestroy() {
        allCategorias().off("value", this.onDataChange);
    },

    methods: {
        vista_previa() {
            console.log(store.state.baseDatos)
            var empresa = store.state.baseDatos
            this.$router.push({
                path: "/carta/" + empresa.bd + empresa.ruc + '/online'
            })
        },
        abre_qr() {
            var empresa = store.state.baseDatos
            this.value = "https://domotica-peru.com/carta/" + empresa.bd + empresa.ruc + '/online'
            this.dial_qr = true
        },
        initialize() {
            allCategorias().on("value", this.onDataChange);
        },
        onDataChange(items) {
            let array = [];
            let array2 = [];
            items.forEach((item) => {
                let data = item.val();
                array2.push(data)
                if (data.carta) {
                    array.push(data);
                }
            });
            array.sort(function (a, b) {
                if (a.posicion > b.posicion) {
                    return 1;
                }
                if (a.posicion < b.posicion) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
            array2.sort(function (a, b) {
                if (a.posicion > b.posicion) {
                    return 1;
                }
                if (a.posicion < b.posicion) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
            this.array_categorias = array2
            this.desserts = array;

        },
        sube(item) {
            console.log(item)
            var pos = parseInt(item.posicion) - 1
            editaCategoria(item.id, "posicion", pos)
        },
        baja(item) {
            console.log(item)
            var pos = parseInt(item.posicion) + 1
            editaCategoria(item.id, "posicion", pos)
        },
        agrega(item) {
            console.log(item)
            editaCategoria(item.id, "carta", true)
            this.dialogocategoria = false
        },
        edita_cate(item) {
            this.descripcion = ''
            if (item.descripcion != undefined) {
                this.descripcion = item.descripcion
            }
            this.item_selecto = item
            this.dial_edita_cate = true
        },
        guarda_cate() {

            this.uploadCroppedImage()
            if (Boolean(this.selectedFile)) {
                // this.onupload()
            }
            editaCategoria(this.item_selecto.id, "descripcion", this.descripcion)
            this.descripcion = ''
            this.selectedFile = ''
            this.dial_edita_cate = false
        },
        elimina_cate() {
            editaCategoria(this.item_selecto.id, "carta", false)
            this.descripcion = ''
            this.selectedFile = ''
            this.dial_edita_cate = false
        },
        irA(item) {
            this.$router.push({
                path: "/productos_carta/" + item.id + '/' + item.nombre
            })
        },
        uploadCroppedImage() {
            this.myCroppa.generateBlob((blob) => {
                if (blob != null) {
                    this.progress = true
                    var task = guardaArchivo(store.state.baseDatos.bd, blob, this.item_selecto.id)
                    task.on('state_changed', snapshot => {
                        let porcentaje = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        this.Uploadvalue = porcentaje
                    }, error => {
                        console.log(error.message)
                    },
                        () => {
                            this.Uploadvalue = 100;
                            task.snapshot.ref.getDownloadURL().then((url) => {
                                editaCategoria(this.item_selecto.id, "url_foto", url)
                                this.progress = false
                            })
                        }
                    )
                }
            }, 'image/webp', 0.8) // 80% compressed jpeg file

        },
        onupload() {
            this.progress = true
            const storageRef = db.storage().ref(`/${store.state.baseDatos.bd}/${this.item_selecto.id}`)
            const task = storageRef.put(this.selectedFile)
            task.on('state_changed', snapshot => {

                let porcentaje = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                this.Uploadvalue = porcentaje
            }, error => {
                console.log(error.message)
            },
                () => {
                    this.Uploadvalue = 100;
                    task.snapshot.ref.getDownloadURL().then((url) => {
                        editaCategoria(this.item_selecto.id, "url_foto", url)
                        this.selectedFile = '',
                            this.progress = false
                    })
                }
            )

        },

    },
}
</script>
