<template>
    <v-container>
        <v-card>
            <v-card-text>
                <h4 class="text-center">RECIBE LOS PEDIDOS DE TUS APP DIRECTAMENTE CON NOSOTROS.</h4>
            </v-card-text>
        </v-card>
        <v-row class="mb-4 mt-4" dense>
            <v-col cols="4" class="pa-3">
                <v-card>
                    <v-container>
                        <v-img class="mx-auto" height="69" width="72" src="/llamafood.png"></v-img>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="4" class="pa-3">
                <v-card>
                    <v-container>
                        <v-img class="mx-auto" height="70" width="80" src="/pedidosya.png"></v-img>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="4" class="pa-3">
                <v-card>
                    <v-container>
                        <v-img class="mx-auto" height="70" width="90" src="/rappy.png"></v-img>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// @ is an alias to /src
import store from '@/store/index'
import {
    allCronogramapago
} from '../../db'
export default {
    name: 'panel',

    data() {
        return {

        }
    },
    created() {

    },
    methods: {
        ver_pedidos() {
            this.$router.push({
                path: '/reporte_pedidos'
            })
        }
    },

}
</script>
