<template>
     <v-dialog
      v-model="$store.state.dialogoaPaletaColores"
      max-width="350"
      >
   <div>
      <v-system-bar window dark>
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-system-bar>
    </div>
      <v-card class="pa-3">
    <v-row>
      <v-col
        class="d-flex justify-center"
      >
        <v-color-picker v-model="color"></v-color-picker>
      </v-col>

    </v-row>
        <v-card-actions> 
          <v-btn
            :color="showColor"
            @click="$store.commit('dialogoaPaletaColores'),
            $store.commit('colorselecto',showColor)"
          >
            Guardar
          </v-btn>
       </v-card-actions>
 
      </v-card>
     </v-dialog>
</template>
<script>
  export default {
      name: 'paletaColores',
    data: () => ({
      types: ['hex', 'hexa', 'rgba', 'hsla', 'hsva'],
      type: 'hex',
      hex: '#FF00FF',
      hexa: '#FF00FFFF',
      rgba: { r: 255, g: 0, b: 255, a: 1 },
      hsla: { h: 300, s: 1, l: 0.5, a: 1 },
      hsva: { h: 300, s: 1, v: 1, a: 1 },
    }),

    computed: {
      color: {
        get () {
          return this[this.type]
        },
        set (v) {
          this[this.type] = v
        },
      },
      showColor () {
        if (typeof this.color === 'string') return this.color

        return JSON.stringify(Object.keys(this.color).reduce((color, key) => {
          color[key] = Number(this.color[key].toFixed(2))
          return color
        }, {}), null, 2)
      },
    },
  }
</script>