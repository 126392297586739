<template>
<div class="pa-2 mt-1">
    <h3>GESTION DE PEDIDOS</h3>
    <mapa :cliente="array_clientes" @cierra="siguiente($event)" />
</div>
</template>

<script>
// @ is an alias to /src
import store from '@/store/index'
import mapa from './mapa_reporte'
import {
    all_pedidos_online
} from '../../db'
export default {
    name: 'panel',
    components: {
        mapa,
    },
    data() {
        return {
            array_clientes:[]
        }
    },
    created() {
        all_pedidos_online().on("value", this.onDataChange);
    },
    mounted() {
        all_pedidos_online().on("value", this.onDataChange);
    },
    beforeDestroy() {
        all_pedidos_online().off("value", this.onDataChange);
    },
    methods: {
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                array.push(data);
            });
            array.sort(function (a, b) {
                if (a.fecha > b.fecha) {
                    return 1;
                }
                if (a.fecha < b.fecha) {
                    return -1;
                }
                return 0;
            });

            this.array_clientes = array;

        },
    },

}
</script>
