<template>
    <div class="mb-6 pa-2">
        <v-icon v-if="!categoria" @click="categoria = !categoria" color="red">mdi-keyboard-return</v-icon>
        <div v-if="categoria">
            <v-row dense>
                <v-col v-for="item in desserts" :key="item.id" cols="12" md="4" sm="4" xs="12" class="mx-auto">
                    <v-card height="200" style="text-align:left" @click="selecciona_categoria(item)">
                        <v-row dense no-gutters>
                            <v-img :src="item.url_foto" class="white--text align-end"
                                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" :width="600" :height="200">
                                <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                    </v-row>
                                </template>
                                <v-card-title v-text="item.nombre"></v-card-title>
                                <v-card-text v-text="item.descripcion" class="mt-n6"></v-card-text>
                            </v-img>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <div v-if="!categoria">
            <v-row dense>
                <v-col v-for="item in array_productos" :key="item.id" cols="12" md="4" sm="6" xs="12" class="mx-auto">
                    <v-card height="200" style="text-align:left" @click="selecciona_pro(item)" elevation="10">
                        <v-row dense no-gutters>

                            <v-img :src="item.url_foto" class="white--text align-end"
                                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" :width="600" :height="200">
                                <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                    </v-row>
                                </template>
                                <v-row>
                                    <v-col cols="6">
                                        <v-card-title>{{ item.nombre }}</v-card-title>
                                        <v-card-text class="mt-n6">{{ item.descripcion }} </v-card-text>
                                    </v-col>
                                    <v-col cols="2">

                                    </v-col>
                                    <v-col cols="4">
                                        <v-card-title>S/.{{ item.precio }}</v-card-title>
                                    </v-col>
                                </v-row>
                            </v-img>

                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <v-dialog v-model="dial_selecciona" max-width="450">

            <v-card>
                <v-img height="225" :src="producto_selecto.url_foto"></v-img>

                <v-row no-gutters>
                    <v-col cols="6">
                        <v-card-title>{{ producto_selecto.nombre }}</v-card-title>
                    </v-col>
                    <v-col cols="2">

                    </v-col>
                    <v-col cols="4">
                        <v-card-title>S/.{{ producto_selecto.precio }}</v-card-title>
                    </v-col>
                </v-row>

                <v-card-text>
                    <v-row align="center" class="mx-0">
                        <v-rating :value="4.5" color="amber" dense half-increments readonly size="14"></v-rating>

                        <div class="grey--text ms-4">
                            4.5 (413)
                        </div>
                    </v-row>

                    <div class="mt-2">{{ producto_selecto.descripcion }}</div>
                </v-card-text>

                <v-divider class="mx-4 mt-n4"></v-divider>
                <v-row no-gutters>
                    <v-col cols="6">
                        <v-card-title>Cantidad</v-card-title>
                    </v-col>

                    <v-col cols="6">
                        <v-row class="text-center mt-4" no-gutters>
                            <v-col cols="4">
                                <v-icon @click="resta()" color="red">mdi-minus</v-icon>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field dense type="number" outlined v-model="cantidad_plato"
                                    label="Cantidad"></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-icon @click="suma()" color="green">mdi-plus</v-icon>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider class="mx-4 mt-n5"></v-divider>

                <v-card-title class="mt-n3 mb-n3">Detalle del Plato</v-card-title>

                <v-card-text>
                    <v-chip-group v-model="selection" multiple active-class="deep-purple accent-4 white--text" column>
                        <v-chip v-for="tag in array_lista_obs" :key="tag.nombre">
                            {{ tag.nombre }}
                        </v-chip>
                    </v-chip-group>
                    <v-textarea outlined dense v-model="obs_adicionales" auto-grow filled label="Descripcion"
                        rows="1"></v-textarea>
                </v-card-text>

                <v-card-actions class="mt-n12">
                    <v-btn color="red lighten-2" text @click="dial_selecciona = false">
                        Cancela
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="deep-purple lighten-2" text @click="agrega_carrito()">
                        Agrega
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
        <v-dialog v-model="dial_confirma_pedido" max-width="250px">
            <v-card class="pa-4">
                <p class="mx-auto text-center" style="font-style: italic">TU PEDIDO HA SIDO INGRESADO CON EXITO</p>
                <v-img src="/gif_delivery.gif" max-width="150" max-height="300" class="mx-auto"></v-img>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="deep-purple lighten-2 mb-n12" text @click="dial_confirma_pedido = false">
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <dial_cliente v-if="dial_inscribe" :dial_activo="dial_inscribe" @cierra="dial_inscribe = $event"
            @termina="termina($event)" />
        <dial_carrito v-if="$store.state.dial_carrito" :dial_activo="$store.state.dial_carrito"
            @cierra="$store.commit('dial_carrito', false)" @continua="continua_carrito($event)" />
    </div>
</template>

<script>
import {
    allCategorias_carta,
    allProductos_carta,
    nuevo_pedido
} from '../../db'
import moment from 'moment'
import store from '@/store/index'
import dial_cliente from './datos_cliente.vue'
import dial_carrito from './carrito.vue'
export default {
    components: {
        dial_cliente,
        dial_carrito
    },
    data: () => ({
        dial_confirma_pedido: false,
        desserts: [],
        categoria: true,
        item_selecto: [],
        array_productos: [],
        producto_selecto: [],
        dial_inscribe: false,
        dial_selecciona: false,
        selection: [],
        cantidad_plato: 1,
        obs_adicionales: '',
        array_lista_obs: [],
        bd_selecta: ''
    }),
    created() {
        this.bd_selecta = this.$route.params.id.substring(0, 4)
    },
    mounted() {
        allCategorias_carta(this.bd_selecta).on("value", this.onDataChange);
    },
    beforeDestroy() {
        allCategorias_carta(this.bd_selecta).off("value", this.onDataChange);
    },

    methods: {
        initialize() {
            allCategorias_carta(this.bd_selecta).on("value", this.onDataChange);
        },
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                if (data.carta) {
                    array.push(data);
                }
            });
            array.sort(function (a, b) {
                if (a.posicion > b.posicion) {
                    return 1;
                }
                if (a.posicion < b.posicion) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
            this.desserts = array;
        },
        selecciona_categoria(items) {
            this.item_selecto = items
            var array = []
            allProductos_carta(this.bd_selecta).once("value").then((snapshot) => {
                snapshot.forEach((item) => {
                    var data = item.val()
                    if (data.carta && data.categoria == items.nombre) {
                        array.push(data)
                    }
                })
                this.array_productos = array
                this.categoria = false
            })

        },
        selecciona_pro(data) {
            this.selection = []
            this.producto_selecto = data
            var lista_obs = store.state.observaciones.find(id => String(id.nombre) === String(data.grupo_obs))
            if (Boolean(this.lista_obs)) {
                this.array_lista_obs = lista_obs.lista
            }

            this.dial_selecciona = true
        },
        agrega_carrito() {
            var obs = this.ver_observaciones(this.selection) + ' ' + this.obs_adicionales
            var array = store.state.array_carrito
            array.push({
                uuid: this.create_UUID(),
                id: this.producto_selecto.id,
                cantidad: this.cantidad_plato,
                precio: this.producto_selecto.precio,
                nombre: this.producto_selecto.nombre,
                categoria: this.producto_selecto.categoria,
                url_foto: this.producto_selecto.url_foto,
                lista_obs: obs,
                obs_adicionales: this.obs_adicionales
            })
            store.commit('array_carrito', array)
            this.dial_selecciona = false
        },
        ver_observaciones(array) {
            var text = ''
            for (var i = 0; i < array.length; i++) {
                if (text == '') {
                    text = this.array_lista_obs[array[i]].nombre
                } else {
                    text = text + ' - ' + this.array_lista_obs[array[i]].nombre
                }
            }
            return text
        },
        suma() {
            this.cantidad_plato = parseFloat(this.cantidad_plato) + 1
        },

        resta() {
            if (this.cantidad_plato > 1) {
                this.cantidad_plato--
            }
        },
        create_UUID() {
            var dt = new Date().getTime();
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid.substring(29);
        },
        continua_carrito() {
            store.commit('dial_carrito', false)
            this.dial_inscribe = true
        },
        termina(data) {
            this.categoria = true
            this.dial_inscribe = false
            var uuid = this.create_UUID()
            var array = {
                uuid: uuid,
                fecha: moment().unix(),
                nombre: data.nombre,
                num_doc: data.num_doc,
                documento: data.documento,
                direccion: data.direccion,
                telefono: data.telefono,
                ubicacion: data.ubicacion,
                detalle: store.state.array_carrito
            }
            nuevo_pedido(this.bd_selecta, array.uuid, array).then(() => {
                store.commit('array_carrito', [])
            })
            this.dial_confirma_pedido = true
        }

    },
}
</script>
