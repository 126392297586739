<template>
    <div class="pa-3">
        <v-row class="mx-auto text-center">
            <v-col cols="3">
                <v-btn block color="info" elevation="3" small @click="abre_nuevo()">Nuevo</v-btn>
            </v-col>
            <v-col cols="3">
                <v-btn block color="success" elevation="3" small @click="abre_reporte()">Ver Reporte</v-btn>
            </v-col>
            <v-col cols="3">
                <v-text-field class="mx-1" outlined dense type="date" v-model="date1" label="Inicio"></v-text-field>
            </v-col>
            <v-col cols="3">
                <v-text-field class="mx-1" outlined dense type="date" v-model="date2" label="Fin"></v-text-field>
            </v-col>
        </v-row>
        <v-card class="mt-n2" elevation="6">
            <v-simple-table fixed-header height="70vh" dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Fecha
                            </th>
                            <th class="text-left">
                                Total
                            </th>
                            <th class="text-left">
                                Accion
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in listafiltrada" :key="item.fecha_creacion">
                            <td>{{ conviertefecha(item.fecha) }}</td>
                            <td>S/.{{ item.total }}</td>
                            <td><v-icon color="red" class="" @click="edita_lista(item)">mdi-pencil</v-icon></td>
                        </tr>
                    </tbody>
                </template>

            </v-simple-table>
        </v-card>

        <v-dialog v-model="dial_crea" max-width="950px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_crea = !dial_crea">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    Total : S/.{{ sumaventas() }}
                    <v-spacer></v-spacer>
                    <v-icon @click="dial_insumos = true" large color="blue">mdi-plus</v-icon>
                    <v-spacer></v-spacer>
                    <v-icon @click="guardar()" large color="green">mdi-content-save</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-4">
                <v-simple-table fixed-header height='50vh' dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Item
                                </th>
                                <th class="text-left">
                                    Costo
                                </th>
                                <th class="text-left">
                                    Cantidad
                                </th>
                                <th class="text-left">
                                    Total
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in lista_des" :key="item.numeracion">
                                <td> {{ item.nombre }}</td>
                                <td>{{ item.costo }}</td>
                                <td>{{ item.cantidad }}</td>
                                <td>S/.{{ (item.cantidad * item.costo).toFixed(2) }} <v-icon color="red" class=""
                                        @click="editaProducto(item)">mdi-pencil</v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_edita" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_edita = !dial_edita">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-icon @click="eliminar()" color="red">mdi-delete</v-icon>
                    <v-spacer></v-spacer>
                    <v-icon @click="guarda_edita()" large color="green">mdi-content-save</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-row class="mx-auto text-center" dense>
                    <v-col cols="12">
                        <v-text-field class="mx-1" outlined dense v-model="precio" label="Precio"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field class="mx-1" outlined dense v-model="cantidad" label="Cantidad"></v-text-field>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_reporte" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_reporte = !dial_reporte">mdi-close</v-icon>
                    <v-spacer></v-spacer>

                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-simple-table class="elevation-1" fixed-header height="60vh" dense>
                    <template v-slot:default>
                        <thead>

                            <tr>
                                <th class="text-left">
                                    Cantidad
                                </th>
                                <th class="text-left">
                                    Nombre
                                </th>
                                <th class="text-left">
                                    Total
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in lista_reporte" :key="item.id">
                                <td>{{ item.cantidad }}</td>
                                <td>{{ item.nombre }}</td>
                                <td>S/.{{ item.costo }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </v-dialog>
        <lista_insumos v-if="dial_insumos" @cierra="dial_insumos = false"
            @array="agregar($event), dial_insumos = false" />
    </div>
</template>

<script>
import moment from 'moment'
import {
    allcosteo_insumo,
    nuevo_costeo_insumo,
    nuevoflujo
} from '../../db'
import store from '@/store/index'
import lista_insumos from '@/views/kardex/lista_insumos'
export default {
    components: {
        lista_insumos
    },
    data: () => ({
        dial_crea: false,
        dial_insumos: false,
        dial_reporte: false,
        date1: moment(String(new Date)).format('YYYY-MM-DD'),
        date2: moment(String(new Date)).format('YYYY-MM-DD'),
        edit: false,
        lista_costeos: [],
        lista_reporte: [],
        dial_edita: false,
        desserts: [],
        precio: '',
        cantidad: '',
        selecto: [],
        id_compra: ''
    }),
    created() {
        this.date1 = moment(String(new Date)).subtract(5, 'd').format('YYYY-MM-DD')
        //this.inicio()
    },
    mounted() {
        allcosteo_insumo().orderByChild('fecha').startAt(moment(String(this.date1)) / 1000).endAt(moment(String(this.date2)).add(23, 'h').add(59, 'm').add(59, 's') / 1000).on("value", this.onDataChange);
    },
    beforeDestroy() {
        allcosteo_insumo().orderByChild('fecha').startAt(moment(String(this.date1)) / 1000).endAt(moment(String(this.date2)).add(23, 'h').add(59, 'm').add(59, 's') / 1000).off("value", this.onDataChange);
    },
    computed: {
        listafiltrada() {
            allcosteo_insumo().orderByChild('fecha').startAt(moment(String(this.date1)) / 1000).endAt(moment(String(this.date2)).add(23, 'h').add(59, 'm').add(59, 's') / 1000).on("value", this.onDataChange);
            return this.lista_costeos
        },
        lista_des() {
            this.dial_edita
            return this.desserts
        },
    },
    methods: {
        edita_lista(item) {
            this.id_compra = item.id
            if (item.data != undefined) {
                this.desserts = item.data
            }

            this.dial_crea = true
        },
        abre_reporte() {
            var list = []
            var array = this.lista_costeos
            for (var i = 0; i < array.length; i++) {
                var data = array[i].data
                for (var e = 0; e < data.length; e++) {
                    var producto = data[e]
                    var costo = parseFloat(producto.costo) * parseFloat(producto.cantidad)
                    if (this.buscarArray(list, producto) == '') {
                        list.push({
                            id: producto.id,
                            cantidad: producto.cantidad,
                            nombre: producto.nombre,
                            costo: costo
                        })
                    } else {
                        var pos = this.buscarposicion(list, producto)
                        var arrays = this.buscarArray(list, producto)
                        var suma_cantidad = parseFloat(producto.cantidad) + parseFloat(arrays.cantidad)
                        list[pos].cantidad = suma_cantidad
                        var suma_costo = parseFloat(costo) + parseFloat(arrays.costo)
                        list[pos].costo = suma_costo
                    }
                }
            }

            this.lista_reporte = list
            console.log(this.lista_reporte)
            this.dial_reporte = true
        },
        buscarArray(array, id) {
            var a = ''
            for (var i = 0; i < array.length; i++) {
                if (array[i].id == id.id) {
                    a = array[i]
                }
            }
            return a
        },
        buscarposicion(array, id) {
            var a = ''
            for (var i = 0; i < array.length; i++) {
                if (array[i].id == id.id) {
                    a = i
                }
            }
            return a
        },
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                array.push(data)
            });
            this.lista_costeos = array
        },
        async guardar() {
            if (confirm('Esta seguro de guardar?')) {
                store.commit("dialogoprogress", 1)
                var id = moment().unix()
                if (this.id_compra != '') {
                    id = this.id_compra
                } else {
                    await this.genera_flujo(id)
                }
                var array = {
                    id: id,
                    total: this.sumaventas(),
                    data: this.desserts,
                    fecha: moment().unix()
                }
                await nuevo_costeo_insumo(array.id, array)
                store.commit("dialogoprogress", 1)
                this.dial_crea = false
            }
        },
        async genera_flujo(id) {
            var flujo = {
                operacion: 'egreso',
                observacion: 'COMPRA DE INSUMOS',
                numeracion_doc: id,
                modo: 'EFECTIVO',
                fecha: id,
                total: this.sumaventas(),
                estado: 'activo',
                responsable: '',
                sujeto: '',
            }
            await nuevoflujo(flujo)
        },
        editaProducto(val) {
            this.selecto = val
            this.precio = val.costo
            this.cantidad = val.cantidad
            this.dial_edita = true
        },
        eliminar() {
            var pos = this.desserts.map(e => e.id).indexOf(this.selecto.id)
            this.desserts.splice(pos, 1)
            this.dial_edita = false
        },
        guarda_edita() {
            var i = this.desserts.map(e => e.id).indexOf(this.selecto.id)
            this.desserts[i].costo = this.precio
            this.desserts[i].cantidad = this.cantidad
            this.dial_edita = false
        },
        abre_nuevo() {
            this.id_compra = ''
            this.desserts = []
            this.dial_crea = true
        },
        agregar(value) {
            this.desserts.push(value)
        },
        sumaventas() {
            var venta = 0
            var lista = this.desserts
            for (var i = 0; i < lista.length; i++) {
                var item = lista[i]
                venta = venta + (item.cantidad * item.costo)

            }

            return venta
        },
        conviertefecha(date) {
            return moment.unix(date).format('DD/MM hh:mm A')
        },
    }
}
</script>
