<template>
<div>
    <v-card class="pa-1">
        {{listafiltrada}}
        <GmapMap :center="center" :options="options" :zoom="zoom" style="width: 100%; height: 70vh" @click="mark">

            <GmapMarker :key="index" v-for="(m, index) in markers" :position="m.position" :title="m.title" :clickable="true" :draggable="true" @click="center=m.position,punto_selecto=m,m.dial=true">
                <Gmap-info-window @closeclick="m.dial=false" :opened="m.dial" :position="m.position" :options="{
              pixelOffset: {
                width: 0,
                height: -35
              }
            }">
                        <h5 class="red--text">PEDIDO.N°{{m.id }} <p class="black--text">{{m.title}}</p></h5>                     
                </Gmap-info-window>
            </GmapMarker>
        </GmapMap>
    </v-card>

    <v-dialog v-model="dialogo" max-width="200px">
        <div>
            <v-system-bar window dark>
                <v-icon @click="dialogo=false">mdi-close</v-icon>
                <v-spacer></v-spacer>
            </v-system-bar>
        </div>
        <v-card class="pa-4">
            <v-card-text>

            </v-card-text>
        </v-card>
    </v-dialog>
</div>
</template>

    
<script>
import store from '@/store/index'
import {
    nuevo_campoCliente
} from '../../db'
export default {
    props: {
        cliente: [],
    },
    data() {
        return {
            zoom: 12,
            dialogo: false,
            window_open: true,
            punto_selecto: [],
            options: {
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                disableDefaultUi: false
            },
            center: {
                lat: -8.106452954120423,
                lng: -79.02572202917145
            },
            markers: []
        }
    },
    async mounted() {
        await this.$gmapApiPromiseLazy();
    },
    created() {
        this.ubicarme()
    },
    computed: {
        listafiltrada() {
            this.markers = []
            this.cliente.forEach((item) => {
                console.log(item)
                this.markers.push({
                    id: this.markers.length+1,
                    position: {
                        lat: item.ubicacion.lat,
                        lng: item.ubicacion.lng
                    },
                    title: item.nombre,
                    dial:true,
                })
            })
        }
    },
    methods: {

        mark(event) {
            this.markers = []
            this.markers = [{
                id: 0,
                position: {
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng()
                },
                title: "Aqui estoy"
            }]
        },
        ubicarme() {
            store.commit("dialogoprogress")
            this.window_open = true
            this.markers = []
            this.$getLocation({}).then(coordinates => {
                this.center = {
                    lat: coordinates.lat,
                    lng: coordinates.lng
                }
                this.zoom = 13
                store.commit("dialogoprogress")
            })
        },
    }
}
</script>

    
<style>
.button {
    border: none;
    color: white;
    padding: 5px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 2px 1px;
    cursor: pointer;
}

.button1 {
    background-color: #4CAF50;
}

/* Green */
.button2 {
    background-color: #008CBA;
}

/* Blue */
.button3 {
    background-color: #ff0000;
}

/* Blue */
.button4 {
    background-color: #ff7b00;
}

/* Blue */
.button5 {
    background-color: #050302;
}

/* Blue */
</style>
