<template>
    <v-dialog v-model="dialog" max-width="500">
        <div>
            <v-system-bar window dark>
                <v-icon @click="cierre()">mdi-close</v-icon>
            </v-system-bar>
        </div>
        <v-card class="pa-3">
            <v-row class="pa-2">
                <v-col cols="6">
                    <v-btn color="primary" x-small block dark @click="dialog_crear = true">Crear Mozo </v-btn>
                </v-col>
                <v-col cols="6">
                    <v-select outlined offset-y dense v-model="zona" :items="array_zonas" hide-details
                        label="ZONA "></v-select>
                </v-col>
            </v-row>
            <v-simple-table fixed-header height="400px" dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Mesa
                            </th>
                            <th class="text-left">
                                Zona
                            </th>
                            <th class="text-left">
                                Nota
                            </th>
                            <th class="text-left">
                                Accion
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in mozos_filtradas" :key="item.id_mesa">
                            <td>{{ item.nom_mozo }}</td>
                            <td>{{ item.zona_mesa }}</td>
                            <td>{{ item.nota_mesa }}</td>
                            <td width="150">
                                <v-row>
                                    <v-col cols="4">
                                        <v-icon color="green" @click="editar_mozo(item)">mdi-lead-pencil</v-icon>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-icon color="red" @click="eliminar_mozo(item)">mdi-delete</v-icon>
                                    </v-col>
                                </v-row>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>
        <v-dialog v-model="dialog_crear" max-width="450">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialog_crear = false">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-row class="mt-n1">
                    <v-col cols="12">
                        <v-select outlined offset-y dense v-model="zona_mesa" :items="array_zonas_f" hide-details
                            label="ZONA "></v-select>
                    </v-col>
                    <v-col cols="12" class="mt-1">
                        <v-text-field outlined dense v-model="nom_mozo" label="Nombre Mozo"></v-text-field>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn dense class="mt-n4" color="green darken-1" text @click="crear_mozo()">
                        crear
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </v-dialog>
</template>

<script>
import {
    nuevoMozo,
    allMozo,
    eliminaMozo
} from '../../db'

import moment from 'moment'
import store from '@/store/index'
export default {

    data() {
        return {
            dialog: false,
            array_zonas: ["TODAS", "ZONA 1", "ZONA 2", "ZONA 3", "ZONA 4", "TERRAZA", "SALON", "CAMPO"],
            array_zonas_f: ["ZONA 1", "ZONA 2", "ZONA 3", "ZONA 4", "TERRAZA", "SALON", "CAMPO"],
            zona: 'TODAS',
            array_mosos: [],
            dialog_gestionar: false,
            dialog_crear: false,
            zona_mesa: "ZONA 1",
            nom_mozo: '',
            editar: ''
        }
    },
    mounted() {
        allMozo().on("value", this.onDataChange);
    },
    beforeDestroy() {
        allMozo().off("value", this.onDataChange);
    },
    created() {
        this.dialog = true
    },
    computed: {
        mozos_filtradas() {
            var filtro = this.array_mosos
            if (this.zona == 'TODAS') {
                return filtro
            } else {
                return filtro.filter(item => item.zona_mesa == this.zona)
            }
        },
    },
    methods: {
        onDataChange(items) {
            this.array_mosos = []
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                array.push(data);
            });
            this.array_mosos = array;
        },
        async crear_mozo() {

            store.commit("dialogoprogress")
            var array = {
                id_mesa: this.nom_mozo,
                nom_mozo: this.nom_mozo,
                zona_mesa: this.zona_mesa,
                estado: 'PENDIENTE'
            }
            await nuevoMozo(array.id_mesa, array)
            this.nom_mozo = ''
            this.zona_mesa = 'ZONA 1'
            this.editar = false
            this.dialog_crear = false
            store.commit("dialogoprogress")
        },
        editar_mozo(val) {
            this.editar = true
            this.nom_mozo = val.nom_mozo
            this.zona_mesa = val.zona_mesa
            this.dialog_crear = true
        },
        async eliminar_mozo(val) {
            if (confirm('SEGURO DE ELIMINAR MESA ' + val.nom_mozo)) {
                store.commit("dialogoprogress")
                await eliminaMozo(val.id_mesa)
                store.commit("dialogoprogress")
            }
        },
        cierre() {
            this.$emit('cierre', false)
        }

    }

}
/*
 */
</script>
