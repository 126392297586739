<template>
<v-dialog v-model="dial_activo" max-width="60vh" transition="dialog-top-transition" persistent>
    <div>
        <v-system-bar window dark>
            <v-icon @click="cierra()" color="red">mdi-close</v-icon>
            <h4 class="text-center">RESUMEN PEDIDO</h4>
            <v-spacer></v-spacer>
            <h4> TOTAL : S/.{{ suma_total() }}</h4>
        </v-system-bar>
    </div>
    <v-card class="pa-1">
        <v-card class="mx-auto mt-1 pa-1" color="white" v-for="carrito in $store.state.array_carrito" :key="carrito">
            <v-row no-gutters>
                <v-col cols="6">
                    <v-list-item-title style="font-size:90%;" class="mt-2 mb-n2">
                        <v-icon color="red" small @click="dialogo_elimina(carrito)">mdi-delete</v-icon> <strong> {{carrito.nombre}} </strong> - <span class="red--text"> x {{ carrito.cantidad }} </span>
                        <p>S/.{{carrito.precio}} x Und.</p>
                    </v-list-item-title>
                </v-col>
                <v-col cols="3" class="mt-5"></v-col>
                <v-col cols="3" class="mt-5">
                    <p class="" style="font-size:90%;">S/.{{(carrito.precio*carrito.cantidad).toFixed(2)}}</p>
                </v-col>
            </v-row>
            <v-list-item-title style="font-size:90%;" class="mt-n3" v-if="carrito.lista_obs!=' '"><strong class="red--text">Obs:</strong> {{ carrito.lista_obs }}</v-list-item-title>
        </v-card>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small color="success" @click="continua()">SIGUIENTE</v-btn>
        </v-card-actions>
    </v-card>
    <v-dialog v-model="dial_elimina" max-width="300px">
        <div>
            <v-system-bar window dark>
                <v-icon @click="(dial_elimina=!dial_elimina)">mdi-close</v-icon>
            </v-system-bar>
        </div>

        <v-card class="pa-3">
            <v-card-text>
                <h3 class="text-center">SEGURO DE QUE DESEA ELIMINAR?</h3>
            </v-card-text>
            <v-card-actions class="mt-n6">
                <v-spacer></v-spacer>
                <v-btn color="info" small @click="elimina()">SI</v-btn>
                <v-btn color="success" small @click="dial_elimina = false">NO</v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</v-dialog>
</template>

<script>
import axios from "axios"
import store from '@/store/index'

export default {
    name: 'caja',
    components: {

    },
    props: {
        dial_activo: '',
    },
    data() {
        return {
            item_selecto:[],
            dial_elimina:false
        }
    },

    methods: {
        dialogo_elimina(data){
            this.item_selecto = data
            this.dial_elimina= true 
        },
        elimina(){
            var array = store.state.array_carrito
            var pos = array.map(e => e.uuid).indexOf(this.item_selecto.uuid)
            array.splice(pos, 1)
            store.commit('array_carrito', array)
            this.dial_elimina = false
        },
        suma_total(){
            var array = store.state.array_carrito
            var suma = 0
            for (var i = 0; i < array.length; i++) {
                suma = suma + (array[i].cantidad * array[i].precio)
            }
            return suma.toFixed(2)
        },
        continua(){
            this.$emit('continua', true)
        },
        cierra() {
            this.$emit('cierra', false)
        }
    },

}
</script>
