<template>
    <v-dialog v-model="dialog" max-width="550">
        <div>
            <v-system-bar window dark>
                <v-icon @click="cierre()">mdi-close</v-icon>
            </v-system-bar>
        </div>
        <v-card class="pa-3">
            <v-row class="pa-2">
                <v-col cols="6">
                    <v-text-field class="mx-1" outlined dense type="date" v-model="date" label="Inicio"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field class="mx-1" outlined dense type="date" v-model="date2" label="Fin"></v-text-field>
                </v-col>
            </v-row>
            <v-simple-table fixed-header height="550px" dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Fecha
                            </th>
                            <th class="text-left">
                                Mesa
                            </th>
                            <th class="text-left">
                                Mozo
                            </th>
                            <th class="text-left">
                                Observacion
                            </th>
                            <th class="text-left">
                                Accion
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in filtro" :key="item.key">
                            <td width="150">{{ conviertefecha(item.fecha) }}</td>
                            <td>{{ item.id_mesa }}</td>
                            <td>{{ item.mozo }}</td>
                            <td width="150">{{ item.obs }}</td>
                            <td>
                                <v-icon color="green" @click="ver_detalle(item)">mdi-eye</v-icon>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>
        <v-dialog v-model="dial_mesa" max-width="550">

            <v-toolbar dark dense>
                <v-spacer></v-spacer>
                <v-btn icon @click="dial_mesa = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card class="no-hover-card">
                <v-simple-table fixed-header height="30vh" dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">Producto</th>
                                <th class="text-left">Obs</th>
                                <th class="text-left">Precio</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in array_comanda" :key="item.uuid">
                                <td><strong class="red--text">{{ item.cantidad }} x</strong> x {{ item.nombre }}
                                    <strong class="red--text" v-if="item.operacion == 'GRATUITA'"> -
                                        Gratuita</strong>

                                </td>
                                <td>{{ item.observacion }}</td>
                                <td v-if="item.operacion != 'GRATUITA'">{{ (item.precio * item.cantidad).toFixed(2) }}
                                </td>
                                <td v-if="item.operacion == 'GRATUITA'">0.00
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>

            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
import {
    ver_historial,
} from '../../db'

import moment from 'moment'
import store from '@/store/index'
export default {

    data() {
        return {
            dialog: false,
            dial_mesa: false,
            date: moment(String(new Date)).format('YYYY-MM-DD'),
            date2: moment(String(new Date)).format('YYYY-MM-DD'),
            desserts: []
        }
    },
    mounted() {
        ver_historial().orderByChild('fecha').startAt(moment(String(this.date)) / 1000)
            .endAt(moment(String(this.date2)).add(23, 'h').add(59, 'm').add(59, 's') / 1000).on("value", this.onDataChange);
    },
    beforeDestroy() {
        ver_historial().off("value", this.onDataChange);
    },
    created() {
        this.dialog = true
    },
    computed: {
        filtro() {
            ver_historial().orderByChild('fecha').startAt(moment(String(this.date)) / 1000)
                .endAt(moment(String(this.date2)).add(23, 'h').add(59, 'm').add(59, 's') / 1000).on("value", this.onDataChange);
            return this.desserts
        },
    },
    methods: {
        onDataChange(items) {
            this.desserts = []
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                data.key = item.key
                array.push(data);
            });
            this.desserts = array;
        },
        ver_detalle(data) {
            this.array_comanda = data.array
            this.dial_mesa = true
        },
        conviertefecha(date) {
            return moment.unix(date).format('DD/MM hh:mm A')
        },
        cierre() {
            this.$emit('cierre', false)
        }

    }

}
/*
 */
</script>
