<template>
    <div class="pa-3">
        <v-btn v-if="false" color="success mb-1" x-small block @click.prevent="modificar_permiso()">modificar</v-btn>
        <v-row align="center" class="mb-n1">
            <v-col cols="6">
                <v-btn color="success mb-1" x-small block @click.prevent="dialog = !dialog">Crear usuario</v-btn>
            </v-col>
            <v-col cols="6" class="red--text text-center">
                Usuarios Restantes : <strong> 0 </strong>
            </v-col>
        </v-row>

        <v-simple-table class="elevation-1 mt-2" fixed-header height="60vh" dense>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">
                            NOMBRE
                        </th>
                        <th class="text-left">
                            Correo
                        </th>
                        <th class="text-left">
                            Accion
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in desserts" :key="item.token">
                        <td>{{ item.nombre }}</td>
                        <td>{{ item.correo }}</td>
                        <td>
                            <v-icon class="ml-4" @click="item_selecto = item, dial_sett = true"
                                color="red">mdi-cog-refresh</v-icon>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-dialog v-model="dial_sett" max-width="460px">
            <v-card class="mx-auto pa-6" color="white">
                <v-row align="center" class="mb-n6">
                    <v-col cols="6">
                        <v-btn class="" color="info" block small @click="cargaPermisos()">
                            Gestionar Permisos
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn disabled class="" color="warning" block small @click="dial_contra = true">
                            Cambiar Contraseña
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn class="" color="error" block small @click="eliminar()">
                            Eliminar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_contra" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_contra = !dial_contra">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>

            <v-card class="mx-auto pa-6" color="white">
                <v-text-field outlined v-model="nueva_contra" label="nueva Contraseña" required></v-text-field>
                <v-btn class="" color="warning" block small @click="cambia_contra()">
                    Cambiar
                </v-btn>
            </v-card>

        </v-dialog>
        <v-dialog v-model="dialog" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialog = !dialog">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>

            <v-card class="mx-auto" color="white">
                <v-card-title class="mx-auto">
                    <h5 class="mx-auto">TOKEN = {{ create_UUID() }}</h5>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-text-field v-model="nombre" label="NOMBRE" required></v-text-field>
                        <v-text-field v-model="email" label="E-mail" required suffix="@domotica.com"></v-text-field>

                        <v-text-field v-model="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1"
                            label="Contraseña" hint="Min 8 caracteres" counter
                            @click:append="show1 = !show1"></v-text-field>
                    </v-form>

                </v-card-text>
                <v-card-actions>
                    <v-btn color="success" block @click.prevent="crearUsuario()">Creausuario</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

    </div>
</template>

<script>
import {
    nuevoUsuario,
    allUsuarios,
    nuevoCampoUsuario,
    borra_usuario
} from '../db'
import store from '@/store/index'
import firebase from 'firebase'
export default {

    data() {
        return {
            dial_contra: false,
            token: '',
            nombre: '',
            correo: '',
            pass: '',
            dialog: false,
            valid: true,
            desserts: [],
            permisosususario: [],
            dial_sett: false,
            item_selecto: [],
            nueva_contra: '',
            password: '',
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                emailMatch: () => (`The email and password you entered don't match`),
            },
            email: '',
            show1: false,

        }
    },
    mounted() {
        allUsuarios().orderByChild('bd').equalTo(store.state.baseDatos.bd).on("value", this.onDataChange);
        //allUsuarios().on("value", this.onDataChange);
    },
    beforeDestroy() {
        allUsuarios().orderByChild('bd').equalTo(store.state.baseDatos.bd).off("value", this.onDataChange);
    },
    methods: {
        onDataChange(items) {
            this.desserts = []
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                array.push(data);
            });
            this.desserts = array;
        },
        async modificar_permiso() {
            for (var i = 0; i < this.desserts.length; i++) {
                var data = this.desserts[i]
                console.log(data.token)
                this.permisos(data.token)
            }
        },
        async permisos(token) {
            // await nuevoUsuario(token + '/modulo_empleados', true)
        },
        cargaPermisos() {
            var items = this.item_selecto
            this.$router.push({
                path: "/accesos_usuarios/" + items.token
            })
        },

        async crearUsuario() {
            if (this.email && this.password) {
                store.commit("dialogoprogress")
                var token = this.create_UUID()
                var array = {
                    token: token,
                    nombre: this.nombre,
                    correo: this.email + "@domotica.com",
                    pass: this.password,
                    bd: store.state.baseDatos.bd,
                    moduloempresa: true,
                    modulokardex: true,
                    modulosunat: true,
                    moduloproductos: true,
                    productos_visualiza_stock: true,
                    moduloclientes: true,
                    modulomesas: true,
                    modulococina: true,
                    limpia_comanda: true,
                    trans_comanda: true,
                    unir_comanda: true,
                    editar_comanda: true,
                    modulocaja: true,
                    modulo_empleados: true,
                    caja_edita_cantidad: true,
                    caja_edita_precio: true,
                    moduloreportes: true,
                }
                await nuevoUsuario(token, array)
                this.dialog = false
                this.nombre = ''
                store.commit("dialogoprogress")
                this.crearUsuarios()

            } else {

            }
        },
        create_UUID() {
            var dt = new Date().getTime();
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid.substring(20);
        },
        async eliminar() {
            if (confirm('SEGURO QUE DESEA ELIMINAR ESTE USUARIO?')) {

                store.commit("dialogoprogress")
                console.log(this.item_selecto)
                await borra_usuario(this.item_selecto.token)
                this.dial_contra = false
                this.dial_sett = false
                store.commit("dialogoprogress")
                /*  const user = firebase.auth().currentUser
                  user.delete().then(() => {
                      store.commit("dialogoprogress")
                      this.dial_contra = false
                      this.dial_sett = false
  
                  }).catch((error) => {
                      alert('CIERRE SESION Y VUELVA A INICIAR')
                      store.commit("dialogoprogress")
                  });*/
            }
        },
        cambia_contra() {
            if (this.nueva_contra.length < 6) {
                alert('contraseña demasiado corta')
                return
            }
            store.commit("dialogoprogress")
            const user = firebase.auth().currentUser
            user.updatePassword(this.nueva_contra).then(() => {
                this.dial_contra = false
                this.dial_sett = false
                store.commit("dialogoprogress")
            }).catch((error) => {
                console.log(error)
                alert('CIERRE SESION Y VUELVA A INICIAR')
                store.commit("dialogoprogress")
            });
        },
        crearUsuarios() {
            firebase.auth().createUserWithEmailAndPassword(this.email + "@domotica.com", this.password)
                .then(user => {
                    store.commit("dialogoprogress")
                    this.$router.push({
                        name: 'panel'
                    })
                }).catch(error => {
                    store.commit("dialogoprogress")
                    alert('error interno, comuniquese con administrador')
                })

        }
    }

}
</script>

<style></style>
