<template>
    <div class="mb-6 mt-2 pa-3">
        <v-row no-gutters class="mt-n1 mb-1">
            <v-col cols="6" class="pa-1" md="6" sm="4" xs="6">
                <v-card @click.prevent="obtenordenproducto()">
                    <v-container>
                        <v-img class="mx-auto" height="30" width="30" src="/clientes.png"></v-img>
                        <h5 block class="text-center">Nuevo Cliente</h5>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="6" class="pa-1" md="6" sm="4" xs="6">
                <v-card disabled>
                    <v-container>
                        <v-img class="mx-auto" height="30" width="30" src="/message.png"></v-img>
                        <h5 block class="text-center">Enviar Publicidad</h5>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-text-field class="mb-n3" outlined dense v-model="buscar" append-icon="mdi-magnify" label="BUSCAR"></v-text-field>
        <v-data-table dense :headers="headers" :items="listafiltrada" mobile-breakpoint="1">
            <template v-slot:item="{ item }">
                <tr>
                    <td width="10">{{ item.id }}</td>
                    <td width="50">{{ item.documento }}</td>
                    <td>{{ item.nombre }}</td>
                    <td>{{ item.telefono }}</td>
                    <td width="150">
                        <v-row>
                            <v-col cols="12">
                                <v-icon color="green" @click="editar(item.id), itemselecto = item">mdi-lead-pencil</v-icon>
                            </v-col>
                        </v-row>
                    </td>
                </tr>
            </template>
            <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">
                    SHOW
                </v-btn>
            </template>
        </v-data-table>

        <v-dialog v-model="dialogo" max-width="600px">
            <div>
                <v-system-bar window dark>
                    <v-icon color="red" large @click="dialogo = !dialogo">mdi-close</v-icon>
                    <v-switch v-model="activo" v-if="!permiso_edita" color="green" label="ACTIVO"></v-switch>
                    <v-spacer></v-spacer>
                    <v-icon color="green" large v-if="!permiso_edita" @click="save()">mdi-content-save</v-icon>
                    <v-icon color="red" large v-if="$store.state.permisos.clientes_elimina"
                        @click="dialogoElimina = !dialogoElimina">mdi-delete</v-icon>
                </v-system-bar>
            </div>

            <v-card>
                <v-card-text>
                    <v-container>
                        <v-row class="mt-1" dense>

                            <v-col cols="6" sm="6" md="6">
                                <v-text-field dense :disabled="!$store.state.configuracion.idcliente" v-model="id"
                                    label="ID"></v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6" md="6">
                                <v-select :disabled="permiso_edita" dense v-model="tipodoc" :items="arraydocumento"
                                    menu-props="auto" hide-details label="TIPO DOC"></v-select>

                            </v-col>
                        </v-row>
                        <v-row class="mt-n4">
                            <v-col cols="6">
                                <v-text-field :disabled="permiso_edita" type="number" dense v-model="documento"
                                    label="DOCUMENTO" append-icon="mdi-magnify" @click:append="BuscarDocumento()"
                                    @keyup.enter="BuscarDocumento()"></v-text-field>
                            </v-col>

                            <v-col cols="6">
                                <v-text-field :disabled="permiso_edita" type="number" dense v-model="telefono"
                                    label="Telefono"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n7">
                            <v-col cols="12">
                                <v-textarea :disabled="permiso_edita" outlined dense v-model="nombre" auto-grow filled
                                    label="NOMBRE/RAZON SOCIAL" rows="1"></v-textarea>
                            </v-col>
                            <v-col cols="12" class="mt-n9">
                                <v-text-field type="email" :disabled="permiso_edita" outlined dense v-model="correo"
                                    label="CORREO"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n9">
                            <v-col cols="6">
                                <v-text-field :disabled="permiso_edita" outlined dense @click="abredialogo('depa')"
                                    v-model="departamento" label="DEPARTAMENTO" readonly></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field :disabled="permiso_edita" outlined dense @click="abredialogo('prov')"
                                    v-model="provincia" label="PROVINCIA" readonly></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row class="mt-n9">
                            <v-col cols="6">
                                <v-text-field :disabled="permiso_edita" outlined dense @click="abredialogo('distr')"
                                    v-model="distrito" label="DISTRITO" readonly></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field :disabled="permiso_edita" outlined dense v-model="ubigeo"
                                    label="UBIGEO"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n7" dense>
                            <v-col cols="12">
                                <v-textarea :disabled="permiso_edita" outlined dense v-model="direccion" auto-grow filled
                                    label="Direccion" rows="1"></v-textarea>
                            </v-col>
                            <v-col cols="12" class="mt-n6">
                                <v-textarea :disabled="permiso_edita" outlined dense v-model="referencia" auto-grow filled
                                    label="Referencia" rows="1"></v-textarea>
                            </v-col>
                            <v-col cols="12" class="mt-n6">
                                <v-textarea :disabled="permiso_edita" outlined dense v-model="alias" auto-grow filled
                                    label="ALIAS" rows="1"></v-textarea>
                            </v-col>
                            <v-col cols="12" class="mt-n6">
                                <v-textarea :disabled="permiso_edita" outlined dense v-model="nota" auto-grow filled
                                    label="Nota" rows="1"></v-textarea>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-card-text>

            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogoElimina" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogoElimina = !dialogoElimina">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card>
                <v-card-text>
                    <h3> DESEA ELIMINAR PERMANENTEMENTE EL CLIENTE?</h3>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="eliminar()">si</v-btn>
                    <v-btn color="error" @click="dialogoElimina = !dialogoElimina">no</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogoFiltro" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogoFiltro = !dialogoFiltro">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-card-text>
                    <v-select :items="arrayfiltroStock" label="Stock" outlined v-model="filtrostock"></v-select>
                    <v-select :items="arrayfiltroestado" label="ESTADO" outlined v-model="filtroestado"></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="dialogoFiltro = !dialogoFiltro">FILTRAR</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div class="text-center">
            <v-snackbar v-model="snackbar" :timeout="timeout" top>
                {{ text }}

                <template v-slot:action="{ attrs }">
                    <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
        </div>

        <v-dialog v-model="dialogoubicaciones" max-width="490">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogoubicaciones = false">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card>
                <v-simple-table fixed-header height="400px" dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    UBIGEO
                                </th>
                                <th class="text-left">
                                    NOMBRE
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in arrayubicaciones" :key="item.id" @click="seleccionaUbica(item)">
                                <td>{{ item.ubigeo }}</td>
                                <td>{{ item.nombre }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>

            </v-card>

        </v-dialog>
        <v-dialog v-model="dialogo_selecc_cliente" max-width="400">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogo_selecc_cliente = false">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-icon color="green" large @click="graba_cliente_Punto()">mdi-content-save</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <h4>{{ cliente_puntos.tipodoc }} : {{ cliente_puntos.documento }}</h4>
                <h4>Cliente : {{ cliente_puntos.nombre }}</h4>
                <v-text-field class="mt-2" dense type="number" outlined v-model="array_cliente.visitas"
                    label="Visitas"></v-text-field>
                <v-text-field class="mt-2" dense type="number" outlined v-model="array_cliente.compras"
                    label="Compras"></v-text-field>
                <v-text-field class="mt-2" dense type="number" outlined v-model="array_cliente.puntos"
                    label="Puntos"></v-text-field>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {
    nuevoCliente,
    eliminaCliente,
} from '../../db'
import {
    departamento,
    provincia,
    distrito
} from '../../ubigeos'
import axios from "axios"
import store from '@/store/index'

export default {

    data: () => ({
        dialogo_selecc_cliente: false,
        snackbar: false,
        text: '',
        timeout: 2000,
        sumacon: false,
        itemselecto: '',
        dialogoElimina: false,
        dialogoubicaciones: false,
        dialogoFiltro: false,
        buscar: '',
        headers: [{
            text: 'id',
            align: 'start',
            sortable: false,
            value: 'id',
        },
        {
            text: 'DOCUMENTO',
            value: 'documento',
        },
        {
            text: 'Nombre',
            value: 'nombre',
        },
        {
            text: 'telefono',
            value: 'telefono',
        },
        {
            text: 'Actions',
            value: 'actions',
            sortable: false
        },
        ],
        desserts: [],
        arraydocumento: ['DNI', 'RUC'],
        dialogo: false,
        arrayfiltroStock: ['incluir 0', 'excluir 0'],
        filtrostock: 'incluir 0',
        arrayfiltroestado: ['TODOS', 'ACTIVO', 'INACTIVO'],
        filtroestado: 'ACTIVO',
        tipoproducto: "BIEN",
        activo: true,
        id: '',
        tipodoc: 'DNI',
        documento: '',
        nombre: '',
        correo: '',
        direccion: '',
        telefono: '',
        alias: '',
        nota: '',
        referencia: '',
        progress: false,
        Uploadvalue: 0,
        arraydepa: [],
        arrayprov: [],
        arraydistr: [],
        departamento: '',
        provincia: '',
        distrito: '',
        ubigeodepa: '',
        ubigeoprov: '',
        tipoubicacion: '',
        ubigeo: '',
        permiso_edita: false,
        activa_mapa: true,
        array_cliente: '',
        cliente_puntos: ''
    }),

    created() {
        this.initialize()
    },

    computed: {
        listafiltrada() {
            this.desserts = store.state.clientes
            var filtro = this.desserts
            return filtro.filter((item) => (item.id + item.documento + item.nombre + item.distrito +
                item.telefono + item.alias + item.nota)
                .toLowerCase().includes(this.buscar.toLowerCase()))
        },
        arrayubicaciones() {
            var array = []
            switch (this.tipoubicacion) {
                case 1:
                    array = this.arraydepa
                    break;
                case 2:
                    array = this.arrayprov
                    break;
                case 3:
                    array = this.arraydistr
                    break;
                default:
                    break;
            }
            return array
        },

    },

    methods: {

        initialize() {
            this.desserts = store.state.clientes

        },
        abredialogo(ubica) {
            this.arrayubica = []
            switch (ubica) {
                case 'depa':
                    this.arraydepa = departamento()
                    this.tipoubicacion = 1
                    break;
                case 'prov':
                    this.arrayprov = provincia(this.ubigeodepa)
                    this.tipoubicacion = 2
                    break;
                case 'distr':
                    this.arraydistr = distrito(this.ubigeoprov)
                    this.tipoubicacion = 3
                    break;

                default:
                    break;
            }

            this.dialogoubicaciones = true
        },
        seleccionaUbica(item) {
            switch (this.tipoubicacion) {
                case 1:
                    this.departamento = item.nombre
                    this.ubigeodepa = item.ubigeo
                    break;
                case 2:
                    this.provincia = item.nombre
                    this.ubigeoprov = item.ubigeo
                    break;
                case 3:
                    this.distrito = item.nombre
                    this.ubigeoselecto = item.ubigeo
                    break;
                default:
                    break;
            }
            this.dialogoubicaciones = false
        },

        obtenordenproducto() {
            this.permiso_edita = false
            this.sumacon = true
            this.activo = true
            this.id = ''
            this.tipodoc = 'DNI'
            this.documento = ''
            this.nombre = ''
            this.correo = ''
            this.departamento = ''
            this.provincia = ''
            this.distrito = ''
            this.ubigeo = ''
            this.direccion = ''
            this.telefono = ''
            this.alias = ''
            this.nota = ''
            this.referencia = ''
            this.dialogo = true

        },
        editar(id) {
            this.permiso_edita = !store.state.permisos.clientes_edita
            this.sumacon = false
            for (var i = 0; i < this.desserts.length; i++) {
                if (this.desserts[i].id == id) {
                    this.id = this.desserts[i].id
                    this.activo = this.desserts[i].activo
                    this.tipodoc = this.desserts[i].tipodoc,
                        this.documento = this.desserts[i].documento,
                        this.nombre = this.desserts[i].nombre,
                        this.correo = this.desserts[i].correo,
                        this.departamento = this.desserts[i].departamento,
                        this.provincia = this.desserts[i].provincia,
                        this.distrito = this.desserts[i].distrito,
                        this.ubigeo = this.desserts[i].ubigeo,
                        this.direccion = this.desserts[i].direccion,
                        this.telefono = this.desserts[i].telefono
                    this.alias = this.desserts[i].alias
                    this.nota = this.desserts[i].nota
                    this.referencia = this.desserts[i].referencia
                    this.dialogo = true
                }
            }
        },
        async save() {
            var array = {
                activo: this.activo,
                id: this.documento,
                tipodoc: this.tipodoc,
                documento: this.documento,
                nombre: this.nombre,
                correo: this.correo,
                departamento: this.departamento,
                provincia: this.provincia,
                distrito: this.distrito,
                ubigeo: this.ubigeo,
                direccion: this.direccion,
                telefono: this.telefono,
                alias: this.alias,
                nota: this.nota,
                referencia: this.referencia,
            }
            await nuevoCliente(array.id, array)
            this.dialogo = false
        },
        eliminar() {
            eliminaCliente(this.itemselecto.id)
            this.itemselecto = ''
            this.dialogoElimina = false
            this.dialogo = false
        },
        BuscarDocumento() {
            if (this.documento != '' && this.tipodoc == "RUC" || this.tipodoc == "DNI" &&
                this.documento.length == 8 || this.documento.length == 11) {
                if (Number(this.documento.length) == 8) {
                    this.tipodoc = "DNI"
                }
                if (Number(this.documento.length) == 11) {
                    console.log(Number(this.documento.length))
                    this.tipodoc = "RUC"
                }

                store.commit("dialogoprogress")
                var self = this,
                    token = '80a4a1c5f2e97c2d78fcd5074cd64ff0a29007ef91880ad2c214af67a084433d'
                axios
                    .get('https://apiperu.dev/api/' + this.tipodoc.toLowerCase() + '/' + this.documento, {
                        headers: {
                            Content_Type: 'application/json',
                            authorization: ' Bearer ' + token
                        }
                    })
                    .then(response => (this.info = response.data,
                        store.commit("dialogoprogress"),
                        self.llenardatos(response.data.data)
                    ))
                    .catch(error => {
                        store.commit("dialogoprogress"),
                            self.snack('Documento Invalido')
                    })
            } else {
                this.snack("Ingrese Documento")
            }
        },
        llenardatos(data) {
            console.log(data)
            if (this.tipodoc == 'DNI') {
                this.nombre = data.nombre_completo
            }
            if (this.tipodoc == 'RUC') {
                this.nombre = data.nombre_o_razon_social
                this.direccion = data.direccion
                this.departamento = data.departamento
                this.provincia = data.provincia
                this.distrito = data.distrito
                this.ubigeo = data.ubigeo_sunat
            }
        },
        snack(text) {
            this.snackbar = true
            this.text = text
        },
    },
}
</script>
