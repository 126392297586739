<template>
<v-dialog v-model="dial_activo" max-width="60vh" transition="dialog-top-transition" persistent>
    <div>
        <v-system-bar window dark>
            <v-icon @click="cierra()">mdi-close</v-icon>
        </v-system-bar>
    </div>
    <v-card class="pa-2">
        <v-row dense class="text-center">
            <v-col cols="6">
                <v-btn dense color="success" x-small block @click="dial_crea_grupo = !dial_crea_grupo">
                    Crea Grupo
                </v-btn>
            </v-col>
            <v-col cols="6">

            </v-col>
        </v-row>
        <v-card-title primary-title class="mt-n5 mb-n2">
            OBSERVACIONES :
        </v-card-title>

        <v-data-table :headers="dessertHeaders" :items="listafiltrada" item-key="nombre" dense show-expand :expanded.sync="expanded">
            <template v-slot:item.actions="{ item }">
                <v-row>
                    <v-col cols="12">
                        <v-icon color="green" @click="agrega(item)">mdi-plus</v-icon>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pa-2">
                    <v-simple-table fixed-header dense>
                        <template v-slot:default>
                            <tbody>
                                <tr v-for="items in item.lista" :key="items.id">
                                    <td>{{ items.nombre }}</td>
                                    <td>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-icon color="red" @click="elimina_obs(items,item)">mdi-delete</v-icon>
                                            </v-col>
                                        </v-row>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </td>
            </template>
        </v-data-table>

    </v-card>
    <v-dialog v-model="dial_crea_grupo" max-width="400" persistent>
        <div>
            <v-system-bar window dark>
                <v-icon @click="dial_crea_grupo = false">mdi-close</v-icon>
            </v-system-bar>
        </div>
        <v-card class="pa-1">
            <v-row dense class="mt-1">
                <v-col cols="12">
                    <v-text-field placeholder="CREMAS" outlined dense v-model="nom_grupo" label="Nombre Grupo"></v-text-field>
                </v-col>
            </v-row>
            <v-card-actions class="mt-n8">
                <v-spacer></v-spacer>
                <v-btn color="deep-purple lighten-2" text @click="crea_grupo()">
                    Agrega
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dial_agrega_obs" max-width="400" persistent>
        <div>
            <v-system-bar window dark>
                <v-icon @click="dial_agrega_obs = false">mdi-close</v-icon>
            </v-system-bar>
        </div>
        <v-card class="pa-1">
            <v-row dense class="mt-1">
                <v-col cols="12">
                    <v-text-field placeholder="CREMAS" outlined dense v-model="nom_obs" label="Nombre Observacion"></v-text-field>
                </v-col>
            </v-row>
            <v-card-actions class="mt-n8">
                <v-spacer></v-spacer>
                <v-btn color="deep-purple lighten-2" text @click="agrega_obs()">
                    Agrega
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-dialog>
</template>

<script>
import axios from "axios"
import store from '@/store/index'
import {
    all_grupo_obs,
    nuevo_grupo_obs,
    edita_campo_grupo_obs
} from '../../db'
export default {
    props: {
        dial_activo: '',
    },
    name: 'caja',
    data() {
        return {
            expanded: [],
            singleExpand: false,
            dessertHeaders: [{
                    text: 'GRUPO',
                    value: 'nombre'
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    sortable: false
                },
            ],
            dial_crea_grupo: false,
            dial_agrega_obs: false,
            desserts: [],
            nom_grupo: '',
            nom_obs: '',
            grupo_selecto: []
        }
    },
    mounted() {
        all_grupo_obs().on("value", this.onDataChange);
    },
    beforeDestroy() {
        all_grupo_obs().on("value", this.onDataChange);
    },
    computed: {
        listafiltrada() {
            all_grupo_obs().on("value", this.onDataChange);
            return this.desserts
        }
    },
    methods: {
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                array.push(data);
            });

            this.desserts = array;
        },
        crea_grupo() {
            this.desserts.push({
                id: this.desserts.length,
                nombre: this.nom_grupo
            })
            nuevo_grupo_obs(this.desserts)
            this.dial_crea_grupo = false
        },
        elimina(data) {
            var pos = this.desserts.map(e => e.id).indexOf(data.id)
            this.desserts.splice(pos, 1)
            nuevo_grupo_obs(this.desserts)
        },
        cierra() {
            this.$emit('cierra', false)
        },
        agrega(data) {
            this.grupo_selecto = data
            this.dial_agrega_obs = true
        },
        agrega_obs() {
            var data = this.grupo_selecto
            var array = []
            if (this.grupo_selecto.lista != undefined) {
                array = this.grupo_selecto.lista
            }
            array.push({
                nombre: this.nom_obs
            })
            edita_campo_grupo_obs(data.id, 'lista', array)
            this.nom_obs = ''
            this.dial_agrega_obs = false
        },
        elimina_obs(data,item) {
            var pos = this.desserts[item.id].lista.map(e => e.nombre).indexOf(data.nombre)
            console.log(pos)
            this.desserts[item.id].lista.splice(pos, 1)
            nuevo_grupo_obs(this.desserts)
        }
    },

}
</script>
