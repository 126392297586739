<template>
    <v-dialog v-model="dial" max-width="460px" persistent>
        <div>
            <v-system-bar window dark>
                <v-icon @click="cierra()">mdi-close</v-icon>
            </v-system-bar>
        </div>

        <v-card class="pa-4">
            <v-row dense class="pa-2">
                <v-col v-for="item in lista_obs" :key="item" cols="6" class="pa-1">
                    <v-card class="pa-2 text-center" @click="abre_detalle_obs(item)">
                        {{ item }}
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="pa-2">
                <v-textarea v-model="observacion" auto-grow filled color="deep-purple" label="OBS MANUAL" rows="1"
                    append-icon="mdi-content-save" @click:append="guarda_manual()"></v-textarea>
            </v-row>
            <v-row dense class="pa-2">
                <v-col cols="12">
                    <v-card class="pa-2">
                        <h4 class="text-center">Lista selecta</h4>
                        <div class="pa-4">
                            <v-chip-group active-class="primary--text" column>
                                <v-chip v-for="tag in grupo_obs" :key="tag" @click="elimina(tag)">
                                    {{ tag }}
                                </v-chip>
                            </v-chip-group>
                        </div>

                    </v-card>
                </v-col>
            </v-row>

            <v-card-actions>


                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="graba()">
                    Graba
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="dialog_detalle" max-width="350px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialog_detalle = !dialog_detalle">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-icon large color="green" @click="guarda_predefi()">mdi-content-save</v-icon>
                </v-system-bar>
            </div>
            <v-card>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>

                            <tr>
                                <th class="text-center">
                                    Detalle
                                </th>
                                <th class="text-center">
                                    Selecciona
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in array_predefini" :key="item.nombre">
                                <td class="text-center">{{ item.nombre }}</td>
                                <td width="50">
                                    <v-checkbox class="mb-n3" dense v-model="item.consolida"></v-checkbox>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>

            </v-card>

        </v-dialog>
    </v-dialog>
</template>

<script>
import store from '@/store/index'
export default {
    props: {
        data: [],
        lista: []
    },
    data() {
        return {
            dialog_detalle: false,
            lista_obs: [],
            grupo_obs: [],
            array_predefini: [],
            item_selecto: [],
            observacion: ''
        }
    },
    created() {

        var producto = store.state.productos.find(item => item.id == this.data.id)
        this.lista_obs = producto.grupo_obs
        if (this.data.observacion != '') {
            this.grupo_obs = this.data.observacion
        }
        this.dial = true
    },
    methods: {
        abre_detalle_obs(items) {
            this.array_predefini = []
            this.item_selecto = items
            var array = store.state.observaciones.find(item => item.nombre == items).lista
            for (var i = 0; i < array.length; i++) {
                this.array_predefini.push({
                    nombre: array[i].nombre,
                    consolida: false
                })
            }
            this.dialog_detalle = true
        },
        guarda_predefi() {
            var array = this.array_predefini
            for (var i = 0; i < array.length; i++) {
                if (array[i].consolida) {
                    this.grupo_obs.push(array[i].nombre)
                }
            }
            this.dialog_detalle = false
        },
        guarda_manual() {
            this.grupo_obs.push(this.observacion)
            this.observacion = ''
            this.graba()
        },
        graba() {
            if (this.observacion != '') {
                this.grupo_obs.push(this.observacion)
            }
            this.$emit('graba', this.grupo_obs)
        },
        elimina(val) {
            var pos = this.grupo_obs.map(e => e).indexOf(val)
            this.grupo_obs.splice(pos, 1)
        },
        cierra() {
            this.$emit('cierra', false)
        }
    }
}
</script>
