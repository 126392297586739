<template>
    <div class="pa-3">

        <v-row dense>
            <v-col cols="5">
                <v-btn rounded elevation="6" block color="info" @click="obtenordenproducto()" small>
                    <v-icon left>
                        mdi-plus
                    </v-icon>Nuevo
                </v-btn>
            </v-col>

            <v-col cols="5">
                <v-btn rounded elevation="6" block color="success" @click="subirXLS()" small>
                    <v-icon left>
                        mdi-microsoft-excel
                    </v-icon>Excel
                </v-btn>
            </v-col>
            <v-col cols="2" class="text-center">
                <v-icon @click="dialogoFiltro = true" large color="info">mdi-filter</v-icon>
            </v-col>
        </v-row>
        <v-row align="center">
            <v-col cols="6">
                <v-text-field outlined dense v-model="buscar" append-icon="mdi-magnify" label="BUSCAR"></v-text-field>
            </v-col>
            <v-col cols="6" class="mt-n6">
                <v-select outlined dense v-model="filtro_categoria" :items="arraycategoria_f" menu-props="auto"
                    hide-details label="Categoria"></v-select>
            </v-col>
        </v-row>

        <v-card class="pa-4">
            <v-data-table dense :headers="headers" :items="listafiltrada" mobile-breakpoint="1">
                <template v-slot:item="{ item }">
                    <tr>
                        <td style="font-size:80%;">{{ item.id }}</td>
                        <td style="font-size:80%;">{{ item.categoria }}</td>
                        <td style="font-size:80%;">{{ item.nombre }}</td>
                        <td style="font-size:80%;">{{ item.stock }}</td>
                        <td style="font-size:80%;">{{ item.precio }}</td>
                        <td style="font-size:80%;">
                            <v-row>
                                <v-col cols="12">
                                    <v-icon color="green" @click="editar(item)">mdi-lead-pencil</v-icon>
                                </v-col>
                            </v-row>
                        </td>

                    </tr>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog v-model="dialogo" max-width="500px">
            <div>
                <v-system-bar window dark>
                    <v-icon color="red" large @click="dialogo = !dialogo">mdi-close</v-icon>
                    <v-switch v-model="activo" color="green" label="ACTIVO"></v-switch>
                    <v-spacer></v-spacer>
                    ID : {{ id }}
                    <v-spacer></v-spacer>
                    <v-icon color="green" large @click="save()">mdi-content-save</v-icon>
                    <v-icon color="red" large @click="eliminar()">mdi-delete</v-icon>
                </v-system-bar>
            </div>

            <v-card class="pa-4">

                <v-row>
                    <v-col cols="6">
                        <v-select :items="arraytipoProducto" label="Tipo" dense outlined
                            v-model="tipoproducto"></v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-select :items="arrayOperacion" label="Operacion" dense outlined
                            v-model="operacion"></v-select>
                    </v-col>
                </v-row>

                <v-row class="mt-n9">
                    <v-col v-if="$store.state.configuracion.codbarras" cols="12" md="12" sm="12">
                        <v-text-field dense class="mb-n7" v-model="codbarra" label="Cod Barras"
                            append-icon="mdi-barcode-scan" autofocus></v-text-field>
                    </v-col>

                    <v-col cols="6">
                        <v-select dense v-model="categoria" :items="arraycategoria" menu-props="auto" hide-details
                            label="Categoria"></v-select>
                    </v-col>
                    <v-col cols="6" xs="6">
                        <v-select dense v-model="medida" :items="$store.state.medidas" menu-props="auto" hide-details
                            label="Medida"></v-select>
                    </v-col>
                </v-row>

                <v-row class="mt-n2">
                    <v-col cols="12">
                        <v-textarea dense outlined v-model="nombre" auto-grow filled label="Descripcion"
                            rows="1"></v-textarea>
                    </v-col>
                </v-row>

                <v-row class="mt-n9">
                    <v-col cols="6" xs="6">
                        <v-text-field type="number" dense v-model="stock" label="Stock"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field type="number" dense v-model="stock_min" label="Stock min"></v-text-field>
                    </v-col>
                </v-row>

                <v-row class="mt-n7">
                    <v-col cols="6">
                        <v-text-field type="number" dense v-model="precio" label="Precio"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field dense type="number" v-model="costo" label="Costo"></v-text-field>
                    </v-col>
                </v-row>

                <v-row class="mt-3">

                    <v-col cols="12" class="mt-n7">
                        <v-select dense v-model="grupo_obs" :items="lista_obs" chips label="Grupo Obs."
                            multiple></v-select>
                    </v-col>
                </v-row>

                <v-row class="mt-n10" v-if="$store.state.baseDatos.restaurante">
                    <v-col cols="6">
                        <v-switch v-model="cocina" label="Cocina 1"></v-switch>
                    </v-col>
                    <v-col cols="6">
                        <v-switch v-model="barra" label="Barra 1"></v-switch>
                    </v-col>
                </v-row>
                <v-row class="mt-n10" v-if="$store.state.baseDatos.restaurante">
                    <v-col cols="6">
                        <v-switch v-model="cocina2" label="Cocina 2"></v-switch>
                    </v-col>
                    <v-col cols="6">
                        <v-switch v-model="barra2" label="Barra 2"></v-switch>
                    </v-col>
                </v-row>
                <v-row class="mt-n10">
                    <v-col cols="6">
                        <v-switch v-model="cargoxconsumo" label="Cargo x consumo"></v-switch>
                    </v-col>
                    <v-col cols="6">
                        <v-switch v-model="controstock" label="Control Stock"></v-switch>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogoFiltro" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogoFiltro = !dialogoFiltro">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-card-text>
                    <v-select :items="arrayfiltroStock" label="Stock" outlined v-model="filtrostock"></v-select>
                    <v-select :items="arrayfiltroestado" label="ESTADO" outlined v-model="filtroestado"></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="dialogoFiltro = !dialogoFiltro">FILTRAR</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dial_setting" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_setting = !dial_setting">mdi-close</v-icon>
                </v-system-bar>
            </div>

            <v-card class="pa-3">
                <v-row dense>
                    <v-col cols="6">
                        <v-card @click.prevent="ir_menu()">
                            <v-container>
                                <v-img class="mx-auto" height="35" width="35" src="/menu.png"></v-img>
                                <h5 block class="text-center pa-1">Configura Carta</h5>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="6">
                        <v-card @click.prevent="transferir_carta()">
                            <v-container>
                                <v-img class="mx-auto" height="35" width="35" src="/transferir.png"></v-img>
                                <h5 block class="text-center pa-1">Cambia Carta</h5>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {
    eliminaProducto,
    obtenContador,
    sumaContador,
    allCategorias,
    editaProducto,
} from '../../db'
import store from '@/store/index'
export default {

    data: () => ({
        headers: [{
            text: 'id',
            align: 'start',
            sortable: false,
            value: 'id',
        },
        {
            text: 'Categoria',
            value: 'categoria',
        },
        {
            text: 'Nombre',
            value: 'nombre',
        },
        {
            text: 'Stock',
            value: 'stock',
        },
        {
            text: 'Precio',
            value: 'precio',
        },
        {
            text: 'Actions',
            value: 'actions',
            sortable: false
        },
        ],
        filtro_categoria: 'TODOS',
        arraycategoria_f: [],
        sumacon: false,
        dialogoFiltro: false,
        dial_setting: false,
        buscar: '',
        desserts: [],
        id: '',
        categoria: '',
        nombre: '',
        medida: 'UNIDAD',
        stock: '',
        precio: '0',
        costo: '',
        cocina: '',
        arraycategoria: [],
        dialogo: false,
        arraytipoProducto: ['BIEN', 'SERVICIO'],
        arrayfiltroStock: ['incluir 0', 'excluir 0'],
        filtrostock: 'incluir 0',
        arrayfiltroestado: ['TODOS', 'ACTIVO', 'INACTIVO'],
        filtroestado: 'ACTIVO',
        tipoproducto: "BIEN",
        arrayOperacion: [
            'GRAVADA',
            'EXONERADA',
            'GRATUITA'
        ],
        operacion: "GRAVADA",
        icbper: false,
        controstock: false,
        codbarra: '',
        activo: true,
        barra: false,
        cargoxconsumo: false,
        item_selecto: [],
        stock_min: '',
        lista_obs: [],
        grupo_obs: [],
        cocina2: false,
        barra2: false,
    }),
    async beforeCreate() {
        var snapshot = await allCategorias().once("value")
        snapshot.forEach((item) => {
            this.arraycategoria_f.push(item.val().nombre)
            this.arraycategoria.push(item.val().nombre)
        })
        var snap = store.state.observaciones
        snap.forEach((item) => {
            this.lista_obs.push(item.nombre)
        })
    },
    created() {
        this.arraycategoria_f.push('TODOS')
    },

    computed: {
        listafiltrada() {
            this.desserts = store.state.productos
            if (this.filtrostock == 'excluir 0') {
                var filtro = this.desserts.filter((item) =>
                    (item.stock) > 0)
            } else {
                var filtro = this.desserts
            }

            if (this.filtroestado == 'TODOS') {
                var lista = filtro
            } else {
                if (this.filtroestado == 'ACTIVO') {
                    var lista = filtro.filter((item) =>
                        (item.activo) == true)
                } else {
                    var lista = filtro.filter((item) =>
                        (item.activo) == false)
                }
            }
            if (this.filtro_categoria == 'TODOS') {
                return lista.filter((item) => (item.id + item.nombre)
                    .toLowerCase().includes(this.buscar.toLowerCase()))
            } else {
                return lista.filter(item => item.categoria == this.filtro_categoria && (item.id + item.nombre)
                    .toLowerCase().includes(this.buscar.toLowerCase()))
            }
        }

    },

    methods: {
        async obtenordenproducto() {
            store.commit("dialogoprogress")
            this.sumacon = true
            var snapshot = await obtenContador().once("value")
            if (snapshot.exists()) {
                this.id = snapshot.val().ordenproducto
                this.codbarra = ''
                this.categoria = '1'
                this.nombre = ''
                this.medida = 'UNIDAD'
                this.stock = 1
                this.precio = 0
                this.costo = 0
                this.cocina = false
                this.barra = false
                this.tipoproducto = 'BIEN'
                this.operacion = 'GRAVADA'
                this.icbper = false
                this.dialogo = true
                this.activo = true
                this.controstock = false
                this.cargoxconsumo = false
                this.grupo_obs = []
                this.cocina2 = false
                this.barra2 = false
                this.stock_min = 1
                if (Boolean(store.state.configuracion.operacion)) {
                    this.operacion = store.state.configuracion.operacion
                }
            }
            store.commit("dialogoprogress")
        },
        editar(val) {
            console.log(val)
            this.item_selecto = val
            this.sumacon = false
            this.id = val.id
            this.activo = val.activo
            this.codbarra = val.codbarra
            this.categoria = val.categoria
            this.nombre = val.nombre
            this.medida = val.medida
            this.stock = val.stock
            this.precio = val.precio
            this.costo = val.costo
            this.cocina = val.cocina
            this.tipoproducto = val.tipoproducto
            this.operacion = val.operacion
            this.icbper = val.icbper
            this.barra = val.barra
            this.controstock = val.controstock
            this.cargoxconsumo = val.cargoxconsumo
            if (!Boolean(val.grupo_obs)) {
                val.grupo_obs = []
            }
            if (!Boolean(val.stock_min)) {
                val.stock_min = 1
            }
            this.grupo_obs = val.grupo_obs
            this.stock_min = val.stock_min
            if (!Boolean(val.cocina2)) {
                val.cocina2 = false
            }
            if (!Boolean(val.barra2)) {
                val.barra2 = false
            }
            this.cocina2 = val.cocina2
            this.barra2 = val.barra2
            this.dialogo = true
        },
        async save() {
            if (this.stock == '') {
                this.stock = 0
            }
            if (this.costo == '') {
                this.costo = 0
            }
            if (this.categoria == 1 || this.categoria == '') {
                alert("DEBE CONSIDERAR UNA CATEGORIA VALIDA")
                return
            }
            if (this.precio == 0) {
                alert("EL PRECIO NO PUEDE SER IGUAL A 0")
                return
            } store.commit("dialogoprogress")
            editaProducto(this.id, "id", this.id)
            editaProducto(this.id, "activo", this.activo)
            editaProducto(this.id, "codbarra", this.codbarra)
            editaProducto(this.id, "nombre", this.nombre.trim())
            editaProducto(this.id, "categoria", this.categoria)
            editaProducto(this.id, "medida", this.medida)
            editaProducto(this.id, "stock", this.stock)
            editaProducto(this.id, "precio", this.precio)
            editaProducto(this.id, "costo", this.costo)
            editaProducto(this.id, "cocina", this.cocina)
            editaProducto(this.id, "tipoproducto", this.tipoproducto)
            editaProducto(this.id, "operacion", this.operacion)
            editaProducto(this.id, "icbper", this.icbper)
            editaProducto(this.id, "barra", this.barra)
            editaProducto(this.id, "controstock", this.controstock)
            editaProducto(this.id, "cargoxconsumo", this.cargoxconsumo)
            editaProducto(this.id, "grupo_obs", this.grupo_obs)
            editaProducto(this.id, "stock_min", this.stock_min)
            editaProducto(this.id, "cocina2", this.cocina2)
            await editaProducto(this.id, "barra2", this.barra2)
            if (this.sumacon == true) {
                this.sumacontador()
            }
            this.dialogo = false
            store.commit("dialogoprogress")
        },

        async eliminar() {
            if (confirm('SEGURO QUE DESEA ELIMINAR?')) {
                await eliminaProducto(this.item_selecto.id)
                this.dialogo = false
            }
        },
        sumacontador() {
            sumaContador("ordenproducto", parseInt(this.id) + 1)
        },
        subirXLS() {
            this.$router.push({
                name: "ImportaExporta"
            })
        },

    },
}
</script>
