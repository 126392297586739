<template>
  <v-dialog v-model="$store.state.dialogohost" max-width="460px">
    <div>
      <v-system-bar window dark>
        <v-icon @click="$store.commit('dialogohost')">mdi-close</v-icon>
        <v-spacer></v-spacer>
        <v-icon @click="dialog = !dialog, initialize()" medium class="white" color="green">mdi-plus</v-icon>
      </v-system-bar>
    </div>

    <v-card>
      <v-card v-if="dialog">

        <v-card-title primary-title>
          <div>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="editedItem.tipo" label="Tipo"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select v-model="editedItem.observacion" :items="arrayImpresoras" menu-props="auto" hide-details
                  label="Ubicacion Impresora"></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-btn @click.prevent="agrega()" color="success">ok</v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card-title>
      </v-card>

      <v-simple-table>
        <template v-slot:default>
          <thead>

            <tr>
              <th class="text-left">
                Tipo
              </th>
              <th class="text-left">
                Observacion
              </th>
              <th class="text-left">
                Accion
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in desserts" :key="item.tipo">
              <td>{{ item.tipo }}</td>
              <td>{{ item.observacion }}</td>
              <td>
                <v-icon @click.prevent="elimina(item.id)" color="red">mdi-close</v-icon>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

    </v-card>

  </v-dialog>
</template>

<script>
import {
  allConfiguraImpresora,
  grabaConfiguraImpresora,
  eliminaConfiguraImpresora
} from '../../db'
export default {

  data() {
    return {
      dialog: false,
      desserts: [],
      arrayImpresoras: ['cocina', 'barra', 'cocina2', 'barra2'],
      editedItem: {
        tipo: '',
        observacion: '',
      },
    }
  },
  mounted() {
    allConfiguraImpresora().on("value", this.onDataChange);
  },
  beforeDestroy() {
    allConfiguraImpresora().off("value", this.onDataChange);
  },

  methods: {

    onDataChange(items) {
      this.desserts = []
      let array = [];
      items.forEach((item) => {
        let data = item.val();
        let key = item.key
        array.push({
          id: key,
          tipo: data.tipo,
          observacion: data.observacion
        });
      });

      this.desserts = array;
    },
    initialize() {
      this.desserts = []
      allConfiguraImpresora().once("value").then((snapshot) => {
        snapshot.forEach((item) => {
          let data = item.val();
          this.desserts.push({
            tipo: data.tipo,
            observacion: data.observacion
          });
        });
      })
    },
    agrega() {
      this.editedItem.tipo = this.editedItem.tipo.toUpperCase()
      console.log(this.desserts.length)
      grabaConfiguraImpresora(this.desserts.length, this.editedItem)
      this.editedItem.tipo = ''
      this.editedItem.observacion = ''
      this.dialog = false
    },
    elimina(id) {
      console.log(id)
      eliminaConfiguraImpresora(id)
    }
  }

}
</script>
