<template>
<v-dialog v-model="dial_activo" max-width="60vh" transition="dialog-top-transition" persistent>
    <div>
        <v-system-bar window dark>
            <v-icon @click="cierra()">mdi-close</v-icon>
            <h4 class="text-center">Datos Cliente</h4>
        </v-system-bar>
    </div>
    <v-card class="pa-1">
        <v-row dense class="mt-1">
            <v-col cols="4">
                <v-select outlined dense v-model="documento" :items="arraydocumento" menu-props="auto" hide-details label="TIPO DOC"></v-select>
            </v-col>
            <v-col cols="8">
                <v-text-field outlined dense v-model="numero" append-icon="mdi-magnify" label="N° DOCUMENTO" @click:append="BuscarDocumento()" @keyup.enter="BuscarDocumento()"></v-text-field>
            </v-col>
            <v-col cols="12" class="mt-n6">
                <v-textarea outlined dense v-model="nombreCompleto" auto-grow filled label="NOMBRE/RAZON SOCIAL" rows="1"></v-textarea>
            </v-col>
            <v-col cols="12" class="mt-n6">
                <v-text-field type="number" outlined dense v-model="telefono" label="Telefono"></v-text-field>
            </v-col>
            <v-col cols="12" class="mt-n6">
                <v-textarea outlined dense v-model="direccion" auto-grow filled label="Direccion Entrega" rows="1"></v-textarea>
            </v-col>
            <v-col cols="12" class="mt-n6">
                <v-textarea outlined dense v-model="referencia" auto-grow filled label="Referencia" rows="1"></v-textarea>
            </v-col>
        </v-row>
        <mapa class="mt-n6" :cliente="array_cliente" @cierra="siguiente($event)" />
    </v-card>
    <v-dialog v-model="dialog_progrees" hide-overlay persistent width="300">
        <v-card color="primary" dark>
            <v-card-text>
                Enviando
                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
        </v-card>
    </v-dialog>
</v-dialog>
</template>

<script>
import axios from "axios"
import store from '@/store/index'
import mapa from './mapa'
export default {
    name: 'caja',
    components: {
        mapa,
    },
    props: {
        dial_activo: '',
    },
    data() {
        return {
            arraydocumento: ['DNI', 'RUC', 'CE', 'PASAPORTE'],
            numero: '',
            documento: 'DNI',
            nombreCompleto: '',
            direccion: '',
            referencia: '',
            telefono: '',
            array_cliente: [],
            dialog_progrees: false,
        }
    },
    created() {

    },
    methods: {
        BuscarDocumento() {
            if (this.numero == '') {
                store.commit("dialogoClientesnuevos")
            } else {
                if (this.numero != '' && this.documento == "RUC" || this.documento == "DNI" &&
                    this.numero.length == 8 || this.numero.length == 11) {
                    store.commit("dialogoprogress")

                    if (this.numero.length == 11) {
                        this.documento = "RUC"
                    } else {
                        this.documento = "DNI"
                    }
                    var cliente = store.state.clientes.find(id => String(id.documento) === String(this.numero))
                    if (Boolean(cliente)) {
                        this.cliente_selecto = cliente
                        this.nombreCompleto = cliente.nombre
                        this.telefono = cliente.telefono
                        this.direccion = cliente.direccion
                        this.referencia = cliente.referencia
                        this.array_cliente = cliente
                        store.commit("dialogoprogress")
                    } else {
                        this.consultaApiPeru()
                    }
                } else {
                    store.commit('dialogosnackbar', 'Documento Invalido')
                }
            }
        },
        consultaApiPeru() {
            var self = this
            var token = '80a4a1c5f2e97c2d78fcd5074cd64ff0a29007ef91880ad2c214af67a084433d'
            axios
                .get('https://apiperu.dev/api/' + this.documento.toLowerCase() + '/' + this.numero, {
                    headers: {
                        Content_Type: 'application/json',
                        authorization: ' Bearer ' + token
                    }
                })
                .then(response => (this.info = response.data,
                    store.commit("dialogoprogress"),
                    self.llenardatos(response.data.data)
                ))
        },
        llenardatos(data) {
            //  console.log(data)
            if (data != undefined) {
                this.consulta_cliente = data
                if (this.documento == 'DNI') {
                    this.nombreCompleto = data.nombre_completo
                    this.direccion = data.direccion_completa
                    this.telefono = ''
                    this.referencia = ''
                }
                if (this.documento == 'RUC') {
                    this.nombreCompleto = data.nombre_o_razon_social
                    this.direccion = data.direccion_completa
                    this.telefono = ''
                    this.referencia = ''
                }
            } else {
                store.commit('dialogosnackbar', 'Documento no existe')
            }
        },
        siguiente(gps) {
            if (gps == '') {
                alert("SELECCIONE UBICACION VALIDA")
            } else {
                if (this.numero == '' || this.nombreCompleto == '' || this.direccion=='') {
                    alert("Datos Cliente incompletos")
                } else {
                    this.dialog_progrees = true
                    setTimeout(() => (this.dialog_progrees = false, this.guarda(gps)), 2000)
                    
                }
            }
        },
        guarda(gps){
            console.log(gps)
            var array = {
                documento : this.documento,
                num_doc: this.numero,
                nombre: this.nombreCompleto,
                telefono: this.telefono,
                direccion: this.direccion,
                referencia: this.referencia,
                ubicacion: gps[0].position
            }
            
            this.$emit('termina', array)
        },
        cierra() {
            this.$emit('cierra', false)
        }
    },

}
</script>
