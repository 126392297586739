import store from "@/store/index";
import {
  grabaCabecera,
  grabaDetalle,
  grabarStock,
  grabaCabecera_p,
  grabaDetalle_p,
  sumaContador,
} from "./db";
import moment from "moment";
import { pdfGenera } from "./pdf_comprobantes";
import { enviaDocumentoApiSunat } from "./servidorsunat";

export const cobrar_js = (cabecera, items) => {
  var c = completa_items(items).then((r) => {
    var tt = procesa_cobro(cabecera, r).then((rr) => {
      return rr;
    });
    return tt;
  });
  return c;
};
function procesa_cobro(cabecera, data) {
  cabecera.vendedor = store.state.permisos.token;
  cabecera.total_op_gravadas = data.totaloperaGravada;
  cabecera.igv = data.totalIGV;
  cabecera.porcentaje_igv = store.state.configuracion.igv;
  cabecera.porcentaje_cargo = store.state.configuracion.cargoxconsumo;
  cabecera.total_op_exoneradas = data.totaloperaExonerada;
  cabecera.total_cargo = data.total_cargo;
  cabecera.total_op_gratuitas = data.total_op_gratuitas;
  var items = data.item;

  var resp = grabaCabecera(cabecera.numeracion, cabecera).then(() => {
    var cc = grabaDetalle(cabecera.numeracion, items).then(() => {
      if (cabecera.tipocomprobante != "T") {
        enviaDocumentoApiSunat(cabecera, items).then((rrr) => {
          console.log(rrr);
        });
      }
      var aa = sumarCorrelativos(
        cabecera.tipocomprobante,
        cabecera.correlativoDocEmitido
      ).then((r) => {
        var xx = actualizaStock(items).then((r) => {
          pdfGenera(
            items,
            cabecera,
            store.state.configImpresora.tamano,
            "abre"
          );
          return true;
        });
        return xx;
      });
      return aa;
    });
    return cc;
  });
  return resp;
}

function completa_items(arrays) {
  var item = [];
  var totaloperaGravada = 0;
  var totaloperaExonerada = 0;
  var total_op_gratuitas = 0;
  var totalIGV = 0;
  var total_cargo = 0;
  var porcentaje_igv = store.state.configuracion.igv / 100;
  var porcentaje_cargo = store.state.configuracion.cargoxconsumo / 100;
  var a = new Promise((resolve, reject) => {
    arrays.forEach((items, index, array) => {
      var data = items;
      var invent = store.state.productos.find((id) => id.id == data.id);
      var descuentositem = parseFloat(data.preciodescuento);
      var precio_item = parseFloat(
        redondear(data.precioedita - descuentositem / data.cantidad)
      );
      if (invent.cargoxconsumo && porcentaje_cargo != 0) {
        var cargo = parseFloat(
          redondear(precio_item / (parseFloat(porcentaje_cargo) + 1))
        );
        var sumcargo = parseFloat(precio_item - cargo);
        precio_item = cargo;
        total_cargo = total_cargo + parseFloat(sumcargo * data.cantidad);
      }
      if (invent.operacion == "GRAVADA") {
        var precioVentaUnitario = precio_item;
        var valor_unitario = precioVentaUnitario / (1 + porcentaje_igv);
        var igv = valor_unitario * data.cantidad * porcentaje_igv;
        var valorTotal = valor_unitario * data.cantidad;
        var antesimpuesto = valor_unitario * data.cantidad;
        var totalImpuesto = valor_unitario * data.cantidad * porcentaje_igv;
        totaloperaGravada = totaloperaGravada + parseFloat(antesimpuesto);
        totaloperaExonerada = totaloperaExonerada + 0;
        total_op_gratuitas = total_op_gratuitas + 0;
        totalIGV = totalIGV + parseFloat(totalImpuesto);
      }
      if (invent.operacion == "EXONERADA") {
        var precioVentaUnitario = precio_item;
        var valor_unitario = precioVentaUnitario;
        var igv = 0.0;
        var valorTotal = valor_unitario * data.cantidad;
        var antesimpuesto = valor_unitario * data.cantidad;
        var totalImpuesto = 0.0;
        totaloperaGravada = totaloperaGravada + 0;
        totaloperaExonerada = totaloperaExonerada + parseFloat(antesimpuesto);
        total_op_gratuitas = total_op_gratuitas + 0;
        totalIGV = totalIGV + parseFloat(totalImpuesto);
      }
      if (data.operacion == "GRATUITA") {
        //console.log("precios", redondear(descuentositem / data.cantidad))
        var precioVentaUnitario = precio_item;
        var valor_unitario = 0;
        var igv = 0.0;
        var valorTotal = valor_unitario * data.cantidad;
        var antesimpuesto = valor_unitario * data.cantidad;
        var totalImpuesto = 0;
        totaloperaGravada = totaloperaGravada + 0;
        totaloperaExonerada = totaloperaExonerada + 0;
        total_op_gratuitas = total_op_gratuitas + parseFloat(antesimpuesto);
        totalIGV = totalIGV + 0;
        totalIGV_GRATUITA = +totalIGV_GRATUITA + 0;
        //antesimpuesto = (precioVentaUnitario*parseFloat(data.cantidad))
      }
      console.log(precioVentaUnitario);

      item.push({
        id: data.id,
        cantidad: data.cantidad,
        nombre: data.nombre,
        medida: invent.medida,
        cod_medida: obtencodigomedida(invent.medida, invent.tipoproducto),
        categoria: invent.categoria,
        precio: data.precio,
        stock: invent.stock,
        precioedita: data.precio,
        preciodescuento: data.preciodescuento,
        costo: invent.costo,
        tipoproducto: invent.tipoproducto,
        operacion: invent.operacion,
        icbper: invent.icbper,
        cargoxconsumo: invent.cargoxconsumo,
        controstock: invent.controstock,
        valor_unitario: valor_unitario.toFixed(5),
        valor_total: valorTotal.toFixed(2),
        igv: igv.toFixed(2),
        valor_icbper: 0.0,
        factor_icbper: store.state.configuracion.icbper,
        total_antes_impuestos: antesimpuesto.toFixed(2),
        total_impuestos: totalImpuesto.toFixed(2),
        precioVentaUnitario: redondear(precioVentaUnitario),
      });
      if (index === array.length - 1) {
        var data = {
          item: item,
          totaloperaGravada: totaloperaGravada.toFixed(2),
          totaloperaExonerada: totaloperaExonerada.toFixed(2),
          total_op_gratuitas: total_op_gratuitas.toFixed(2),
          totalIGV: totalIGV.toFixed(2),
          total_cargo: total_cargo.toFixed(2),
        };
        resolve(data);
      }
    });
  });
  a.then((value) => {
    return value;
  });
  return a;
}
function obtencodigomedida(medida, tipoproducto) {
  if (tipoproducto == "SERVICIO") {
    return "ZZ";
  } else {
    var array = store.state.medidassunat;
    var nomenclatura = "NIU";
    for (var i = 0; i < array.length; i++) {
      if (array[i].nombre == medida) {
        nomenclatura = array[i].corto;
      }
    }
    return nomenclatura;
  }
}
function redondear(valor) {
  return parseFloat(valor).toFixed(store.state.configuracion.decimal);
}
function sumarCorrelativos(data, contadores) {
  if (data == "T") {
    var valor = (parseInt(contadores) + 1).toString().padStart(8, 0);
    var campo = "ordenticket";
  }
  if (data == "B") {
    var valor = (parseInt(contadores) + 1).toString().padStart(8, 0);
    var campo = "ordenboleta";
  }
  if (data == "F") {
    var valor = (parseInt(contadores) + 1).toString().padStart(8, 0);
    var campo = "ordenfactura";
  }
  if (data == "Flujo") {
    var valor = parseInt(contadores)
      .toString()
      .padStart(4, 0);
    var campo = "flujocaja";
  }
  var a = sumaContador(campo, valor).then(() => {
    return true;
  });
  return a;
}
function actualizaStock(arrays) {
  var stock = 0;
  var a = new Promise((resolve, reject) => {
    if (
      store.state.configuracion.inventario &&
      !store.state.configuracion.controlplatos
    ) {
      arrays.forEach((items, index, array) => {
        var data = items;
        if (data.stock != 9090909 && data.controstock) {
          stock = data.stock - data.cantidad;
          grabarStock(data.id, stock.toFixed(2)).then(() => {
            if (index === array.length - 1) {
              resolve(true);
            }
          });
        } else {
          if (index === array.length - 1) {
            resolve(true);
          }
        }
      });
    } else {
      resolve(true);
    }
  });
  return a;
}
export const sumarCorrelativo = async (data, contadores) => {
  if (data == "T") {
    var valor = (parseInt(contadores) + 1).toString().padStart(8, 0);
    var campo = "ordenticket";
  }
  if (data == "B") {
    var valor = (parseInt(contadores) + 1).toString().padStart(8, 0);
    var campo = "ordenboleta";
  }
  if (data == "F") {
    var valor = (parseInt(contadores) + 1).toString().padStart(8, 0);
    var campo = "ordenfactura";
  }
  if (data == "Flujo") {
    var valor = (parseInt(contadores) + 1).toString().padStart(5, 0);
    var campo = "flujocaja";
  }
  if (data == "clientes") {
    var valor = (parseInt(contadores) + 1).toString().padStart(5, 0);
    var campo = "ordenclientes";
  }
  var a = await sumaContador(campo, valor);
  return a;
};
