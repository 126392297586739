<template>

<v-container class="mb-6">

        <v-dialog
      persistent
      v-model="dialogoprogress"
      max-width="200"
      >
    <v-card class="pa-12">
        <v-progress-circular
          :rotate="90"
          :size="100"
          :width="15"
          color="primary"
          indeterminate
        ></v-progress-circular>
    </v-card>
      </v-dialog>
<v-card>
    <div class="d-flex align-content-start flex-wrap">

                <v-text-field            
                class="mx-1"    
                outlined
                dense
                @click="$store.commit('dialogoFecha')"
                v-model="date"                   
                label="Inicio"
                readonly
              ></v-text-field>

                <v-text-field
                class="mx-1"   
                 outlined
                 dense
                @click="$store.commit('dialogoFecha2')"
                v-model="date2"                   
                label="Fin"
                readonly
              ></v-text-field>

                <v-text-field
                disabled
                class="mx-1"   
                 outlined
                 dense
                v-model="num_doc"                   
                label="BD"
                @click:prepend-inner="cambia_doc()"
                @keyup.enter="busca()"
              ></v-text-field>

              <v-btn elevation="2" rounded color="red" small  @click="busca()">
                  <v-icon color="white"  
                  class="mx-auto text--center" small>mdi-magnify</v-icon>           
              </v-btn> 
    </div>



 <v-simple-table
    fixed-header
    height="70vh"
    dense
  >
    <template v-slot:default >
      <thead>
        <tr>
          <th class="text-left">
            Correlativo
          </th>
          <th class="text-left">
            Fecha
          </th>
            <th class="text-left">
            Estado
          </th>
            <th class="text-left">
            Total
          </th>
            <th class="text-left">
            Accion
          </th>
        </tr>
      </thead>
      <tbody >
        <tr
          v-for="item in listafiltrada"
          :key="item.id"
        >
          <td >{{ item.numeracion }}</td>
          <td>{{ conviertefecha(item.fecha) }}</td>
          <td ><v-icon @click="consultaApisunat(item)" :color="item.color">mdi-circle</v-icon></td>
          <td>S/.{{ redondear(item.total - item.descuentos) }}</td>
          <td> 
            <v-row>
          <v-col cols="6" xs="6">
            <v-icon color="green"  @click.prevent="ejecutaConsolida( item.numeracion )">mdi-eye</v-icon> 
          </v-col>
          <v-col cols="6" xs="6">
           <v-icon color="red" @click.prevent="verPDF(item)">mdi-text-box-search-outline</v-icon>
          </v-col>
            </v-row>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>

  </v-card>
  <fecha @fecha="date = $event"/>
  <fecha2 @fecha="date2 = $event"/>

        <v-dialog
        v-model="dialog"
        max-width="850px"
    >
    <div>
      <v-system-bar window dark>
        <v-icon @click="dialog=!dialog">mdi-close</v-icon>
        <v-spacer></v-spacer>
      </v-system-bar>
    </div>
     <v-card class="pa-3">   
      <v-row dense>
          <v-col cols="12"> 
          </v-col>
      </v-row>
  <v-simple-table
    dark 
    fixed-header
    max-width="70vh"
    dense
  >
    <template v-slot:default >
      
      <thead>
        <tr>
          <th class="text-left">
            Descripcion
          </th>
          <th class="text-left">
            Medida
          </th>
          <th class="text-left">
            Cantidad.
          </th>
          <th class="text-left">
            Precio
          </th>
          <th class="text-left">
            Descuento
          </th>
          <th class="text-left">
            Total
          </th>
        </tr>
      </thead>
      
      <tbody >
        
        <tr
          v-for="item in arrayConsolidar"
          :key="item.id"
        >
          <td>{{ item.nombre }}</td>
          <td>{{ item.medida }}</td>
          <td>{{ item.cantidad }}</td>
          <td>S/.{{ item.precioedita }}</td>
          <td class="red--text">S/.{{ item.preciodescuento }}</td>
          <td>S/.{{ redondear(item.precioedita * item.cantidad) }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  </v-card>

    </v-dialog>
  <v-dialog
      v-model="dialogocomprobante"
      max-width="590"
      >
    <div>
      <v-system-bar window dark>
        <v-icon @click="dialogocomprobante = false">mdi-close</v-icon>
        <v-spacer></v-spacer>
        <v-icon color="red" large @click="dialogosetting = true">mdi-cog-transfer-outline</v-icon>
      </v-system-bar>
    </div>
      <v-card>
        <v-card-title primary-title>
          RESPUESTA SUNAT:   
        </v-card-title>
        <v-card-text>
           {{seleccionado.mensajeSunat}}
        </v-card-text>
        <v-card-text >
        <v-row v-if="seleccionado.estado=='aprobado'">
            <v-col cols="4">  
                <v-btn outlined class="red--text" 
                @click="descargaXML">XML</v-btn> 
            </v-col>
            <v-col cols="4">  
                <v-btn outlined class="red--text" 
                @click="descargaCDR">CDR</v-btn> 
            </v-col>
            <v-col cols="4">  
                <v-btn outlined class="red--text" 
                @click="verPDF(seleccionado)">PDF</v-btn> 
            </v-col>
        </v-row>
        </v-card-text>
      </v-card>
  </v-dialog>

    <v-dialog
      v-model="dialogosetting"
      max-width="400"
      >
    <div>
      <v-system-bar window dark>
        <v-icon @click="dialogosetting = false">mdi-close</v-icon>
      </v-system-bar>
    </div>
      <v-card>
        <v-card-actions>
           <v-row>
            <v-col cols="6">  
              <v-btn
              block
              @click="reenvia(seleccionado)"
              color="success" >Reenviar</v-btn>
            </v-col>
            <v-col cols="6">  
                <v-btn
                block
                @click="consultar()"
                color="info" >Consultar</v-btn>
            </v-col>
            <v-col cols="6">  
                <v-btn
                block
                @click="bajaC(seleccionado)"
                color="info" >Baja</v-btn>
            </v-col>
           </v-row>
        </v-card-actions>
      </v-card>
  </v-dialog>

</v-container>
</template>
<script>
import { allCabecera,consultaDetalle,grabaconsultacomprobante,grabaDatoC,consulta_Cabecera } from '../../db'
import store from '@/store/index'
import { pdfGenera} from '../../pdf'
import { ejecutaresumen} from '../../resumenes'
import fecha from '@/components/dialogos/dialogFecha'
import fecha2 from '@/components/dialogos/dialogFecha2'
import moment from 'moment'
import {enviaDocumentoApiSunat, descargaXML,descargaCDR,consultasunat} from '../../servidorsunat'

  export default {
    components:{
      fecha,
      fecha2
    },

    data: () => ({
      dialogocomprobante:false,
      dialogoprogress:false,
      dialogosetting:false,
      documento : '',
      desserts: [ ],
      documentoEnvio:'',
      dialog:false,
      arrayConsolidar:[],
      buscar:'',
      date: moment(String(new Date)).format('YYYY-MM-DD'),
      date2: moment(String(new Date)).format('YYYY-MM-DD'),
      rechazados:0,
      img : '',
      error:'',
      seleccionado:'',
      num_doc:'',
      tipo_doc:'B'
    }),

     computed: {
        listafiltrada() {
        return this.desserts.filter((item)=>(item.numeracion)
                .toLowerCase().includes(this.num_doc.toLowerCase()))
        }
     },

    methods: {
      verPDF(item){
        store.commit("dialogoprogress")
          var arraydatos = []
          consultaDetalle(item.numeracion).once("value").then((snapshot) => {
                arraydatos = snapshot.val()
                if(snapshot.exists()){
                pdfGenera(arraydatos,item.hash,'',item)
                }
              store.commit("dialogoprogress")
            })          
      },
        consultaApisunat(item){
            this.seleccionado = item            
            this.arrayConsolidar = []
            this.dialogocomprobante=true           
        },
        comparafecha(date){
          var hoy = moment(String(new Date())).add(-5,'d').format('YYYY-MM-DD')
          var fecha = moment.unix(date).format('YYYY-MM-DD')    
          if(moment(fecha).isAfter(hoy)){
            return true
          }else{
            return false
          }
        },        
        conviertefecha(date){
            return moment.unix(date).format('DD/MM/YYYY hh:mm A')
        },
        sumaventas(){
          var array = {}
          var ventaboleta = 0
          var ventafactura = 0        
            for(var i=0;i<this.desserts.length;i++){
                if(this.desserts[i].tipocomprobante == 'B'){
                    ventaboleta = ventaboleta + parseFloat(this.desserts[i].total) - parseFloat(this.desserts[i].descuentos)      
                }
                if(this.desserts[i].tipocomprobante == 'F'){
                    ventafactura = ventafactura + parseFloat(this.desserts[i].total) - parseFloat(this.desserts[i].descuentos)      
                }                       
             }
            
            array ={
              boleta: this.redondear(ventaboleta),
              factura: this.redondear(ventafactura),
            }
            return array
      },
      ejecutaConsolida(value){
        store.commit("dialogoprogress")
          this.arrayConsolidar = []
                consultaDetalle(value).once("value").then((snapshot) => {
                   snapshot.forEach((item) => {
                       this.arrayConsolidar.push(item.val())
                   })            
                   store.commit("dialogoprogress")
                   this.dialog = true
                  })  
        },


        
      redondear(valor){
          return parseFloat(valor).toFixed(store.state.configuracion.decimal)
      },
    
    consultar(){
          store.commit("dialogoprogress")  
          if(this.seleccionado.tipocomprobante=="F"){
              var tipodoc = '01'
              var serie = this.seleccionado.serie
          }else{
              var tipodoc = '03'
              var serie = this.seleccionado.serie
          }
         // console.log(serie)
        consultasunat(tipodoc,serie,
        this.seleccionado.numeracion.substring(1)).then((r)=>{
          store.commit('dialogosnackbar',r)

          if(r.data=='0002'){
           grabaconsultacomprobante(this.seleccionado.numeracion,"RECHAZADO",r.hash)
           grabaDatoC(this.seleccionado.numeracion,"automata",'0002')
            this.dialogocomprobante=false           
          }
            if(r.data=='0001'){
           grabaconsultacomprobante(this.seleccionado.numeracion,"aprobado",r.hash)
           grabaDatoC(this.seleccionado.numeracion,"automata",'0001')
            this.dialogocomprobante=false
            }else{
          grabaconsultacomprobante(this.seleccionado.numeracion,"error",r.hash)
            this.dialogocomprobante=false
            }
            this.busca()
          store.commit("dialogoprogress")
          this.dialogosetting = false 
        })
    },
    editacomprobante(){
       //store.commit("dialogoprogress")
      //   <v-btn color="success" @click="editacomprobante()">text</v-btn>
       for(var i=0;i<this.desserts.length;i++){
         //grabaDatoC(this.desserts[i].numeracion,"automata","")
         // grabaconsultacomprobante(this.desserts[i].numeracion,"rechazado","El comprobante fue registrado previamente con otros datos - Detalle: xxx.xxx.xxx value='ticket: 202208635648110 error: El comprobante B001-00000094 fue informado anteriormente'")
        console.log( this.desserts[i].numeracion)
         if(this.desserts[i].tipocomprobante=="F"){
              var tipodoc = '01'
              var serie = this.desserts[i].serie
          }else{
              var tipodoc = '03'
              var serie = this.desserts[i].serie
          }
            this.consultamasiva(tipodoc,serie,this.desserts[i],this.desserts.length-1,i)

       }
   // grabaconsultacomprobante(this.desserts[0].numeracion,"rechazado","El comprobante fue registrado previamente con otros datos - Detalle: xxx.xxx.xxx value='ticket: 202208635648110 error: El comprobante B001-00000094 fue informado anteriormente'")
  //console.log(this.desserts[0].numeracion)
    },
    consultamasiva(tipodoc,serie,array,tam,pos){
          consultasunat(tipodoc,serie,
       array.numeracion.substring(1)).then((r)=>{
         console.log(r)
        // store.commit('dialogosnackbar',r.data)
            if(r.data=='0001'){
              grabaDatoC(array.numeracion,"automata",'0001')
           grabaconsultacomprobante(array.numeracion,"aprobado",r.hash)
        //    this.dialogocomprobante=false
            }else{
          grabaconsultacomprobante(array.numeracion,"RECHAZADO",r.hash)
          //  this.dialogocomprobante=false
            }
            if(tam==pos){
              //store.commit("dialogoprogress")
            this.dialogosetting = false
            }
        //  this.dialogoprogress=false   
        })
    },
    
    modificafechas(){
      for(var i=0;i<this.desserts.length;i++){
        console.log(this.desserts[i].numeracion)
     grabaDatoC(this.desserts[i].numeracion,"fecha",1656438412)
     grabaDatoC(this.desserts[i].numeracion,"vencimientoDoc",1656438412)
     //grabaDatoC(this.desserts[i].numeracion,"automata","no")
      }
    },
    asigna_color_doc(datas){
        let data = datas
        var color =''
            if(data.estado!='aprobado'|| data.estado!='ACEPTADO' ||
             data.estado!='PENDIENTE'||data.estado!='pendiente'){
              color = '#FF0000'
            }    
            if(data.estado=='aprobado'|| data.estado=='ACEPTADO'){
              color = '#46FF00'
            }  
            if(data.estado=='PENDIENTE'||data.estado=='pendiente'){
              color = '#FFB200'
            } 
            return color
      },
    }
  }
</script>