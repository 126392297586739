<template>
  <v-dialog v-model="dialog" max-width="460px" persistent>
    <div>
      <v-system-bar window dark>
        <v-icon large color="red" @click="cierra()">mdi-close</v-icon>
        <v-spacer></v-spacer>
        <v-icon large color="success" @click="guardar()">mdi-content-save</v-icon>
      </v-system-bar>
    </div>
    <v-card class="pa-2">
      <v-row class="mt-1" dense>
        <v-col cols="12">
          <v-textarea outlined dense v-model="qr_yape" auto-grow filled label=" YAPE" rows="1" append-icon="mdi-eye"
            @click:append="abre_qr(qr_yape)"></v-textarea>
        </v-col>
        <v-col cols="12" class="mt-n5">
          <v-textarea outlined dense v-model="num_yape" auto-grow filled label="NUM YAPE" rows="1"></v-textarea>
        </v-col>
      </v-row>
      <v-row class="mt-n5" dense>
        <v-col cols="12">
          <v-textarea outlined dense v-model="qr_plin" auto-grow filled label="QR PLIN" rows="1" append-icon="mdi-eye"
            @click:append="abre_qr(qr_plin)"></v-textarea>
        </v-col>
      </v-row>
      <v-row class="mt-n5" dense>
        <v-col cols="12">
          <v-textarea outlined dense v-model="bcp" auto-grow filled label="BCP" rows="1"></v-textarea>
        </v-col>
      </v-row>
      <v-row class="mt-n5" dense>
        <v-col cols="12">
          <v-textarea outlined dense v-model="bcp_i" auto-grow filled label="BCP INTERBANCARIA" rows="1"></v-textarea>
        </v-col>
      </v-row>
      <v-row class="mt-n5" dense>
        <v-col cols="12">
          <v-textarea outlined dense v-model="bbva" auto-grow filled label="BBVA" rows="1"></v-textarea>
        </v-col>
      </v-row>
      <v-row class="mt-n5" dense>
        <v-col cols="12">
          <v-textarea outlined dense v-model="bbva_i" auto-grow filled label="BBVA INTERBANCARIA" rows="1"></v-textarea>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="dialog_visualiza" max-width="460px">
      <div>
        <v-system-bar window dark>
          <v-icon large color="red" @click="dialog_visualiza = false">mdi-close</v-icon>
          <v-spacer></v-spacer>

        </v-system-bar>
      </div>
      <v-card class="pa-2">
        <h4></h4>
        <v-img :src="imagen"></v-img>
      </v-card>

    </v-dialog>
  </v-dialog>
</template>

<script>
import {
  nuevoModoPago_qr,
  allModoPago_qr
} from '../../db'
import store from '@/store/index'
import QR from 'qrcode-base64'
export default {

  data() {
    return {
      dialog: false,
      dialog_visualiza: false,
      qr_plin: '',
      qr_yape: '',
      imagen: '',
      bcp: '',
      bbva: '',
      bcp_i: '',
      bbva_i: '',
      num_yape: ''
    }
  },
  created() {
    this.inicia()
  },
  methods: {
    async inicia() {
      var snap = await allModoPago_qr().once('value')
      if (snap.exists()) {
        this.qr_plin = snap.val().PLIN
        this.qr_yape = snap.val().YAPE
        this.num_yape = snap.val().num_yape
        this.bbva = snap.val().BBVA
        this.bbva_i = snap.val().BBVA_I
        this.bcp = snap.val().BCP
        this.bcp_i = snap.val().BCP_I
      }
      this.dialog = true
    },
    async abre_qr(data) {

      this.imagen = await QR.drawImg(data, {
        typeNumber: 4,
        errorCorrectLevel: 'M',
        size: 500
      })
      this.dialog_visualiza = true
    },
    guardar() {
      nuevoModoPago_qr('YAPE', this.qr_yape)
      nuevoModoPago_qr('num_yape', this.num_yape)
      nuevoModoPago_qr('PLIN', this.qr_plin)
      nuevoModoPago_qr('BCP', this.bcp)
      nuevoModoPago_qr('BBVA', this.bbva)
      nuevoModoPago_qr('BCP_I', this.bcp_i)
      nuevoModoPago_qr('BBVA_I', this.bbva_i)

      this.cierra()
    },

    cierra() {
      this.$emit('cierra', false)
    }

  }

}
</script>
